import { configureStore} from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import organizationSlice from "./slice/organization-slice";
import emailVerificationSlice from "./slice/email-verification-slice";
import authSlice from "./slice/auth-slice";

const store = configureStore({
  reducer: {
    organization: organizationSlice,
    emailVerification: emailVerificationSlice,
    auth: authSlice
  },
});

export const useAppDispatch:()=>typeof store.dispatch=useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector;
export default store;
