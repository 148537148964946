import { toast } from "react-toastify";
import { TypeOptions, ToastOptions } from "react-toastify/dist/types";

const setToastMessage=(statusCode:number, toastMessage:string | null)=>{
  interface ErrorMessaage{
    [key:number]:string
  }

  // these are default error messages. It is used if a valid toast message is not passed when function is called.
  const defaultToastMessages:ErrorMessaage = {
    200:"Success",
    201:"Success",
    204:"Success",
    400:"Wrong Input provided",
    404:"Invaild request",
    403:"Access Denied. Not a vaild user",
    502:"Having technical issues. Please try after sometime"
  }
  if(statusCode === 502 || statusCode === 403){
    return defaultToastMessages[statusCode];
  }
  if(toastMessage && toastMessage.trim()?.length>0){
    return toastMessage;
  }else{
    // is error message for respective statuscode is not available in defaultToastMessages object then return "Something went wrong"
    return defaultToastMessages[statusCode] ?? statusCode>=400 ? "Something went wrong. Please try after sometime":"";
  }
}

export const toastMessage = (
  statusCode: number,
  toastMessage: string | null,
  toastType?: TypeOptions
) => {

  const config: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "colored",
  };
  
  toastMessage = setToastMessage(statusCode,toastMessage);
  
  let type: TypeOptions;
  switch (statusCode) {
    case 201:
    case 200:
    case 204:
      type = toastType ?? "success";
      break;
    case 400:
    case 403:
    case 404:
    case 502:
      type = "error";
      break;
    case -1:
      // If there is no httpStatusCode and still want to display toast message. 
      // When toastMessage function is called consider all three function argument are mandatory. 
      type = toastType ?? "info";
      break;
    default:
      type = toastType ?? statusCode>=400 ? "error" : "info";
  }

  console.log("type ", type);
  const showToast = (type: TypeOptions, message: string) => {
    if (type === "success") toast.success(message, config);
    if (type === "error") toast.error(message, config);
    if (type === "warning") toast.warning(message, config);
    if (type === "info") toast.info(message, config);
  };
  showToast(type, toastMessage);
};
