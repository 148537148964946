import "./App.scss";
import { observer } from "mobx-react-lite";
import { useAuthController } from "./app/core/controllers/auth/auth.controller";

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { LoginPageComponent } from "./app/pages/auth/login/login-page.component";
import SignupPageComponent from "./app/pages/auth/signup/signup-page.component";
import OrganizationComponent from "./app/pages/admin/organizations/organization.component";
import CandidatesComponent from "./app/pages/admin/candidates/candidates.component";
import { UserRole } from "./app/types/user.interface";
import AppHeaderComponent from "./app/components/app-header/app-header.component";
import SidebarComponent from "./app/components/sidebar/sidebar.component";
import ManageUserComponent from "./app/pages/customer/users/manage-user.component";
import LandingPageComponent from "./app/pages/common/dashboard/landing-page.component";
import AdminSubscription from "./app/pages/admin/subscriptions/admin-subscription.component";

import JobsList from "./app/pages/customer/jobs/jobs-list.component";
import AtsJobs from "./app/pages/customer/ats-jobs/ats-jobs.component";
import PublishJobs from "./app/pages/customer/ats-jobs/publish-jobs.component";
import OrganizationReportComponent from "./app/pages/admin/organizations/organization-report.component";
import AdminUsersComponent from "./app/pages/admin/users/admin-users.component";
import { useEffect, useState, useRef } from "react";
import SubscriptionPlans from "./app/pages/subscriptions/plans/subscription-plans";
import EmailVerification from "./app/pages/auth/email-verification/email-verification";
import { SubscriptionCheckout } from "./app/pages/subscriptions/checkout/subscription-checkout";
import { CheckoutPlan, OrganizationalPlans, CheckoutSuccess, unsubscribe } from "./app/core/constants";
import ViewOrganizationComponent from "./app/pages/admin/organizations/view-organization.component";
import SubscriptionSuccess from "./app/pages/subscriptions/success/subscription-success";
import { SubscriptionPaywall } from "./app/pages/subscriptions/paywall/subscription-paywall";
import { SubscriptionCheckWrapper } from "./app/components/common/Subscription-Check-Wrapper";
import OrganizationSubscriptionComponent from "./app/pages/customer/subscription/organization-subscription.component";
import AdminReportedUsersComponent from './app/pages/admin/reported-users/admin-reported-users.component';
import ReportedUserHistoryComponent from "./app/pages/admin/reported-users/reported-user-history.component";
import ReferralList from "./app/pages/admin/referral/referral-list";
import CandidateReferralsComponent from "./app/pages/admin/referral/candidate-referrals";
import { ForgotPasswordPageComponent } from "./app/pages/auth/forgot-password/forgot-password-page.component";
import CreateOrganization from "./app/pages/admin/organizations/createOrganization";
import AppFooter from "./app/components/app-footer/app-footer";
import ATSCandidatesComponent from "./app/pages/admin/ats-candidates/ats-candidates.component";
import JobsComponent from "./app/pages/admin/jobs/jobs.component";
import NsfwComponent from "./app/pages/admin/nsfw/nsfw.component";
import PricingHeaderComponent from "./app/components/app-header/pricing-header.component";
import { PricingFooter } from "./app/components/app-footer/pricing-footer.component";
import { UnSubscribeEmailComponent } from "./app/pages/common/unsubscribe/unsubscribe-email.component";


const App = () => {
  const location = useLocation();
  const sideNavRef = useRef<any>(null);
  const authController = useAuthController();
  const isLoggedIn = authController.isUserLoggedIn();
  const [openSlide, setOpenSlide] = useState(true);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const hasOrgSubscription = authController.isOrganizationSubscribed();

  const handleSidePanel = () => {
    setOpenSlide(!openSlide);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobileScreen(true);
      setOpenSlide(false);
    } else {
      setIsMobileScreen(false);
      setOpenSlide(true);
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (hasOrgSubscription && !isMobileScreen) {
      setOpenSlide(true);
    }else{
      setOpenSlide(false);
    }
  }, [hasOrgSubscription, isMobileScreen]);

  useEffect(() => {
    if (isMobileScreen) {
      setOpenSlide(false);
    }
  }, [location, isMobileScreen]);

  useEffect(() => {
    if (isMobileScreen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileScreen]);

  function handleClickOutside(event: any) {
    if (isMobileScreen && openSlide) {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
        setOpenSlide(false);
      }
    }
  }

  const headerAndSidebarComponent = () => {
    if (!isLoggedIn && [OrganizationalPlans].includes(url)) {
      return (
        <PricingHeaderComponent />
      );
    } else if (isLoggedIn && ![unsubscribe].includes(url)) {
      return (
        <>
          <AppHeaderComponent
            handleSidePanel={handleSidePanel}
            openSlide={openSlide}
            isMobileScreen={isMobileScreen}
            role={role}
          ></AppHeaderComponent>
          <div ref={sideNavRef}>
            <SidebarComponent
              isSidebarOpen={openSlide}
              isLoggedIn={isLoggedIn}
            ></SidebarComponent>
          </div>
        </>
      );
    } else {
      return (
        <></>
      );
    }
  }

  const getMainClassName = () => {
    let cls = "main secondary--bg ";
    if (isLoggedIn && ![unsubscribe].includes(url) && !isMobileScreen) {
      cls = cls + " layout ";
    }
    if (isLoggedIn && ![unsubscribe].includes(url)) {
      cls = cls + " main__layout ";
    }
    if (!openSlide && ([OrganizationalPlans, CheckoutPlan, CheckoutSuccess, unsubscribe].includes(url))) {
      cls = cls + " fullwidth ";
    }
    if (!isLoggedIn || [unsubscribe].includes(url)) {
      cls = cls + " main_loggedOut ";
    }
    return cls;
  }

  const url = window.location.pathname;
  const role = authController.role;
  
  return (
  
    <div>
      {headerAndSidebarComponent()}
      <main
        className={getMainClassName()}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<LoginPageComponent />} />
          <Route path="/signup" element={isLoggedIn ? <Navigate to="/dashboard"/> : <SignupPageComponent />} />
          <Route path="/forgot-password" element={isLoggedIn ? <Navigate to="/dashboard"/> : <ForgotPasswordPageComponent />} />
          <Route path="/organization-lead" element={<CreateOrganization />} />

          {role === UserRole.Admin && (
            <Route path="/admin-portal">
              <Route index path="dashboard" element={<LandingPageComponent />} />
              <Route path="organizations" element={<OrganizationComponent />} />
              <Route path="organization-report/:orgId" element={<OrganizationReportComponent />} />
              <Route path="organization-details/:orgId" element={<ViewOrganizationComponent />} />
              <Route path="admin-users" element={<AdminUsersComponent />} />
              <Route path="subscriptions" element={<AdminSubscription />}/>
              <Route path="candidates" element={<CandidatesComponent />} />
              <Route path="ats-candidates" element={<ATSCandidatesComponent />} />
              <Route path="jobs" element={<JobsComponent />} />
              <Route path="reported-users" element={<AdminReportedUsersComponent />} />
              <Route path="reported-user/history" element={<ReportedUserHistoryComponent />} />
              <Route path="referral" element={<ReferralList />} />
              <Route path="candidate-referral/:referralId" element={<CandidateReferralsComponent />} />
              <Route path="nsfw" element={<NsfwComponent />} />
            </Route>
          )}

          {(role === UserRole.Recruiter || role === UserRole.HRManager) && (
            <Route path="/customer-portal">
              <Route index path="dashboard" element={<SubscriptionCheckWrapper Component={LandingPageComponent}/>}/>
              <Route path="manage-user" element={<SubscriptionCheckWrapper Component={ManageUserComponent}/>} />
              <Route path="jobs" element={<SubscriptionCheckWrapper Component={JobsList}/>} />
              <Route path="ats-jobs" element={ <SubscriptionCheckWrapper Component={AtsJobs}/> } />
              <Route path="publish-jobs" element={<SubscriptionCheckWrapper Component={PublishJobs}/>} />
              <Route path="organization-subscription" element={<SubscriptionCheckWrapper Component={OrganizationSubscriptionComponent}/>} />
            </Route>
          )}
          
          <Route path="/subscriptions/paywall" element={<SubscriptionPaywall/>} />
          <Route path="/organizational-plans" element={<SubscriptionPlans/>} />
          <Route path="/email-verification" element={<EmailVerification/>} />
          <Route path="/subscriptions/checkout" element={<SubscriptionCheckout/>} />
          <Route path="/subscription/success" element={<SubscriptionSuccess/>} />
          <Route path="/unsubscribe" element={<UnSubscribeEmailComponent/>} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>

        {(!isLoggedIn) && [OrganizationalPlans].includes(url) ? (
          <PricingFooter />
        ) : (
          isLoggedIn && ![unsubscribe].includes(url) && <AppFooter isMobileScreen={isMobileScreen}/>
        )}
      </main>
    </div>

  );
};

export default observer(App);
