import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./terms-of-service.scss";

export function TermsOfService(props: any) {
  const [checked, setChecked] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const handleTermsOfService = () => {
    setShowTermsOfService(true);
  };

  const handlePrivacyPolicy = () => {
    setShowPrivacyPolicy(true);
  };

  const handleClose = () => {
    setShowTermsOfService(false);
    setShowPrivacyPolicy(false);
  };

  const handleChange = (event: any) => {
    setChecked(!checked);
    props.handleChange(event.target.checked)
  };

  
  return (
    <>
      <Box className="terms_of_service">
        <InputLabel size="normal" className="input_label" required sx={{fontSize:`${props?.fontSize} !important`}}>
          <Checkbox onChange={handleChange} checked={checked}/>I agree to the{" "}
          <button className="terms-and-policy" onClick={handleTermsOfService}> Terms Of Service </button> and{" "}
          <button className="terms-and-policy" onClick={handlePrivacyPolicy}> Privacy Policy </button>
        </InputLabel>
      </Box>
      <Dialog
        open={showTermsOfService || showPrivacyPolicy}
        PaperProps={{
          style: {
            padding: "14px 8px 20px",
            position: "absolute",
            margin: "auto",
            borderRadius: 10,
            width: "920px",
            height: 800,
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle
          id="alert-dialog-title"
          className="custom__title"
          component="div"
          sx={{ color: "#9f2223", fontFamily: 'Inter-Regular, sans-serif' }}
        >
          <div>{showTermsOfService ? "Terms Of Service" : "Privacy Policy"}</div>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{ color: "#9f2223" }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{overflow: "hidden"}}>
          {showTermsOfService && (
            <iframe
              title="Terms of Service"
              src="https://tangoapp.co/terms-of-service/"
              width="100%"
              height="800px"
              allow="fullscreen"
            />
          )}
          {showPrivacyPolicy && (
            <iframe
              title="Privacy Policy"
              src="https://tangoapp.co/privacy-policy/"
              width="100%"
              height="800px"
              allow="fullscreen"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
