import React from "react";
import dayjs from 'dayjs';
import { useSearchParams, Link } from "react-router-dom";
import { authorizationService } from "../../../core/services/authorization.service";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { apiService } from "../../../core/services/api.service";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import Avatar from "@mui/material/Avatar";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
import { IconButton, MenuItem, Select } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { candidateStatus} from "../../../core/constants";
import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";
import moment from "moment";
import { useAppSelector } from "../../../../store";

  interface Data {
    email: string;
    status: number;
    subscription: string;
    name: string;
    contact: string;
    createdAt: string;
  }
  
  type Order = 'asc' | 'desc';
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    sorting: boolean;
    numeric: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Email',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Status',
    },
    {
        id: 'contact',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Contact',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Created Date',
    },
  ];

  const rowSpan = 10;
  const colSpan = 6;

  export default function CandidatesComponent() {
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          Candidates
        </Typography>,
      ];
    let [searchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const initialFilterValue = {
      startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };
    const userDetails: any = useAppSelector(
      (state) => state.auth.userDetail
    );
    const isTokenAvailable: any = useAppSelector(
      (state) => state.auth.token
    );
    const limit = 100;
    const [candidates, setCandidates] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [candidateListLoading, setCandidateListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;
    const [status, setStatus] = React.useState('all');
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [confirmationType, setConfirmationType] = React.useState('');
    const [confirmationTitle, setConfirmationTitle] = React.useState('');
    const [confirmationMsg, setConfirmationMsg] = React.useState('');
    const [index, setIndex] = React.useState(-1);

    React.useEffect(() => {
      const candidatesPermissions = authorizationService.getPermissions(userDetails?.role, 'candidates', false, userDetails?.isSuperAdmin);
      setPermissions(candidatesPermissions);
    }, [userDetails]);

    React.useEffect(() => {
      setCandidateListLoading(true);
      const delayDebounceFn = setTimeout(() => {
        if (isTokenAvailable && permissions?.read) {
          getCandidatesList();
        }
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }, [page, order, orderBy, filters, searchTerm, status, isTokenAvailable, permissions])

    const getCandidatesList = async() => {
      let url = `/user/profile/candidates?take=${limit}&skip=${(page-1)*limit}&order=${order === 'asc'?1:-1}&orderBy=${orderBy}` ;
      if (searchTerm.trim() !== '') {
        url = `${url}&term=${searchTerm}`;
      }
      if (status && status !== "all") {
        url = `${url}&status=${status}`;
      }
      if (filters.startDate) {
        url = `${url}&startDate=${filters.startDate}`;
      }
      if (filters.endDate) {
          url = `${url}&endDate=${filters.endDate}`;
      }
      try{
      const { data } = await apiService.get(url);
      const candidateListData = data?.result;
      const count = data?.count;
      setCandidates(candidateListData);
      setPages(Math.ceil(count/limit));
      setCandidateListLoading(false);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const onDelete = (index:number, type:string) => {
      setIndex(index)
      setOpenConfirmation(true);
      setConfirmationType(type);
      if (type === 'assets') {
        setConfirmationTitle("Delete Assets");
        setConfirmationMsg("Are you sure you want to delete all assets of candidate profile including videos and pictures?");
      } else {
        setConfirmationTitle("Delete Profile");
        setConfirmationMsg("Are you sure you want to delete candidate profile?");
      }
    }

    const handleConfirmationClose = (value: number) => {
      setOpenConfirmation(false);
      setConfirmationType('');        
      setIndex(-1);
    };

    const handleConfirmationConfirm = (value: number) => {
      setCandidateListLoading(true);
      deleteCandidate(candidates[value])
    }

    const deleteCandidate = async (candidate: any) => {
      try{
          let url = `/user/${candidate._id}`;
          if (confirmationType === 'assets') {
            url = `${url}/archive`;
          }
          const response = await apiService.delete(url);
          getCandidatesList();
          let message:string =`Successfully deleted candidate profile`;
          if (confirmationType === 'assets') {
            message =`Successfully deleted all assets of candidate profile`;
          }
          toastMessage(response?.status, message, "success");
      }catch(err:any){
          let message:string =err?.response?.data?.message?err?.response?.data?.message:null;
          toastMessage(err?.response?.data?.statusCode, message);
      } finally {
        setOpenConfirmation(false);   
        setConfirmationType('');     
        setIndex(-1);
      }     
    }

    const onDownloadProfileVideo = (index: number) => {
      const candidate = candidates[index];
      const videoUrl = candidate?.candidate?.profileVideo?.publicUrl;
      if (videoUrl) {
        window.open(videoUrl, '_blank');
      } else {
        toastMessage(500, 'Profile video is not available!');
      }
    }

    const dateFormat = (date:any) => {
      if (date) {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
      }
    }

    const candidateList=()=>{
      if(candidates.length > 0){
        const candidatesList = candidates.map((row:any, index:number) => {
          return(
            <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="custom__cell__row"
                >
                <TableCell align="left" className="custom__cell">
                    <Avatar>
                        <PersonOutlineOutlinedIcon /> 
                    </Avatar>
                    <span>{row?.candidate?.name || row?.name}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.candidate?.email || row?.email}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.candidate?.status ? candidateStatus[row?.candidate?.status as keyof typeof candidateStatus] : candidateStatus[row?.status as keyof typeof candidateStatus]}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.candidate?.contact || row?.contact}</TableCell>
                <TableCell align="left" className="custom__cell__element">{dateFormat(row?.candidate?.createdAt || row?.createdAt)}</TableCell>
                <TableCell align="left" className="custom__cell__element action__icons d-flex">
                  <IconButton title="Download Profile Video" onClick={()=>onDownloadProfileVideo(index)}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton title="Delete Profile" onClick={()=>onDelete(index, 'profile')}>
                    <DeleteOutlineIcon />
                  </IconButton>
                  <IconButton title="Delete Assets" onClick={()=>onDelete(index, 'assets')}>
                    <FolderDeleteIcon />
                  </IconButton>
                </TableCell>
            </TableRow>
          )
      })
      return candidatesList;
      }else{
        return (<TableRow>
                  <TableCell colSpan={colSpan} className="no__data">
                  No Data Available !
                  </TableCell>
              </TableRow>)
      }
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setCandidateListLoading(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setCandidateListLoading(true);
      setPage(value)
    }

    const handleSearchChange = (value: string) => {
      if ((value && value.length > 3) || value.length === 0) {
        setPage(1);
        setCandidateListLoading(true);
        setSearchTerm(value);
      }
    };

    const handleFilterChange = (filterValues: any) => {
      setCandidateListLoading(false);
      setFilters(filterValues);
    }
  
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorFilter(anchorFilter ? null : event.currentTarget);
      setOpenFilter((previousOpen) => !previousOpen);
    }

    const handleStatusChange = (value: string) => {
      setCandidateListLoading(true);
      setPage(1);
      setStatus(value);
    };

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        Candidates
                    </div>
                    <div className="search__wrapper">
                      <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            className="custom__select__box"
                            defaultValue={status}
                            onChange={(event)=> handleStatusChange(event.target.value)} 
                            fullWidth
                        >
                          <MenuItem value="all">All</MenuItem>
                          {Object.keys(candidateStatus).map((status: string) => {
                            return (
                              <MenuItem key={status} value={status}>{candidateStatus[status as keyof typeof candidateStatus]}</MenuItem>
                            );
                          })}
                        </Select>
                        <TextField
                            id="search"
                            type="search"
                            name="new-search"
                            autoComplete='off'
                            placeholder="Search"
                            variant="outlined"
                            className="custom__search"
                            defaultValue={searchTerm}
                            onChange={(event: any) => handleSearchChange(event.target.value)}
                            sx={{ width: 350 }}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>
                </div>
                <div className="table__wrapper">
                  <TableContainer component={Paper} className="custom__table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        isAction = {true}
                        isSorting = {true}
                    />
                        <TableBody>
                            {candidateListLoading ? <TableSkeltonComponent rows={rowSpan} columns={colSpan} /> : candidateList()
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={colSpan}>
                                  <TablePagination count={pages} siblingCount={0} defaultPage={page} boundaryCount={2} className="custom__pagination" onChange={handleChange}/>
                                </TableCell>                                        
                            </TableRow>
                        </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
            </div> 
            {openFilter && <FilterPopupComponent 
              open={openFilter}
              popoverId={popoverId} 
              anchorEl={anchorFilter}
              filterState={filters}
              onlyDateFilter={true}
              onClose={handleToggleFilterPopup}
              onApplyFilter={handleFilterChange}
            />}  
            {openConfirmation && <ConfirmationPopupComponent
              open={openConfirmation}
              index={index}
              title={confirmationTitle}
              message={confirmationMsg}
              btnLabel="Delete"
              onClose={handleConfirmationClose}
              onConfirm={handleConfirmationConfirm}
            />}
        </>
    );
  }
