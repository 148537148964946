import React from "react";
import './app-header.scss';
import logo from '../../../assets/tango_logo.svg';
import { UserRole } from "../../types/user.interface";
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import avatarImage from '../../../assets/hr-profile.svg';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Avatar from "@mui/material/Avatar";
import { authController } from "../../core/controllers/auth/auth.controller";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchUserDetails } from "../../../store/slice/auth-slice";
import { fetchMyOrganizationDetails } from "../../../store/slice/organization-slice";

export default function AppHeaderComponent({handleSidePanel, openSlide, isMobileScreen, role}:any){
    const dispatch = useAppDispatch();
    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );
    const organizationDetails : any = useAppSelector(
        state => state.organization.myOrganization
    );
    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isLoggedIn = authController.isUserLoggedIn();

    React.useEffect(() => {
        if (isTokenAvailable && (!organizationDetails || organizationDetails === null) && (role === UserRole.Recruiter || role === UserRole.HRManager)) {
            dispatch(fetchMyOrganizationDetails());
        }
    }, [dispatch, role, isTokenAvailable, organizationDetails]);

    React.useEffect(()=>{
        if(isTokenAvailable && (!userDetails || userDetails === null)){
            setTimeout(() => dispatch(fetchUserDetails(null)), 1000);
        }

    },[dispatch, isTokenAvailable, userDetails])

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogoutClcik = () => {
        authController.logout();
    }
    

        return (
            <>
            <div className={'app-header ' + (isLoggedIn ?  'light--header':'dark--header primary--bg')}>
                    <div className="brand with_menu">
                        {isMobileScreen && <div>
                            {openSlide ? 
                                <ArrowBackIosIcon className="menu_icon" onClick={handleSidePanel}/>
                            : 
                                <MenuIcon className="menu_icon" onClick={handleSidePanel}/> 
                            }
                        </div>}
                        <img src={logo} alt="brand-logo" />
                    </div>
                    <div className="username">
                        {/* <span>{profileDetail.email}</span> */}
                        {/* <IconButton className="icons">
                            <SearchIcon />
                        </IconButton>
                        <IconButton className="icons icon-padd">
                            <NotificationsActiveIcon />
                        </IconButton> */}
                        <Avatar 
                            className="avatar__image" 
                            sx={{ width: 60, height: 60 }} 
                            src={avatarImage} 
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        />
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                            'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <div className="padd-10 custom__menu">
                                <div>
                                    {userDetails?.name}
                                </div>
                                <div className="font14">
                                    {userDetails?.email}
                                </div>
                                <button className="logout__btn" onClick={onLogoutClcik} >
                                    <LogoutOutlinedIcon/> <span className="text">Log Out</span> 
                                </button>
                            </div>
                        </Menu>
                    </div>
            </div>
            </>
        );
    }