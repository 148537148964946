import React from 'react'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Skeleton } from "@mui/material";

export default function TableSkeltonComponent(props: any) {
    let rowKeys:number[]=[];
    let colKeys:number[]=[];
    for(let row=0; row<props.rows; row++){
        rowKeys.push(row);
    }
    for(let col=0; col<props.columns; col++){
        colKeys.push(col);
    }
    return (
        <>
            {[...Array(props.rows)].map((value, index) => {
                return (
                    <TableRow key={rowKeys[index]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className="custom__cell__row">
                        {[...Array(props.columns)].map((value, index) => {
                            return (<TableCell key={colKeys[index]} align="left" className="custom__cell__element">
                                <Skeleton animation="wave" variant="text" />
                            </TableCell>)
                        })}
                    </TableRow>
                )
            })}
        </>
    )
}