import React from "react";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { apiService } from "../../../core/services/api.service";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
import { IconButton, MenuItem, Select } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from "moment";
import { useAppSelector } from "../../../../store";
import { authorizationService } from "../../../core/services/authorization.service";
import JobDetailsPopupComponent from "../../../components/dialogs/job-details/job-details.component";

  interface Data {
    title: string;
    postedBy: string;
    status: string;
    organization: string;
    createdAt: string;
    jobLikesCount: string;
    candidatesLikesCount: string;
    matchesCount: string;
    connectionsCount: string;
  }
  
  type Order = 'asc' | 'desc';
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    sorting: boolean;
    numeric: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Title',
    },
    {
        id: 'postedBy',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Posted By',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Status',
    },
    {
        id: 'organization',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Organization',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Posted At',
    },
    {
        id: 'jobLikesCount',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Job Likes',
    },
    {
        id: 'candidatesLikesCount',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Candidate Likes',
    },
    {
        id: 'matchesCount',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Matches',
    },
    {
        id: 'connectionsCount',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Connections',
    },
  ];

  const rowSpan = 10;
  const colSpan = 10;

  export default function JobsComponent() {
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          Jobs
        </Typography>,
    ];
    const userDetails: any = useAppSelector(
      (state) => state.auth.userDetail
    );
    const isTokenAvailable: any = useAppSelector(
      (state) => state.auth.token
    );
    const initialFilterValue: any = {
      startDate: null,
      endDate: null,
      org: null
    };
    const limit = 100;
    const [jobs, setJobs] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [selectedJobDetails, setSelectedJobDetails] = React.useState(null);
    const [jobsListLoading, setJobsListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [status, setStatus] = React.useState('all');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
    const [openJobDetailPopup, setOpenJobDetailPopup] = React.useState(false);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    React.useEffect(() => {
      const candidatesPermissions = authorizationService.getPermissions(userDetails?.role, 'jobs', false, userDetails?.isSuperAdmin);
      setPermissions(candidatesPermissions);
    }, [userDetails]);

    React.useEffect(() => {
      setJobsListLoading(true);
      const delayDebounceFn = setTimeout(() => {
        if (isTokenAvailable && permissions?.read) {
          getJobs();
        }
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }, [page, order, orderBy, filters, searchTerm, status, isTokenAvailable, permissions])

    const getJobs = async() => {
      let url = `/employment?take=${limit}&skip=${(page-1)*limit}&order=${order === 'asc'?1:-1}&orderBy=${orderBy}` ;
      if (searchTerm.trim() !== '') {
        url = `${url}&term=${searchTerm}`;
      }
      if (status && status !== "all") {
        url = `${url}&status=${status}`;
      }
      if (filters.startDate) {
        url = `${url}&startDate=${filters.startDate}`;
      }
      if (filters.endDate) {
          url = `${url}&endDate=${filters.endDate}`;
      }
      if (filters?.org?._id) {
        url = `${url}&organizationId=${filters.org._id}`;
      }
      try{
      const { data } = await apiService.get(url);
      const jobsListData = data?.result;
      const count = data?.count;
      setJobs(jobsListData);
      setPages(Math.ceil(count/limit));
      setJobsListLoading(false);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const getUserNameOrEmail = (user: any) => {
      if (!user || user?.name === null || user?.name === undefined) {
        return "Hiring Manager Not Yet Determined";
      } else if (user?.name) {
        return user?.name;
      } else {
        return <span className="email">{user?.email}</span>
      }
    }

    const jobList=()=>{
      if(jobs.length > 0){
        const jobList = jobs.map((row:any, index:number) => {
          return(
            <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="custom__cell__row"
                >
                <TableCell align="left" className="custom__cell__element">
                    <span>{row?.title}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">
                  {getUserNameOrEmail(row?._user)}
                </TableCell>
                <TableCell align="left" className="custom__cell__element textCapitalize">{row?.status === "closed" ? "Archived" : row?.status}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?._org?.name}</TableCell>
                <TableCell align="left" className="custom__cell__element">{dateFormat(row?.createdAt)}</TableCell>
                <TableCell align="left" className="custom__cell__element">{cardsDataCount(row?.cardsData, 'jobLikesCount')}</TableCell>
                <TableCell align="left" className="custom__cell__element">{cardsDataCount(row?.cardsData, 'candidatesLikesCount')}</TableCell>
                <TableCell align="left" className="custom__cell__element">{cardsDataCount(row?.cardsData, 'matchesCount')}</TableCell>
                <TableCell align="left" className="custom__cell__element">{cardsDataCount(row?.cardsData, 'connectionsCount')}</TableCell>
                <TableCell align="left" className="custom__cell__element action__icons d-flex">
                  <IconButton title="Job Details" onClick={()=>handleJobDetailPopup(row)}>
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </TableCell>
            </TableRow>
          )
      })
      return jobList;
      }else{
        return (<TableRow>
                  <TableCell colSpan={colSpan} className="no__data">
                  No Data Available !
                  </TableCell>
              </TableRow>)
      }
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setJobsListLoading(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setJobsListLoading(true);
      setPage(value)
    }

    const handleSearchChange = (value: string) => {
      if ((value && value.length > 3) || value.length === 0) {
        setPage(1);
        setJobsListLoading(true);
        setSearchTerm(value);
      }
    };

    const handleStatusChange = (value: string) => {
        setJobsListLoading(true);
        setPage(1);
        setStatus(value);
    };

    const handleFilterChange = (filterValues: any) => {
        setJobsListLoading(false);
      setFilters(filterValues);
    }
  
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorFilter(anchorFilter ? null : event.currentTarget);
      setOpenFilter((previousOpen) => !previousOpen);
    }

    const dateFormat = (date:any) => {
        if (date) {
            let currentDate = moment(date).format('DD-MMM-YYYY');
            return currentDate;
        }
    }

    const cardsDataCount = (cardsData:any, cardType:string) => {
      let count = 0;
      if (cardsData.length) {
        const card = cardsData.find((e:any) => e.id === cardType);
        count = card ? card.value : 0;
      }
      return count;
    }

    const handleJobDetailPopup = (job: any) => {
      setSelectedJobDetails(job);
      setOpenJobDetailPopup(true);
    }

    const handlePopupClose = () => {
      setSelectedJobDetails(null);
      setOpenJobDetailPopup(false);
    };

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        Jobs
                    </div>
                    <div className="search__wrapper">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            className="custom__select__box"
                            defaultValue={status}
                            onChange={(event)=> handleStatusChange(event.target.value)} 
                            fullWidth
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="draft">Draft</MenuItem>
                            <MenuItem value="closed">Archived</MenuItem>
                        </Select>
                        <TextField
                            id="search"
                            type="search"
                            name="new-search"
                            autoComplete='off'
                            placeholder="Search"
                            variant="outlined"
                            className="custom__search"
                            defaultValue={searchTerm}
                            onChange={(event: any) => handleSearchChange(event.target.value)}
                            sx={{ width: 350 }}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>
                </div>
                <div className="table__wrapper">
                  <TableContainer component={Paper} className="custom__table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        isAction = {true}
                        isSorting = {true}
                    />
                        <TableBody>
                            {jobsListLoading ? <TableSkeltonComponent rows={rowSpan} columns={colSpan} /> : jobList()
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={colSpan}>
                                  <TablePagination count={pages} siblingCount={0} defaultPage={page} boundaryCount={2} className="custom__pagination" onChange={handleChange}/>
                                </TableCell>                                        
                            </TableRow>
                        </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
            </div> 
            {openFilter && <FilterPopupComponent 
              open={openFilter}
              popoverId={popoverId} 
              anchorEl={anchorFilter}
              filterState={filters}
              onlyDateFilter={true}
              organizationFilter={true}
              onClose={handleToggleFilterPopup}
              onApplyFilter={handleFilterChange}
            />}
            {openJobDetailPopup && 
              <JobDetailsPopupComponent 
                  open={openJobDetailPopup}
                  jobDetails={selectedJobDetails}
                  onClose={handlePopupClose}
              />
            }  
        </>
    );
}
