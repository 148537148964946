import Carousel from "react-multi-carousel";
import { atsPartners, reactPublicS3BucketUrl } from "../../../core/constants";
import "react-multi-carousel/lib/styles.css";
import "./AtsPartners.component.scss";

export function AtsPartners() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      slidesToSlide: 7, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
      slidesToSlide: 4, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  return (
    <div className="parent">
      <div className="ats_header">40+ ATS SYSTEMS</div>
      <Carousel
        responsive={responsive}
        autoPlay={false}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={false}
        dotListClass=""
        arrows={true}
      >
        {atsPartners?.map((partner) => {
          return (
            <div className="slider" key={`${partner?.name}`}>
              <img src={`${reactPublicS3BucketUrl}${partner?.url}`} alt={partner?.name} title={partner?.name} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
