import React, { useCallback } from "react";
import './sidebar.scss';
import { Skeleton, Icon } from "@mui/material";
import { authController } from "../../core/controllers/auth/auth.controller";
import { authorizationService } from "../../core/services/authorization.service";
import { AdminMenu, UsersMenu } from "../../core/constants";
import { UserRole } from "../../types/user.interface";
import { useAppDispatch, useAppSelector } from "../../../store";
import { NavLink } from "react-router-dom";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { fetchMyOrganizationDetails } from "../../../store/slice/organization-slice";
import { useMergeLink } from "@mergeapi/react-merge-link";

import { apiService } from "../../core/services/api.service";
import { toastMessage } from "../../core/services/toast";

export default function SidebarComponent(props:any) {
    const dispatch = useAppDispatch();
    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );
    const organizationDetails : any = useAppSelector(
        state => state.organization.myOrganization
    );
    const isLoggedIn = props.isLoggedIn;
    const role = authController.role
    const [usersMenuItems, setUsersMenuItems] = React.useState<any>([]);
    const [adminMenuItems, setAdminMenuItems] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(true);
    const [isATS,setIsATS] = React.useState(-1);
    const [linkToken, setLinkToken] = React.useState('');

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );

    React.useEffect(()=>{
        if(isTokenAvailable && (!organizationDetails || organizationDetails === null) && (role === UserRole.Recruiter || role === UserRole.HRManager)){
            dispatch(fetchMyOrganizationDetails());
        }
        if(isTokenAvailable){
            if(role === UserRole.Recruiter || role === UserRole.HRManager){
                setAtsSession();
            }else{
                setIsATS(0) 
            }
        }      
    },[organizationDetails])

    React.useEffect(() => {
        if (userDetails) {
            setTimeout(() => createMenuItems(), 2000);
        }
    }, [userDetails]);

    const setAtsSession = () => {
        if(organizationDetails?.ats?._id){
            setIsATS(1)
        }else{
            setIsATS(0) 
        }
    };

    const onSuccess = useCallback(async(public_token:string) => {
        // Send public_token to server (Step 3)
        try{
        let url = '/ats/integration/account-token';
            const { data } = await apiService.post(url,{publicToken: public_token});
            if(data){
                dispatch(fetchMyOrganizationDetails());
                setTimeout(() => {
                    setAtsSession();
                }, 2000)
            }
        }catch(err:any){
            toastMessage(err.response.data.statusCode, null);
        }
    }, []);

       
    const { open } = useMergeLink({
        linkToken: linkToken, // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
        onSuccess,
        // tenantConfig: {
        // apiBaseURL: "https://api-eu.merge.dev" /* OR your specified single tenant API base URL */
        // },
    });


    const openATS = async () => {
      try{
        if(isLoggedIn && !isATS){
            let url = '/ats/integration/create-link-token';
            const { data } = await apiService.atsPost(url);
            if(data){
                setLinkToken(data.linkToken)
                open();
            }
        }
      }catch(err:any){
        toastMessage(err.response.data.statusCode, null);
      }
    }

    const createMenuItems = () => {
        const usersMenuItem = UsersMenu.map((data: any, index: number)=>{
            const permission = authorizationService.getPermissions(userDetails?.role, data.name, userDetails?.isPoc);
            let returnValue;
            if (index === UsersMenu.length - 1) {
                setLoading(false)
                returnValue = false;
            }
            if (permission?.read) {
                returnValue =  (
                    <li className="menu__item" key={data.id}>
                        <NavLink to={data.url}><Icon>{data.icon}</Icon> <span className="text">{data.label}</span> </NavLink>
                    </li>
                )
            }
            return returnValue;
        });
        setUsersMenuItems(usersMenuItem)
    
        const adminMenuItem = AdminMenu.map((data: any, index: number)=>{
            const permission = authorizationService.getPermissions(userDetails?.role, data.name, false, userDetails?.isSuperAdmin);
            let returnValue;
            if (index === AdminMenu.length - 1) {
                setLoading(false)
                returnValue = false;

            }
            if (permission?.read) {
                returnValue =  (
                    <li className="menu__item" key={data.id}>
                        <NavLink to={data.url}><Icon>{data.icon}</Icon> <span className="text">{data.label}</span> </NavLink>
                    </li>
                )
            }
            return returnValue;
        });
        setAdminMenuItems(adminMenuItem)
    }

    const atsPermission = authorizationService.getPermissions(userDetails?.role, 'connectATS', userDetails?.isPoc);
    const keys = [0,1,2,3,4,5,6,7,8,9,10];

    const menuItems=()=>{
        if(role === UserRole.Recruiter  || role === UserRole.HRManager){
            return(<ul className="menu">
            {usersMenuItems}
            {isLoggedIn && atsPermission && atsPermission.read && (<li className={isATS?"disabled menu__item":"menu__item"}>
                <div onClick={openATS}>
                    <InsertLinkOutlinedIcon /> <span className="text">Connect ATS</span>
                </div>
            </li>)}
        </ul>)
        }else{
            return (<ul className="menu">
            {adminMenuItems}
        </ul>)
        }
    }

    return (
        
        <div className={`sidebar primary--bg ${props.isSidebarOpen ? 'show' : 'hide'}`}>
            <div className="sidebar__menu_wrapper">
                {loading ? 
                <ul className="menu">
                    {[...Array(8)].map((value, index) => {
                        return (
                            <Skeleton
                                width={"90%"}
                                height={46}
                                key={keys[index]}
                                animation="wave"
                                variant="rectangular"
                                sx={{margin: "5px 0px 5px 12px", padding: "5px 0px"}}
                            />
                        );
                    })}
                </ul>
                :
                menuItems()
                }
                 
            </div>
            <div className="sidebar__btn">
                {isATS && (role === UserRole.Recruiter || role === UserRole.HRManager) && (<div className="ats">
                    <div>Connected ATS</div>
                    <div>
                        <img src={organizationDetails?.ats?.image} alt={organizationDetails?.ats?.name} className="ats__image" />
                    </div>
                </div>)
                }
            </div>
        </div>
        
    );
    
}