import React from "react";

import './admin-users.scss';
import { authorizationService } from "../../../core/services/authorization.service";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Avatar from "@mui/material/Avatar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import { apiService } from "../../../core/services/api.service";
import AdminFormComponent from "../../../components/forms/admin-form/admin-form.component";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import { toastMessage } from "../../../core/services/toast";

const inviteSchema = Yup.object().shape({
  email: Yup.string().trim('The email cannot include leading and trailing spaces').strict(true).email('Invalid Email').required('Email is required').matches(/tangoapp.co$/, 'Only tangoapp.co domain is allowed')
})

interface Data {
  email: string;
  status: string;
  name: string;
  role: string;
  contact: string;
}
  
type Order = 'asc' | 'desc';
  
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  isWidth: boolean,
}
  
const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    isWidth: true,
    label: 'Name',
  },
  {
    id: 'contact',
    numeric: false,
    disablePadding: false,
    isWidth: true,
    label: 'Contact',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    isWidth: false,
    label: 'Email',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    isWidth: false,
    label: 'Role',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    isWidth: false,
    label: 'Status',
  }
];

export default function AdminUsersComponent() {
  const breadcrumbs = [
      <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
        Home
      </Link>,
      <Typography key="2" color="text.primary" className="active_menu">
        Users
      </Typography>,
    ];
  const userDetails: any = useAppSelector(
    (state) => state.auth.userDetail
  );
  const itemsPerPage=10;
  const [users, setUsers] = React.useState([]);
  const [permissions, setPermissions] = React.useState<any>(null);
  const [searchTerm, setSearchTerm] = React.useState('')
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [id, setId] = React.useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});

  React.useEffect(() => {
    const usersPermissions = authorizationService.getPermissions(userDetails?.role, 'users', false, userDetails?.isSuperAdmin);
    setPermissions(usersPermissions);
  }, [userDetails]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (permissions?.read) {
        getUserList();
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [itemsPerPage, page, order, orderBy, permissions, searchTerm])

  const getUserList = async () => {
    try{
      const userList = await fetchUserList()
      setUsers(userList)
    }catch(err:any){

    }
    setIsLoaded(true)
  }

  const fetchUserList = async () => {
    try{
      setIsLoaded(false)
      let url = `/user?take=${itemsPerPage}&skip=${(page-1)*itemsPerPage}&order=${order === 'asc' ? 1 : -1}&orderBy=${orderBy}&name=${searchTerm}` ;
      const { data } = await apiService.get(url)
      const { result = [], count = [{count: 0}] } = data.length ? data[0] : [];
      const totalPages = Math.ceil(((count.length ? count[0]?.count : 0) || 0)/itemsPerPage);
      setCount(totalPages);
      setIsLoaded(true)
      return result;
    }catch(err:any){
      toastMessage(err.response.data.statusCode,null);
    }
  }

  const formik = useFormik({
    initialValues: {
        email: ''
    },
    validationSchema: inviteSchema,
    onSubmit: async(values,{resetForm}) => {
      if(formik.isValid){
        sendInvite(values.email, false);
      }
    }
  })

  const sendInvite = async (email:string, resend:boolean) => {
    let message:string = "";
    try {
      setIsLoaded(false)
      if(resend){
        let url = `/user/admin/resend/invitation`;
        await apiService.put(url, {email});
      } else {
        let url = '/user/invite/admin';
        await apiService.post(url, {email});
      }
      setIsLoaded(true)
      message = `Invitation sent successfully to ${email}`;
      toastMessage(200, message);
      setOpen(false);
    }catch(error:any){
      let message = "";
      if(error.response.data.statusCode === 400){
          message = error.response.data.message
      }else{
          message = "Invitation sent failed"
      }
      toastMessage(error.response.data.statusCode, message, "error");
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleEditClick = async (id:string) => {
    try{
    let url = `/user/${id}` ;
    const { data } = await apiService.get(url)
    setEditData(data)
    setEditOpen(true);
    }catch(err:any){
      toastMessage(err.response.data.statusCode,null);
    }
  }

  const updateUserProfile = async (id:string, values:any) => {
    let message:string ="";
    try{
      setIsLoaded(false)
      let url = `/user/${id}`;
      const { data , status} = await apiService.put(url, values);
      setIsLoaded(true)
      message = `${data?.name} successfully updated.`;
      toastMessage(status, message, "success");
      setEditOpen(false);
      getUserList();
    }catch(error:any){
        let message = "";
        if(error.response.data.statusCode === 400){
            message = error.response.data.message
        }else{
            message = "Update failed."
        }
        setIsLoaded(true)
        toastMessage(error.response.data.statusCode,message);
    }
  }

  const handleConfirmationClickOpen = (id:string) => {
    setId(id)
    setOpenConfirmation(true);
  };

  const handleConfirmationClose = async (id: string) => {
    let message:string = "";
    try {
      setIsLoaded(false)
      let url = `/user/${id}`;
      const { data, status } = await apiService.delete(url);
      setIsLoaded(true)
      message = `${data.email} deleted successfully`;
      toastMessage(status, message)
      getUserList();
    } catch(error:any) {
      let message = "";
      if(error.response.data.status === 400){
          message = `Deletion Failed: ${error.response.data.message}`;
      }else{
          message = `Deletion Failed`;
      }
      toastMessage(error.response.data.statusCode,message)
    }
    setIsLoaded(true)
    setOpenConfirmation(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
    
  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleSearch = (value: string) => {
    if ((value && value.length > 3) || value.length === 0) {
      setPage(1);
      setIsLoaded(false);
      setSearchTerm(value);
    }
  }

  const showUsers=()=>{
    if(users.length > 0){
      const usersList= users?.map((row:any, index:number) => {
        return(
          <TableRow
            key={row._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className="custom__cell__row">
            <TableCell align="left" className="custom__cell width70">
              <Avatar>
                <PersonOutlineOutlinedIcon /> 
              </Avatar>
              <span>{row.name}</span>
            </TableCell>
            <TableCell align="left" className="custom__cell__element">{row.contact}</TableCell>
            <TableCell align="left" className="custom__cell__element">{row.email}</TableCell>
            <TableCell align="left" className="custom__cell__element">{row.role}</TableCell>
            <TableCell align="left" className="custom__cell__element">
              {row.status}           
            </TableCell>
            <TableCell align="left" className="custom__cell__element action__icons">
              {permissions?.delete && <IconButton onClick={()=>handleConfirmationClickOpen(row._id)} title="Delete">
                <DeleteOutlineIcon />
              </IconButton>}
              {permissions?.edit && <IconButton title="Edit" onClick={() => handleEditClick(row._id)}>
                <DriveFileRenameOutlineIcon />
              </IconButton>}
              {
                row.status === 'invited' && permissions?.resendInvite && 
                <IconButton onClick={()=>sendInvite(row.email, true)} title="Resend Invite">
                  <SendOutlinedIcon />
                </IconButton>
              }
            </TableCell>
          </TableRow>
        )
      }) 

      return usersList;
    }else{
      return <TableRow>
                <TableCell colSpan={6} className="no__data">
                  No Data Available !
                </TableCell>
              </TableRow>
    }
  }

  const usersList=()=>{
    return !isLoaded?<TableSkeltonComponent rows={10} columns={6} />:showUsers();
  }
  const prevBtn = (props:any)=>{
    return <Button startIcon={<ArrowBackIcon />} {...props} className="pagination__buttons">Prev</Button>
  }
  const nextBtn=(props:any)=>{
    return <Button endIcon={<ArrowForwardIcon />}  {...props} className="pagination__buttons">Next</Button>
  }
  
  return (
    <>
      <div className="dashboard__header">
        <div className="org__details">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard__wrapper">
          <div className="heading">
            Users
          </div>
          <div className="search__wrapper">
            <TextField
              id="search"
              type="search"
              name="new-search"
              autoComplete='off'
              placeholder="Search by name"
              variant="outlined"
              className="custom__search"
              defaultValue={searchTerm}
              onChange={(event) => handleSearch(event.target.value)}
              sx={{ width: 350 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }} />
            <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button">Filter</Button>
            {permissions?.add && <Button variant="contained" className="custom__button custom__create__button"  onClick={handleClickOpen}>Add User</Button>}
          </div>
        </div>
        <div className="table__wrapper">
          <TableContainer component={Paper} className="custom__table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
                isAction = {true} 
                isSorting = {true}/>
              <TableBody>
                {usersList()}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Pagination
                      color="primary"
                      shape="rounded"
                      count={count} 
                      siblingCount={0} 
                      defaultPage={page} 
                      boundaryCount={2} 
                      className="custom__pagination"
                      page={page}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: (props) => prevBtn(props),
                            next: (props) => nextBtn(props),
                          }}
                          {...item} />
                      )} />
                  </TableCell>                                        
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>                
        </div>
        <Dialog
          open={editOpen}
          onClose={()=>{setEditOpen(false)}}
          PaperProps={{
            style: {
              minWidth: 450,
              padding: "15px 20px",
              borderRadius: 6
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
            <div>
              Edit User
            </div>
            <IconButton aria-label="close" onClick={()=>{setEditOpen(false)}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <AdminFormComponent onSuccess={updateUserProfile} data={editData} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              minWidth: 450,
              padding: "15px 20px",
              borderRadius: 6
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
            <div>
              Invite User
            </div>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="user__form">
            <form onSubmit={formik.handleSubmit}>
              <div className="input__items">
                <InputLabel size="normal" className="input__label" focused>Email address</InputLabel>
                <TextField 
                  id="email" 
                  name="email" 
                  type="email" 
                  variant="outlined" 
                  placeholder="Email" 
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  fullWidth />
                {
                  (formik.touched.email && formik.errors.email) &&
                  (<FormHelperText id='email-error-text' className="error_message">{formik.errors.email}</FormHelperText>)
                }
              </div>
              <div className="action__btn">
                  <button type="submit">Invite</button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        <ConfirmationPopupComponent
          open={openConfirmation}
          index={id}
          title="Delete"
          btnLabel="Delete"
          message="Do you want to delete user?"
          onClose={()=>{setOpenConfirmation(false)}}
          onConfirm={handleConfirmationClose} />
      </div>    
    </>
  );
}