import React from "react";
import "../../common/dashboard/landing-page.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { apiService } from "../../../core/services/api.service";
import { toastService } from '../../../core/services/toast.service'
import { TypeOptions } from 'react-toastify/dist/types';
import { userDashboardCharts} from "../../../core/constants";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Skeleton } from "@mui/material";
import Button from '@mui/material/Button';
import CountCard from "../../../components/common/dashboard/count-card.component";
import ChartCard from "../../../components/common/dashboard/chart-card-component";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import { useAppSelector } from "../../../../store";
import { toastMessage } from "../../../core/services/toast";

const initialFilterValue = {
    frequency: 'monthly',
    frequencyType: 'YTD',
    startDate: dayjs(dayjs().startOf('year')).format("YYYY-MM-DD") ,
    endDate: dayjs(dayjs().endOf('year')).format("YYYY-MM-DD")
}

export default function OrganizationReportComponent() {
    const navigate = useNavigate();
    let { orgId } = useParams();
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Link key="2" color="inherit" to="/admin-portal/organizations" className="inactive_menu">
          Organizations
        </Link>,
        <Typography key="3" color="text.primary" className="active_menu">
          Organizations Report
        </Typography>,
    ];

    const [loading, setLoading] = React.useState(false);
    const [userStats, setUserStats] = React.useState([]);
    const [charts, setCharts] = React.useState<any>(null);
    const [organizationDetails, setOrganizationDetails] = React.useState<any>(null);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement >(null);
    const [open, setOpen] = React.useState(false);
    const canBeOpen = Boolean(anchorEl);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );
    const keys=[0,1,2,3];

    React.useEffect(()=>{
        if(isTokenAvailable){
            if (orgId && orgId !== undefined) {
                getOrganizationDetails();
                setLoading(true);
                setTimeout(() => {
                    fetchData();
                }, 2000);
            } else {
                let status: TypeOptions = "error";
                let message: string = "Something went wrong";
                toastService.showToastNotification(status, message);
                navigate(`/admin-portal/organizations`);
            }
        }
    },[isTokenAvailable])

    React.useEffect(()=>{
        setLoading(true);
        isTokenAvailable && fetchData();
    },[filters,isTokenAvailable])

    const fetchData = async() => {
        try{
            let stats: any = [];
            let charts: any = [];
            const url = `/user/org-report?startDate=${filters.startDate}&endDate=${filters.endDate}&frequency=${filters.frequency}&orgId=${(orgId && orgId !== undefined?orgId:'')}`;
            const { data } = await apiService.get(url);
            data.cards.forEach((item: any) => {
                stats.push({
                    id: item.id,
                    title: item.label,
                    count: item.value
                });
            });
            Object.keys(userDashboardCharts).forEach((key: string, index: number) => {
                const dashboardChart = userDashboardCharts[key as keyof typeof userDashboardCharts];
                const chartData = data[key as keyof typeof userDashboardCharts];
                let chart = {
                    id: key,
                    type: dashboardChart.type,
                    chartHeight: dashboardChart.chartHeight,
                    height: dashboardChart.height,
                    width: dashboardChart.width,
                    title: dashboardChart.title,
                    chartData: chartData,
                    showCustomeLegends: dashboardChart.customLegends,
                    legendAlignment: dashboardChart.legendAlignment
                };
                charts.push(chart);
            });
            setCharts(charts);
            setUserStats(stats);
            setLoading(false);
        } catch(err:any) {
            toastMessage(err?.response?.data?.message,null,"error");
        }
    }

    const getOrganizationDetails = async() => {
      try{
        let url = `/organizations/${orgId}` ;
        const { data } = await apiService.get(url)
        setOrganizationDetails(data);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const handleFilterChange = (filterValues: any) => {
        setLoading(true);
        setFilters(filterValues);
    }
    
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    }

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard__main">
                <div className="dashboard_header">
                    <div className="heading_wrapper">
                        <div className="dashboard__heading">{organizationDetails?.name}</div>
                    </div>
                    <div className="filter__wrapper">
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>
                            Filter
                            <span className="count">(1)</span>
                        </Button>
                    </div>
                </div>
                <div className="dashboard__cards">
                    {loading && <div className="dashboard_inner_card">
                        {[...Array(4)].map((value, index) => {
                            return (
                                <Skeleton width={287} height={150} key={keys[index]} animation="wave" variant="text" />
                            )
                        })}                            
                    </div>}
                    {!loading && <div className="dashboard_inner_card">
                            {userStats.map((data:any) =>
                                <div className="count_box" key={data.id}>
                                    <CountCard data={data} filters={filters} redirectOnPage={false}/>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div className="graph__wrapper">
                    {loading && Object.keys(userDashboardCharts).map((value) => {
                        return (
                            <Skeleton key={value} width={`${userDashboardCharts[value as keyof typeof userDashboardCharts].width}%`} height={userDashboardCharts[value as keyof typeof userDashboardCharts].height} variant="rectangular" animation="wave" />
                        )
                    })}
                    {!loading && charts && <ChartCard data={charts} />}
                </div>                   
            </div>
            {open && <FilterPopupComponent 
                open={open}
                popoverId={popoverId} 
                anchorEl={anchorEl}
                filterState={filters}
                onlyDateFilter={false}
                onClose={handleToggleFilterPopup}
                onApplyFilter={handleFilterChange}
            />}
        </>
    )
}
