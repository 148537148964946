import {
  Button,
  Tooltip,
  CircularProgress,
  ButtonGroup,
} from "@mui/material";
import "../plans/subscription-plans.scss";
import { AddOn, PlanData, PlanType } from "../../../types/plans.interface";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import logo from "../../../../assets/tango_logo.svg";
import CheckIcon from "@mui/icons-material/Check";
import { authController } from "../../../core/controllers/auth/auth.controller";
import { useEffect, useState } from "react";
import { apiService } from "../../../core/services/api.service";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  updateOrganizationDetails,
} from "../../../../store/slice/organization-slice";
import { useSearchParams } from "react-router-dom";
import {
  LiteJobsQuantity,
  PremiumJobsQuantity,
  freemiumFeatures,
  planIntervalType,
  premiumFeatures,
} from "../../../core/constants";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from "react-toastify";
import AppFooter from "../../../components/app-footer/app-footer";
import { toastMessage } from "../../../core/services/toast";

const initialDisableCounterState = {
  'LITE': {
    increment: false,
    decrement: true
  },
  'PREMIUM': {
    increment: false,
    decrement: true
  },
  'PREMIUM_MONTHLY': {
    increment: false,
    decrement: true
  }
}

export function SubscriptionCheckout(props: any) {
  const [plan, setPlan] = useState<any>();
  const dispatch = useAppDispatch();
  const isLoggedIn = authController.isUserLoggedIn();
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState<any>(); 
  const id = searchParams.get("requestId");
  const [showContact, setShowContact] = useState(false);
  const [liteJobsIndex, setLiteJobsIndex] = useState(0);
  const [premiumJobsIndex, setPremiumJobsIndex] = useState(0);
  const [disableCounter, setDisableCounter] = useState(initialDisableCounterState);
  const pageHeightClassName = isLoggedIn ? "pageHeightWithNavbar" : "pageHeightWithoutNavbar"; 
  const [quantity, setQuantity] = useState<any>();
  const [requestId, setRequestId] = useState<any>(null);
  const subType = planIntervalType.ANNUALLY;
  
  const isTokenAvailable: any = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if (id) {
      const reqId = atob(id);
      setRequestId(reqId);
    }
  }, [id]);

  useEffect(() => {
    if (isTokenAvailable) {
      fetchPlanDetails();
    }
  }, [isTokenAvailable]);

  const fetchPlanDetails = async () => {
    const { data } = await apiService.get("/organizations/my_organization");
    const orgData = data;
    if (!orgData || orgData === null || orgData.length === 0) {
      return;
    }
    setOrganizationDetails(orgData);
    const planCode = orgData?.plan?.plan_code;
    const addons = orgData?.addons
      ?.map((addon: any) => addon.addon_code)
      .join(",");
    try{
    const { data } = await apiService.get(
      `/subscription-management/organization/plans/${planCode}?addons=${addons}`
    );
    const planDetails: PlanData = data;

    formatPlanDetails(planDetails, orgData);
  }catch(err:any){
    toastMessage(err.response.data.statusCode, null);
  }
  };

  const formatPlanDetails = (planDetails: any, orgData: any) => {
    if (planDetails) {
      const addons: AddOn[] = [];
      planDetails?.addons?.forEach((addon: AddOn) => {
        const planQty = setPlanDetailQuantity(planDetails, orgData, addon);
        setPlanJobPostCounterState(planDetails, planQty);
        const resultOptions = getResultOptions(planDetails);
        addons.push({
          ...addon,
          plan_id: planDetails?.plan_id,
          options: resultOptions,
          selected_qty: planQty,
          totalPrice: addon?.price_brackets[0]?.price * planQty,
        });
      });
      planDetails.addons = [...addons];
      planDetails.totalPrice =
        planDetails?.price_brackets[0]?.price +
        planDetails?.addons?.reduce(
          (prev: any, current: any) => prev + current.totalPrice,
          0
        );
      setPlan(planDetails);
    }
  }

  const setPlanJobPostCounterState = (planDetails: any, planQty: any) => {
    if (planDetails.plan_code === PlanType.LITE) {
      const index = LiteJobsQuantity.findIndex((elem: any) => elem === planQty);
      setLiteJobsIndex(index);
      if (index > 0) {
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: false}}
        })
      }
    }
    if (planDetails.plan_code === PlanType.PREEMIUM || planDetails.plan_code === PlanType.PREMIUM_MONTHLY) {
      const index = PremiumJobsQuantity.findIndex((elem: any) => elem === planQty);
      setPremiumJobsIndex(index);
      if (index > 0) {
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: false}, PREMIUM_MONTHLY: {increment: false, decrement: false}}
        })
      }
    }
  }

  const setPlanDetailQuantity = (planDetails: any, orgData: any, addon: any) => {
    let quantity: any;
    if (orgData?.addons?.length !== 0) {
      const matchingAddOn = orgData?.addons.find(
        (addOn: any) => addOn?.addon_code === addon?.addon_code
      );
      quantity = matchingAddOn?.quantity;
    } else {
      switch(planDetails?.plan_code) {
        case PlanType.PREEMIUM:
        case PlanType.PREMIUM_MONTHLY:
          quantity = PremiumJobsQuantity[0];
          break;
        case PlanType.FREEMIUM:
          quantity = 1;
          break;
        default:
          quantity = 5;
          break;
      }
    }

    setQuantity(quantity);
    return quantity;
  }

  const getResultOptions = (planDetails: any) => {
    let resultOptions: any = [];

    switch(planDetails?.plan_code) {
      case PlanType.PREEMIUM:
      case PlanType.PREMIUM_MONTHLY:
        resultOptions = PremiumJobsQuantity;
        break;
      case PlanType.FREEMIUM:
        resultOptions = [1];
        break;
      case PlanType.LITE:
        resultOptions = LiteJobsQuantity;
        break;
      default:
        resultOptions = [];
        break;
    }
    return resultOptions;
  }

  const handleJobPostChangeIncrement = (addOnId: any, planId: any, planCode: any) => {
    if (planCode === PlanType.LITE) {
      if (liteJobsIndex < (LiteJobsQuantity.length - 1)) {
        const index = liteJobsIndex + 1;
        const value = LiteJobsQuantity[index];
        setLiteJobsIndex(index);
        handleAddon(value, addOnId, planId);
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: false}}
        })
        if (index === LiteJobsQuantity.length - 1) {
          setDisableCounter(prevstate => {
            return {...prevstate, LITE: {increment: true, decrement: false}}
          })
        }
      } else {
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: true, decrement: false}}
        })
      }
    }
    if (planCode === PlanType.PREEMIUM || planCode === PlanType.PREMIUM_MONTHLY) {
      if (premiumJobsIndex < (PremiumJobsQuantity.length - 1)) {
        const index = premiumJobsIndex + 1;
        const value = PremiumJobsQuantity[index];
        setPremiumJobsIndex(index);
        handleAddon(value, addOnId, planId);
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: false}, PREMIUM_MONTHLY: {increment: false, decrement: false}}
        })
      } else {
        handleAddon("Above 100", addOnId, planId);
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: true, decrement: false}, PREMIUM_MONTHLY: {increment: true, decrement: false}}
        })
      }
    }
  }

  const handleJobPostChangeDecrement = (addOnId: any, planId: any, planCode: any) => {
    if (planCode === PlanType.LITE) {
      if (liteJobsIndex > 0) {
        const index = liteJobsIndex - 1;
        const value = LiteJobsQuantity[index];
        setLiteJobsIndex(index);
        handleAddon(value, addOnId, planId);
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: false}}
        })
        if (index === 0) {
          setDisableCounter(prevstate => {
            return {...prevstate, LITE: {increment: false, decrement: true}}
          })
        }
      } else {
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: true}}
        })
      }
    }
    if (planCode === PlanType.PREEMIUM || planCode === PlanType.PREMIUM_MONTHLY) {
      if (premiumJobsIndex > 0) {
        const index = premiumJobsIndex - 1;
        const value = PremiumJobsQuantity[index];
        setPremiumJobsIndex(index);
        handleAddon(value, addOnId, planId);
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: false}, PREMIUM_MONTHLY: {increment: false, decrement: false}}
        })
        if (index === 0) {
          setDisableCounter(prevstate => {
            return {...prevstate, PREMIUM: {increment: false, decrement: true}, PREMIUM_MONTHLY: {increment: false, decrement: true}}
          })
        }
      } else {
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: true}, PREMIUM_MONTHLY: {increment: false, decrement: true}}
        })
      }  
    }  
  }

  // Update Addon price on each onChange event
  const handleAddon = (value: any, addOnId: any, planId: any) => {
    const qty = value;
    if (qty?.toString().toLowerCase() === "Above 100".toLowerCase()) {
      setShowContact(true);
      toast.info("For more jobs, Please contact us.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    setQuantity(qty);
    setShowContact(false);
    // Check for same plan and having same addon added
    if (plan?.addons.find((addon: any) => addon?.addon_id === addOnId)) {
      const addons: any[] = [];
      plan.addons?.forEach((addon: any) => {
        if (addon?.addon_id === addOnId && plan?.plan_id === planId) {
          addons.push({
            ...addon,
            plan_id: plan?.plan_id,
            totalPrice: addon?.price_brackets[0]?.price * qty,
            selected_qty: qty,
          });
        } else {
          addons.push({
            ...addon,
            plan_id: plan?.plan_id,
          });
        }
      });
      plan.addons = [...addons];
      plan.totalPrice =
        plan?.price_brackets[0]?.price +
        plan?.addons?.reduce(
          (prev: any, current: any) => prev + current.totalPrice,
          0
        );
    }
  };

  const onSubmitHandler = async (plan: PlanData) => {
    const addOns = plan.addons?.map((addon: any) => ({
      addon_code: addon?.addon_code,
      price: addon?.price_brackets[0]?.price,
      quantity: addon?.selected_qty,
    }));
    const payload = {
      plan: {
        plan_code: plan?.plan_code,
      },
      addons: [...addOns],
    };
    const updatedPayload = {
      ...organizationDetails,
      plan: {
        plan_code: plan?.plan_code,
      },
      addons: [...addOns],
    };
    if (isLoggedIn) {
      try{
        await apiService.put(
          `/organizations/${requestId}`,
          updatedPayload
        );
      }catch(err:any){
        toastMessage(err.response.data.statusCode, null);
      }
    }
    setShowLoader(true);
    try {
      dispatch(updateOrganizationDetails(updatedPayload));
      const { data } = await apiService.post(
        `/subscription-management/organization/checkout`,
        payload
      );
      setShowLoader(false);
      window.location.href = data?.url;
    } catch (error:any) {
      toastMessage(error.response.data.statusCode, null);
      setShowLoader(false);
    }
  };

  const planFeature =(planCode:string)=>{

    const feature:string[] = planCode === PlanType.FREEMIUM ? freemiumFeatures : premiumFeatures;
    const planFeatures = feature.map((feature) => (
      <Tooltip title={feature} key={feature}>
        <div className="list-content">
          <div className="left_list_content_icon">
          <PlayCircleFilledWhiteIcon
            sx={{
              stroke: "#fff",
              width: 26,
              height: 26,
              fontSize:26,
            }}
          />
          </div>
          <div className="right_list_content_text">
          {feature.includes('#qty#') ? feature.replace('#qty#', quantity) : feature}
          </div>
        </div>
      </Tooltip>
    ))

    return planFeatures;
  }

  const handleContactUSRedirection = () => {
    const contactUsUrl = 'https://info.tangoapp.co/contactus';
    window.open(contactUsUrl, '_blank', 'noopener');
  }

  const planTerm = (planCode: string) => {
    switch (planCode) {
      case PlanType.FREEMIUM:
        return "For 2 Months";
      case PlanType.LITE:
        return "For 2 Months";
      case PlanType.PREEMIUM:
        return "For 1 Year";
      case PlanType.PREMIUM_MONTHLY:
        return "For 1 Month";
    }
  };
  return (
    <>
      <div
          className={`pricing-root ${pageHeightClassName}`}
        >
          <div
            className={`pricing-container ${pageHeightClassName}`}
          >
            {!isLoggedIn && (
              <div className="app-heading">
                <img src={logo} alt="brand-logo" />
              </div>
            )}
            <div className="pricing-header-container">
              <header className="pricing-header">Selected Subscription Plan</header>
            </div>

            <div className="pricing-main">
              {plan ? (
                <div
                  className={`pricing-card-base ${
                    showContact ? "pricing-card-base-checkout" : ""
                  } ${plan?.plan_code}`}
                  key={plan?.plan_id}
                >
                  <div className="diagonal_strip">
                    <div className="diagonal_strip_content">
                    {plan?.plan_code === PlanType.FREEMIUM  && <>Credit Card <br /> not required!</>}
                    {plan?.plan_code === PlanType.LITE  && <></>}
                    {plan?.plan_code === PlanType.PREEMIUM  && <>Best Value !</>}
                    {plan?.plan_code === PlanType.PREMIUM_MONTHLY  && <>Best Value !</>}
                    {plan?.plan_code === PlanType.PRO  && <></>}
                    </div>
                  </div> 
                  <div className="pricing-card-base-1 ">
                    <div className="pricind-card">
                      <div className="pricing_header">
                        <div className="plan-name">
                          <Tooltip title={plan?.name}>
                            <span className="plan-name-text">{plan?.name && plan.name.charAt(0).toUpperCase() + plan.name.slice(1).toLowerCase()}</span>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="plan-name-desc">
                        <div className="plan-name">
                          <Tooltip title={plan?.description}>
                            <span className="plan-desc">
                              {plan?.description}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="addon-details">
                        {plan?.addons?.map((addon: any) => (
                          <div
                            className="addon-details-div"
                            key={addon?.addon_id}
                          >
                            {!plan?.name?.includes(PlanType.Enterprise) ? (
                              <Tooltip title={addon?.name}>
                                <span className="list-content">
                                  {addon?.name}
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip title={addon?.name}>
                                <span className="list-content list-content-1">
                                  <CheckIcon
                                    sx={{
                                      stroke: "#9f2223",
                                      width: 14,
                                      height: 14,
                                      fontWeight: "bold",
                                      strokeWidth: 3,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: 2,
                                    }}
                                  />
                                  {addon?.name}
                                </span>
                              </Tooltip>
                            )}
                            {!plan?.name?.includes(PlanType.Enterprise) && (
                              <ButtonGroup size="small" aria-label="small outlined button group" className="jobPostBtnGroup">
                              <Button className="jobPostBtn" onClick={(event) =>
                                handleJobPostChangeDecrement(
                                  addon?.addon_id,
                                  plan?.plan_id,
                                  plan?.plan_code
                                )
                              } disabled={
                                plan?.plan_code ===
                                PlanType.FREEMIUM || disableCounter[plan?.plan_code as keyof typeof disableCounter]?.decrement
                              }><RemoveCircleIcon /></Button>
                              <Button className="jobPostBtnField" disabled>{addon?.selected_qty}</Button>
                              <Button className="jobPostBtn" onClick={(event) =>
                                handleJobPostChangeIncrement(
                                  addon?.addon_id,
                                  plan?.plan_id,
                                  plan?.plan_code
                                )
                              } disabled={
                                plan?.plan_code ===
                                PlanType.FREEMIUM || disableCounter[plan?.plan_code as keyof typeof disableCounter]?.increment
                              }><AddCircleIcon /></Button>
                            </ButtonGroup>
                            )}
                          </div>
                        ))}
                        </div>
                        <div className="pricing-btn-container">
                      <div className="price-container">
                        {((plan?.plan_code !== PlanType.PREEMIUM || subType === planIntervalType.MONTHLY) && plan?.plan_code !== PlanType.LITE) && <div className="plan-price">
                          ${plan?.totalPrice ? Math.trunc(plan?.totalPrice) : 0}
                        </div>}
                        {plan?.plan_code === PlanType.PREEMIUM && subType === planIntervalType.ANNUALLY && <div className="plan-price">
                          ${plan?.totalPrice ? Math.trunc(plan?.totalPrice) : 0}
                        </div>}
                        {plan?.plan_code === PlanType.LITE && <div className="plan-price">
                          ${plan?.totalPrice ? Math.trunc(plan?.totalPrice) * 2 : 0}
                        </div>}
                        <div className="plan-price-per">
                          {planTerm(plan?.plan_code)}
                        </div>
                        
                      </div>
                    </div>
                        <div className="plan-features">
                          {planFeature(plan?.plan_code)}
                        </div>
                      </div>
                    </div>
                    <div className="pricing-btn-container_footer">
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      className="buy-plan-btn"
                      onClick={() => onSubmitHandler(plan)}
                    >
                      Checkout
                    </Button>
                    {showContact && (
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="buy-plan-btn no_link_btn"
                        id="basic-button"
                        onClick={handleContactUSRedirection}
                      >
                        Contact Us
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="no--content">
                    <CircularProgress className="custom__spinner" />
                  </div>
                </>
              )}
            </div>
          </div>
      </div>
      {showLoader && (
        <div className="no--content">
          <CircularProgress className="custom__spinner" />
        </div>
      )}
      <AppFooter />
    </>
  );
}
