export interface AddOn {
  name: string;
  addon_code: string;
  addon_id: string;
  plan_id: string;
  description: string;
  price_brackets: [
    {
      price: number;
    }
  ];
  min_qty: string;
  max_qty: string;
  fixed_qty: string;
  step: string;
  options: string[];
  selected_qty: string;
  totalPrice: any;
}

export interface PlanData {
  plan_id: string;
  plan_code: string;
  name: string;
  addons: AddOn[];
  description: string;
  price_brackets: [
    {
      price: number;
    }
  ];
  totalPrice: any;
}

export enum PlanType {
  Core_Standard = "CORES",
  SMB_Standard = "SMBS",
  Enterprise = "Enterprise",
  SMB_LESS_500 = "SMBEL500",
  SMB_MORE_500 = "SMBEM500",
  CORE_1K_TO_25K = "COREE1KTO25K",
  FREEMIUM = "TRIAL",
  PREEMIUM = "PREMIUM",
  PREMIUM_MONTHLY = "PREMIUM_MONTHLY",
  PRO = "PRO",
  LITE = "LITE"
}

export enum OrganizationEmployeeSize {
  SIZE_A = "Small (1-50 employees)",
  SIZE_B = "Medium (51-500 employees)",
  SIZE_C = "Large (501-1,000 employees)",
  SIZE_D = "Enterprise (1,001+ employees)",
}

export enum SubscriptionFeatureEnum {
  JOB = 'JOB',
  VIDEO_ANALYSIS = 'VIDEO_ANALYSIS',
  OTHER = 'OTHER',
}
