import "./pricing-footer.scss";
import logo from "../../../assets/tango_logo.png";
import linkedIn from "../../../assets/linkedin.png";
import instagram from "../../../assets/instagram.png";
import closeIcon from "../../../assets/close_icon.png";
import { reactWebsiteBaseUrl, reactWebsiteHubspotFormBaseUrl } from "../../core/constants";

export function PricingFooter() {
  return (
    <>
        <div className="pricing-footer-container">
          <div className="top-footer">
            <div className="menuLinkRows">
                <div className="menuLinks topMenus">
                    <div className="gridItem">
                        <span><a href={reactWebsiteBaseUrl} target="_blank">Overview</a></span>
                    </div>
                    <div className="gridItem">
                        <span><a href={`${reactWebsiteBaseUrl}/how-it-works/`} target="_blank">How It Works</a></span>
                    </div>
                    <div className="gridItem">
                        <span><a href="">Pricing</a></span>
                    </div>
                </div>
                <div className="menuLinks bottomMenus">
                    <div className="gridItem">
                        <span><a href={`${reactWebsiteHubspotFormBaseUrl}/schedule-demo/`} target="_blank">Schedule a Demo</a></span>
                    </div>
                    <div className="gridItem">
                        {/* <span><a href={`${reactWebsiteBaseUrl}/about/`} target="_blank">About</a></span> */}
                    </div>
                    <div className="gridItem">
                        <span><a href={`${reactWebsiteBaseUrl}/resources/`} target="_blank">Resources</a></span>
                    </div>
                </div>
            </div>
            <div className="logoMsgWrapper">
                <div className="logoWrapper">
                    <a href={reactWebsiteBaseUrl} target="_blank">
                        <img src={logo} alt="Logo" />
                    </a>
                </div>
                {/* <div className="msgWrapper">
                    <span>Find your fit, face-to-face.</span>
                </div> */}
                <div className="socialLinks">
                    <div className="linkedIn">
                        <a href="https://www.linkedin.com/company/letstangoofficial" target="_blank"><img src={linkedIn} alt="LinkedIn"/></a>
                    </div>
                    <div className="instagram">
                        <a href="https://www.instagram.com/letstangoofficial/" target="_blank"><img src={instagram} alt="Instagram"/></a>
                    </div>
                    <div className="closeIcon">
                        <a href="https://twitter.com/TangoJobApp" target="_blank"><img src={closeIcon} alt="Icon"/></a>
                    </div>
                </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="footerText">
                © 2024 LetsTango. All rights reserved.
            </div>
            <div className="privacyPolicy">
                <a href={`${reactWebsiteBaseUrl}/privacy-policy/`} target="_blank">Privacy Policy</a>
            </div>
          </div>
        </div>
    </>
  );
};
