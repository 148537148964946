import { apiService } from "../../core/services/api.service";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { SubscriptionPaywall } from "../../pages/subscriptions/paywall/subscription-paywall";
import { authController } from "../../core/controllers/auth/auth.controller";
import { UserRole } from "../../types/user.interface";
import { useAppSelector } from "../../../store";
import { toastMessage } from "../../core/services/toast";

export const SubscriptionCheckWrapper = (props: any) => {
  const [isSubscribed, setisSubscribed] = useState(true);
  const { Component } = props;
  const [orgaization, setOrganization] = useState<any>();
  const hasOrgSubscription = authController.isOrganizationSubscribed();
  const role = authController.role;

  const isTokenAvailable: any = useAppSelector(
    (state) => state.auth.token
  );

  const hasSubscription = async () => {
    if (
      role !== UserRole.Admin &&
      (role === UserRole.Recruiter || role === UserRole.HRManager)
    ) {
      try{
        const { data } = await apiService.get("/organizations/my_organization");
        const orgData = data;
        if (orgData) {
          setOrganization(orgData);
        }
        if (orgData && !orgData?.subscription) {
          setisSubscribed(false);
        } 
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }
  };
  useEffect(() => {
    if(isTokenAvailable){
      // If organization details are not available fetch myOrganization and check subscription details. Based on subscription set its status. 
      !orgaization && hasSubscription();

      if (!isSubscribed && orgaization) {
        // When organization details are available and is not subscribed 
        window.location.replace(`/organizational-plans?requestId=${btoa(orgaization?._id)}`);
      }
    }
  }, [isSubscribed, orgaization?._id, isTokenAvailable]);

  return (
    <>
      {!hasOrgSubscription ? (
        <>
          <div className="no--content">
            <CircularProgress className="custom__spinner" />
          </div>
          <SubscriptionPaywall />
        </>
      ) : (
        <Component />
      )}
    </>
  );
};
