import './edit-user.scss';
import { Dialog, DialogTitle, IconButton, DialogContent, InputLabel, TextField, FormHelperText, Button, Select, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { apiService } from "../../../../core/services/api.service";
import { useFormik } from 'formik';
import { phoneRegExp } from "../../../../core/constants";
import * as Yup from 'yup';
import { toastMessage } from '../../../../core/services/toast';

interface FormFields {
    name: string;
    email: string;
    role: string;
    contact: string;
}

export default function EditUserComponent(props: any) {
    const {open, onClose, onSuccess, userDetail, UserRole} = props;

    const initialValues = {
        name: `${userDetail?.name || ''}`,
        email: `${userDetail?.email || ''}`,
        role: `${userDetail?.role || ''}`,
        contact: `${userDetail?.contact || ''}`
    };

    const validations = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().trim('The email cannot include leading and trailing spaces').strict(true).email('Invalid Email').required('Email is required'),
        role: Yup.string().required('Role is required'),
        contact: Yup.string().matches(phoneRegExp, 'Contact is not valid').required('Contact is required'),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validations,
        onSubmit: async (values) => {
            if (formik.isValid) {
                handleSubmit(values);
            }
        },
    })

    const handleSubmit = async (formValues: FormFields) => {
        const url = `/user/${userDetail?._id}`;
        const contact = formValues.contact.replace(/[()\s\-.]/g, '');
        const payload = {
            name: formValues.name,
            role: formValues.role,
            email: formValues.email,
            contact: contact
        }
        try {
            const response = await apiService.put(url, payload);
            let message:string =`Successfully updated the user`;
            toastMessage(response.status,message);
            onSuccess();
            formik.resetForm({ values: initialValues });
        } catch(err:any) {
            toastMessage(err.response.data.statusCode,null);
        }
    }

    const handleClose = () => {
        onClose();
    };

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        maxWidth: 454,
                        padding: '14px 8px 20px',
                        borderRadius: 6
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        Edit User Details
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="user__form">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input__items">
                                <InputLabel size="normal" className="input__label" focused>Name</InputLabel>
                                <TextField
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formik.values.name}
                                    variant="outlined"
                                    placeholder="Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    fullWidth />
                                {(formik.touched.name && formik.errors.name) && (
                                    <FormHelperText id='name-error-text' className="error_message">{formik.errors.name}</FormHelperText>
                                )}
                            </div>
                            <div className="input__items">
                                <InputLabel size="normal" className="input__label" focused>Email</InputLabel>
                                <TextField
                                    id="email"
                                    name="email"
                                    type="email"
                                    disabled
                                    className="disabled__input"
                                    value={formik.values.email}
                                    variant="outlined"
                                    placeholder="Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    fullWidth />
                                {(formik.touched.email && formik.errors.email) && (
                                    <FormHelperText id='email-error-text' className="error_message">{formik.errors.email}</FormHelperText>
                                )}
                            </div>
                            <div className="input__items">
                                <InputLabel size="normal" className="input__label" focused>Contact</InputLabel>
                                <TextField
                                    id="contact"
                                    name="contact"
                                    type="text"
                                    value={formik.values.contact}
                                    variant="outlined"
                                    placeholder="Contact"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.contact && Boolean(formik.errors.contact)}
                                    fullWidth />
                                {(formik.touched.contact && formik.errors.contact) && (
                                    <FormHelperText id='contact-error-text' className="error_message">{formik.errors.contact}</FormHelperText>
                                )}
                            </div>
                            <div className="input__items">
                                <InputLabel size="normal" className="input__label" focused id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role"
                                    variant="outlined" 
                                    fullWidth
                                    name="role"
                                    disabled
                                    className="disabled__select"
                                    size='small'
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.role && Boolean(formik.errors.role)}
                                >
                                    <MenuItem value={UserRole.Recruiter}>Recruiter</MenuItem>
                                    <MenuItem value={UserRole.HRManager}>Hiring Manager</MenuItem>
                                </Select>
                                {(formik.touched.role && formik.errors.role) && (
                                    <FormHelperText id='name-error-text' className="error_message">{formik.errors.role}</FormHelperText>
                                )}
                            </div>
                            <div className="action__btn">
                                <Button type="submit" color="secondary" variant="contained" className="custom__action__button confirm__button">
                                    Update
                                </Button>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
    );
}