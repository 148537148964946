import React from "react";
import dayjs from 'dayjs';
import './admin-reported-users.scss';
import { UserRole } from "../../../types/user.interface";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { authorizationService } from "../../../core/services/authorization.service";
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import { apiService } from "../../../core/services/api.service";
import { toastService } from '../../../core/services/toast.service'
import { TypeOptions } from 'react-toastify/dist/types';
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";

interface Cards {
    chats: string;
    applications: string,
    jobPosts: string,
    connections: string,
    associations: string
};

interface Data {
    name: string;
    postedBy: string;
    jobStatus: string;
    status: string;
    JobName: string;
    hr: string;
    recruiter: string;
    candidate: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isWidth: boolean,
}

const applicationHeadCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Name',
    },
    {
        id: 'postedBy',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Posted By',
    },
    {
        id: 'jobStatus',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Job Status',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Application Status',
    }
];

const jobPostsHeadCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Name',
    },
    {
        id: 'postedBy',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Posted By',
    },
    {
        id: 'jobStatus',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Job Status',
    }
];

const connectionsHeadCells: HeadCell[] = [
    {
        id: 'JobName',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Job Name',
    },
    {
        id: 'hr',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Hiring Manager',
    },
    {
        id: 'candidate',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Candidate',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Status',
    }
];

const associationsHeadCells: HeadCell[] = [
    {
        id: 'JobName',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Job Name',
    },
    {
        id: 'hr',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Hiring Manager',
    },
    {
        id: 'recruiter',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Recruiter',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Status',
    }
];

const action = {
    'warn': 'warning',
    'ignore': 'ignore',
    'deactivate': 'deactivate',
    'block': 'Spam content'
};

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 150,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 16,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const EnhancedTableFooter = (props: any) => {
    const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.onPageChange(value)
    }
    return (
        <TableFooter>
            <TableRow>
                <TableCell colSpan={4}>
                    <TablePagination count={props.pages} siblingCount={0} defaultPage={props.page} page={props.page} boundaryCount={2} className="custom__pagination" onChange={onPageChange}/>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}

const initialLoadingState = {
    chats: true,
    applications: true,
    jobPosts: true,
    connections: true,
    associations: true,
};

const initialPageState = {
    chats: 1,
    applications: 1,
    jobPosts: 1,
    connections: 1,
    associations: 1,
};

const initialLimitState = {
    chats: 3,
    applications: 5,
    jobPosts: 5,
    connections: 5,
    associations: 5,
};

const initialPageCountState = {
    chats: 0,
    applications: 0,
    jobPosts: 0,
    connections: 0,
    associations: 0,
};

const initialUserDetails = {
    id: '',
    name: '',
    email: '',
    role: '',
    status: '',
};

export default function ReportedUserHistoryComponent() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const loginUserDetails: any = useAppSelector(
        (state) => state.auth.userDetail
      );
    const limit = initialLimitState;
    const [selectedUser, setSelectedUser] = React.useState({...state?.reportedUserData});
    const [userDetails, setUserDetails] = React.useState<any>(initialUserDetails);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [chatList, setChatList] = React.useState<any>([]);
    const [applicationsList, setApplicationsList] = React.useState<any>([]);
    const [jobPostsList, setJobPostsList] = React.useState<any>([]);
    const [connectionsList, setConnectionsList] = React.useState<any>([]);
    const [associationsList, setAssociationsList] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<any>(initialLoadingState);
    const [page, setPage] = React.useState<any>(initialPageState);
    const [pages, setPages] = React.useState<any>(initialPageCountState);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [confirmationTitle, setConfirmationTitle] = React.useState('');
    const [confirmationMsg, setConfirmationMsg] = React.useState('');
    const [confirmationBtnLabel, setConfirmationBtnLabel] = React.useState('');
    const [confirmationFor, setConfirmationFor] = React.useState('');
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const initialFilterValue = {
        startDate: dayjs().subtract(7, 'day').format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD")
    };
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement>(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
      );
    
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
            Home
        </Link>,
        <Link key="2" color="inherit" to="/admin-portal/reported-users" className="inactive_menu">
            Reported Users
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu textCapitalize">
            {userDetails.name !== '' ? userDetails.name : userDetails.email}
        </Typography>,
    ];

    React.useEffect(() => {
        setSelectedUser(state?.reportedUserData);
      }, [selectedUser]);

    React.useEffect(() => {
        const reportedUsersPermissions = authorizationService.getPermissions(loginUserDetails?.role, 'reportedUsers', false, loginUserDetails?.isSuperAdmin);
        setPermissions(reportedUsersPermissions);
      }, [loginUserDetails]);

    React.useEffect(() => {
        if (!selectedUser || selectedUser === undefined || selectedUser === null) {
            let status: TypeOptions = "error";
            let message: string = "Something went wrong";
            toastService.showToastNotification(status, message);
            navigate(`/admin-portal/reported-users`);
        }
    }, [])

    React.useEffect(() => {
        isTokenAvailable && fetchChatsList()
    }, [page.chats, filters,isTokenAvailable]);

    React.useEffect(() => {
        isTokenAvailable && fetchApplicationsList()
    }, [page.applications, filters,isTokenAvailable]);

    React.useEffect(() => {
        isTokenAvailable && fetchJobPostsList()
    }, [page.jobPosts, filters,isTokenAvailable]);

    React.useEffect(() => {
        isTokenAvailable && fetchConnectionsList()
    }, [page.connections, filters,isTokenAvailable]);

    React.useEffect(() => {
        isTokenAvailable && fetchAssociationsList()
    }, [page.associations, filters,isTokenAvailable]);

    const fetchChatsList = async () => {
        let url = `/user/chathistory/${selectedUser?.reportee?._id}?take=${limit.chats}&skip=${(page.chats - 1) * limit.chats}`;
        if (filters.startDate) {
            url = `${url}&startDate=${new Date(filters.startDate).toISOString()}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${new Date(new Date(filters.endDate).setHours(23,59,59,999)).toISOString()}`;
        }
      try{
        const { data } = await apiService.get(url);
        let chatsListData = data.length ? data[0].chat.rooms : [];
        const count = data.length ? data[0].count : 0;
        setPages((prevState: any) => ({
            ...prevState,
            chats: Math.ceil(count / limit.chats)
        }));
        setLoading((prevState: any) => ({
            ...prevState,
            chats: false
        }));
        const userDetailsData = {
            id: data[0]._id,
            name: data[0].name,
            email: data[0].email,
            role: data[0].role,
            status: data[0].status
        };
        setUserDetails(userDetailsData);
        chatsListData = chatsListData.map((chat: any, index: number) => {
            const take = 5;
            const displayMsg = chat.messages.length > 0 ? chat.messages.slice(0, take) : [];
            return { ...chat, displayMsg: displayMsg, take: take }
        })
        setChatList(chatsListData);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const fetchApplicationsList = async () => {
        let url = `/user/applicationhistory/${selectedUser?.reportee?._id}?take=${limit.applications}&skip=${(page.applications - 1) * limit.applications}`;
        if (filters.startDate) {
            url = `${url}&startDate=${filters.startDate}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${filters.endDate}`;
        }
      try{
        const { data } = await apiService.get(url);
        const applicationsListData = data.length ? data[0].applications : [];
        const count = data.length ? data[0].count : 0;
        setPages((prevState: any) => ({
            ...prevState,
            applications: Math.ceil(count / limit.applications)
        }));
        setLoading((prevState: any) => ({
            ...prevState,
            applications: false
        }));
        setApplicationsList(applicationsListData);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const fetchJobPostsList = async () => {
        let url = `/user/jobposthistory/${selectedUser?.reportee?._id}?take=${limit.jobPosts}&skip=${(page.jobPosts - 1) * limit.jobPosts}`;
        if (filters.startDate) {
            url = `${url}&startDate=${filters.startDate}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${filters.endDate}`;
        }
      try{
        const { data } = await apiService.get(url);
        const jobPostsListData = data.length ? data[0].jobPosts : [];
        const count = data.length ? data[0].count : 0;
        setPages((prevState: any) => ({
            ...prevState,
            jobPosts: Math.ceil(count / limit.jobPosts)
        }));
        setLoading((prevState: any) => ({
            ...prevState,
            jobPosts: false
        }));
        setJobPostsList(jobPostsListData);
      }catch(err:any){
        toastMessage(err.response.data.statusCode, null);
      }
    }

    const fetchConnectionsList = async () => {
        let url = `/user/connectionhistory/${selectedUser?.reportee?._id}?take=${limit.connections}&skip=${(page.connections - 1) * limit.connections}`;
        if (filters.startDate) {
            url = `${url}&startDate=${filters.startDate}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${filters.endDate}`;
        }
      try{
        const { data } = await apiService.get(url);
        const connectionsListData = data.length ? data[0].connections : [];
        const count = data.length ? data[0].count : 0;
        setPages((prevState: any) => ({
            ...prevState,
            connections: Math.ceil(count / limit.connections)
        }));
        setLoading((prevState: any) => ({
            ...prevState,
            connections: false
        }));
        setConnectionsList(connectionsListData);
      }catch(err:any){
        toastMessage(err.response.data.statusCode, null)
      }
    }

    const fetchAssociationsList = async () => {
        let url = `/user/associationshistory/${selectedUser?.reportee?._id}?take=${limit.associations}&skip=${(page.associations - 1) * limit.associations}`;
        if (filters.startDate) {
            url = `${url}&startDate=${filters.startDate}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${filters.endDate}`;
        }
      try{
        const { data } = await apiService.get(url);
        const associationsListData = data.length ? data[0].associations : [];
        const count = data.length ? data[0].count : 0;
        setPages((prevState: any) => ({
            ...prevState,
            associations: Math.ceil(count / limit.associations)
        }));
        setLoading((prevState: any) => ({
            ...prevState,
            associations: false
        }));
        setAssociationsList(associationsListData);
      }catch(err:any){
        toastMessage(err.response.data.statusCode, null);
      }
    }

    const handleCardToggle = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmationClickOpen = (status: string) => {
        setOpenConfirmation(true);
        setConfirmationFor(status);
        let title = '';
        let msg = '';
        let btnLabel = '';
        switch (status) {
            case action.warn:
                btnLabel = 'Warn';
                title = 'Warn User';
                msg = 'Are you sure you want to warn user?';
                break;
            case action.ignore:
                btnLabel = 'Ignore';
                title = 'Ignore User';
                msg = 'Are you sure you want to ignore user?';
                break;
            case action.deactivate:
                btnLabel = 'Deactivate';
                title = 'Deactivate User';
                msg = 'Are you sure you want to deactivate user?';
                break;
            case action.block:
                btnLabel = 'Block';
                title = 'Block User';
                msg = 'Are you sure you want to block user?';
                break;
        }
        setConfirmationTitle(title);
        setConfirmationMsg(msg);
        setConfirmationBtnLabel(btnLabel);
    };

    const handleConfirmationClose = (value: number) => {
        setOpenConfirmation(false);
        resetConfirmationPopupState();
    };

    const handleConfirmationConfirm = (value: number, reason: string) => {
        updateUserStatus(reason);
        setOpenConfirmation(false);
        resetConfirmationPopupState();
    };

    const updateUserStatus = async (reason: string) => {
        const url = `/report/action/${selectedUser?._id}`;
        const payload = {
            actionType: confirmationFor,
            actionComment: reason
        };
        try {
            const response = await apiService.put(url, payload);
            let message: string = `Successfully updated status.`;
            toastMessage(response.status, message,"success");
            navigate(`/admin-portal/reported-users`);
        } catch (err: any) {
            let message: string = err?.response?.data?.message ? err?.response?.data?.message : null;
            toastMessage(err.response.data.statusCode, message, "error");
        }
    }

    const resetConfirmationPopupState = () => {
        setConfirmationFor('');
        setConfirmationTitle('');
        setConfirmationMsg('');
        setConfirmationBtnLabel('');
    }

    const handleChange = (value: number, type: string) => {
        setLoading((prevState: any) => ({
            ...prevState,
            [type as keyof Cards]: true
        }));
        setPage((prevState: any) => ({
            ...prevState,
            [type as keyof Cards]: value
        }));
    }

    const handleLoadMoreMsg = (room: any, index: number) => {
        const chatListData: any = [];
        chatList.forEach((chatRoom: any) => {
            chatListData.push(chatRoom);
        });
        const take = room.take + 5;
        const displayMsg = room.messages.length > 0 ? room.messages.slice(0, take) : [];
        const updatedRoom = { ...room, take: take, displayMsg: displayMsg };
        chatListData[index] = updatedRoom;
        setChatList(chatListData);
    }

    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilter(anchorFilter ? null : event.currentTarget);
        setOpenFilter((previousOpen) => !previousOpen);
    }

    const handleFilterChange = (filterValues: any) => {
        console.log(filterValues)
        setLoading(initialLoadingState);
        setFilters(filterValues);
    }

    const getClassNameByMsgSource = (msg: any) => {
        return msg.sender._id === userDetails.id ? "reciever" : "sender";
    }
    const loadingSkeleton=()=>{
        const keys = [0,1,2];
        const skeleton = [...Array(3)].map((value, index) => {
            return (
                <Skeleton width={378} height={300} key={keys[index]} animation="wave" variant="rectangular" />
            )
        })
        return skeleton;
    }
    const showChatList=()=>{
        if(chatList && chatList?.length > 0){
            const list = chatList.map((room: any, index: number) => {
                return (
                    <Card className="chat_card" key={room._id}>
                        <CardContent className="chat_card_content">
                            <div className="participants_wrapper">
                                <div className="participant_heading">Participants </div>
                                <div className="participant_detail textCapitalize">
                                    {room.participants.map((user: any, i: number) => {
                                        return (<div key={user._id}>{user.name} ({user.role})</div>);
                                    })}
                                </div>
                            </div>
                            <div className="messages_wrapper">
                                <div className="messages_heading">Messages</div>
                                <div className="messages_msg_wrapper">
                                    {room.displayMsg.length > 0 ? room.displayMsg.map((msg: any, i: number) => {
                                        return (
                                            <div key={msg._id} className="messages_msg">
                                                <div className={getClassNameByMsgSource(msg)}>
                                                    <div className="msg_sender">{msg.sender.name} </div>
                                                    <div className="msg_body">{msg.body}</div>
                                                </div>
                                            </div>
                                        );
                                    }) : <div className="no__data">No Chats Data Available !</div>
                                    }
                                </div>
                            </div>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center' }}>
                            {room.messages.length > 5 && (room.displayMsg.length < room.messages.length) && <Button size="medium" className="loadMore" onClick={() => handleLoadMoreMsg(room, index)}>Load More</Button>}
                        </CardActions>
                    </Card>
                )
            })
            return list;
        }else{
            return <div className="no__data">No Data Available !</div>
        }
    }
    const chatsCard=()=>{
        if(loading.chats){
            return loadingSkeleton();
        }else{
            return showChatList();
        }
    }

    const showJobPosts=()=>{
        if(jobPostsList && jobPostsList?.length > 0){
            const jobs = jobPostsList.map((row: any, index: number) => {
                return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="custom__cell__row"
                    >
                        <TableCell align="left" className="custom__cell__element">
                            <span>
                                {row?.title}
                            </span>
                        </TableCell>
                        <TableCell align="left" className="custom__cell__element">{row?.user?.name}</TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.status}
                        </TableCell>
                    </TableRow>
                )
            })
            return jobs;
        }else{
            return  <TableRow>
                        <TableCell colSpan={3} className="no__data">
                            No Data Available !
                        </TableCell>
                    </TableRow>
        }
    }

    const jobPosts=()=>{
        if(loading.jobPosts){
            return <TableSkeltonComponent rows={5} columns={3} />
        }else{
            return showJobPosts()
        }
    }

    const showConnectionList=()=>{
        if(connectionsList && connectionsList?.length > 0){
            const connections= connectionsList.map((row: any, index: number) => {
                return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="custom__cell__row"
                    >
                        <TableCell align="left" className="custom__cell__element">
                            <span>
                                {row?.jobPost?.title}
                            </span>
                        </TableCell>
                        <TableCell align="left" className="custom__cell__element">{row?.hrManager?.name}</TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.candidateProfile?.name}
                        </TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.status}
                        </TableCell>
                    </TableRow>
                )
            })

            return connections
        }else{
            return <TableRow>
                        <TableCell colSpan={4} className="no__data">
                            No Data Available !
                        </TableCell>
                    </TableRow>
        }
    }
    const connections=()=>{
        if(loading?.connections){
            return  <TableSkeltonComponent rows={5} columns={4} />
        }else{
            return showConnectionList();
        }
    }

    const showAssociations=()=>{
        if(associationsList && associationsList.length > 0){
            const associations = associationsList.map((row: any, index: number) => {
                return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="custom__cell__row"
                    >
                        <TableCell align="left" className="custom__cell__element">
                            <span>
                                {row?.title}
                            </span>
                        </TableCell>
                        <TableCell align="left" className="custom__cell__element">{row?.user?.name}</TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.recruiter?.name}
                        </TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.status}
                        </TableCell>
                    </TableRow>
                )
            })

            return associations;
        }else{
            return <TableRow>
                        <TableCell colSpan={4} className="no__data">
                            No Data Available !
                        </TableCell>
                    </TableRow>
        }
    }
    const associations=()=>{
        if(loading.associations){
            return <TableSkeltonComponent rows={5} columns={4} />
        }else{
            return showAssociations();
        }

    }
    const showApplications=()=>{
        if(applicationsList && applicationsList.length > 0){
            const application = applicationsList.map((row: any, index: number) => {
                return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="custom__cell__row"
                    >
                        <TableCell align="left" className="custom__cell__element">
                            <span>
                                {row?.jobPost?.title}
                            </span>
                        </TableCell>
                        <TableCell align="left" className="custom__cell__element">{row?.jobPost?.user?.name}</TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.jobPost?.status}
                        </TableCell>
                        <TableCell align="left" className=" custom__cell__element">
                            {row?.status}
                        </TableCell>
                    </TableRow>
                )
            })
            return application;
        }else{
            return  <TableRow>
                        <TableCell colSpan={4} className="no__data">
                            No Data Available !
                        </TableCell>
                    </TableRow>
        }
    }
    const applications=()=>{
        if(loading.applications){
            return <TableSkeltonComponent rows={5} columns={4} />
        }else{
            return showApplications()
        }
    }
    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper2">
                    <div className="heading pageHeading">
                        User: {userDetails.name !== '' ? <span className="textCapitalize">{userDetails.name}</span> : <span>{userDetails.email}</span>}
                    </div>
                    <div className="search__wrapper">
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                        {permissions?.action && <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            className="custom__create__button custom__button"
                            variant="contained"
                            disableElevation
                            onClick={handleMenuOpen}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            Actions
                        </Button>}
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleConfirmationClickOpen(action.warn)} disableRipple sx={{ fontFamily: 'Inter-Regular, sans-serif', fontSize: '14px', color: '#323539' }}>
                                <ReportOutlinedIcon sx={{ color: '#323539' }} />
                                Warn
                            </MenuItem>
                            <MenuItem onClick={() => handleConfirmationClickOpen(action.ignore)} disableRipple sx={{ fontFamily: 'Inter-Regular, sans-serif', fontSize: '14px', color: '#323539' }}>
                                <CancelOutlinedIcon sx={{ color: '#323539' }} />
                                Ignore
                            </MenuItem>
                            <MenuItem onClick={() => handleConfirmationClickOpen(action.deactivate)} disableRipple sx={{ fontFamily: 'Inter-Regular, sans-serif', fontSize: '14px', color: '#323539' }}>
                                <PersonRemoveOutlinedIcon sx={{ color: '#323539' }} />
                                Deactivate
                            </MenuItem>
                            <MenuItem onClick={() => handleConfirmationClickOpen(action.block)} disableRipple sx={{ fontFamily: 'Inter-Regular, sans-serif', fontSize: '14px', color: '#323539' }}>
                                <BlockOutlinedIcon sx={{ color: '#323539' }} />
                                Block
                            </MenuItem>
                        </StyledMenu>
                    </div>
                </div>
                <div className="cardsWrapper">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleCardToggle('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordian_header"
                        >
                            <Typography className="tab_heading" sx={{ width: '33%', flexShrink: 0 }}>
                                Chats
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian_content">
                            <div className="chat_card__wrapper">
                                {chatsCard()}
                            </div>
                            {chatList && chatList.length > 0 &&
                                <div className="pagination_wrapper table__wrapper">
                                    <TablePagination count={pages.chats} siblingCount={0} defaultPage={page.chats} page={page.chats} boundaryCount={2} className="custom__pagination" onChange={(event: React.ChangeEvent<unknown>, value: number) => handleChange(value, 'chats')}/>
                                </div>
                            }
                        </AccordionDetails>
                    </Accordion>
                    {userDetails && userDetails?.role === UserRole.Candidate && <Accordion expanded={expanded === 'panel2'} onChange={handleCardToggle('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            className="accordian_header"
                        >
                            <Typography className="tab_heading" sx={{ width: '33%', flexShrink: 0 }}>Applications</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian_content">
                            <div className="table__wrapper">
                                <TableContainer component={Paper} className="custom__table">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <EnhancedTableHead
                                            headCells={applicationHeadCells}
                                            isAction={false}
                                            isSorting={false}
                                        />
                                        <TableBody>
                                            {applications()}
                                        </TableBody>
                                        <EnhancedTableFooter
                                            pages={pages.applications}
                                            page={page.applications}
                                            onPageChange={(value: number) => handleChange(value, 'applications')}
                                        />
                                    </Table>
                                </TableContainer>
                            </div>
                        </AccordionDetails>
                    </Accordion>}
                    {userDetails && userDetails?.role !== UserRole.Candidate && <Accordion expanded={expanded === 'panel3'} onChange={handleCardToggle('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            className="accordian_header"
                        >
                            <Typography className="tab_heading" sx={{ width: '33%', flexShrink: 0 }}>
                                Job Posts
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian_content">
                            <div className="table__wrapper">
                                <TableContainer component={Paper} className="custom__table">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <EnhancedTableHead
                                            headCells={jobPostsHeadCells}
                                            isAction={false}
                                            isSorting={true}
                                        />
                                        <TableBody>
                                            {jobPosts()}
                                        </TableBody>
                                        <EnhancedTableFooter
                                            pages={pages.jobPosts}
                                            page={page.jobPosts}
                                            onPageChange={(value: number) => handleChange(value, 'jobPosts')}
                                        />
                                    </Table>
                                </TableContainer>
                            </div>
                        </AccordionDetails>
                    </Accordion>}
                    <Accordion expanded={expanded === 'panel4'} onChange={handleCardToggle('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            className="accordian_header"
                        >
                            <Typography className="tab_heading" sx={{ width: '33%', flexShrink: 0 }}>Connections</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian_content">
                            <div className="table__wrapper">
                                <TableContainer component={Paper} className="custom__table">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <EnhancedTableHead
                                            headCells={connectionsHeadCells}
                                            isAction={false}
                                            isSorting={true}
                                        />
                                        <TableBody>
                                            {connections()}
                                        </TableBody>
                                        <EnhancedTableFooter
                                            pages={pages.connections}
                                            page={page.connections}
                                            onPageChange={(value: number) => handleChange(value, 'connections')}
                                        />
                                    </Table>
                                </TableContainer>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {userDetails && userDetails?.role !== UserRole.Candidate && <Accordion expanded={expanded === 'panel5'} onChange={handleCardToggle('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                            className="accordian_header"
                        >
                            <Typography className="tab_heading" sx={{ width: '33%', flexShrink: 0 }}>Associations</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordian_content">
                            <div className="table__wrapper">
                                <TableContainer component={Paper} className="custom__table">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <EnhancedTableHead
                                            headCells={associationsHeadCells}
                                            isAction={false}
                                            isSorting={true}
                                        />
                                        <TableBody>
                                            {associations()}
                                        </TableBody>
                                        <EnhancedTableFooter
                                            pages={pages.associations}
                                            page={page.associations}
                                            onPageChange={(value: number) => handleChange(value, 'associations')}
                                        />
                                    </Table>
                                </TableContainer>
                            </div>
                        </AccordionDetails>
                    </Accordion>}
                </div>
            </div>
            <ConfirmationPopupComponent
                open={openConfirmation}
                showReasonField={true}
                index={-1}
                title={confirmationTitle}
                message={confirmationMsg}
                btnLabel={confirmationBtnLabel}
                confirmationFor={confirmationFor}
                onClose={handleConfirmationClose}
                onConfirm={handleConfirmationConfirm}
            />
            {openFilter && <FilterPopupComponent
                open={openFilter}
                popoverId={popoverId}
                anchorEl={anchorFilter}
                filterState={filters}
                onlyDateFilter={true}
                onClose={handleToggleFilterPopup}
                onApplyFilter={handleFilterChange}
            />}
        </>
    );
}
