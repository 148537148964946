import { useEffect, useState } from "react";
import "./subscription-plans.scss";
import { CircularProgress } from "@mui/material";
import { apiService } from "../../../core/services/api.service";
import { toast } from "react-toastify";
import { PlansComponent } from "../../../components/subscriptions/plans/Plans.component";
import { AddOn, PlanData, PlanType } from "../../../types/plans.interface";
import { LiteJobsQuantity, PremiumJobsQuantity } from "../../../core/constants";
import { authController } from "../../../core/controllers/auth/auth.controller";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UserRole } from "../../../types/user.interface";
import { useAppSelector } from "../../../../store";
import { toastMessage } from "../../../core/services/toast";
const defaultPlanQty = [
  { planCode: PlanType.PREEMIUM, quantity: 5 },
  { planCode: PlanType.LITE, quantity: 1 },
];

export default function SubscriptionPlans(props: any) {
  const [plans, setPlans] = useState<PlanData[]>([]);
  const [searchParams] = useSearchParams();
  const isLoggedIn = authController.isUserLoggedIn();
  const navigate = useNavigate();
  const inviteCode = searchParams.get("inviteCode");
  const [organization, setOrganization] = useState<any>();
  const location = useLocation();
  const { state } = location;
  let isUpgrade = state?.isUpgrade;
  const role = authController.role;

  const isTokenAvailable: any = useAppSelector(
    (state) => state.auth.token
  );
  
  useEffect(() => {
    if(role === UserRole.Admin){
      navigate("/dashboard")
    }

    fetchSubscriptionsPlanList()
  }, [isTokenAvailable, role]);

  const setPlansQty = (planCode: any, qty: any) => {
    if(defaultPlanQty?.find((planQty: any) => planQty?.planCode === planCode)){
      defaultPlanQty.forEach((planQty: any) =>{
        if( planQty?.planCode === planCode){
          planQty.quantity = qty;
        }
      })
    }
  }

  const planDetail =(planDetails:any)=>{
    planDetails?.forEach((plan: PlanData) => {
      let qty: any;
      switch(plan?.plan_code) {
        case PlanType.PREEMIUM:
        case PlanType.PREMIUM_MONTHLY:
          qty = 5;
          setPlansQty(plan?.name,5);
          break;
        case PlanType.FREEMIUM:
        case PlanType.LITE:
          qty = 1;
          setPlansQty(plan?.name,1);
          break;
      }
      
      let resultOptions: any = [];

      switch(plan?.plan_code) {
        case PlanType.PREEMIUM:
        case PlanType.PREMIUM_MONTHLY:
          resultOptions = PremiumJobsQuantity;
          break;
        case PlanType.FREEMIUM:
          resultOptions = [1];
          break;
        case PlanType.LITE:
          resultOptions = LiteJobsQuantity;
          break;
        default:
          resultOptions = [];
          break;
      }
      const addons: any[] = [];
      plan?.addons?.forEach((addon: AddOn) => {
        addons.push({
          ...addon,
          plan_id: plan?.plan_id,
          totalPrice:
            addon?.price_brackets[0]?.price * qty,
          options: resultOptions,
          selected_qty: qty
        });
      });
      plan.addons = [...addons];
      plan.totalPrice =
        plan?.price_brackets[0]?.price +
        plan?.addons?.reduce(
          (prev: any, current: any) => prev + current?.totalPrice,
          0
        );
    });
    setPlans(planDetails);
  }

  // Fetch Subscription plans
  const fetchSubscriptionsPlanList = async () => {
    try {
      let url = `/subscription-management/organization/plans`;
      const { data } = await apiService.get(url);
      let planDetails: PlanData[] = data;
      if (isLoggedIn && role !== UserRole.Admin && isTokenAvailable) {
        const { data } = await apiService.get(`/organizations/my_organization`);
        if (data) {
          setOrganization(data);
          if (data?.subscription && !isUpgrade) {
            toastMessage(-1, "Your organization has already purchased the subscription plan.", "info");
            planDetails = filterPlans(planDetails);
            navigate(`/organizational-plans?requestId=${btoa(data?._id)}`, {state: {isUpgrade: true}});
          } 
        }
      } 

      if(isUpgrade){
        planDetails = filterPlans(planDetails);
      }
        
      planDetail(planDetails)
      return plans;
    } catch (error:any) {
      toastMessage(error.response.data.statusCode, null);
    }
  };

  const filterPlans = (plans : PlanData[]) =>{
    let filteredPlans:PlanData[] = plans?.filter((plan: PlanData) => plan?.plan_code !== PlanType.FREEMIUM) 
    return filteredPlans;
  }

  // Update Addon price on each onChange event
  const handleAddonPrice = (qty: any, addOnId: any, planId: any, planCode: any) => {
    if (qty?.toString().toLowerCase() === "Above 100".toLowerCase()) {
      if (planCode !== PlanType.PREMIUM_MONTHLY) {
        toast.info("For more jobs, Please contact us.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          closeButton: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
      return;
    }
    setPlansQty(planCode, qty);
   
    // Check for same plan and having same addon added
    if (
      plans.find(
        (plan) =>
          plan?.plan_id === planId &&
          plan?.addons.find((addon) => addon?.addon_id === addOnId)
      )
    ) {
      const planDetails = plans.map((plan: any) => {
        const addons: any[] = [];
        plan.addons?.forEach((addon: any) => {
          if (addon?.addon_id === addOnId && plan?.plan_id === planId) {
            addons.push({
              ...addon,
              plan_id: plan?.plan_id,
              totalPrice: addon.price_brackets[0]?.price * qty,
              selected_qty: qty,
            });
          } else {
            addons.push({
              ...addon,
              plan_id: plan?.plan_id,
            });
          }
        });
        plan.addons = [...addons];
        plan.totalPrice =
          plan.price_brackets[0]?.price +
          plan.addons?.reduce(
            (prev: any, current: any) => prev + current.totalPrice,
            0
          );
        return plan;
      });
      setPlans(planDetails);
    }
  };
  return (
    <>
      {plans.length > 0 ? (
        <PlansComponent
          plans={plans}
          handleAddonPrice={handleAddonPrice}
          inviteCode={inviteCode}
          isLoggedIn = {isLoggedIn}
          organization = {organization}
          isUpgrade = {isUpgrade}
          quantity = {defaultPlanQty}
        />
      ) : (
        <>
          <div className="no--content height100">
            <CircularProgress className="custom__spinner" />
          </div>
        </>
      )}
    </>
  );
}
