import React from "react";
import './job-list.scss';
import { useSearchParams, Link } from "react-router-dom";
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Skeleton, Tooltip, Select, MenuItem} from "@mui/material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { authorizationService } from "../../../core/services/authorization.service";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import { apiService } from "../../../core/services/api.service";
import { useAppSelector } from "../../../../store";
import JobAssociateComponent from "../../../components/dialogs/job-associate/job-associate.component";
import moment from 'moment';
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";

export default function JobsList(props:any){
    let [searchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const initialFilterValue = {
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };
    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );

    const breadcrumbs = [
        <Link key="1" color="inherit" to="/customer-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          Jobs
        </Typography>,
      ];
    const limit = 9;
    const [jobs, setJobs] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [jobsListLoading, setJobsListLoading] = React.useState(true);
    const [selectedJobDetails, setSelectedJobDetails] = React.useState(null)
    const [page, setPage] = React.useState(1);
    const [status, setStatus] = React.useState('all');
    const [searchTerm, setSearchTerm] = React.useState('')
    const [pages, setPages] = React.useState(0);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [index, setIndex] = React.useState(-1);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );

    React.useEffect(() => {
        const jobsPermissions = authorizationService.getPermissions(userDetails?.role, 'jobs', userDetails?.isPoc);
        setPermissions(jobsPermissions);
      }, [userDetails]);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(isTokenAvailable){
                getJobs()
            }
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, status, filters,isTokenAvailable])

    const getJobs = async() => {
        try{
            let url = `/employment?take=${limit}&skip=${(page-1)*limit}&order=-1&orderBy=${'createdAt'}` ;
            if (searchTerm.trim() !== '') {
              url = `${url}&term=${searchTerm}`;
            }
            if (status && status !== "all") {
              url = `${url}&status=${status}`;
            }
            if (filters.startDate) {
                url = `${url}&startDate=${filters.startDate}`;
            }
            if (filters.endDate) {
                url = `${url}&endDate=${filters.endDate}`;
            }
            const { data } = await apiService.get(url)
            setJobs(data.result)
            setPages(Math.ceil(data.count/limit))
            setJobsListLoading(false);
        }catch(err:any){
          toastMessage(err.response.data.statusCode, null);
        }
    }

    const dateFormat = (date:any) => {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setJobsListLoading(true);
        setPage(value)
    }

    const handleConfirmationClickOpen = (index:number, job: any) => {
        setIndex(index)
        setSelectedJobDetails(job);
        setOpenConfirmation(true);
    };

    const handleStatusChange = (value: string) => {
        setJobsListLoading(true);
        setPage(1);
        setStatus(value);
    };

    const handleSearchChange = (value: string) => {
        if ((value && value.length > 3) || value.length === 0) {
            setPage(1);
            setJobsListLoading(true);
            setSearchTerm(value);
        }
    }

    const handleConfirmationClose = (value: number) => {
        setOpenConfirmation(false);
        setSelectedJobDetails(null);
    };

    const handleFilterChange = (filterValues: any) => {
        setJobsListLoading(true);
        setPage(1);
        setFilters(filterValues);
    }
    
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilter(anchorFilter ? null : event.currentTarget);
        setOpenFilter((previousOpen) => !previousOpen);
    }  

    const associationPermission=(row:any,index:number)=>{
      if(permissions?.association){
        return (
          <div
            className="associate_job_action"
            title="Associate Hiring manager/Recruiter"
            onClick={() => handleConfirmationClickOpen(index, row)}
          >
            <InsertLinkOutlinedIcon />
          </div>
        );
      }
    }

    const reportPermission=(row:any)=>{
      if(permissions?.report){
        return (
          <div className="reports_details_wrapper">
            {row?.cardsData.map((card: any) => {
              return (
                <div key={`${row?._id}_${card.id}`} className="reports_details">
                  <div className="icon">
                    {card.id === "jobLikesCount" && <ThumbUpAltOutlinedIcon />}
                    {card.id === "candidatesLikesCount" && (
                      <HowToRegOutlinedIcon />
                    )}
                    {card.id === "matchesCount" && <HubOutlinedIcon />}
                    {card.id === "connectionsCount" && (
                      <ConnectWithoutContactOutlinedIcon />
                    )}
                  </div>
                  <div className="title">{card.label}</div>
                  <div className="count">{card.value}</div>
                </div>
              );
            })}
          </div>
        );
      }
    }

    const postedBy=(row:any)=>{
      if(!row?._user || row?._user?.name === null || row?._user?.name === undefined){
        return (
          <div className="detail">
            <div className="value">Hiring Manager Not Yet Determined</div>
          </div>
        );
      }else{
        return (
          <div className="detail">
            <div className="title">Posted By</div>
            <div className="value">
              {row?._user?.name ? (
                row?._user?.name
              ) : (
                <span className="email">{row?._user?.email}</span>
              )}
            </div>
          </div>
        );
      }
    }

    const showJobs=()=>{
      if(jobs.length > 0){
        const jobsList = jobs.map((row: any, index: number) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={row?._id}>
              <Card className="job__details_card">
                <CardContent className="job__details_card_content">
                  <div className="card_header_wrapper">
                    <Tooltip title={row?.title}>
                      <div className="card__heading">{row?.title}</div>
                    </Tooltip>
                    {associationPermission(row, index)}
                  </div>
                  {reportPermission(row)}
                  <div className="job__details_wrapper">
                    <div className="job__details borderRight">
                      <div className="icon">
                        <CalendarMonthOutlinedIcon />
                      </div>
                      <div className="detail">
                        <div className="title">Posted At</div>
                        <div className="value">
                          {dateFormat(row?.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div className="job__details">
                      <div className="icon">
                        <VerifiedUserOutlinedIcon />
                      </div>
                      <div className="detail">
                        <div className="title">Status</div>
                        <div className="value">{row?.status}</div>
                      </div>
                    </div>
                  </div>
                  <div className="job__details_wrapper">
                    <div className="job__details">
                      <div className="icon">
                        <AssignmentIndOutlinedIcon />
                      </div>
                      {postedBy(row)}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        });

        return jobsList;
      }else{
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card className="no_data_card">
              <CardContent>
                <div className="no__data">No Data Available !</div>
              </CardContent>
            </Card>
          </Grid>
        );
      }
    }

    const jobList=()=>{
      if(jobsListLoading){
        const skeleton =  [...Array(9)].map((value, key) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={value}>
              <Skeleton
                width={'100%'}
                height={310}
                animation="wave"
                variant="rectangular"
              />
            </Grid>
          );
        });

        return skeleton;
      }else{
        return showJobs();
      }

    }
    return(
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        Jobs
                    </div>
                    <div className="search__wrapper">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            className="custom__select__box"
                            defaultValue={status}
                            onChange={(event)=> handleStatusChange(event.target.value)} 
                            fullWidth
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="draft">Draft</MenuItem>
                            <MenuItem value="closed">Archived</MenuItem>
                        </Select>
                        <TextField
                            id="search"
                            type="search"
                            name="new-search"
                            autoComplete='off'
                            placeholder="Search"
                            variant="outlined"
                            className="custom__search"
                            defaultValue={searchTerm}
                            onChange={(event: any) => handleSearchChange(event.target.value)}
                            sx={{ width: 350 }}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>                    
                </div>
                <div className="job__details_card_wrapper">
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        {jobList()}
                    </Grid>
                    {jobs.length > 0 && <div className="pagination_wrapper">
                      <TablePagination count={pages} siblingCount={0} boundaryCount={2} className="custom__pagination" page={page} onChange={handleChange} />
                    </div>}
                </div>
            </div>
            {openConfirmation && 
                <JobAssociateComponent 
                open={openConfirmation}
                index={index}
                jobDetails={selectedJobDetails}
                onClose={handleConfirmationClose}
                onRefresh={getJobs}/>
            }
            {openFilter && <FilterPopupComponent 
                open={openFilter}
                popoverId={popoverId} 
                anchorEl={anchorFilter}
                filterState={filters}
                onlyDateFilter={true}
                onClose={handleToggleFilterPopup}
                onApplyFilter={handleFilterChange}
            />}
        </>
    )
}