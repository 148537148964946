import React from "react";
import '../confirmation-popup/confirmation-popup.scss';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { apiService } from "../../../core/services/api.service";
import { toastMessage } from "../../../core/services/toast";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";

const dateFormat = "YYYY-MM-DD";

export default function SyncCandidatesPopupComponent(props:any){
    const { onClose, open, orgId } = props;

    const [checked, setChecked] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<Dayjs | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setDate(null);
    };

    const syncOrganizationATSCandidates = async () => {
        try {
            let url = `/ats/candidate/sync?orgId=${orgId}`;
            if (dayjs(date).isValid()) {
                url = `${url}&syncAfter=${dayjs(date).format(dateFormat)}`;
            }
            const response = await apiService.get(url);
            let message:string = "Synchronizing ATS candidates!";
            toastMessage(response?.status, message, "info");
        } catch(error:any) {
            toastMessage(error?.response?.data?.statusCode, error?.response?.data?.message, "error");
        } finally {
            handleClose();
        }
    }

    const handleClose = () => {
        onClose();
        setChecked(false);
        setDate(null);
    };

    return(
        <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
                          style: {
                            maxWidth: 422,
                            padding: '14px 8px 24px',
                            borderRadius: 6
                          },
                        }} className="confirmDialog">
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        Sync ATS Candidates
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog__content">
                    <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} sx={{'&.Mui-checked': { color: '#9F2223' }}} />} label="Sync candidates after specific date" className="custom__popup__checkbox_content" />

                    {checked && <div className="reason__wrapper">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker 
                                    label="Date" 
                                    value={date} 
                                    onChange={(newValue) => setDate(newValue)} 
                                    format="MM-DD-YYYY" 
                                    slotProps={{ textField: { size: "small", error: false, } }} 
                                    sx={{ maxWidth: '150px !important', minWidth: '150px !important' }} 
                                    maxDate={dayjs()} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>}
                </DialogContent>
                <DialogActions className="custom__actions">
                    <Button color="primary" variant="contained" onClick={handleClose} className="custom__action__button cancel__button">
                    Cancel
                    </Button>
                    <Button color="secondary" variant="contained" onClick={syncOrganizationATSCandidates} className="custom__action__button confirm__button">
                        Sync
                    </Button>
                </DialogActions>
        </Dialog>
    )
}