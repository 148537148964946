import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../../app/core/services/api.service";
import {StoreState} from '../../app/core/controllers/auth/auth.interface';

export const fetchUserDetails = createAsyncThunk<any, any, {state: StoreState}>(
  "fetchUserDetails",
  async (_any, { getState, rejectWithValue }) => {
    try {
      const { data } = await apiService.get(`/user/me`);
      data.isPoc = false;
      const organizationData = await getState()?.organization?.myOrganization;
      if (organizationData && organizationData.email === data.email) {
        data.isPoc = true;
      }
      return data;
    } catch (error: any) {
      const errorData = {
        statusCode: error.response.status,
        error: error.response.data.detail,
      };
      return rejectWithValue(errorData);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userDetail: null,
    error: null,
    statusCode: null,
    token:false,
  },
  reducers: {
    resetError(state: any) {
      state.statusCode = null;
      state.error = null;
    },
    setTokenTrue(state){
      state.token = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
        fetchUserDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
            state.userDetail = action.payload;
            state.error = null;
            state.statusCode = 200;
        }
    );
    builder.addCase(
        fetchUserDetails.rejected,
        (state, action: PayloadAction<any>) => {
            const data = action.payload ? action.payload : null;
            state.statusCode = data ? data.statusCode : 500;
            state.error = data?.error ?? "Something went wrong";
            state.userDetail = null;
        }
    );
  }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
