import React from "react";
import './confirmation-popup.scss';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Typography,
    TextField
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { toastService } from '../../../core/services/toast.service'
import { TypeOptions } from 'react-toastify/dist/types';


export default function ConfirmationPopupComponent(props:any){
    const { onClose, index, open, message, title, btnLabel, onConfirm, showCheckbox, showReasonField } = props;

    const [checked, setChecked] = React.useState<boolean>(false);
    const [reason, setReason] = React.useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleReasonFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReason(event.target.value);
    };
    
    const handleConfirm = () => {
        if (showCheckbox) {
            onConfirm(index, checked);
        } else if (showReasonField) {
            if (reason.trim() === '') {
                let status: TypeOptions = "error";
                let message: string = "Reason is mandatory!";
                toastService.showToastNotification(status, message);
            } else {
                onConfirm(index, reason);
            }
        } else {
            onConfirm(index);
        }
        setChecked(false);
        setReason('');
    };

    const handleClose = () => {
        onClose(index);
        setChecked(false);
        setReason('');
    };

    const descriptionArea=()=>{
        if(showReasonField){
            return <>
            <Typography className="custom__popup__content textLeft" component="div">{message}</Typography>
            <div className="reason__wrapper">
                <InputLabel size="normal" className="input__label" focused>Reason*</InputLabel>
                <TextField
                    id="reason"
                    name="reason"
                    type="text"
                    size="small"
                    value={reason}
                    variant="outlined"
                    placeholder="Reason"
                    onChange={handleReasonFieldChange}
                    fullWidth />
            </div>
        </>
        }else{
            return <Typography className="custom__popup__content" component="div">{message}</Typography>
        }
    }

    return(
        <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
                          style: {
                            maxWidth: 422,
                            padding: '14px 8px 24px',
                            borderRadius: 6
                          },
                        }} className="confirmDialog">
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        {title}
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog__content">
                    {showCheckbox ? 
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} sx={{'&.Mui-checked': { color: '#9F2223' }}} />} label={message} className="custom__popup__checkbox_content" />
                    : descriptionArea()}
                </DialogContent>
                <DialogActions className="custom__actions">
                    <Button color="primary" variant="contained" onClick={handleClose} className="custom__action__button cancel__button">
                    Cancel
                    </Button>
                    <Button color="secondary" variant="contained" onClick={handleConfirm} className="custom__action__button confirm__button">
                    {btnLabel}
                    </Button>
                </DialogActions>
        </Dialog>
    )
}