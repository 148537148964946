import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Card,
    CardContent,
    Tooltip,
    Chip,
    Stack,
  } from '@mui/material';
import "../dialog.scss";
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from 'moment';

export default function JobDetailsPopupComponent(props:any){
    const { onClose, open, jobDetails } = props;

    const jobLikesStatistics=(jobDetail:any)=>{
        return (
            <div className="reports_details_wrapper">
              {jobDetail?.cardsData.map((card: any) => {
                return (
                  <div key={`${jobDetail?._id}_${card.id}`} className="reports_details">
                    <div className="icon">
                      {card.id === "jobLikesCount" && <ThumbUpAltOutlinedIcon />}
                      {card.id === "candidatesLikesCount" && (
                        <HowToRegOutlinedIcon />
                      )}
                      {card.id === "matchesCount" && <HubOutlinedIcon />}
                      {card.id === "connectionsCount" && (
                        <ConnectWithoutContactOutlinedIcon />
                      )}
                    </div>
                    <div className="title">{card.label}</div>
                    <div className="count">{card.value}</div>
                  </div>
                );
              })}
            </div>
        );
    }

    const postedBy=(jobDetail:any)=>{
        if(!jobDetail?._user || jobDetail?._user?.name === null || jobDetail?._user?.name === undefined){
          return (
            <div className="detail">
              <div className="value">Hiring Manager Not Yet Determined</div>
            </div>
          );
        }else{
          return (
            <div className="detail">
              <div className="title">Posted By</div>
              <div className="value">
                {jobDetail?._user?.name ? (
                  jobDetail?._user?.name
                ) : (
                  <span className="email">{jobDetail?._user?.email}</span>
                )}
              </div>
            </div>
          );
        }
    }

    const dateFormat = (date:any) => {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
    }

    const getLocationLabel = (location:any) => {
        let locationText = location?.city;
        if (location.state) {
            locationText = `${locationText}, ${location.state}`;
        }
        return locationText;
    }

    const handleClose = () => {
        onClose();
    };
    return(
        <>
            <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
                          style: {
                            maxWidth: 554,
                            padding: '14px 8px 20px',
                            borderRadius: 6
                          },
                        }}>
                <DialogTitle id="alert-dialog-title" className="custom__title jobDetailsPopupTitle" component="div">
                    <div>
                        Job Details
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component="div" className="dialog_content">
                        <div className="job__details_card_wrapper">
                            <Card className="job__details_card">
                                <CardContent className="job__details_card_content">
                                <div className="card_header_wrapper">
                                    <Tooltip title={jobDetails?.title}>
                                        <div className="card__heading">{jobDetails?.title}</div>
                                    </Tooltip>
                                </div>
                                {jobLikesStatistics(jobDetails)}
                                <div className="job__details_wrapper">
                                    <div className="job__details borderRight">
                                    <div className="icon">
                                        <CalendarMonthOutlinedIcon />
                                    </div>
                                    <div className="detail">
                                        <div className="title">Posted At</div>
                                        <div className="value">
                                        {dateFormat(jobDetails?.createdAt)}
                                        </div>
                                    </div>
                                    </div>
                                    <div className="job__details">
                                    <div className="icon">
                                        <VerifiedUserOutlinedIcon />
                                    </div>
                                    <div className="detail">
                                        <div className="title">Status</div>
                                        <div className="value">{jobDetails?.status}</div>
                                    </div>
                                    </div>
                                </div>
                                <div className="job__details_wrapper">
                                    <div className="job__details borderRight">
                                        <div className="icon">
                                            <AssignmentIndOutlinedIcon />
                                        </div>
                                        {postedBy(jobDetails)}
                                    </div>
                                    <div className="job__details">
                                        <div className="icon">
                                            <CorporateFareIcon />
                                        </div>
                                        <div className="detail">
                                            <div className="title">Organization</div>
                                            <div className="value">
                                                {jobDetails?._org?.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="job__details_wrapper">
                                    <div className="job__details borderRight">
                                    <div className="icon">
                                        <WorkOutlineIcon />
                                    </div>
                                    <div className="detail">
                                        <div className="title">Job Type</div>
                                        <div className="value">
                                        {jobDetails?.schedule}
                                        </div>
                                    </div>
                                    </div>
                                    <div className="job__details">
                                    <div className="icon">
                                        <AutoModeIcon />
                                    </div>
                                    <div className="detail">
                                        <div className="title">Work Mode</div>
                                        <div className="value">{jobDetails?.workMode}</div>
                                    </div>
                                    </div>
                                </div>
                                <div className="job__details_wrapper">
                                    <div className="job__details">
                                        <div className="icon">
                                            <LocationOnIcon />
                                        </div>
                                        <div className="detail">
                                            <div className="title">Locations</div>
                                            <div className="value">
                                                <Stack direction="row" sx={{flexWrap: "wrap", gap: '6px'}}>
                                                {jobDetails?.locations.map((location: any) => {
                                                    return (
                                                        <Chip key={getLocationLabel(location)} label={getLocationLabel(location)} variant="outlined" size="small" />
                                                    );
                                                })}
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="job__details_wrapper">
                                    <div className="job__details">
                                        <div className="icon">
                                            <PlaylistAddCheckIcon />
                                        </div>
                                        <div className="detail">
                                            <div className="title">Required Skills</div>
                                            <div className="value">
                                                <Stack direction="row" sx={{flexWrap: "wrap", gap: '6px'}}>
                                                {jobDetails?.requiredSkills.map((skill: string) => {
                                                    return (
                                                        <Chip key={skill} label={skill} variant="outlined" size="small" />
                                                    );
                                                })}
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="job__details_wrapper">
                                    <div className="job__details">
                                        <div className="icon">
                                            <VerticalSplitIcon />
                                        </div>
                                        <div className="detail">
                                            <div className="title">Desired Skills</div>
                                            <div className="value">
                                                <Stack direction="row" sx={{flexWrap: "wrap", gap: '6px'}}>
                                                {jobDetails?.desiredSkills.map((skill: string) => {
                                                    return (
                                                        <Chip key={skill} label={skill} variant="outlined" size="small" />
                                                    );
                                                })}
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="job__details_wrapper">
                                    <div className="job__details">
                                        <div className="icon">
                                            <BadgeIcon />
                                        </div>
                                        <div className="detail">
                                            <div className="title">Candidate Attributes</div>
                                            <div className="value">
                                                <Stack direction="row" sx={{flexWrap: "wrap", gap: '6px'}}>
                                                {jobDetails?.candidateAttributes.map((attribute: string) => {
                                                    return (
                                                        <Chip key={attribute} label={attribute} variant="outlined" size="small" />
                                                    );
                                                })}
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    )
}