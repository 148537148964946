import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, IconButton, InputLabel, TextField, Typography } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import dayjs from 'dayjs';
import { toastMessage } from '../../../core/services/toast';
import { apiService } from '../../../core/services/api.service';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';

import './create-referral.scss'
import React from "react";

interface FormFields {
    name: string;
    couponCode: string;
    referralLimit: string;
    expirationTimestamp: string;
};

dayjs.extend(quarterOfYear)

export default function CreateReferral(props: any) { 
    const { onClose, onRefresh, index, open, referralDetails } = props;

    const handleClose = () => {
        onClose(index);
    };

    const validations = Yup.object().shape({
        couponCode: Yup.string().trim().required('Coupon Code is required'),
        name: Yup.string().trim().required('Name is required'),
        referralLimit: Yup.string().trim().required('Referral Limit is required'),
        expirationTimestamp: Yup.string().trim().required('Expiry date is required'),
    })

    const initialValues = {
        name: `${referralDetails?.name ? referralDetails?.name : ''}`,
        couponCode: `${referralDetails?.couponCode ?referralDetails?.couponCode: '' }`,
        referralLimit: `${referralDetails?.referralLimit ? referralDetails?.referralLimit : ''}`,
        expirationTimestamp: `${referralDetails?.expirationTimestamp || new Date()}`
    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: validations,
        onSubmit: async (values, { resetForm }) => {
            if (formik.isValid) {               
                handleSubmit(values);
                resetForm({ values: {couponCode: '', name: '',referralLimit: '', expirationTimestamp: ''} });
            }
        }
    })

    const handleSubmit = async (formValues: FormFields) => {
        if(referralDetails?._id){
            const url = `/referral/${referralDetails?._id}`;
            try {
                const response = await apiService.patch(url, formValues);
                handleClose();
                onRefresh();
                let message:string =`Referral details updated successfully`;
                toastMessage(response.status, message)
            } catch(err:any) {
                toastMessage(err?.response?.data?.statusCode, err?.response?.data?.message);
            }
        }else{
            const url = `/referral`;
            try {
                const response = await apiService.post(url, formValues);
                handleClose();
                onRefresh();
                let message:string =`Referral created successfully`;
                toastMessage(response.status, message)
            } catch(err:any) {
                toastMessage(err?.response?.data?.statusCode, err?.response?.data?.message);
            }
        }
        
    }

    return(
        <>
            <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
                          style: {
                            maxWidth: 454,
                            padding: '14px 8px 20px',
                            borderRadius: 6
                          },
                        }}>
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        {referralDetails?._id? 'Edit' : 'Create'} Referral
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component="div" className="dialog_content">
                        <div className='referral__form'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="input__items">
                                    <InputLabel size="normal" className="input__label" focused>Name</InputLabel>
                                    <TextField
                                        id="name"
                                        name="name"
                                        type="text"
                                        value={formik.values.name}
                                        variant="outlined"
                                        placeholder="Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        fullWidth />
                                    {(formik.touched.name && formik.errors.name) && (
                                        <FormHelperText id='name-error-text' className="error_message">{formik.errors.name}</FormHelperText>
                                    )}
                                </div>
                                <div className="input__items">
                                    <InputLabel size="normal" className="input__label" focused>Coupon Code</InputLabel>
                                    <TextField
                                        id="couponCode"
                                        name="couponCode"
                                        type="text"
                                        value={formik.values.couponCode}
                                        variant="outlined"
                                        placeholder="Coupon Code"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                                        fullWidth />
                                    {(formik.touched.couponCode && formik.errors.couponCode) && (
                                        <FormHelperText id='name-error-text' className="error_message">{formik.errors.couponCode}</FormHelperText>
                                    )}
                                </div>
                                <div className="input__items">
                                    <InputLabel size="normal" className="input__label" focused>Referral Limit</InputLabel>
                                    <TextField
                                        id="referralLimit"
                                        name="referralLimit"
                                        type="text"
                                        value={formik.values.referralLimit}
                                        variant="outlined"
                                        placeholder="Referral Limit"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.referralLimit && Boolean(formik.errors.referralLimit)}
                                        fullWidth />
                                    {(formik.touched.referralLimit && formik.errors.referralLimit) && (
                                        <FormHelperText id='name-error-text' className="error_message">{formik.errors.referralLimit}</FormHelperText>
                                    )}
                                </div>
                                <div className="input__items">
                                    <InputLabel size="normal" className="input__label">Expiry</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker 
                                                value={dayjs(formik.values.expirationTimestamp)} 
                                                onChange={(value) => formik.setFieldValue("expirationTimestamp", value, true)}
                                                format="MM-DD-YYYY" 
                                                minDate={dayjs(new Date())}
                                                slotProps={{ textField: { 
                                                    size: "small", 
                                                    id: 'expirationTimestamp', 
                                                    name: 'expirationTimestamp', 
                                                    placeholder: "Expiry Date", 
                                                    variant: "outlined",
                                                    fullWidth: true, 
                                                    error: formik.touched.expirationTimestamp && Boolean(formik.errors.expirationTimestamp),
                                                    helperText: formik.touched.expirationTimestamp && formik.errors.expirationTimestamp
                                                } }} 
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                                <Grid container spacing={2} sx={{mt: '16px'}}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Button color="primary" variant="contained" onClick={handleClose} className="custom__action__button cancel__button"> Cancel </Button>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Button color="secondary" variant="contained" type="submit" className="custom__action__button confirm__button"> Save </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    )
}