import React from "react";
import dayjs from 'dayjs';
import { useSearchParams, Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { apiService } from "../../../core/services/api.service";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import Avatar from "@mui/material/Avatar";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";

  interface Data {
    email: string;
    status: number;
    subscription: string;
    name: string;
    contact: string;
  }
  
  type Order = 'asc' | 'desc';
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: true,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Email',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Status',
    },
    {
        id: 'contact',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Contact',
    },
  ];

  const rowSpan = 10;
  const colSpan = 4;

  export default function CandidateReferralsComponent() {
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Link key="2" color="inherit" to="/admin-portal/referral" className="inactive_menu">
            Referrals
        </Link>,
        <Typography key="3" color="text.primary" className="active_menu">
          Candidates
        </Typography>,
      ];
    let [searchParams] = useSearchParams();
    let { referralId } = useParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const initialFilterValue = {
      startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };
    const limit =10;
    const [candidates, setCandidates] = React.useState<any>([]);
    const [candidateListLoading, setCandidateListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    React.useEffect(() => {
      setCandidateListLoading(true);
      const delayDebounceFn = setTimeout(() => {
        getCandidatesList();
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }, [page, order, orderBy, filters, searchTerm])

    const candidateListCount=(data:any)=>{
      if(data[0].count.length){
        return data[0].count[0].count
      }else{
        return 0;
      }
    }

    const getCandidatesList = async() => {
      let url = `/referral/candidates/${referralId}?take=${limit}&skip=${(page-1)*limit}&order=${order === 'asc'?1:-1}&orderBy=${orderBy}&name=${searchTerm}` ;
      if (filters.startDate) {
        url = `${url}&startDate=${filters.startDate}`;
      }
      if (filters.endDate) {
          url = `${url}&endDate=${filters.endDate}`;
      }
      try{
      const { data } = await apiService.get(url);
      const candidateListData = data.length ? data[0].result : [];
      const count = data.length ? candidateListCount(data) : 0;
      setCandidates(candidateListData);
      setPages(Math.ceil(count/limit));
      setCandidateListLoading(false);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const candidateList=()=>{
      if(candidates.length > 0){
        const candidatesList = candidates.map((row:any, index:number) => {
          return(
            <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="custom__cell__row"
                >
                <TableCell align="left" className="custom__cell width70">
                    <Avatar>
                        <PersonOutlineOutlinedIcon /> 
                    </Avatar>
                    <span>{row.name}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">{row.email}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row.status === "active" ? 'Active' : 'Inactive' }</TableCell>
                <TableCell align="left" className="custom__cell__element">{row.contact}</TableCell>
            </TableRow>
          )
      })
      return candidatesList;
      }else{
        return (
            <TableRow>
                <TableCell colSpan={colSpan} className="no__data">
                No Data Available !
                </TableCell>
            </TableRow>
        )
      }
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setCandidateListLoading(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setCandidateListLoading(true);
      setPage(value)
    }

    const handleSearchChange = (value: string) => {
      if ((value && value.length > 3) || value.length === 0) {
        setPage(1);
        setCandidateListLoading(true);
        setSearchTerm(value);
      }
    };

    const handleFilterChange = (filterValues: any) => {
      setCandidateListLoading(false);
      setFilters(filterValues);
    }
  
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorFilter(anchorFilter ? null : event.currentTarget);
      setOpenFilter((previousOpen) => !previousOpen);
    }

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        Candidates
                    </div>
                    <div className="search__wrapper">
                        <TextField
                            id="search"
                            type="search"
                            name="new-search"
                            autoComplete='off'
                            placeholder="Search by name"
                            variant="outlined"
                            className="custom__search"
                            defaultValue={searchTerm}
                            onChange={(event: any) => handleSearchChange(event.target.value)}
                            sx={{ width: 350 }}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>
                </div>
                <div className="table__wrapper">
                  <TableContainer component={Paper} className="custom__table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        isAction = {false}
                        isSorting = {true}
                    />
                        <TableBody>
                            {candidateListLoading ? <TableSkeltonComponent rows={rowSpan} columns={colSpan} /> : candidateList()
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={colSpan}>
                                  <TablePagination count={pages} siblingCount={0} defaultPage={page} boundaryCount={2} className="custom__pagination" onChange={handleChange}/>
                                </TableCell>                                        
                            </TableRow>
                        </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
            </div> 
            {openFilter && <FilterPopupComponent 
              open={openFilter}
              popoverId={popoverId} 
              anchorEl={anchorFilter}
              filterState={filters}
              onlyDateFilter={true}
              onClose={handleToggleFilterPopup}
              onApplyFilter={handleFilterChange}
            />}  
        </>
    );
  }
