import React from 'react'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface PaginationInterface{
  color?:"primary" | "secondary" | "standard",
  count:number,
  siblingCount:number,
  defaultPage?: number,
  disabled?:boolean,
  page?:number;
  boundaryCount:number,
  className:string,
  onChange:(event: React.ChangeEvent<unknown>, value: number)=>void,

}
const TablePagination = (props:PaginationInterface) => {
  const prevBtn = (props:any)=>{
    return <Button startIcon={<ArrowBackIcon />} {...props} className="pagination__buttons">Prev</Button>
  }
  const nextBtn=(props:any)=>{
    return <Button endIcon={<ArrowForwardIcon />}  {...props} className="pagination__buttons">Next</Button>
  }
  return (
    <Pagination 
      color={props.color}
      count={props.count} 
      siblingCount={props.siblingCount} 
      defaultPage={props.defaultPage} 
      page={props.page}
      disabled={props.disabled}
      boundaryCount={props.boundaryCount} 
      className={props.className}
      onChange={props.onChange}
      renderItem={(item) => (
          <PaginationItem
          components={{
              previous: (props: any) => prevBtn(props),
              next: (props: any) => nextBtn(props),
          }}
          {...item}
          />
      )}/>
  )
}

export default TablePagination