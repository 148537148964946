import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../../app/core/services/api.service";

// fetch organization details
export const fetchOrganizationDetails = createAsyncThunk(
  "fetchOrganizationDetails",
  async (requestId: any, { rejectWithValue }) => {
    try {
      const { data } = await apiService.get(`/organization-lead/${requestId}`);
      console.log(data);
      return data;
    } catch (error: any) {
      const errorData = {
        statusCode: error.response.status,
        error: error.response.data.detail,
      };
      return rejectWithValue(errorData);
    }
  }
);

// update organization details
export const updateOrganizationDetails = createAsyncThunk(
  "updateOrganizationDetails",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await apiService.put(
        `/organization-lead/${payload._id}`,
        payload
      );
      console.log(data);
      return data;
    } catch (error: any) {
      const errorData = {
        statusCode: error.response.status,
        error: error.response.data.detail,
      };
      return rejectWithValue(errorData);
    }
  }
);

// fetch plan details
export const fetchPlan = createAsyncThunk(
  "fetchPlanDetails",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await apiService.get(
        `/subscription-management/organization/plans/${payload.planCode}?addons=${payload.addons}`
      );
      console.log(data);
      return data;
    } catch (error: any) {
      const errorData = {
        statusCode: error.response.status,
        error: error.response.data.detail,
      };
      return rejectWithValue(errorData);
    }
  }
);

// fetch my-organization details
export const fetchMyOrganizationDetails = createAsyncThunk(
  "fetchMyOrganizationDetails",
  async (any, { rejectWithValue }) => {
    try {
      const { data } = await apiService.get(`/organizations/my_organization`);
      console.log(data);
      return data;
    } catch (error: any) {
      const errorData = {
        statusCode: error.response.status,
        error: error.response.data.detail,
      };
      return rejectWithValue(errorData);
    }
  }
);

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization: null,
    error: null,
    statusCode: null,
    plan: null,
    myOrganization: null,
  },
  reducers: {
    resetError(state: any) {
      state.statusCode = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchOrganizationDetails.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.organization = action.payload;
        state.error = null;
        state.statusCode = 200;
      }
    );
    builder.addCase(
      fetchOrganizationDetails.rejected,
      (state, action: PayloadAction<any>) => {
        const data = action.payload ? action.payload : null;
        console.log(data);
        state.statusCode = data ? data.statusCode : 500;
        state.error = data?.error ?? "Something went wrong";
        state.organization = null;
      }
    );

    builder.addCase(
      updateOrganizationDetails.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.error = null;
        state.statusCode = 200;
      }
    );
    builder.addCase(
      updateOrganizationDetails.rejected,
      (state: any, action: PayloadAction<any>) => {
        const data = action.payload ? action.payload : null;
        state.statusCode = data ? data.statusCode : 500;
        state.error = data?.error ?? "Something went wrong";
      }
    );

    builder.addCase(
      fetchPlan.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.plan = action.payload;
        state.error = null;
        state.statusCode = 200;
      }
    );
    builder.addCase(
      fetchPlan.rejected,
      (state: any, action: PayloadAction<any>) => {
        state.plan = null;
        const data = action.payload ? action.payload : null;
        state.statusCode = data ? data.statusCode : 500;
        state.error = data?.error ?? "Something went wrong";
      }
    );

    builder.addCase(
      fetchMyOrganizationDetails.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.myOrganization = action.payload;
        state.error = null;
        state.statusCode = 200;
      }
    );
    builder.addCase(
      fetchMyOrganizationDetails.rejected,
      (state: any, action: PayloadAction<any>) => {
        const data = action.payload ? action.payload : null;
        state.statusCode = data ? data.statusCode : 500;
        state.error = data?.error ?? "Something went wrong";
      }
    );
  },
});

export const organizationActions = organizationSlice.actions;
export default organizationSlice.reducer;
