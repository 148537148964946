import React, { useEffect } from "react";
import './organization-details.scss';
import { Skeleton } from "@mui/material";
import Card from '@mui/material/Card';
import { useAppSelector } from "../../../../store";
import CardContent from '@mui/material/CardContent';
import { subscriptionAddons } from "../../../core/constants";
import { UserRole } from "../../../types/user.interface";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import Button from '@mui/material/Button';
import AddEditOrganization from "../../dialogs/organizations/add-edit-organization.component";
import PlanCard1Component from './plan-cards/PlanCard1.component';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { PlanType } from "../../../types/plans.interface";



export default function OrganizationDetailsComponent(props: any) {
    const {loading, renderedFrom, organizationDetails, showLoader, getOrganizationDetails} = props;

    const userDetails: any = useAppSelector(
      (state) => state.auth.userDetail
    );
    const [open, setOpen] = React.useState(false);
    const [showPlanDetails, setshowPlanDetails] = React.useState(true);
    const navigate = useNavigate();
    const keys=[0,1,2];

    useEffect(() => {
      if(organizationDetails && !organizationDetails?.subscription){
        setshowPlanDetails(false);
      }
    }, [organizationDetails?._id])
    
    const handleEditClick = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        showLoader(true);
        setTimeout(() => {
            getOrganizationDetails();
        }, 2000);
    };

    const handlePlanDetailsToggleClick = () => {
        setshowPlanDetails(prevState => !prevState);
    }

    const dateFormat = (date:any) => {
        let formattedDate = moment(date).format('DD-MMM-YYYY');
        return formattedDate;
    }

    const handleUpgradeAddons = () => {
        navigate(`/organizational-plans?requestId=${btoa(organizationDetails?._id)}`, {state: {isUpgrade: true}});
    }

    function iconComp (index:number){
      let iconComponent;
  
      if (index === 0) {
          iconComponent = <GroupOutlinedIcon className="icon" />;
      } else if (index === 1) {
          iconComponent = <PortraitOutlinedIcon className="icon" />;
      } else {
          iconComponent = <AccountCircleOutlinedIcon className="icon" />;
      }
      return iconComponent;
    }

    return (
      <>
        <div className="details_card_wrapper">
          {loading ? (
            <Skeleton
              width={"100%"}
              height={363}
              animation="wave"
              variant="rectangular"
            />
          ) : (
            <Card className="details_card" sx={{ width: "100%" }}>
              <CardContent className="details_card_content">
                <div className="details_card_heading_wrapper">
                  <div className="details_card_heading">Details</div>
                  {renderedFrom === UserRole.Admin && (
                    <div
                      className="edit_icon"
                      title="Edit"
                      onClick={handleEditClick}
                    >
                      <DriveFileRenameOutlineIcon className="icon" />
                    </div>
                  )}
                </div>
                <div className="details_card_details_row">
                  <div className="details_card_details_item">
                    <div className="item_heading">Organization</div>
                    <div className="item_detail">
                      {organizationDetails?.name}
                    </div>
                  </div>
                  <div className="details_card_details_item">
                    <div className="item_heading">POC Name</div>
                    <div className="item_detail">
                      {organizationDetails?.personName}
                    </div>
                  </div>
                </div>
                <div className="details_card_details_row">
                  <div className="details_card_details_item">
                    <div className="item_heading">POC Email</div>
                    <div className="item_detail">
                      {organizationDetails?.email}
                    </div>
                  </div>
                  <div className="details_card_details_item">
                    <div className="item_heading">Phone</div>
                    <div className="item_detail">
                      {organizationDetails?.contact}
                    </div>
                  </div>
                </div>
                {/* Commenting this code for now, may be needed in the future */}
                {/* <div className="details_card_details_row">
                  <div className="details_card_details_item">
                    <div className="item_heading">Company Size</div>
                    <div className="item_detail">
                      {organizationDetails?.size}
                    </div>
                  </div>
                  <div className="details_card_details_item">
                    <div className="item_heading">Revenue</div>
                    <div className="item_detail">
                      {organizationDetails?.revenue}
                    </div>
                  </div>
                </div> */}
                <div className="details_card_details_row">
                  <div className="details_card_details_item">
                    <div className="item_heading">Website</div>
                    <div className="item_detail">
                      {organizationDetails?.website}
                    </div>
                  </div>
                  <div className="details_card_details_item">
                    <div className="item_heading">Address</div>
                    <div className="item_detail">
                      {organizationDetails?.address}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
        {renderedFrom === UserRole.Admin && (
          <div className="subscriptions">
            {loading
              ? [...Array(3)].map((value, index) => {
                  return (
                    <Skeleton
                      width={388}
                      height={82}
                      key={keys[index]}
                      animation="wave"
                      variant="rectangular"
                    />
                  );
                })
              : organizationDetails?.cardsData.map(
                  (item: any, index: number) => {
                    return (
                      <PlanCard1Component
                        key={item.id}
                        count={item.value}
                        text={item.label}
                        icon={iconComp(index)}
                      />
                    );
                  }
                )}
          </div>
        )}
        <div className="plan_details__strip">
          {loading ? (
            <Skeleton
              width={"100%"}
              height={78}
              animation="wave"
              variant="rectangular"
            />
          ) : (
            <Card className="plan_details_card" sx={{ width: "100%" }}>
              <CardContent className="plan_details_card_content">
                <div className="plan_details_icon">
                  <AccountBalanceOutlinedIcon className="plan_icon" />
                </div>
                <div className="plan_details_heading_wrapper">
                  <div className="plan_details_heading">Plan Details</div>
                  <div className="plan_details_sub_heading">
                    {organizationDetails?.subscription
                      ? `${
                          organizationDetails?.subscription?.plan?.name
                        } valid till ${dateFormat(
                          organizationDetails?.subscription
                            ?.current_term_ends_at
                        )}`
                      : "Not Subscribed!!"}
                  </div>
                </div>
                <div className="view_details_btn_wrapper">
                  <Button
                    variant="contained"
                    disabled={!organizationDetails?.subscription}
                    className="custom__button custom__create__button"
                    onClick={handlePlanDetailsToggleClick}
                  >
                    View Details
                    {showPlanDetails ? (
                      <ArrowDownwardOutlinedIcon />
                    ) : (
                      <ArrowForwardOutlinedIcon />
                    )}
                  </Button>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
        {showPlanDetails && (
          <div className="plan_details_wrapper">
            {loading ? (
              <Skeleton
                width={"100%"}
                height={220}
                animation="wave"
                variant="rectangular"
              />
            ) : (
              <Card className="details_card" sx={{ width: "100%" }}>
                <CardContent className="details_card_content">
                  <div className="details_card_heading_wrapper">
                    <div className="details_card_heading">Plan Details</div>
                  </div>
                  <div className="details_card_details_row">
                    <div className="details_card_details_item">
                      <div className="item_heading">Plan Name</div>
                      <div className="item_detail">
                        {organizationDetails?.subscription?.plan?.name}
                      </div>
                    </div>
                    <div className="details_card_details_item">
                      <div className="item_heading">Price</div>
                      <div className="item_detail">{`${organizationDetails?.subscription?.currency_symbol}${organizationDetails?.subscription?.sub_total}`}</div>
                    </div>
                  </div>
                  <div className="details_card_details_row">
                    <div className="details_card_details_item">
                      <div className="item_heading">Subscribed On</div>
                      <div className="item_detail">
                        {dateFormat(
                          organizationDetails?.subscription
                            ?.current_term_starts_at
                        )}
                      </div>
                    </div>
                    <div className="details_card_details_item">
                      <div className="item_heading">Expired On</div>
                      <div className="item_detail">
                        {dateFormat(
                          organizationDetails?.subscription
                            ?.current_term_ends_at
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        )}
        {showPlanDetails && (
          <div className="plan_details_wrapper">
            {loading ? (
              <Skeleton
                width={"100%"}
                height={155}
                animation="wave"
                variant="rectangular"
              />
            ) : (
              <Card className="details_card" sx={{ width: "100%" }}>
                <CardContent className="details_card_content">
                  <div className="details_card_heading_wrapper">
                    <div className="details_card_heading">Addons</div>
                    {renderedFrom !== UserRole.Admin && userDetails?.isPoc && (
                      <div className="view_details_btn_wrapper">
                        <Button
                          variant="contained"
                          className="custom__button custom__create__button"
                          onClick={handleUpgradeAddons}
                        >
                          {organizationDetails?.subscription?.plan
                            ?.plan_code === PlanType.FREEMIUM
                            ? "Upgrade Plan"
                            : "Buy More Addons"}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="details_card_details_row">
                    <div className="details_card_details_item">
                      <div className="item_heading">Name</div>
                      {organizationDetails?.subscriptionAddons?.map(
                        (addon: any, index: number) => {
                          return (
                            <div className="item_detail" key={addon.name}>
                              {
                                subscriptionAddons[
                                  addon?.name as keyof typeof subscriptionAddons
                                ]
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="details_card_details_item">
                      <div className="item_heading">Total Quantity</div>
                      {organizationDetails?.subscriptionAddons?.map(
                        (addon: any, index: number) => {
                          return (
                            <div
                              className="item_detail item_detail_center"
                              key={addon.name}
                            >
                              {addon?.totalCount}
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="details_card_details_item">
                      <div className="item_heading">Used Quantity</div>
                      {organizationDetails?.subscriptionAddons?.map(
                        (addon: any, index: number) => {
                          return (
                            <div
                              className="item_detail item_detail_center"
                              key={addon.name}
                            >
                              {addon?.usageCount}
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="details_card_details_item">
                      <div className="item_heading">Remaining Quantity</div>
                      {organizationDetails?.subscriptionAddons?.map(
                        (addon: any, index: number) => {
                          return (
                            <div
                              className="item_detail item_detail_center"
                              key={addon.name}
                            >
                              {addon?.totalCount - addon?.usageCount}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        )}
        {open && (
          <AddEditOrganization
            open={open}
            data={organizationDetails}
            onClose={handleClose}
          />
        )}
      </>
    );
}
