// import '../admin-subscription.scss'
import "../../../../pages/admin/subscriptions/admin-subscription.scss"

export default function PlanCard1Component(props:any){
    return(
        <>
            <div className='subscription__box'>
                <div className='subscription__inner__box'>
                    <div className='icon__box'>
                        {/* <PaidIcon className='icon' /> */}
                        {props?.icon}
                    </div>
                    <div className='text__box'>
                        <div className='main__text'>
                            {props?.count}
                        </div>
                        <div className='sub__text'>
                            {props?.text}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} 