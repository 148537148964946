export const permissions = {
    "super_admin": {
        "dashboard": {
            read: true
        },
        "referral": {
            read: true,
            add: true,
            edit: true,
            delete: true,
            report: true,
            details: true
        },
        "organizations": {
            read: true,
            add: true,
            edit: true,
            delete: true,
            resendInvite: true,
            report: true,
            details: true
        },
        "users": {
            read: true,
            add: true,
            edit: true,
            delete: true,
            resendInvite: true
        },
        "subscriptions": {
            read: true
        },
        "candidates": {
            read: true
        },
        "atsCandidates": {
            read: true
        },
        "jobs": {
            read: true
        },
        "nsfw": {
            read: true
        },
        "reportedUsers": {
            read: true,
            viewHistory: true,
            action: true
        }
    },
    "admin": {
        "dashboard": {
            read: true
        },
        "organizations": {
            read: true,
            add: true,
            edit: true,
            delete: true,
            resendInvite: true,
            report: true,
            details: true
        },
        "referral": {
            read: true,
            add: true,
            edit: true,
            delete: true,
            report: true,
            details: true
        },
        "users": {
            read: false,
            add: false,
            edit: false,
            delete: false,
            resendInvite: false
        },
        "subscriptions": {
            read: true
        },
        "candidates": {
            read: true
        },
        "atsCandidates": {
            read: true
        },
        "jobs": {
            read: true
        },
        "nsfw": {
            read: true
        },
        "reportedUsers": {
            read: true,
            viewHistory: true,
            action: true
        }
    },
    "poc": {
        "dashboard": {
            read: true
        },
        "users": {
            read: true,
            add: true,
            edit: true,
            delete: true,
            resendInvite: true,
            association: true
        },
        "jobs": {
            read: true,
            association: true,
            report: true
        },
        "atsJobs": {
            read: true,
            add: true
        },
        "subscription": {
            read: true
        },
        "connectATS": {
            read: true
        }
    },
    "recruiter": {
        "dashboard": {
            read: true
        },
        "users": {
            read: true,
            add: true,
            edit: true,
            delete: false,
            resendInvite: true,
            association: true
        },
        "jobs": {
            read: true,
            association: true,
            report: true
        },
        "atsJobs": {
            read: true,
            add: true
        },
        "subscription": {
            read: true
        },
        "connectATS": {
            read: false
        }
    },
    "hr": {
        "dashboard": {
            read: true
        },
        "users": {
            read: true,
            add: false,
            edit: true,
            delete: false,
            resendInvite: true,
            association: true
        },
        "jobs": {
            read: true,
            association: false,
            report: true
        },
        "atsJobs": {
            read: true,
            add: true
        },
        "subscription": {
            read: true
        },
        "connectATS": {
            read: false
        }
    }
};
