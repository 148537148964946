import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import logo from "../../../../assets/tango_logo.png";
import "./email-verification.scss";
import {
  AuthMethodEnum,
  EmailVerificationPayload,
} from "../../../core/controllers/auth/auth.interface";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { apiService } from "../../../core/services/api.service";
import { Box, Button, CircularProgress, FormGroup } from "@mui/material";
import { useAppDispatch} from "../../../../store";
import {
  resendEmailOTP
} from "../../../../store/slice/email-verification-slice";
import { authController } from "../../../core/controllers/auth/auth.controller";
import { toastService } from "../../../core/services/toast.service";
import { TermsOfService } from "../terms-of-service/terms-of-service";
import AppFooter from "../../../components/app-footer/app-footer";
import { toastMessage } from "../../../core/services/toast";

export default function EmailVerification() {
  const [searchParams] = useSearchParams();
  const requestId: any = searchParams.get("requestId");
  const requestData: any = JSON.parse(atob(requestId));

  const dispatch = useAppDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [checked, setChecked] = useState(false);
  const [termsAndPolicyError, setTermsAndPolicyError] = useState(false);

  const subscriptionValidationSchema = Yup.object().shape({
    email: Yup.string()
      .trim("The email cannot include leading and trailing spaces")
      .strict(true)
      .email("Invalid Email")
      .required("Email is required"),
    password: Yup.string()
      .required("Create Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), ""], "Confirm Password does not match"),
    otp: Yup.number().typeError("Invalid Secure Code").required("Secure Code is required"),
  });

  const resendOTPHandler = async () => {
    const payload = {
      email: requestData?.email,
      task: "organization invite",
    };
    const data: any = await dispatch(resendEmailOTP(payload));
    if (data && data?.payload?.statusCode === 400) {
      toastService.showToastNotification("error", data?.payload?.error);
    } else if (data && data?.payload?.success === false) {
      toastService.showToastNotification("error", data?.payload?.message);
    } else {
      toastService.showToastNotification(
        "info",
        `Secure Code has been sent to ${requestData?.email}`
      );
    }
  };

  const handleChange = (checked:any) => {
    setChecked(checked);
    if (!checked) {
      setTermsAndPolicyError(true);
    } else {
      setTermsAndPolicyError(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: `${requestData?.email ? requestData?.email : ""}`,
      otp: `${requestData?.otp ? requestData?.otp : ""}`,
      password: "",
      confirmPassword: "",
    },
    validationSchema: subscriptionValidationSchema,
    onSubmit: async (values) => {
      if (!checked) {
        setTermsAndPolicyError(true);
        return;
      }
      setShowLoader(true);
      if (!requestData) {
        return;
      }
      const payload: EmailVerificationPayload = {
        _id: requestData?._id,
        email: requestData?.email,
        otp: values?.otp,
        password: values?.password,
        confirmPassword: values?.confirmPassword,
      };
      try {
        await apiService.post(
          `/organization-lead/onboard/${requestData?._id}`,
          payload
        );
        authController.login({
          method: AuthMethodEnum.EmailAndPassword,
          username: requestData?.email,
          password: values.password,
          isSubscriptionCheckout: true,
          requestId: btoa(requestData?._id),
        });
      } catch (error: any) {
        setChecked(false);
        setShowLoader(false);
        let message = error?.response?.data?.message;
        toastMessage(error.response.data.statusCode, message);
      }
    },
  });

  return (
    <>
      {showLoader ? (
        <div className="no--content">
          <CircularProgress className="custom__spinner" />
        </div>
      ) : (
        <div className="email_verify_root">
          <div className="email__content__background"></div>
          <div className="email_verification_wrapper">
            <div className="email_verification_box">
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <div className="heading">Verify Email</div>
              <form onSubmit={formik.handleSubmit}>
                <div className="input__items">
                  <InputLabel size="normal" className="input__label" focused>
                    Email Address
                  </InputLabel>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    variant="outlined"
                    placeholder="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth
                    disabled
                    className="disabled__input"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText
                      id="email-error-text"
                      className="error_message"
                    >
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </div>
                <div className="input__items">
                  <InputLabel size="normal" className="input__label" focused>
                    Enter Secure Code
                  </InputLabel>
                  <FormGroup row>
                    <TextField
                      id="otp"
                      name="otp"
                      type="text"
                      variant="outlined"
                      placeholder="Enter Secure Code sent over the mail"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.otp}
                      error={formik.touched.otp && Boolean(formik.errors.otp)}
                      sx={{ width: "340px" }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        background: "#9f2223",
                        ":hover": { background: "#c91111" },
                      }}
                      disableElevation
                      onClick={resendOTPHandler}
                    >
                      Resend
                    </Button>
                  </FormGroup>
                  {formik.touched.otp && formik.errors.otp && (
                    <FormHelperText
                      id="otp-error-text"
                      className="error_message"
                    >
                      {formik.errors.otp}
                    </FormHelperText>
                  )}
                </div>
                <div className="input__items">
                  <InputLabel size="normal" className="input__label" focused>
                    Create Password
                  </InputLabel>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    variant="outlined"
                    placeholder="Create Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    fullWidth
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText
                      id="password-error-text"
                      className="error_message"
                    >
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </div>
                <div className="input__items">
                  <InputLabel size="normal" className="input__label" focused>
                    Confirm Password
                  </InputLabel>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    variant="outlined"
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    fullWidth
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <FormHelperText
                        id="confirm-error-text"
                        className="error_message"
                      >
                        {formik.errors.confirmPassword}
                      </FormHelperText>
                    )}
                </div>
                <TermsOfService handleChange = {handleChange} fontSize={"15px"}/>
                {termsAndPolicyError && (
                    <Box sx={{ color: "red", justifyContent:"start" }}>
                      Please agree to the Terms Of Service and Privacy Policy.
                    </Box>
                  )}
                <div className="action__btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
              <div>
                <span className="remember__text">Already have an account?</span>
                &nbsp;&nbsp;&nbsp;
                <a className="m-r-5" href={`/login`}>
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <AppFooter/>

    </>
  );
}
