import React from "react";

import './count-card.scss'
import { useNavigate, useLocation  } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CountCard = (props:any) => {
    const navigate = useNavigate();
    const { pathname, hash, key } = useLocation();

    React.useEffect(() => {
        if (hash === '') {
          window.scrollTo(0, 0);
        } else {
          setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id)?.offsetTop;
            if (element) {
                window.scrollTo({ top: element - 100, behavior: 'smooth'});
            }
          }, 0);
        }
    }, [pathname, hash, key]);

    const handleNavigation = () => {
        if (props.data.url === '#job-posts') {
            navigate('#jobPostsChart');
        } else {
            const cardIds = ['userCount', 'jobsCount', 'organizationsCount', 'candidatesCount', 'subscriptionsCount'];
            const filters = props.filters;
            const url = `${props.data.url}${cardIds.includes(props.data.id) ? '?' : '&'}startDate=${filters.startDate}&endDate=${filters.endDate}`;
            navigate(url);
        }
    };

    return(
        <>
            <Card variant="outlined" className="custom__card">
                <CardContent sx={{p: '0px !important'}}> 
                    <div className="custom__card__header">
                        <div className="card__title">
                            {props.data.title}
                        </div>
                        {props.redirectOnPage && <ArrowForwardIcon className="forward__icon" onClick={handleNavigation} />}
                    </div>                  
                    <div className="count">
                        <span className="count__title">{props.data.count}</span>
                        <span className="count__subtitle">{props.data.subtitle}</span>
                    </div>
                    {props.data?.isActive && props.data?.activeCount && (
                        <div className="active__count">
                            {props.data?.activeCount} Active
                        </div>
                    )}
                </CardContent>
            </Card>
        </>
    )
}

export default CountCard