import React from "react";
import './organization-form.scss';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { OrganizationRevenue, employeeSize, phoneRegExp } from "../../../core/constants";
import { apiService } from "../../../core/services/api.service";
import Grid from '@mui/material/Grid';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toastMessage } from "../../../core/services/toast";

const orgSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().trim('The email cannot include leading and trailing spaces').strict(true).email('Invalid Email').required('Email is required'),
    address: Yup.string().required('Address is required'),
    website: Yup.string().trim().required("Website is required").test("websiteValidation","Enter valid website (must start with https://)",(websiteName)=>{
    const websiteRegex = /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})(?:\/[\w.-]*)*\/?$/;
    const result = websiteRegex.test(websiteName);
    return result;
    }),
    contact: Yup.string().trim().required("Contact Number is required").test("contactValidation","Enter Valid Contact Number",(contact)=>{
        const result = phoneRegExp.test(contact);
        return result;
    }),
    personName: Yup.string().required('POC Name is required'),
    size: Yup.string().required('Company Size is required'),
    revenue: Yup.string().required('Revenue is required'),
    city: Yup.string(),
    state: Yup.string(),
    zipcode: Yup.string(),
    country: Yup.string(),
  })

export default function OrganizationFormComponent(props:any) {

    const [cities, setCities] = React.useState<any>([]);
    const [cityInputValue, setCityInputValue] = React.useState('');

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (cityInputValue.length > 2) {
                fetchCities();
            } else {
                setCities([]);
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [cityInputValue])

    const formik = useFormik({
        initialValues: {
            name: `${props?.data?.name || ''}`,
            email: `${props?.data?.email || ''}`,
            website: `${props?.data?.website || ''}`,
            address: `${props?.data?.address || ''}`,
            contact: `${props?.data?.contact || ''}`,
            personName: `${props?.data?.personName || ''}`,
            size: `${props?.data?.size || ''}`,
            revenue: `${props?.data?.revenue || ''}`,
            city: `${props?.data?.city || ''}`,
            state: `${props?.data?.state || ''}`,
            zipcode: `${props?.data?.zipcode || ''}`,
            country: `${props?.data?.country || ''}`,
        },
        enableReinitialize: true,
        validationSchema: orgSchema,
        onSubmit: async(values) => {
            
            let message:string ="";
            try{
                if(props?.data?._id){
                    let url = `/organizations/${props?.data?._id}`;
                    const response = await apiService.put(url, values);
                    message = `Successfully ${props?.data?.name}, Organization updated.`;
                    toastMessage(response.status, message);
                    props.onSuccess();
                }else{
                    let url = '/organizations/create';
                    const { data, status } = await apiService.post(url, values);
                    if(data){
                        message = `Successfully ${values.name}, Organization created.`;
                        toastMessage(status, message);
                        props.onSuccess();
                        // return <Navigate to="/dashboard"/>
                    }
                }
                
            }catch(error:any){
                let message = "";
                if(error.response.data.statusCode === 400){
                    message = error.response.data.message
                }else{
                    message = "Organization not created"
                }
               
                toastMessage(error.response.data.statusCode, message, "error");
            }
        },
      });

      const fetchCities = async () => {
        try {
            setCities([]);
            const url = `/organizations/search/location?partialLocation=${cityInputValue.trim()}`;
            const { data } = await apiService.get(url);
            const citiesList = data || [];
            setCities(citiesList);
        } catch (err: any) {
            setCities([]);
            toastMessage(err.response.data.statusCode,null);
        }
      };

      const handleCityChange = (value: any) => {
        if (value && value !== null) {
            formik.setFieldValue("city", value.city);
            formik.setFieldValue('state', value.state);
            formik.setFieldValue('country', value.country);
        } else {
            formik.setFieldValue("city", '');
            formik.setFieldValue('state', '');
            formik.setFieldValue('country', '');
        }
      }

        return (
            
                <div className="organization__form">
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>Organization Name</InputLabel>
                                <TextField 
                                    id="name" 
                                    name="name" 
                                    type="text" 
                                    size="small"
                                    variant="outlined" 
                                    placeholder="Name" 
                                    value={formik.values.name}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    fullWidth />
                                {(formik.touched.name && formik.errors.name) && (
                                    <FormHelperText id='name-error-text' className="error_message">{formik.errors.name}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>POC Name</InputLabel>
                                <TextField 
                                    id="personName" 
                                    name="personName" 
                                    type="text" 
                                    size="small"
                                    variant="outlined" 
                                    placeholder="POC Name" 
                                    value={formik.values.personName}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.personName && Boolean(formik.errors.personName)}
                                    fullWidth />
                                {(formik.touched.personName && formik.errors.personName) && (
                                    <FormHelperText id='name-error-text' className="error_message">{formik.errors.personName}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>POC Email address</InputLabel>
                                <TextField 
                                    id="email" 
                                    name="email" 
                                    type="email" 
                                    size="small"
                                    variant="outlined" 
                                    placeholder="Email" 
                                    value={formik.values.email}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    fullWidth
                                    disabled = {props?.data?.email} 
                                    className={props?.data?.email ? "disabled__input" : ""}/>
                                {(formik.touched.email && formik.errors.email) && (
                                    <FormHelperText id='email-error-text' className="error_message">{formik.errors.email}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>Phone</InputLabel>
                                <TextField 
                                    id="contact" 
                                    name="contact" 
                                    type="text" 
                                    size="small"
                                    variant="outlined" 
                                    placeholder="Contact" 
                                    value={formik.values.contact}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.contact && Boolean(formik.errors.contact)}
                                    fullWidth />
                                {(formik.touched.contact && formik.errors.contact) && (
                                    <FormHelperText id='contact-error-text' className="error_message">{formik.errors.contact}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused  id="size-select-label">Company Size</InputLabel>
                                <Select
                                    labelId="size-select-label"
                                    id="size-select"
                                    variant="outlined"
                                    displayEmpty
                                    name="size"
                                    className='input__select'
                                    fullWidth
                                    size='small'
                                    value={formik.values.size}
                                    onChange={formik.handleChange}
                                    error={formik.touched.size && Boolean(formik.errors.size)}
                                >
                                    <MenuItem sx={{fontSize:14}} value="" disabled>
                                        Select Company Size
                                    </MenuItem>                                    
                                    {Object.values(employeeSize).map((data: any) => (
                                        <MenuItem key={data} sx={{fontSize:14}} value={data}>{data}</MenuItem>
                                    ))}
                                </Select>
                                {(formik.touched.size && formik.errors.size) && (
                                    <FormHelperText id='size-error-text' className="error_message">{formik.errors.size}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused id="revenue-select-label">Revenue</InputLabel>
                                <Select
                                    labelId="revenue-select-label"
                                    id="revenue-select"
                                    variant="outlined"
                                    displayEmpty
                                    name="revenue"
                                    className='input__select'
                                    fullWidth
                                    size='small'
                                    value={formik.values.revenue}
                                    onChange={formik.handleChange}
                                    error={formik.touched.revenue && Boolean(formik.errors.revenue)}
                                >
                                    <MenuItem sx={{fontSize:14}} value="" disabled>
                                        Select Revenue
                                    </MenuItem>
                                    {OrganizationRevenue.map((data: any) => (
                                        <MenuItem key={data} sx={{fontSize:14}} value={data}>{data}</MenuItem>
                                    ))}
                                </Select>
                                {(formik.touched.revenue && formik.errors.revenue) && (
                                    <FormHelperText id='revenue-error-text' className="error_message">{formik.errors.revenue}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>Website</InputLabel>
                                <TextField 
                                    id="website" 
                                    name="website" 
                                    type="text" 
                                    size="small"
                                    variant="outlined" 
                                    placeholder="Website" 
                                    value={formik.values.website}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.website && Boolean(formik.errors.website)}
                                    fullWidth />
                                {(formik.touched.website && formik.errors.website) && (
                                    <FormHelperText id='name-error-text' className="error_message">{formik.errors.website}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>Address</InputLabel>
                                <TextField 
                                    id="address" 
                                    name="address" 
                                    type="text" 
                                    size="small"
                                    multiline
                                    rows={2}
                                    variant="outlined" 
                                    placeholder="Address" 
                                    value={formik.values.address}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                    fullWidth />
                                {(formik.touched.address && formik.errors.address) && (
                                    <FormHelperText id='name-error-text' className="error_message">{formik.errors.address}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused id="city-select-label">City</InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="city-dropdown"
                                    onInputChange={(event: any, newInputValue) => {
                                        setCityInputValue(newInputValue);
                                    }}
                                    size="small"
                                    options={cities}
                                    filterOptions={(x) => x}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    value={formik.values.city ? formik.values.city : null}
                                    getOptionLabel={(option: any) => typeof option === 'string' ? option : option.city +", "+ option.state}
                                    onChange={(event: any, newValue) => {
                                        handleCityChange(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} name="city" placeholder="Search city"  className="cityDroddownInput" />}
                                />
                                {(formik.touched.city && formik.errors.city) && (
                                    <FormHelperText id='city-error-text' className="error_message">{formik.errors.city}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>State</InputLabel>
                                <TextField 
                                    id="state" 
                                    name="state" 
                                    type="text" 
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    className="disabled__input"
                                    variant="outlined" 
                                    placeholder="State" 
                                    value={formik.values.state}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.state && Boolean(formik.errors.state)}
                                    fullWidth />
                                {(formik.touched.state && formik.errors.state) && (
                                    <FormHelperText id='state-error-text' className="error_message">{formik.errors.state}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>Country</InputLabel>
                                <TextField 
                                    id="country" 
                                    name="country" 
                                    type="text" 
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    className="disabled__input"
                                    variant="outlined" 
                                    placeholder="Country" 
                                    value={formik.values.country}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    fullWidth />
                                {(formik.touched.country && formik.errors.country) && (
                                    <FormHelperText id='country-error-text' className="error_message">{formik.errors.country}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel size="normal" className="input__label" focused>Zipcode</InputLabel>
                                <TextField 
                                    id="zipcode" 
                                    name="zipcode" 
                                    type="text" 
                                    size="small"
                                    variant="outlined" 
                                    placeholder="Zipcode" 
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                                    fullWidth />
                                {(formik.touched.zipcode && formik.errors.zipcode) && (
                                    <FormHelperText id='zipcode-error-text' className="error_message">{formik.errors.zipcode}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <div className="action__btn">
                            <button type="submit">{props?.data?._id ? 'Update' : 'Save'} Organization</button>
                        </div>
                    </form>
                </div>     
            

        );
}