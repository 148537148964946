import React from "react";
import './thankyou-popup.scss';
import { useNavigate } from "react-router-dom";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    DialogActions,
    Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function ThankyouPopupComponent(props:any){
    const navigate = useNavigate();
    const { onClose, open, requestId, msg} = props;

    const handleClose = () => {
        onClose();
    };

    const handleContinueBuyPlan = () => {
        navigate(`/email-verification?requestId=${requestId}`);
    };

    return(
        <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
            style: {
            maxWidth: 422,
            padding: '14px 8px 24px',
            borderRadius: 6
            },
        }} className="confirmDialog">
            <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                <div>Thank You</div>
                <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog__content">
                <Typography className="custom__popup__content" component="div">
                    {msg}
                </Typography>
            </DialogContent>
            <DialogActions className="custom__actions">
                {requestId && <Typography className="custom__popup__content" component="div">
                    If you want to buy this plan then <Link component="button" variant="body2" onClick={handleContinueBuyPlan} underline="hover">click here!!</Link>
                </Typography>}
            </DialogActions>
        </Dialog>
    )
}