import React from "react";
import './admin-form.scss';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const orgSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    contact: Yup.string().required('Contact No. is required'),
  })

export default function AdminFormComponent(props:any) {
    const formik = useFormik({
        initialValues: {
            name: `${props?.data?.name || ''}`,
            contact: `${props?.data?.contact || ''}`,
            email: `${props?.data?.email || ''}`
        },
        enableReinitialize: true,
        validationSchema: orgSchema,
        onSubmit: async(values) => {
            props.onSuccess(props?.data?._id, values);
        },
      })

        return (
                <div className="admin__form">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="input__items">
                            <InputLabel size="normal" className="input__label" focused>User Name</InputLabel>
                            <TextField 
                            id="name" 
                            name="name" 
                            type="text" 
                            defaultValue={props?.data?.name}
                            variant="outlined" 
                            placeholder="Name" 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            fullWidth />
                            {(formik.touched.name && formik.errors.name) && (
                                <FormHelperText id='name-error-text' className="error_message">{formik.errors.name}</FormHelperText>
                            )}
                        </div>
                        <div className="input__items">
                            <InputLabel size="normal" className="input__label" focused>Contact</InputLabel>
                            <TextField 
                            id="contact" 
                            name="contact" 
                            type="text" 
                            defaultValue={props?.data?.contact}
                            variant="outlined"
                            placeholder="Contact" 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            error={formik.touched.contact && Boolean(formik.errors.contact)}
                            fullWidth />
                            {(formik.touched.contact && formik.errors.contact) && (
                                <FormHelperText id='contact-error-text' className="error_message">{formik.errors.contact}</FormHelperText>
                            )}
                        </div>
                        <div className="input__items">
                            <InputLabel size="normal" className="input__label" focused>Email</InputLabel>
                            <TextField 
                            id="email" 
                            name="email" 
                            type="email" 
                            disabled={true}
                            defaultValue={props?.data?.email}
                            variant="outlined" 
                            placeholder="Email" 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            fullWidth 
                            className="disabled__input"/>
                            {(formik.touched.email && formik.errors.email) && (
                                <FormHelperText id='email-error-text' className="error_message">{formik.errors.email}</FormHelperText>
                            )}
                        </div>
                        <div className="action__btn">
                            <button type="submit">Update</button>
                        </div>
                    </form>
                </div>     
        );
}