import React, { useEffect, useState } from "react";
import "./app-footer.scss";

const AppFooter = ({isMobileScreen}:any) => {
  const url = window.location.pathname;
  const [footerClass, setFooterClass] = useState("");

  useEffect(() => {
    interface Obj {
      [key: string]: string;
    }
    const obj: Obj = {
      loggedIn_Light: "loggedin-footer light",
      loggedIn_Dark: "loggedin-footer dark",
      loggedOut_Light: "loggedout-footer light",
      loggedOut_Dark: "loggedout-footer dark",
    };
    let type;
    if (
      url.split("/")?.[1] === "admin-portal" ||
      url.split("/")?.[1] === "customer-portal"
    ) {
      type = "loggedIn_Light";
    } else if (url.split("/")?.[1] === "subscription") {
      type = "loggedOut_Light";
    } else {
      type = "loggedOut_Dark";
    }
    const footerClass = obj[type];
    setFooterClass(footerClass);
  }, []);

  return (
    <>
      {footerClass && (
        <div className="app-footer-root">
          <div className={`app-footer ${footerClass} ${isMobileScreen ? "fullwidth" : ""}`}>
            Tango © Copyright 2024 – All rights reserved.
          </div>
        </div>
      )}
    </>
  );
};

export default AppFooter;
