import React from "react";
import './filter-popup.scss';
import { apiService } from "../../core/services/api.service";
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import { toastMessage } from "../../core/services/toast";
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import dayjs, { Dayjs } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frequencies, frequencyType } from "../../core/constants";

dayjs.extend(quarterOfYear)

const dateFormat = "YYYY-MM-DD";

export default function FilterPopupComponent(props: any) {
    const { open, popoverId, anchorEl, filterState, onClose, onApplyFilter, onlyDateFilter } = props;
    
    const [frequency, setFrequency] = React.useState(filterState.frequencyType);
    const [showDates, setShowDates] = React.useState((filterState.frequencyType === frequencies.dateRange || onlyDateFilter));
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(filterState.startDate));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(filterState.endDate));

    const [organizations, setOrganizations] = React.useState<any>([]);
    const [organizationInputValue, setOrganizationInputValue] = React.useState("");
    const [selectedOrganization, setSelectedOrganization] = React.useState<any>(filterState.org ? filterState.org : null);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (organizationInputValue.length > 2) {
            fetchOrganizations();
          } else {
            setOrganizations([]);
          }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [organizationInputValue]);

    const fetchOrganizations = async () => {
        setOrganizations([]);
        try {
          const url = `/organizations?skip=0&take=100&order=1&orderBy=name&term=${organizationInputValue.trim()}`;
          const { data } = await apiService.get(url);
          const organizationsList = data?.result || [];
          setOrganizations(organizationsList);
        } catch (err: any) {
          setOrganizations([]);
          toastMessage(err?.response?.data?.message,null);
        }
    };

    const handleFrequencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDates(false);
        setFrequency((event.target as HTMLInputElement).value);
        switch((event.target as HTMLInputElement).value) {
            case frequencies.dateRange:
                setShowDates(true);
                setStartDate(dayjs().startOf('year'));
                setEndDate(dayjs().endOf('year'));
                break;
            case frequencies.ytd:
                setStartDate(dayjs().startOf('year'));
                setEndDate(dayjs().endOf('year'));
                break;
            case frequencies.qtd:
                setStartDate(dayjs().startOf('quarter'));
                setEndDate(dayjs().endOf('quarter') );
                break;
            case frequencies.mtd:
                setStartDate(dayjs().startOf('month'));
                setEndDate(dayjs().endOf('month'));
                break;
        }
    }

    const freqTypeDaysRange=(daysRange:number)=>{
        if(daysRange && daysRange > 16 && daysRange < 46){
            return frequencyType.weekly;
        }else{
            return frequencyType.monthly
        }
    }
    const handleSubmit = () => {
        let filtersData: any = {};
        if (onlyDateFilter) {
            filtersData = {
                startDate: getFormattedDate(startDate),
                endDate: getFormattedDate(endDate)
            };
        } else {
            let freqType = frequencyType.monthly;
            if (frequency === frequencies.dateRange || frequency === frequencies.mtd) {
                const daysRange = endDate?.diff(startDate, 'day');
                freqType = (daysRange && daysRange <= 16) ? frequencyType.daily : freqTypeDaysRange(daysRange as number);
            }
            filtersData = {
                frequency: freqType,
                frequencyType: frequency,
                startDate: getFormattedDate(startDate),
                endDate: getFormattedDate(endDate)
            };
        }

        if (props.organizationFilter) {
            filtersData['org'] = selectedOrganization;
        }

        onApplyFilter(filtersData);
        onClose();
    }

    const handleReset = () => {
        let filtersData: any = {};
        if (onlyDateFilter) {
            filtersData = {
                startDate: null,
                endDate: null
            };
        } else {
            filtersData = {
                frequency: frequencyType.monthly,
                frequencyType: frequencies.ytd,
                startDate: dayjs().startOf('year').format(dateFormat),
                endDate: dayjs().endOf('year').format(dateFormat)
            };
        }

        if (props.organizationFilter) {
            filtersData['org'] = null;
        }

        onApplyFilter(filtersData);
        onClose();
    };

    const handleClose = () => {
        if (onlyDateFilter) {
            setStartDate(null);
            setEndDate(null);
        } else {
            setFrequency(frequencies.ytd);
            setStartDate(dayjs().startOf('year'));
            setEndDate(dayjs().endOf('year'));
        }

        if (props.organizationFilter) {
            setSelectedOrganization(null);
        }
        onClose();
    };

    const getFormattedDate = (date: any) => {
        return dayjs(date).isValid() ? dayjs(date).format(dateFormat) : null;
    }

    return (
        
            <Popover id={popoverId} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>
                <div className="filter_popup__wrapper">
                    <div className="filter_header">
                        <div className="filter_heading">Filters</div>
                        <Button startIcon={<CloseIcon sx={{ mr: 0 }} />} className="reset" onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                    <div className="filter_popup__content">
                        <div className="filter_popup__card date_filter_card">
                            <div className="filter_popup__cardHeader">
                                Date
                            </div>
                            <div className="filter_popup__cardContent">
                                {!onlyDateFilter && <div className="filter_popup__frequency">
                                    <FormControl variant="standard">
                                        <RadioGroup
                                            name="gender"
                                            value={frequency}
                                            onChange={handleFrequencyChange}
                                        >
                                            <FormControlLabel
                                                value={frequencies.mtd}
                                                control={<Radio size="small" sx={{ color: '#9F2223', '&.Mui-checked': { color: '#9F2223' } }} />}
                                                label={frequencies.mtd}
                                            />
                                            <FormControlLabel
                                                value={frequencies.qtd}
                                                control={<Radio size="small" sx={{ color: '#9F2223', '&.Mui-checked': { color: '#9F2223' } }} />}
                                                label={frequencies.qtd}
                                            />
                                            <FormControlLabel
                                                value={frequencies.ytd}
                                                control={<Radio size="small" sx={{ color: '#9F2223', '&.Mui-checked': { color: '#9F2223' } }} />}
                                                label={frequencies.ytd}
                                            />
                                            <FormControlLabel
                                                value={frequencies.dateRange}
                                                control={<Radio size="small" sx={{ color: '#9F2223', '&.Mui-checked': { color: '#9F2223' } }} />}
                                                label="Date Range"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </div>}
                                {showDates && <div className="filter_popup__daterange">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker 
                                                label="From" 
                                                value={startDate} 
                                                onChange={(newValue) => setStartDate(newValue)} 
                                                format="MM-DD-YYYY" 
                                                slotProps={{ textField: { size: "small", error: false, } }} 
                                                sx={{ maxWidth: '150px !important', minWidth: '150px !important' }} 
                                                maxDate={endDate} />
                                            
                                            <DatePicker 
                                                label="To" 
                                                value={endDate} 
                                                onChange={(newValue) => setEndDate(newValue)} 
                                                format="MM-DD-YYYY" 
                                                slotProps={{ textField: { size: "small", error: false, } }} 
                                                sx={{ maxWidth: '150px !important', minWidth: '150px !important' }} 
                                                minDate={startDate} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>}
                            </div>
                        </div>
                        {props.organizationFilter && <div className="filter_popup__card user_filter_card">
                            <div className="filter_popup__cardHeader">
                                Organization
                            </div>
                            <div className="filter_popup__cardContent">
                                <div className="filter_popup__daterange">
                                <Autocomplete
                                    disablePortal
                                    id="organization-dropdown"
                                    onInputChange={(event: any, newInputValue) => {
                                        setOrganizationInputValue(newInputValue);
                                    }}
                                    size="small"
                                    options={organizations}
                                    filterOptions={(x) => x}
                                    sx={{ width: 300 }}
                                    value={selectedOrganization}
                                    getOptionLabel={(option: any) => option?.name ? option?.name : option?.email }
                                    onChange={(event: any, newValue) => {
                                        setSelectedOrganization(newValue)
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                        <li {...props} key={option._id}>
                                            {option?.name ? option?.name : option?.email }
                                        </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        name="organization"
                                        className="cityDroddownInput"
                                        placeholder="Search organization"
                                        />
                                    )}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="filter_popup__cardFooter">
                        <div className="filter_popup__actionBtns">
                            <Button color="primary" variant="contained" onClick={handleClose} className="custom__action__button cancel__button" sx={{flexBasis: 'content'}}>
                                Cancel
                            </Button>
                            <Button color="secondary" variant="contained" onClick={handleSubmit} className="custom__action__button confirm__button" sx={{flexBasis: 'content'}}>
                                Apply Filter
                            </Button>
                        </div>
                    </div>
                </div>
            </Popover>

    );
}
