import "./subscription-paywall.scss";

export function SubscriptionPaywall(props: any) {
  return (
      <div className="paywall-root">
        <div className="paywall-container">
          <div className="pricing-header-container">
            <header className="pricing-header">
              Your organization has not purchased any subscription plan yet,
              Please buy first.
            </header>
            <div className="pricing-header-description">
              You will be redirected to the Subscription Plans page shortly.
            </div>
          </div>
        </div>
        <div className="app-footer-root">
          <div className={`app-footer loggedout-footer dark`}>
            Tango © Copyright 2024 – All rights reserved.
          </div>
        </div>
      </div>
  );
}
