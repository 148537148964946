import React from "react";

import './chart-card.scss';
import { chartType, barCartOptions, barChartBarColors, lineChartOptions, lineChartBorderColors, lineChartBackgroundColors, lineChartAreaFillColors, pieChartOptions, pieChartBackgroundColors, doughnutChartOptions, doughnutChartBackgroundColors } from "../../../core/constants";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Legend, Filler } from 'chart.js';
import { Doughnut, Bar, Pie, Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, LineElement, PointElement, BarElement, Title, Legend, Filler);

interface Chart {
    id: string;
    type: string;
    chartHeight: string;
    height: number;
    width: number;
    title: string;
    chartData: any;
    showCustomeLegends: boolean;
    legendAlignment: string;
}

const ChartCard = (props: any) => {
    const [userCharts, setUserCharts] = React.useState<Chart[]>([]);

    React.useEffect(() => {
        let charts: any = [];
        props.data.forEach((item: any, index: number) => {
            let data = {};
            switch(item.type) {
                case chartType.pie:
                    const pieChartLabelValues = getCircularChartLabelAndValues(item.chartData);
                    data = getPieChartData(pieChartLabelValues.labels, pieChartLabelValues.values, item.tooltipLabel);
                    break;
                case chartType.doughnut:
                    const doughnutChartLabelValues = getCircularChartLabelAndValues(item.chartData);
                    data = getDoughnutChartData(doughnutChartLabelValues.labels, doughnutChartLabelValues.values, item.tooltipLabel);
                    break;
                case chartType.line:
                    const lineChartLabelValues = getStackedChartLabelAndValues(item.chartData);
                    data = getLineChartData(lineChartLabelValues.labels, lineChartLabelValues.values);
                    break;
                case chartType.bar:
                    const barChartLabelValues = getStackedChartLabelAndValues(item.chartData);
                    data = getBarChartData(barChartLabelValues.labels, barChartLabelValues.values);
                    break;
            }

            let chart: Chart = {
                id: item.id,
                type: item.type,
                chartHeight: item.chartHeight,
                height: item.height,
                width: item.width,
                title: item.title,
                chartData: data,
                showCustomeLegends: item.showCustomeLegends,
                legendAlignment: item.legendAlignment
            };
            charts.push(chart);
        });
        setUserCharts(charts);
    }, [props.data]);

    const getLabelAndValueArray = (data: any) => {
        let labels: string[] = [];
        let values: number[] = [];
        data.forEach((item: {label: string, value: number}) => {
            labels.push(item.label);
            values.push(item.value);
        });
        return {labels, values};
    }

    const getCircularChartLabelAndValues = (chartData: any) => {
        const {labels, values} = getLabelAndValueArray(chartData);
        return {labels, values};
    }

    const getStackedChartLabelAndValues = (chartData: any) => {
        let labels: string[] = [];
        let values: {title: any, chartValues: number[]}[] = [];
        chartData.forEach((item: any, index: number) => {
            const formatedData = getLabelAndValueArray(item.data);
            const valuesItem = {
                title: item.label,
                chartValues: formatedData.values
            }
            values.push(valuesItem);
            if (index === 0) {
                labels = formatedData.labels;
            }
        });
        return {labels, values };
    }

    const getBarChartData = (labels: string[], values: any) => {
        let dataset: any = [];
        values.forEach((value: any, index: number) => {
            dataset.push({
                label: value.title,
                data: value.chartValues,
                backgroundColor: barChartBarColors[index],
            });
        });
        const StackChartdata = {
            labels: labels,
            datasets: dataset,
        };
        return StackChartdata;
    }

    const getLineChartData = (labels: string[], values: any) => {
        let dataset: any = [];
        values.map((value: any, index: number) => {
            dataset.push({
                label: value.title,
                data: value.chartValues,
                borderColor: lineChartBorderColors[index],
                backgroundColor: lineChartBackgroundColors[index],
                pointRadius: 2,
                pointHoverRadius: 2,
                fill: {
                    target: "origin",
                    above: lineChartAreaFillColors[index],
                }
            });
        });
        const lineChartData = {
            labels: labels,
            datasets: dataset,
        };
        return lineChartData;
    }

    const getPieChartData = (labels: string[], values: number[], tooltipLabel: string) => {
        const chartData = {
            labels: labels,
            datasets: [{
                label: tooltipLabel,
                data: values,
                backgroundColor: pieChartBackgroundColors,
                borderColor: pieChartBackgroundColors,
                borderWidth: 1,
            }],
            emptyChartData: values.every(value => value === 0)
        };
        return chartData;
    }

    const getDoughnutChartData = (labels: string[], values: number[], tooltipLabel: string) => {
        const chartData = {
            labels: labels,
            datasets: [
              {
                label: tooltipLabel,
                data: values,
                backgroundColor: doughnutChartBackgroundColors,
                borderColor: doughnutChartBackgroundColors,
                borderWidth: 1,
              },
            ],
            emptyChartData: values.every(value => value === 0)
        };
        return chartData;
    }

    return (
        <>
        {userCharts.length > 0 && userCharts.map((chart) => {
            return (
                <div className={`${chart.type}__chart height${chart.height} width${chart.width}`} key={chart.id} id={chart.id}>
                    <Card className="custom__chart_card">
                        <CardHeader title={chart.title} className="custom__title" classes={{ title: 'cardheader__title' }} />
                        <CardContent className={`${chart.legendAlignment}__chart_alignment custom__content_container`}>
                            <div className={`chart_container ${chart.type}_chart_container`} style={{height: `${chart.chartHeight}`}}>
                                {chart.type === chartType.pie && !chart.chartData.emptyChartData && <Pie data={chart.chartData} options={pieChartOptions} />}
                                {chart.type === chartType.bar && <Bar options={barCartOptions} data={chart.chartData} />}
                                {chart.type === chartType.doughnut && !chart.chartData.emptyChartData && <Doughnut data={chart.chartData} options={doughnutChartOptions} />}
                                {chart.type === chartType.line && <Line options={lineChartOptions} data={chart.chartData} />}
                                {chart.chartData.emptyChartData && <span>No Data Available!</span>}
                            </div>
                            {chart.showCustomeLegends && chart.legendAlignment === 'right' && <div className="custom__labels_container">
                                {chart.chartData.labels.map((value: any, index: number) => {
                                return (
                                    <div className={`custom__labels ${chart.type}_labels`} key={value}>
                                        <div className="custom__labels__title">
                                            <FiberManualRecordIcon className="font13" sx={{ color: chart.chartData.datasets[0].backgroundColor[index] }} />
                                            <span>{value}</span>
                                        </div>
                                        <div className="custom__labels__value">
                                            <span>{chart.chartData.datasets[0].data[index]}</span>
                                        </div>
                                    </div>
                                )
                                })}
                            </div>}
                            {chart.showCustomeLegends && chart.legendAlignment === 'bottom' && chart.chartData.labels.map((value: any, index: number) => {
                                return (
                                    <div className={`custom__labels ${chart.type}_labels`} key={value}>
                                        <div className="custom__labels__title">
                                            <FiberManualRecordIcon className="font13" sx={{ color: chart.chartData.datasets[0].backgroundColor[index] }} />
                                            <span>{value}</span>
                                        </div>
                                        <div className="custom__labels__value">
                                            <span>{chart.chartData.datasets[0].data[index]}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </CardContent>
                    </Card>
                </div>
            );
        })}
        </>
    )
}

export default ChartCard;
