import React from 'react'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormHelperText,
    IconButton,
    Typography,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Divider from '@mui/material/Divider';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TypeOptions } from 'react-toastify/dist/types';
import '../../dialog.scss';
import ConfirmationPopupComponent from "../../confirmation-popup/confirmation-popup.component";
import { toastService } from '../../../../core/services/toast.service'
import { apiService } from '../../../../core/services/api.service';
import { toastMessage } from '../../../../core/services/toast';

interface FormFields {
    role: string;
    recruiter: string;
    hiringManager: string;
};

export default function AssociateUserRelationship(props: any) {

    const { onClose, userDetail, open, UserRole } = props;
    const limit = 5;
    const [dropdownList, setDropdownList] = React.useState([]);
    const [associatedUsersList, setAssociatedUsersList] = React.useState<any>([]);
    const [page, setPage] = React.useState(1);
    const [displayShowMore, setDisplayShowMore] = React.useState<boolean>(false);
    const [index, setIndex] = React.useState(-1);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const userRoleType = userDetail ? userDetail.role : UserRole.Recruiter;

    const initialValues = {
        role: userRoleType,
        recruiter: '',
        hiringManager: ''
    };

    const validations = Yup.object().shape({
        role: Yup.string(),
        recruiter: Yup.string().when('role', {
            is: (role: string) => role === UserRole.HRManager,
            then: () =>
                Yup.string().required('Recruiter is required')
        }),
        hiringManager: Yup.string().when('role', {
            is: (role: string) => role === UserRole.Recruiter,
            then: () =>
                Yup.string().required('Hiring Manager is required')
        })
    })

    React.useEffect(() => {
        fetchDropdownOptions();
    }, [])

    React.useEffect(() => {
        getAssociatedUsersList();
    }, [page])

    const fetchDropdownOptions = async () => {
        let url = '/organizations/hr/list';
        if(userRoleType === UserRole.HRManager){
            url = '/organizations/recuiter/list';
        }
        try {
            const { data } = await apiService.get(url)
            setDropdownList(data)
        } catch (err:any) {
            toastMessage(err.response.data.statusCode, null);
        }
    }

    const getAssociatedUsersList = async () => {
        try{
        let url = `/recruiter-pairing?userId=${userDetail._id}&role=${userRoleType}&take=${limit}&skip=${(page-1)*limit}`;
        const { data } = await apiService.get(url);
        let relationListData = data.result.length ? data.result : [];
        relationListData = relationListData.map((item: any) => {
            item.name = userRoleType === UserRole.Recruiter ? item._user.name : item._recruiter.name;
            item.recruiter_id = item._recruiter._id;
            item.recruiter_email = item._recruiter.email;
            item.hr_id = item._user._id;
            item.hr_email = item._user.email;
            return item;
        });
        setAssociatedUsersList((prevData: any[]) => {
            return [...relationListData, ...prevData];
        });
        if (relationListData.length === 5) {
            setDisplayShowMore(true);
        } else {
            setDisplayShowMore(false);
        }
        }catch(err:any){
            toastMessage(err.response.data.statusCode, null);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validations,
        onSubmit: async (values, { resetForm }) => {
            if (formik.isValid) {
                resetForm({ values: initialValues });
                let status:TypeOptions ="error";
                let message:string ="Pairing already done!";
                if ((values.role === UserRole.HRManager && associatedUsersList.find((user: any) => user.recruiter_id === values.recruiter))||(values.role === UserRole.Recruiter && associatedUsersList.find((user: any) => user.hr_id === values.hiringManager))) {
                    toastService.showToastNotification(status, message);
                    return false;
                }else {
                    handleSubmit(values);
                }
            }
        }
    })

    const handleSubmit = async (formValues: FormFields) => {
        let payload = {};
        const url = '/recruiter-pairing';
        if(userRoleType === UserRole.HRManager){
            payload = {
                _user: userDetail._id,
                _recruiter: formValues.recruiter
            }
        } else {
            payload = {
                _user: formValues.hiringManager,
                _recruiter: userDetail._id
            }
        }
        try {
            const response = await apiService.post(url, payload);
            setAssociatedUsersList([]);
            if (page === 1) {
                getAssociatedUsersList();
            } else {
                setPage(1);
            }
            let message:string =`Successfully associated user`;
            toastMessage(response.status, message);
        } catch(err:any) {
            toastMessage(err.response.data.statusCode, null);
        }
    }

    const handleShowMore = () => {
        setPage(prevPage => prevPage + 1);
    }

    const handleClose = () => {
        setAssociatedUsersList([]);
        onClose();
    };

    const onDelete = (index: number) => {
        setIndex(index);
        setOpenConfirmation(true);
    }

    const handleConfirmationClose = (value: number) => {
        setOpenConfirmation(false);        
    };

    const handleConfirmationConfirm = (index: number, checked: boolean) => {
        dissociateRelation(associatedUsersList[index], checked);
        setOpenConfirmation(false);        
    };

    // role variable has the role of the recruiter / hiring manager which is going to disassociate
    const dissociateRelation = async (user: any, checked: boolean) => {
        const dissociateFromAllJob = checked ? 1 : 0;
        const role = userRoleType === UserRole.HRManager ? UserRole.Recruiter : UserRole.HRManager;
        const url = `/recruiter-pairing/${user._id}?role=${role}&all=${dissociateFromAllJob}`;
        try{
            const response = await apiService.delete(url);
            const updatedAssociatedUsersList = associatedUsersList.filter((item: any) => item._id !== user._id);
            setAssociatedUsersList(updatedAssociatedUsersList);
            let message:string =`Successfully dissociated user relationship`;
            toastMessage(response.status, message);
        } catch(err:any){
            let message:string =err?.response?.data?.message?err?.response?.data?.message:null;
            toastMessage(err.response.data.statusCode,message)
        }
    }

    const listItemText=(item: any)=>{
        if(userRoleType === UserRole.Recruiter){
            return item.hr_email;
        }else{
            return item.recruiter_email;
        }
    }

    return (
        <>
            <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
                style: {
                    maxWidth: 454,
                    padding: '14px 8px 20px',
                    borderRadius: 6
                },
            }}>
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        User Association
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component="div" className="dialog_content">
                        <div className='user_details_wrapper'>
                            <div className='detail_row'>
                                <span className='detail_label'>Name: </span>
                                <span className='detail_value'>{userDetail.name}</span>
                            </div>
                            <div className='detail_row'>
                                <span className='detail_label'>Email: </span>
                                <span className='detail_value'>{userDetail.email}</span>
                            </div>
                        </div>
                        <div className='association_form'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="input__items">
                                    <Grid container spacing={2} sx={{alignItems: 'end'}}>
                                        <Grid item md={9} sm={9} xs={9}>
                                            <InputLabel id="demo-simple-select-label" className="input__label">
                                                {userRoleType === UserRole.HRManager ? 'Associate Recruiter' : 'Associate Hiring Manager'}
                                            </InputLabel>
                                            {userRoleType && userRoleType === UserRole.HRManager &&
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined"
                                                displayEmpty
                                                name="recruiter"
                                                className='input__select'
                                                fullWidth
                                                size='small'
                                                value={formik.values.recruiter}
                                                onChange={formik.handleChange}
                                                error={formik.touched.recruiter && Boolean(formik.errors.recruiter)}
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Select Recruiter</em>
                                                </MenuItem>
                                                {dropdownList.map((data: any, index: number) => (
                                                    <MenuItem key={data._id} value={data._id}>{data?._user?.name ? data?._user?.name : data?._user?.email}</MenuItem>
                                                ))}
                                            </Select>}
                                            {(formik.touched.recruiter && formik.errors.recruiter) && (
                                                <FormHelperText id='recruiter-error-text' className="error_message">{formik.errors.recruiter}</FormHelperText>
                                            )}
                                            {userRoleType && userRoleType === UserRole.Recruiter &&
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined"
                                                displayEmpty
                                                name="hiringManager"
                                                className='input__select'
                                                fullWidth
                                                size='small'
                                                value={formik.values.hiringManager}
                                                onChange={formik.handleChange}
                                                error={formik.touched.hiringManager && Boolean(formik.errors.hiringManager)}
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Select Hiring Manager</em>
                                                </MenuItem>
                                                {dropdownList.map((data: any, index: number) => (
                                                    <MenuItem key={data._id} value={data._id}>{data?._user?.name ? data?._user?.name : data?._user?.email}</MenuItem>
                                                ))}
                                            </Select>}
                                            {(formik.touched.hiringManager && formik.errors.hiringManager) && (
                                                <FormHelperText id='hiringManager-error-text' className="error_message">{formik.errors.hiringManager}</FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={3}>
                                            <Button color="secondary" variant="contained" type="submit" className="custom__action__button confirm__button" size="large">
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        </div>
                        <div className='associated_list_wrapper'>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 300,
                                    padding: 0,
                                    '& ul': { padding: 0 },
                                }}
                                subheader={<li />}
                            >
                                <ul>
                                    <ListSubheader sx={{p: 0}}>
                                        <InputLabel id="demo-simple-select-label" className="list_header">
                                            {userRoleType === UserRole.HRManager ? 'Associated Recruiter' : 'Associated Hiring Manager'}
                                        </InputLabel>
                                    <Divider sx={{color: '#E5E5E7', mb: '16px'}} />
                                    </ListSubheader>
                                    {associatedUsersList.map((item: any, index: number) => (
                                        <>
                                            <ListItem key={`item-${item}`} className='list_item'>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <PersonOutlineOutlinedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.name !== "" ? item.name : listItemText(item)} className='list_item_text' />
                                                <IconButton title="Dissociate" onClick={() => onDelete(index)}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </ListItem>
                                            {index < associatedUsersList.length - 1 && <Divider sx={{color: '#E5E5E7', mb: '5px'}} />}
                                        </>
                                    ))}
                                    {associatedUsersList?.length === 0 && (
                                        <ListItem className='list_item'>
                                            <ListItemText primary={`No Data Available !`} />
                                        </ListItem>
                                    )}
                                </ul>
                            </List>
                        </div>
                        {displayShowMore && <div className='show_more_link'>
                            <Link component="button" variant="body2" onClick={handleShowMore} underline="hover">
                                {'Show more...'}
                            </Link>
                        </div>}
                    </Typography>
                </DialogContent>
            </Dialog>
            <ConfirmationPopupComponent
                    open={openConfirmation}
                    index={index}
                    showCheckbox={true}
                    title="Dissociate"
                    message="Do you want to dissociate user relationship from all job posts?"
                    btnLabel="Dissociate"
                    onClose={handleConfirmationClose}
                    onConfirm={handleConfirmationConfirm}
                />
        </>
    )
}