import React from 'react'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../dialog.scss'
import OrganizationFormComponent from "../../forms/organization-form/organization-form.component";

export default function AddEditOrganization(props: any) {

    const { onClose, data, open } = props;
    
    const handleClose = () => {
        onClose();
    };

    return (
        
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: 450,
                        padding: "15px 20px",
                        borderRadius: 6
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        {data?.id === '' ? 'Create' : 'Edit'} Organization
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <OrganizationFormComponent onSuccess={handleClose} data={data} />
                </DialogContent>
            </Dialog>
        
    )
}
