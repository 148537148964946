import { useEffect, useState } from "react";

import "./unsubscribe.scss";
import logo from "../../../../assets/tango_logo.png";
import backgroundVideo from "../../../../assets/background_video.mp4";
import { apiService } from "../../../core/services/api.service";
import { toastMessage } from "../../../core/services/toast";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import CircularProgress from "@mui/material/CircularProgress";
import { Button, Typography } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export const UnSubscribeEmailComponent = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [disableBtn, setDisableBtn] = useState<boolean>(false);
    const [showThankyou, setShowThankyou] = useState<boolean>(false);
    const requestId: any = searchParams.get("requestId");
    const userId: any = requestId ? atob(requestId) : null;

  useEffect(() => {
    if (!userId) {
        toastMessage(500, 'Invalid URL!');
    }
  }, [userId]);

  const handleCancel = () => {
    setShowLoader(true);
    setDisableBtn(true);
    toastMessage(500, 'Unsubscribe Cancelled!');
    setTimeout(() => {
        navigate('/login');
    }, 1000)
  };

  const handleConfirm = async () => {
    if (userId) {
        const payload: any = {
            userId: userId,
            isSubscribeEmailNotification: false
        }
        try{
            setShowLoader(true);
            setDisableBtn(true);
            await apiService.put('/user/email/unsubscribe', payload);
            setShowThankyou(true);
            resetState();
        }catch(err:any){
            resetState();
            let message:string =err?.response?.data?.message?err?.response?.data?.message:null;
            toastMessage(err.response.data.statusCode, message);
            setShowThankyou(false);
        } 
    } else {
        toastMessage(500, 'Invalid URL!!');
        resetState();
        return false;
    }
  };

  const resetState = () => {
    setShowLoader(false);
    setDisableBtn(false);
  }

  return (
    <>
        <div className="unsubscribe__page">
          <div className="video__wrapper">
            <video className="videoTag" autoPlay loop muted>
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          </div>

          <div className="content__background"></div>
          <div className={showThankyou ? "thankyou__wrapper" : "login__wrapper"}>
            <div className="loginBox">
                {showThankyou ? <div>
                    <div className="checkIcon">
                        <TaskAltIcon sx={{color: '#338573', width: '100px', height: '100px'}} />
                    </div>
                    <Typography className="custom__popup__content" component="div">Unsubscribed Successfully</Typography>
                    <Typography className="custom__popup__bottomcontent" component="div">To Login <Link to="login">click here!</Link></Typography>
                </div> : <>
                    <div>
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className="heading">Unsubscribe</div>
                    <Typography className="custom__popup__content" component="div">Do you want to unsubscribe from email notifications?</Typography>
                    <div className="custom__actions">
                        <Button color="primary" variant="contained" onClick={handleCancel} className="custom__action__button cancel__button" disabled={disableBtn}>
                            Cancel
                        </Button>
                        <Button color="secondary" variant="contained" onClick={handleConfirm} className="custom__action__button confirm__button" disabled={disableBtn}>
                            Unsubscribe
                        </Button>
                    </div>
                </>}
            </div>
          </div>
        </div>
        {showLoader && <div className="no--content">
            <CircularProgress className="custom__spinner" />
        </div>}
    </>
  );
};
