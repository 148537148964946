import React from "react";
import "../../../components/admin/organizations/organization-details.scss"
import { UserRole } from "../../../types/user.interface";
import { useParams, Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { apiService } from "../../../core/services/api.service";
import { toastService } from '../../../core/services/toast.service'
import { TypeOptions } from 'react-toastify/dist/types';
import OrganizationDetailsComponent from "../../../components/admin/organizations/organization-details.component";
import { useAppSelector } from "../../../../store";
import { toastMessage } from "../../../core/services/toast";

export default function ViewOrganizationComponent() {
    let { orgId } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [organizationDetails, setOrganizationDetails] = React.useState<any>(null);

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
      );

    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
            Home
        </Link>,
        <Link key="2" color="inherit" to="/admin-portal/organizations" className="inactive_menu">
            Organizations
        </Link>,
        <Typography key="3" color="text.primary" className="active_menu">
            {organizationDetails?.name}
        </Typography>,
    ];

    React.useEffect(() => {
        if(isTokenAvailable){
            if (orgId && orgId !== undefined) {
                setLoading(true);
                getOrganizationDetails();
            } else {
                let status: TypeOptions = "error";
                let message: string = "Something went wrong";
                toastService.showToastNotification(status, message);
            }
        }
    }, [isTokenAvailable])

    const getOrganizationDetails = async () => {
        try {
            let url = `/organizations/${orgId}`;
            const { data } = await apiService.get(url);
            setOrganizationDetails(data);
            setLoading(false);
        } catch (err: any) {
            toastMessage(err.response.data.statusCode, null);
        }
    }

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard__main">
                <div className="dashboard_header">
                    <div className="heading_wrapper">
                        <div className="dashboard__heading">{organizationDetails?.name}</div>
                    </div>
                </div>
                <OrganizationDetailsComponent
                    loading={loading}
                    renderedFrom={UserRole.Admin}
                    organizationDetails={organizationDetails}
                    showLoader={(value: boolean) => setLoading(value)} 
                    getOrganizationDetails={getOrganizationDetails}
                />
            </div>
        </>
    )
}
