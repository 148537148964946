// import '../admin-subscription.scss'
import "../../../../pages/admin/subscriptions/admin-subscription.scss"

export default function PlanCard2Component(props:any){
    return(
        <>
            <div className='plan__card'>
                <div className='icon__box'>
                    {props?.icon}
                </div>
                <div className='plan__text__box'>
                    <div className='plan__text'>
                        {props?.count}
                    </div>
                    <div className='plan__subtext'>
                        {props?.text}
                    </div>
                </div>
            </div>
        </>
    )
} 