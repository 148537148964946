// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth as FirebaseAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_SENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MESUREMENTID
};
  


// Initialize Firebase


class FirebaseService{
    private _app: FirebaseApp;
    private _auth: FirebaseAuth;
    
    constructor(){
        this._app = initializeApp(firebaseConfig);
        this._auth = getAuth(this._app);
    }

    get auth() { return this._auth; }

    singinWithEmailAndPassword(email: string, password: string){
        return signInWithEmailAndPassword(this._auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            return user;
        })
        .catch((error) => {
            return error;
        });
    }

    sendPasswordResetEmail(email: string) {
        return sendPasswordResetEmail(this._auth, email)
        .then(() => {
            const res = {
                code: 200,
                status: "success"
            }
            return res;
        }).catch((error) => {
            return error;
        })
    }
}

export const firebaseService = new FirebaseService();