import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import logo from "../../../../assets/tango_logo.png";
import backgroundVideo from "../../../../assets/background_video.mp4";
import "./singup-page.scss";
import { authController } from "../../../core/controllers/auth/auth.controller";
import {
  AuthMethodEnum,
  SignupPayload,
} from "../../../core/controllers/auth/auth.interface";
import { invitationAccept } from "../../../../store/slice/email-verification-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../../store";
import { TermsOfService } from "../terms-of-service/terms-of-service";
import { Box } from "@mui/material";

const signupSchema = Yup.object().shape({
  email: Yup.string()
    .trim("The email cannot include leading and trailing spaces")
    .strict(true)
    .email("Invalid Email")
    .required("Email is required"),
  inviteCode: Yup.string().required("Invitation Code is required"),
  password: Yup.string()
    .required("Create Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), ""], "Confirm Password does not match"),
});

export default function SignupPageComponent() {
  const [searchParams] = useSearchParams();
  let emailParam =
    decodeURIComponent(
      encodeURIComponent(searchParams.get("email") as string).replaceAll(
        "%20",
        "+"
      )
    ) || "";
  let InviteCodeParam =
    encodeURIComponent(searchParams.get("inviteCode") as string) || "";
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const [termsAndPolicyError, setTermsAndPolicyError] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: emailParam !== "null" ? (emailParam) : "",
      inviteCode: InviteCodeParam !== "null" ? (InviteCodeParam) : "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupSchema,
    onSubmit: (values) => {
      if (!checked) {
        setTermsAndPolicyError(true);
        return;
      }
      const payload: SignupPayload = {
        email: values.email,
        inviteCode: values?.inviteCode,
        data: {
          password: values?.password,
          confirmPassword: values?.confirmPassword,
        },
      };

      dispatch(invitationAccept(payload));
      setTimeout(() => {
        authController.login({
          method: AuthMethodEnum.EmailAndPassword,
          username: values.email,
          password: values.password,
        });
      }, 2000);
    },
  });

  const handleChange = (checked: any) => {
    setChecked(checked);
    if (!checked) {
      setTermsAndPolicyError(true);
    } else {
      setTermsAndPolicyError(false);
    }
  };

  return (
    <>
      <div className="video__wrapper">
        <video className="videoTag" autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      </div>

      <div className="content__background"></div>
      <div className="signup__root">
        <div className="signup__wrapper">
          <div className="signupBox">
            <div>
              <img src={logo} alt="Logo" />
            </div>
            <div className="heading">Sign Up</div>
            {/* <h1 className="heading">Sign Up your new Account</h1> */}
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="input__items">
                <InputLabel size="normal" className="input__label" focused>
                  Company Email
                </InputLabel>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  variant="outlined"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  fullWidth
                />
                {formik.touched.email && formik.errors.email && (
                  <FormHelperText
                    id="email-error-text"
                    className="error_message"
                  >
                    {formik.errors.email}
                  </FormHelperText>
                )}
              </div>
              <div className="input__items">
                <InputLabel size="normal" className="input__label" focused>
                  Invitation Code
                </InputLabel>
                <TextField
                  id="inviteCode"
                  name="inviteCode"
                  type="text"
                  variant="outlined"
                  placeholder="Enter Invitation Code"
                  value={formik.values.inviteCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.inviteCode &&
                    Boolean(formik.errors.inviteCode)
                  }
                  fullWidth
                />
                {formik.touched.inviteCode && formik.errors.inviteCode && (
                  <FormHelperText
                    id="invite-error-text"
                    className="error_message"
                  >
                    {formik.errors.inviteCode}
                  </FormHelperText>
                )}
              </div>
              <div className="input__items">
                <InputLabel size="normal" className="input__label" focused>
                Create Password
                </InputLabel>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  variant="outlined"
                  placeholder="Create Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  fullWidth
                />
                {formik.touched.password && formik.errors.password && (
                  <FormHelperText
                    id="password-error-text"
                    className="error_message"
                  >
                    {formik.errors.password}
                  </FormHelperText>
                )}
              </div>
              <div className="input__items">
                <InputLabel size="normal" className="input__label" focused>
                  Confirm Password
                </InputLabel>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  fullWidth
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <FormHelperText
                      id="confirm-error-text"
                      className="error_message"
                    >
                      {formik.errors.confirmPassword}
                    </FormHelperText>
                  )}
              </div>
              <TermsOfService handleChange={handleChange} fontSize={"15px"}/>
              {termsAndPolicyError && (
                <Box sx={{ color: "red", justifyContent: "start" }}>
                  Please agree to the Terms Of Service and Privacy Policy.
                </Box>
              )}
              <div className="action__btn">
                <button type="submit">Sign Up</button>
              </div>
            </form>
            <div>
              <span className="remember__text">Already have an account?</span>
              <a className="m-r-5" href={`/login`}>
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
