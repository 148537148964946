import React from "react";
import './manage-user.scss';
import dayjs from 'dayjs';
import { useSearchParams, Link } from "react-router-dom";
import { UserRole } from "../../../types/user.interface";
import { apiService } from "../../../core/services/api.service";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { authorizationService } from "../../../core/services/authorization.service";
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import InputAdornment from '@mui/material/InputAdornment';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import { useAppSelector } from "../../../../store";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';

import SendIcon from '@mui/icons-material/Send';

import IconButton from '@mui/material/IconButton';
import Avatar from "@mui/material/Avatar";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";

import InviteUser from "../../../components/dialogs/users/invite-users/invite-user.component";
import AssociateUserRelationship from "../../../components/dialogs/users/associate-users/associate-user-relationship.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import EditUserComponent from "../../../components/dialogs/users/edit-users/edit-user.component";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
interface Data {
    email: string;
    status: string;
    name: string;
    recruiterName: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isWidth: boolean,
}

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: true,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Email',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Status',
    }
  ];

const EnhancedTableBody = (props:any) => {
    const {users, userListLoading, permissions, loggedinUserDetails} = props;
    const onDelete = (index:number, status:string) => {
        props.onOpen(index, status)
    }
    const onEdit = (index:number) => {
        props.onOpenEditPopup(index)
    }
    const onResendInvitation = (email:string) => {
        props.onResendInvitation(email)
    }
    const onOpenAssociationPopup = (row: any) => {
        props.onOpenAssociationPopup(row);
    }
    const tableContent=()=>{
        if(users.length > 0){
            const rowData = users.map((row:any, index:number) => {
                return(
                <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="custom__cell__row"
                >
                <TableCell align="left" className="custom__cell width70">
                    <Avatar>
                        <PersonOutlineOutlinedIcon /> 
                    </Avatar>
                    <span>{row.name}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">{row.email}</TableCell>
                <TableCell align="left" className={row.status==="active"?"success":"custom__cell__element"}>
                    {row.status}
                </TableCell>
                <TableCell align="left" className="custom__cell__element action__icons d-flex">
                    {row.status!=="active" && permissions?.resendInvite && (loggedinUserDetails?.isPoc || loggedinUserDetails?._id === row._id) && (<IconButton title="Resend Invitation" onClick={()=>onResendInvitation(row.email)}>
                        <SendIcon />
                    </IconButton>)}
                    {permissions?.edit && (loggedinUserDetails?.isPoc || loggedinUserDetails?._id === row._id) && <IconButton title="Edit" onClick={()=>onEdit(index)}>
                        <DriveFileRenameOutlineOutlinedIcon />
                    </IconButton>}
                    {permissions?.delete && <IconButton title="Deactivate" onClick={()=>onDelete(index, row.status)}>
                        <DeleteOutlineIcon />
                    </IconButton>}
                    {permissions?.association && (loggedinUserDetails?.isPoc || loggedinUserDetails?._id === row._id) && <IconButton title="Associate User" onClick={() => onOpenAssociationPopup(row)}>
                        <InsertLinkOutlinedIcon />
                    </IconButton>}
                    {(!loggedinUserDetails?.isPoc && loggedinUserDetails?._id !== row._id) && <span>N/A</span>}
                </TableCell>
                </TableRow>
                )
            })
            return rowData;
        }else{
            return <TableRow>
                        <TableCell colSpan={4} className="no__data">
                            No Data Available !
                        </TableCell>
                    </TableRow>
        }
    }
    return(
            <TableBody>
                {userListLoading ?  <TableSkeltonComponent rows={10} columns={4} /> : tableContent()}
            </TableBody>
    )
}

const EnhancedTableFooter = (props:any) => {
    const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.onPageChange(value)
    }
    return(
        <TableFooter>
            <TableRow>
                <TableCell colSpan={5}>
                    <TablePagination count={props.pages} siblingCount={0} defaultPage={props.page} page={props.page} boundaryCount={2} className="custom__pagination" disabled={props.userListLoading} onChange={onPageChange} />
                </TableCell>     
            </TableRow>
        </TableFooter>
    )
}

export default function ManageUserComponent() {
    let [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const initialFilterValue = {
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };
    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );
    const organizationDetails : any = useAppSelector(
        state => state.organization.myOrganization
    );
    const limit = 10;
    const [permissions, setPermissions] = React.useState<any>(null);
    const [selectedTabValue, setSelectedTabValue] = React.useState(tab ?? "all")
    const [userList, setUserList] = React.useState([])
    const [userListLoading, setUserListLoading] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false)
    const [openAssociationPopup, setOpenAssociationPopup] = React.useState(false)
    const [selectedUserDetails, setSelectedUserDetails] = React.useState(null)
    const [searchTerm, setSearchTerm] = React.useState('')
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
    const [isOpenEditPopup, setIsOpenEditPopup] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [index, setIndex] = React.useState(-1);
    const [confirmationTitle, setConfirmationTitle] = React.useState('');
    const [confirmationMsg, setConfirmationMsg] = React.useState('');
    const [confirmationFor, setConfirmationFor] = React.useState('');
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );

    const breadcrumbs = [
        <Link key="1" color="inherit" to="/customer-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          Users
        </Typography>,
      ];

    React.useEffect(() => {
        const usersPermissions = authorizationService.getPermissions(userDetails?.role, 'users', userDetails?.isPoc);
        setPermissions(usersPermissions);
    }, [userDetails]);

    React.useEffect(() => {
        if(isTokenAvailable){
            const delayDebounceFn = setTimeout(() => {
                getUsersList(selectedTabValue);
            }, 1500)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [page, order, orderBy, filters, searchTerm,isTokenAvailable])

    const fetchUserList = async (role:any) => {
        let url = `/organizations/users?take=${limit}&skip=${(page-1)*limit}&order=${order === 'asc'?1:-1}&orderBy=${orderBy}&name=${searchTerm}&role=${role}`;
        if (filters.startDate) {
            url = `${url}&startDate=${filters.startDate}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${filters.endDate}`;
        }
      try{
        const { data } = await apiService.get(url);
        const userListData = data.length ? data[0].result : [];
        const userListCount=()=>{
            if(data[0].count.length){
              return data[0].count[0].count
            }else{
              return 0;
            }
          }
        const count = data.length ? userListCount() : 0;
        setPages(Math.ceil(count/limit))
        setUserListLoading(false);
        return userListData;
      }catch(err:any){
          toastMessage(err.response.data.statusCode, null);
      }
    }

    const handleTabChange = async (event: React.SyntheticEvent, newValue: any) => {
        setUserListLoading(true);
        setSelectedTabValue(newValue);
        if (page === 1) {
            getUsersList(newValue);
        } else {
            setPage(1);
        }
    };

    const getUsersList = async (selectedRole:any) => {
        let role = selectedRole;
        setUserList([]);
        const userListData = await fetchUserList(role);
        setUserList(userListData);
    }

    const hrInvitationClick = async (email: string) => {
        let validEmailInput = validateEmailDomain(email)
        if(!validEmailInput){
            alert("Email domain of the invitee doesn't match with that of the company")
            return 
        }
        const payload = {
            email: email,
            role: selectedTabValue === UserRole.HRManager ? selectedTabValue : UserRole.Recruiter
        }
        let url = '/organizations/invite/recruiter';
        const role = selectedTabValue;
        if(role === UserRole.HRManager){
            url = '/organizations/hr/invite';
        }
        try{
            const response = await apiService.post(url, payload);        
            let message:string =`Successfully sent invitation to ${payload.email}`;
            toastMessage(response.status, message, "success");
        }catch(err:any){
            toastMessage(err?.response?.data?.statusCode, err?.response?.data?.message);
        } finally {
            getUsersList(selectedTabValue);
            setIsOpen(false)
        }        
    }

    const resendInvitationClick = async (email: string) => {
        setUserListLoading(true);
        const payload: any = { email: email }
        try{
            const response = await apiService.put('/organizations/hr/resend/invitation', payload);
            getUsersList(selectedTabValue);
            let message:string =`Successfully sent invitation to ${payload.email}`;
            toastMessage(response.status, message, "success");
        }catch(err:any){
            toastMessage(err.response.data.statusCode, null);
        }        
    }

    const handleConfirmationClickOpen = (index:number, status:string) => {
        const title = status === 'active' ? 'Deactivate' : 'Delete';
        const msg = status === 'active' ? 'Are you sure you want to deactivate user?' : 'Are you sure you want to delete user?';
        const confirmationType = status === 'active' ? 'deactivate' : 'delete';
        setConfirmationTitle(title);
        setConfirmationMsg(msg);
        setIndex(index)
        setOpenConfirmation(true);
        setConfirmationFor(confirmationType);
    };

    const handleInviteClose = () => {
        setIsOpen(false);
    };

    const handleAssociationPopupClose = () => {
        setSelectedUserDetails(null);
        setOpenAssociationPopup(false);
    };

    const handleConfirmationClose = (value: number) => {
        setOpenConfirmation(false);        
        setIndex(-1);
    };

    const handleConfirmationConfirm = (value: number) => {
        setUserListLoading(true);
        if (confirmationFor === 'delete') {
            deleteInvitationClick(userList[value])
        }
        if (confirmationFor === 'deactivate') {
            deactivateUser(userList[value]);
        }
        setOpenConfirmation(false);        
        setIndex(-1);
    };

    const deleteInvitationClick = async (user: any) => {
        const payload: any = {
            email: user.email
        }
        try{
            const response = await apiService.put('/organizations/delete/hr/invitation', payload);
            getUsersList(selectedTabValue);
            let message:string =`Successfully deleted ${payload.email}`;
            toastMessage(response.status, message, "success");
        }catch(err:any){
            let message:string =err?.response?.data?.message?err?.response?.data?.message:null;
            toastMessage(err.response.data.statusCode, message);
        }     
    }

    /*Checking the input email domain and actual company domain is same or not */
    const validateEmailDomain = (email:string)=>{
        let { emailDomains } = organizationDetails;
        let emailDomain = email.split('@')[1]?.trim();
        return emailDomains.some((v:string) => v === emailDomain)
    }

    const handleClickOpen = () => {
        setIsOpen(true)
    };

    const handleAssociationPopupOpen = (userDetail: any) => {
        setSelectedUserDetails(userDetail);
        setOpenAssociationPopup(true);
    };

    const handleInviteSubmit = (event:any) => {
        setUserListLoading(true);
        hrInvitationClick(event.email)
        // setIsOpen(false)
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setUserListLoading(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (value: number) => {
        setUserListLoading(true);
        setPage(value)
    }

    const handleSearch = (value: string) => {
        if ((value && value.length > 3) || value.length === 0) {
            setPage(1);
            setUserListLoading(true);
            setSearchTerm(value);
        }
    }

    const deactivateUser = async (user: any) => {
        const url = `/user/deactivate/${user.role}/${user._id}`;
        try{
            const response = await apiService.delete(url);
            getUsersList(selectedTabValue);
            let message:string =`Successfully deactivated user.`;
            toastMessage(response.status, message, "success");
        }catch(err:any){
            let message:string =err?.response?.data?.message?err?.response?.data?.message:null;
            toastMessage(err.response.data.statusCode, message);
        } 
    }

    const handleEditUserPopupOpen = (index: number) => {
        setIndex(index);
        setSelectedUserDetails(userList[index]);
        setIsOpenEditPopup(true);
    }

    const handleEditUserPopupClose = () => {
        setIndex(-1);
        setSelectedUserDetails(null);
        setIsOpenEditPopup(false);
    }

    const handleEditUserPopupSuccess = () => {
        handleEditUserPopupClose();
        getUsersList(selectedTabValue);
    }

    const handleFilterChange = (filterValues: any) => {
        setUserListLoading(true);
        setFilters(filterValues);
    }
    
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilter(anchorFilter ? null : event.currentTarget);
        setOpenFilter((previousOpen) => !previousOpen);
    }
        
        return (
            <>
                <div className="dashboard__header">
                    <div className="org__details">
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="dasboard dashboard">
                    <div className="dashboard__wrapper">
                        <div className="heading">
                            Users
                        </div>
                        <div className="search__wrapper">
                            <TextField
                                id="search"
                                type="search"
                                name="new-search"
                                autoComplete='off'
                                placeholder="Search by name"
                                variant="outlined"
                                className="custom__search"
                                defaultValue={searchTerm}
                                onChange={(event: any) => handleSearch(event.target.value)}
                                sx={{ width: 350 }}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                }}
                            />
                            <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                            {permissions?.add && <Button variant="contained" className="custom__button custom__create__button"  onClick={handleClickOpen}>Add User</Button>}
                        </div>
                    </div>
                    <div className="hrInvitation__wrapper">
                        <div className="hrInvitationBox">
                            <TabContext value={selectedTabValue} >
                                <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                <TabList style={{borderBottomWidth:'0'}} onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab className="custom__tab" style={{textTransform: 'capitalize',fontWeight:'550'}} sx={{ color: 'black', '&.Mui-selected': { color: '#9F2223 !important'},indicator: { backgroundColor: 'red' } }}  label="All" value="all" />
                                    <Tab className="custom__tab" style={{textTransform: 'capitalize',fontWeight:'550'}} sx={{ color: 'black', '&.Mui-selected': { color: '#9F2223 !important'},indicator: { backgroundColor: 'red' } }}  label="Recruiters" value={UserRole.Recruiter} />
                                    <Tab  className="custom__tab" style={{textTransform: 'capitalize',fontWeight:'550'}} sx={{ color: 'black', '&.Mui-selected': { color: '#9F2223 !important'} }}  label="Hiring Mangers" value={UserRole.HRManager} />
                                </TabList>
                                </Box>
                                <TabPanel value="all" className="tab__panel">
                                    <div className="table__wrapper">
                                        <TableContainer component={Paper} className="custom__table">
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                headCells={headCells}
                                                isAction = {true}
                                                onRequestSort={handleRequestSort}
                                                isSorting = {true}
                                            />
                                            <EnhancedTableBody 
                                                users={userList} 
                                                loggedinUserDetails={userDetails}
                                                permissions={permissions}
                                                userListLoading={userListLoading}
                                                onOpen={handleConfirmationClickOpen} onOpenEditPopup={handleEditUserPopupOpen} 
                                                selectedTabValue={selectedTabValue}
                                                onOpenAssociationPopup={handleAssociationPopupOpen}
                                                onResendInvitation={resendInvitationClick} 
                                                />
                                            <EnhancedTableFooter
                                                users={userList}
                                                userListLoading={userListLoading}
                                                pages={pages}
                                                page={page}
                                                onPageChange={handleChange}
                                                />
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </TabPanel>
                                <TabPanel value={UserRole.Recruiter} className="tab__panel">
                                    <div className="table__wrapper">
                                        <TableContainer component={Paper} className="custom__table">
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                headCells={headCells}
                                                isAction = {true}
                                                onRequestSort={handleRequestSort}
                                                isSorting = {true}
                                            />
                                            <EnhancedTableBody 
                                                users={userList} 
                                                loggedinUserDetails={userDetails} 
                                                permissions={permissions}
                                                userListLoading={userListLoading}
                                                onOpen={handleConfirmationClickOpen} onOpenEditPopup={handleEditUserPopupOpen} 
                                                selectedTabValue={selectedTabValue}
                                                onOpenAssociationPopup={handleAssociationPopupOpen}
                                                onResendInvitation={resendInvitationClick} 
                                                />
                                            <EnhancedTableFooter
                                                userListLoading={userListLoading}
                                                pages={pages}
                                                page={page}
                                                onPageChange={handleChange}
                                                />
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    {/* <form onSubmit={hrInvitationClick} >
                                        <p className="invite__heading montserrat-bold">Invite a Recruiter</p>
                                        <div className="input__items">
                                            <input className="montserrat-regular" type="email" name="email"  value={email} placeholder="enter email" />
                                        </div>
                                        <div className="action__btn">
                                            <button  className="action__btn" type="submit" >Send Invite </button>
                                        </div>
                                    </form>
                                    <div className="hrList_wrapper">
                                        <div className="hrList">
                                            {
                                                userList.length > 0 ? (
                                                    userList.map((userListItem: any, i) => {
                                                        return (
                                                            <div key={i} className="hrList_item">
                                                                <div className="hr__details">
                                                                    <div className="profile__img">
                                                                        <HRProfile/>
                                                                    </div>
                                                                    <div className="profile__details">
                                                                        <div className="name montserrat-bold">
                                                                            {userListItem.name}
                                                                        </div>
                                                                        <div className="email">
                                                                            {userListItem.email}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="user__status">
                                                                    <div className="user__status_item">
                                                                        <button className={ userListItem.status =='inactive' ?'inactive':'active'} disabled type="button"  >{ userListItem.status }
                                                                        </button>
                                                                    </div>
                                                                    <div className="user__status_menu">
                                                                    <button
                                                                        id="demo-positioned-button"
                                                                        aria-controls={isOpen ? 'demo-positioned-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={isOpen ? 'true' : undefined}
                                                                        onClick={handleMenuClick}
                                                                    ><MoreVertIcon/></button>
                                                                    <Menu
                                                                        id="demo-positioned-menu"
                                                                        aria-labelledby="demo-positioned-button"
                                                                        anchorEl={anchorEl}
                                                                        open={isOpen}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                        }}
                                                                        >
                                                                            <MenuItem style={{padding:'10px',borderBottom:'1px solid #EEEEEE'}} onClick={handleClose}>
                                                                                    <div className="status_item">
                                                                                        <button onClick={e => resendInvitationClick(userListItem.email)} type="button">
                                                                                        <SyncAltIcon className="icon" />  Resend Invitation</button>
                                                                                    </div>
                                                                            </MenuItem>
                                                                            <MenuItem style={{padding:'10px',borderBottom:'1px solid #EEEEEE'}} onClick={handleClose}>
                                                                                    <div className="status_item">
                                                                                        <button onClick={e => deleteInvitationClick(userListItem.email)} type="button">
                                                                                        <DeleteIcon className="icon" />Delete Invitation </button>
                                                                                    </div>
                                                                            </MenuItem>
                                                                    </Menu>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                ):(
                                                    <p className="empty--data"> No data available</p>
                                                )
                                                
                                            }
                                        </div>
                                    </div> */}
                                </TabPanel>
                                <TabPanel value={UserRole.HRManager} className="tab__panel">
                                <div className="table__wrapper">
                                    <TableContainer component={Paper} className="custom__table">
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            headCells={headCells}
                                            isAction = {true}
                                            onRequestSort={handleRequestSort}
                                            isSorting = {true}
                                        />
                                        <EnhancedTableBody users={userList} permissions={permissions} loggedinUserDetails={userDetails} userListLoading={userListLoading} onOpen={handleConfirmationClickOpen} onOpenEditPopup={handleEditUserPopupOpen} onOpenAssociationPopup={handleAssociationPopupOpen} onResendInvitation={resendInvitationClick} selectedTabValue={selectedTabValue} />
                                        <EnhancedTableFooter
                                            userListLoading={userListLoading}
                                            pages={pages}
                                            page={page}
                                            onPageChange={handleChange}
                                            />
                                        </Table>
                                    </TableContainer>
                                </div>
                                {/* <form onSubmit={hrInvitationClick} >
                                        <p className="invite__heading montserrat-bold">Invite a Hiring Manager</p>
                                        <div className="input__items">
                                            <input className="montserrat-regular" type="email" name="email"  value={email} placeholder="enter email" />
                                        </div>
                                        <div className="action__btn">
                                            <button  className="action__btn" type="submit" > Send Invite</button>
                                        </div>
                                    </form>
                                    <div className="hrList_wrapper">
                                        <div className="hrList">
                                            {
                                                userList.length > 0 ? (
                                                    userList.map((userListItem: any, i) => {
                                                        return (
                                                            <div key={i} className="hrList_item">
                                                                <div className="hr__details">
                                                                    <div className="profile__img">
                                                                        <HRProfile/>
                                                                    </div>
                                                                    <div className="profile__details">
                                                                        <div className="name montserrat-bold">
                                                                            {userListItem.name}
                                                                        </div>
                                                                        <div className="email">
                                                                            {userListItem.email}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    selectedTabValue === UserRole.HRManager ? (
                                                                        <div className="recruiter__details">
                                                                            <div className="label montserrat-bold">
                                                                                Recruiter Name
                                                                            </div>
                                                                            <div className="name ">
                                                                            {userListItem.recruiterName}
                                                                            </div>
                                                                        </div>
                                                                    ) : ""
                                                                }
                                                                <div className="user__status">
                                                                    <div className="user__status_item">
                                                                        <button disabled  className={ userListItem.status =='inactive' ?'inactive':'active'}  type="button"  >{ userListItem.status }
                                                                        </button>
                                                                    </div>
                                                                    <div className="user__status_menu">
                                                                    <button
                                                                        id="demo-positioned-button"
                                                                        aria-controls={isOpen ? 'demo-positioned-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={isOpen ? 'true' : undefined}
                                                                        onClick={handleMenuClick}
                                                                    ><MoreVertIcon/></button>
                                                                    <Menu
                                                                        id="demo-positioned-menu"
                                                                        aria-labelledby="demo-positioned-button"
                                                                        anchorEl={anchorEl}
                                                                        open={isOpen}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                        }}
                                                                        >
                                                                            <MenuItem style={{padding:'10px',borderBottom:'1px solid #EEEEEE'}} onClick={handleClose}>
                                                                                    <div className="status_item">
                                                                                        <button onClick={e => resendInvitationClick(userListItem.email)} type="button">
                                                                                        <SyncAltIcon className="icon" />  Resend Invitation</button>
                                                                                    </div>
                                                                            </MenuItem>
                                                                            <MenuItem style={{padding:'10px',borderBottom:'1px solid #EEEEEE'}} onClick={handleClose}>
                                                                                    <div className="status_item">
                                                                                        <button onClick={e => deleteInvitationClick(userListItem.email)} type="button">
                                                                                        <DeleteIcon className="icon" />Delete Invitation </button>
                                                                                    </div>
                                                                            </MenuItem>
                                                                    </Menu>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                ):(
                                                    <p className="empty--data"> No data available</p>
                                                )
                                                
                                            }
                                        </div>
                                    </div> */}
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </div>
                <ConfirmationPopupComponent
                    open={openConfirmation}
                    index={index}
                    title={confirmationTitle}
                    message={confirmationMsg}
                    btnLabel={confirmationTitle}
                    onClose={handleConfirmationClose}
                    onConfirm={handleConfirmationConfirm}
                />
                <InviteUser 
                    open={isOpen}
                    emailDomain={organizationDetails}
                    handleClose={handleInviteClose}
                    handleInviteSubmit={handleInviteSubmit}
                />
                {openAssociationPopup &&
                <AssociateUserRelationship
                    open={openAssociationPopup}
                    userDetail={selectedUserDetails}
                    UserRole={UserRole}
                    onClose={handleAssociationPopupClose}
                />}
                {isOpenEditPopup && <EditUserComponent 
                    open={isOpenEditPopup}
                    UserRole={UserRole}
                    userDetail={selectedUserDetails}
                    onClose={handleEditUserPopupClose}
                    onSuccess={handleEditUserPopupSuccess}
                />}
                {openFilter && <FilterPopupComponent 
                    open={openFilter}
                    popoverId={popoverId} 
                    anchorEl={anchorFilter}
                    filterState={filters}
                    onlyDateFilter={true}
                    onClose={handleToggleFilterPopup}
                    onApplyFilter={handleFilterChange}
                />}
            </>

        );

}

