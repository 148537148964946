import { Breadcrumbs, Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, TextField, Typography } from "@mui/material";
import dayjs from 'dayjs';
import React from "react";
import { useSearchParams, Link, useNavigate  } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import { apiService } from "../../../core/services/api.service";
import { useAppSelector } from "../../../../store";
import { authorizationService } from "../../../core/services/authorization.service";
import { toastMessage } from "../../../core/services/toast";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TablePagination from "../../../components/common/tablePagination";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from "moment";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";
import CreateReferral from "./create-referral";

interface Data {
    name: string;
    couponCode: string;
    referralLimit: string;
    candidatesReferralCount: string;
    expirationTimestamp: string;
    createdAt: string;
}


type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
  
const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'couponCode',
        numeric: true,
        disablePadding: false,
        label: 'Coupon Code',
    },
    {
        id: 'referralLimit',
        numeric: true,
        disablePadding: false,
        label: 'Referral Limit',
    },
    {
        id: 'candidatesReferralCount',
        numeric: true,
        disablePadding: false,
        label: 'Usage Count',
    },
    {
        id: 'expirationTimestamp',
        numeric: true,
        disablePadding: false,
        label: 'Expiry Date',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        label: 'Created At',
    },
];


const ReferralList = () => {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    const initialFilterValue = {
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };

    const [referrals, setReferrals] = React.useState([]);
    const itemsPerPage= 10;

    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );
    

    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
            Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
            Referrals
        </Typography>,
    ];

    const [searchName, setSearchName] = React.useState('')
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
    const [permissions, setPermissions] = React.useState<any>(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedReferral, setSelectedReferral] = React.useState<any>(null);
    const [index, setIndex] = React.useState(-1);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    React.useEffect(() => {
        const organizationsPermissions = authorizationService.getPermissions(userDetails?.role, 'organizations', false, userDetails?.isSuperAdmin);
        setPermissions(organizationsPermissions);
    }, [userDetails]);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (isTokenAvailable && permissions?.read) {
            getReferralsList();
            }
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [permissions,itemsPerPage, page, order, orderBy, filters, searchName, isTokenAvailable])

    const getReferralsList = async () => {
        try{
          const organizationList = await fetchReferralsList()
          setReferrals(organizationList)
        }catch(err:any){
  
        }
        setIsLoaded(true)
      }
  
      const fetchReferralsList = async () => {
          let url = `/referral?take=${itemsPerPage}&skip=${(page-1)*itemsPerPage}&order=${order === 'asc' ? 1 : -1}&orderBy=${orderBy}&name=${searchName}` ;
          if (filters.startDate) {
            url = `${url}&startDate=${filters.startDate}`;
          }
          if (filters.endDate) {
              url = `${url}&endDate=${filters.endDate}`;
          }
          try{
            const { data } = await apiService.get(url)
            const result = data?.result;
            const count = data?.count;
            const totalPages = Math.ceil(count/itemsPerPage);
            setCount(totalPages);
            return result;
          }catch(err:any){
            toastMessage(err.response.data.statusCode,null);
          }
      }

      const handleFilterChange = (filterValues: any) => {
        setIsLoaded(false);
        setFilters(filterValues);
      }
    
      const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilter(anchorFilter ? null : event.currentTarget);
        setOpenFilter((previousOpen) => !previousOpen);
      }

      const dateFormat = (date:any) => {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
      }

      const handleConfirmationClickOpen = (index:number) => {
        setIndex(index);
        const referral = referrals[index] || null;
        setSelectedReferral(referral);
        setOpenConfirmation(true);
      };

      const handleClickOpen = (index:number = -1) => {
        setIndex(index);
        const referral = referrals[index] || null;
        setSelectedReferral(referral);
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
        setIndex(-1)
        setSelectedReferral({})
      }

      const openCandidatesPage = (referralId: string) => {
        navigate(`/admin-portal/candidate-referral/${referralId}`);
      }

      const handleConfirmationClickClose = () => {
        setIndex(-1);
        const referral = null;
        setSelectedReferral(referral);
        setOpenConfirmation(false);
      };
    
      const handleConfirmationClose = async (value: number) => {
        let url = `/referral/${selectedReferral?._id}`
        try{
            const response = await apiService.delete(url)
            let message:string =`Referral deleted successfully`;
            toastMessage(response.status, message)
          }catch(err:any){
            toastMessage(err.response.data.statusCode,null);
          }

        referrals.splice(value, 1);
        setOpenConfirmation(false);
      };

      const referralsTable = () => {
        if(referrals.length > 0){
          const referralList =  referrals.map((row:any, index:number) => (
            <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className="custom__cell__row"
            >
            <TableCell component="th" scope="row" className="custom__cell">{row?.name}</TableCell>
            <TableCell align="left" className="custom__cell__element">{row?.couponCode}</TableCell>
            <TableCell align="left" className="custom__cell__element">{row?.referralLimit}</TableCell>
            <TableCell align="left" className="custom__cell__element">{row?.candidatesReferralCount}</TableCell>
            <TableCell align="left" className="custom__cell__element">{dateFormat(row?.expirationTimestamp)}</TableCell>
            <TableCell align="left" className="custom__cell__element">{dateFormat(row?.createdAt)}</TableCell>
            <TableCell align="left" className="custom__cell__element action__icons">
              {permissions?.details && <IconButton title="View Details" onClick={() => openCandidatesPage(row._id)}>
                <VisibilityOutlinedIcon />
              </IconButton>}
              {permissions?.delete && <IconButton onClick={()=>handleConfirmationClickOpen(index)} title="Delete">
                <DeleteOutlineIcon />
              </IconButton>}
              {permissions?.edit &&<IconButton  onClick={()=>handleClickOpen(index)} title="Edit">
                <DriveFileRenameOutlineIcon />
              </IconButton>}
            </TableCell>
          </TableRow>
          ));
          return referralList;
        }else{
          return (
            <TableRow>
              <TableCell colSpan={7} className="no__data">
                No Data Found
              </TableCell>
            </TableRow>
          )
        }
      }

      const handleSearch = (value: string) => {
        if ((value && value.length > 3) || value.length === 0) {
          setPage(1);
          setIsLoaded(false);
          setSearchName(value);
        }
      }

      const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setIsLoaded(false);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

      const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setIsLoaded(false);
        setPage(value)
      }
    
    return(
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        Referrals
                    </div>
                    <div className="search__wrapper">
                        <TextField
                            id="search"
                            type="search"
                            name="new-search"
                            autoComplete='off'
                            placeholder="Search by coupon code"
                            variant="outlined"
                            className="custom__search"
                            defaultValue={searchName}
                            onChange={(event: any) => handleSearch(event.target.value)}
                            sx={{ width: 350 }}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                        {permissions?.add && (<Button variant="contained" className="custom__button custom__create__button" onClick={()=> handleClickOpen()}>Create</Button>)}
                        {permissions?.view && (<Button variant="contained" className="custom__button custom__create__button" onClick={()=> handleClickOpen()}>Create</Button>)}
                    </div>
                </div>
                <div className="table__wrapper">
                    <TableContainer component={Paper} className="custom__table">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              headCells={headCells}
                              isAction={true}
                              isSorting = {true}
                          />
                            <TableBody>
                              {!isLoaded ?  <TableSkeltonComponent rows={10} columns={7} /> : referralsTable()}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={7}>
                                      <TablePagination color="primary" count={count} siblingCount={0} defaultPage={page} boundaryCount={2} page={page} className="custom__pagination" onChange={onPageChange} />
                                    </TableCell>                                        
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
                  {open && 
                      <CreateReferral 
                      open={open}
                      index={index}
                      referralDetails={selectedReferral}
                      onClose={handleClose}
                      onRefresh={getReferralsList}/>
                  }
                    <ConfirmationPopupComponent
                    open={openConfirmation}
                    index={index}
                    title="Delete"
                    message={`Are you sure want to delete coupon code ${selectedReferral?.couponCode}?`}
                    btnLabel="Delete"
                    onClose={handleConfirmationClickClose}
                    onConfirm={handleConfirmationClose}
                    /> 
                    {openFilter && <FilterPopupComponent 
                    open={openFilter}
                    popoverId={popoverId} 
                    anchorEl={anchorFilter}
                    filterState={filters}
                    onlyDateFilter={true}
                    onClose={handleToggleFilterPopup}
                    onApplyFilter={handleFilterChange}
                    />}
            </div>  
        </>
    )
}

export default ReferralList