export enum AuthMethodEnum{
    Google="Google",
    LinkedIn="LinkedIn",
    AppleId="AppleId",
    EmailAndPassword='EmailandPassword'
}

export interface AuthControllerInterface{
    get authorized():boolean;
    get token():string | null;
    get role():string | null;
    fetchAccessToken():void;
    login(args: AuthOptions):void;
    signup(args:SignUpOptions):void;
    forgotPassword(args:ForgotPasswordOptions):void;
    logout():void;
    redirectToDashboard():void;
    isUserLoggedIn():boolean;
    isOrganizationSubscribed():boolean;
};

export type AuthResponse = {
    method: AuthMethodEnum,
    accessToken: string,
    refreshToken: string
};

export type AuthOptions = {
    method: AuthMethodEnum,
    username?: string,
    password?: string,
    isSubscriptionCheckout?: boolean,
    requestId?: string,
    handleRegisterBtnDisplay?:(status:boolean)=>void
};
export type SignUpOptions = {
    method: string,
    payload: SignUpPayload
};
export type SignUpPayload = {
        email: string,
        inviteCode: string,
        data: SignUpPayloadUserDetails
}
export type SignUpPayloadUserDetails = {
    contact: number | string,
    name: string,
    password: string,
    confirmPassword: string
}

export type ForgotPasswordOptions = {
    email: string,
    handleRegisterBtnDisplay?:(status:boolean)=>void
}

export type SignupPayload = {
  email: string;
  inviteCode: string;
  data: {
    password: string;
    confirmPassword: string;
  };
};

export type EmailVerificationPayload = {
    _id: string;
    email: string;
    otp: string;
    password: string;
    confirmPassword: string;
  };

export interface StoreState {
    organization: {
      myOrganization: any;
    }
}
