import axios, { AxiosRequestConfig } from "axios";
import { reactApiBaseUrl, reactAtsApiBaseUrl } from "../constants";

class ApiService {
  // private _baseUrl = "http://localhost:5000";
  // private _baseUrl = 'http://ec2-3-219-113-202.compute-1.amazonaws.com/api';
  // private _baseUrl = 'http://ec2-54-211-6-15.compute-1.amazonaws.com/api';
  private _baseUrl = reactApiBaseUrl;
  private _atsbaseUrl = reactAtsApiBaseUrl;
  private _token?: string;

  set token(t: string | undefined) {
    this._token = t;
  }

  private _getDefaultConfig(providedConfig?: any): AxiosRequestConfig<any> {
    let config: any = providedConfig || {
        headers: {},
      };

    if (this._token) {
      if (config.headers && !config.headers.authorization) {
        const authorization = `Bearer ${this._token}`;
        config.headers.authorization = authorization;
      }
    }

    return config;
  }

  get(path: string, config?: AxiosRequestConfig<any> | undefined) {
    const url = `${this._baseUrl}${path}`;
    config = this._getDefaultConfig(config);
    return axios.get(url, config);
  }

  post(path: string, data: any, config?: AxiosRequestConfig<any> | undefined) {
    const url = `${this._baseUrl}${path}`;
    config = this._getDefaultConfig(config);
    return axios.post(url, data, config);
  }

  atsPost(path: string, config?: AxiosRequestConfig<any> | undefined) {
    const url = `${this._baseUrl}${path}`;
    config = this._getDefaultConfig(config);
    return axios.post(url,{}, config);
  }

  put(path: string, data: any, config?: AxiosRequestConfig<any> | undefined) {
    const url = `${this._baseUrl}${path}`;
    config = this._getDefaultConfig(config);
    return axios.put(url, data, config);
  }

  patch(path: string, data: any, config?: AxiosRequestConfig<any> | undefined) {
    const url = `${this._baseUrl}${path}`;
    config = this._getDefaultConfig(config);
    return axios.patch(url, data, config);
  }

  delete(path: string, config?: AxiosRequestConfig<any> | undefined) {
    const url = `${this._baseUrl}${path}`;
    config = this._getDefaultConfig(config);
    return axios.delete(url, config);
  }
}

export const apiService = new ApiService();
