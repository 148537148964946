import { toast } from 'react-toastify';

import { TypeOptions } from 'react-toastify/dist/types';

class ToastService {
    showToast = (type:TypeOptions,message:string,config:any) => {
        if(type === 'success') return toast.success(message,config);
        if(type === 'error') return toast.error(message,config);
        if(type === 'warning') return toast.warning(message,config);
        if(type === 'info') return toast.info(message,config);
    };
    showToastNotification = (type:TypeOptions, mesaage:string) =>{
        const config = {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            closeButton:false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        }
        this.showToast(type, mesaage, config)
    }
}

export const toastService = new ToastService();