import React from "react";
import "./subscription-success.scss";
import { apiService } from "../../../core/services/api.service";
import RedirectTimer from "../../../components/subscriptions/success/RedirectTimer";
import { CircularProgress, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { useAppSelector } from "../../../../store";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { toastMessage } from "../../../core/services/toast";

interface Addon{
  name:string;
  quantity:number|string;
  description:string;
}

const SubscriptionSuccess = () => {
  const [planName, setPlanName] = React.useState<string>("");
  const [addOns, setAddOns] = React.useState<Addon[]>([]);
  const [price, setPrice] = React.useState<number>(-1);
  const [nextBillingDate , setNextBillingDate] = React.useState<string>("");
  const { search } = useLocation();
  const { hostedpage_id} = queryString.parse(search);
  const [showLoader, setShowLoader] = React.useState(true);
  
  const isTokenAvailable: any = useAppSelector(
    (state) => state.auth.token
  );

  const getPlanDetails = async () => {
    try {
      if(!hostedpage_id) {
         return;
      }
      const { data: orgData} = await apiService.get(`/subscription-management/organization/${hostedpage_id}`);
      localStorage.setItem('hasSubscription', 'true');
      let addons:Addon[]=[];
      orgData?.subscription?.addons.map((addon:any)=>{
        let {name, quantity, description} = addon;
        quantity = quantity>=1000000 ? 'unlimited' : quantity
        addons.push({name, quantity, description})
      })
      // set addons
      setAddOns(addons)
      // get total Price
      const { amount } = orgData?.subscription ?? { amount: 0 };
      setPrice(amount);
      // set plan name
      setPlanName(orgData?.subscription?.plan?.name);
      // set next billing date
      let date =  dayjs(orgData?.subscription?.current_term_ends_at, "YYYY-MM-DD").format("DD MMM YYYY")
      setNextBillingDate(date);
      setShowLoader(false);
    } catch (error:any) {
      setShowLoader(false);
      toastMessage(error.response.data.statusCode, null);
    }
  };
  React.useEffect(() => {
    if(isTokenAvailable){
      getPlanDetails();
    }
    
  }, [isTokenAvailable]);

  return (
    <>
    {showLoader ? (
      <div className="no--content">
        <CircularProgress className="custom__spinner" />
      </div>) : (
    <div className="subscription-success-root">
      <div className="subscription">
        {planName && addOns.length>0 && price>=0 && nextBillingDate ? (
          <>
            <div className="subscription-header-container">
              <header className="subscription-header">Thank you</header>
              <div className="subscription-header-description">
                <div>
                  Your subscription to {planName} plan is successful.
                </div>
                <span>
                  You will be redirected to the Dashboard in 10 secs, or{" "}
                </span>
                <span
                  className="redirect-text"
                  onClick={() =>
                    window.location.replace(`/customer-portal/dashboard`)
                  }
                >
                  Click here
                </span>
              </div>

              <div className="success-spinner">
                <div className="spinner">
                  <RedirectTimer />
                </div>
              </div>
            </div>
            <div className="selected-plan">
              <div className="plan">
                <div className="plan-name">{planName}</div>
                <hr
                  style={{
                    marginBottom: "15px",
                    backgroundColor: "#bcbcbc",
                    height: "1px",
                    border: "none",
                  }}
                />

                <div className="plan-addons-headers">
                  <div className="addon-name-header">Addons</div>
                  <div className="addon-qty-header">Qty</div>
                </div>

                <div>
                  {addOns.map((addon) => {
                    return (
                      <div key={addon?.name} className="plan-addons">
                        <div className="addon-name">{addon?.name}</div>
                        <div className="addon-qty">{addon?.quantity}</div>
                      </div>
                    );
                  })}
                </div>

                <div className="pricing">
                  <div>

                  <span className="price" style={{ color: "#2e2e2e" }}>
                    Price: 
                  </span>
                  <span className="price">
                    ${price}
                  </span>
                  </div>
                  <span>Next Renewal On {nextBillingDate}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="subscription-header-container">
            <Skeleton
              sx={{
                bgcolor: "grey.300",
                marginTop: "30px",
                borderRadius: "10px",
              }}
              variant="rectangular"
              animation="wave"
              width={"30%"}
              height={70}
            />
            <Skeleton
              sx={{
                bgcolor: "grey.300",
                marginTop: "10px",
                borderRadius: "10px",
              }}
              variant="rectangular"
              animation="wave"
              width={"50%"}
              height={50}
            />
            <Skeleton
              sx={{ bgcolor: "grey.300", margin: "25px 0" }}
              animation="wave"
              variant="circular"
              width={90}
              height={90}
            />

            <div className="pricing-card-base">
              <div className="pricing-card-base-1 ">
                <div className="pricind-card">
                  <div className="plan-name-desc">
                    <div className="plan-name">
                      <Skeleton variant="text" width={330} height={30} />
                    </div>
                  </div>
                  <div className="addon-details">
                    {[...Array(2)].map((value, key) => (
                      <div key={value} className="addon-details-div">
                        <Skeleton variant="text" width={"100%"} height={30} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="price-container">
                  <div className="plan-price">
                    <Skeleton variant="text" width={150} height={30} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    )}
    </>
  );
};


export default SubscriptionSuccess;