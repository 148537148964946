import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { authorizationService } from "../../../core/services/authorization.service";
import { useAppSelector } from "../../../../store";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { apiService } from "../../../core/services/api.service";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
import { IconButton } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import moment from "moment";

  interface Data {
    email: string;
    firstName: string;
    lastName: string;
    contact: string;
    organization: string;
    createdAt: string;
  }
  
  type Order = 'asc' | 'desc';
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    sorting: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'First Name',
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Last Name',
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Email',
    },
    {
        id: 'contact',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Contact',
    },
    {
        id: 'organization',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Organization',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Created Date',
    },
  ];

  const rowSpan = 10;
  const colSpan = 7;

  export default function ATSCandidatesComponent() {
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          ATS Candidates
        </Typography>,
    ];
    const initialFilterValue: any = {
      startDate: null,
      endDate: null,
      org: null
    };
    const userDetails: any = useAppSelector(
      (state) => state.auth.userDetail
    );
    const isTokenAvailable: any = useAppSelector(
      (state) => state.auth.token
    );
    const limit = 100;
    const [searchParams] = useSearchParams();
    const requestData: any = searchParams.get("requestData");

    const [candidates, setCandidates] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [candidateListLoading, setCandidateListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    React.useEffect(() => {
      const candidatesPermissions = authorizationService.getPermissions(userDetails?.role, 'atsCandidates', false, userDetails?.isSuperAdmin);
      setPermissions(candidatesPermissions);
    }, [userDetails]);

    React.useEffect(() => {
      if (requestData && isTokenAvailable && permissions?.read) {
        const parsedRequestData = atob(requestData);
        fetchOrganizationDetails(parsedRequestData);
      }
    }, [requestData, isTokenAvailable, permissions])

    React.useEffect(() => {
      setCandidateListLoading(true);
      const delayDebounceFn = setTimeout(() => {
        if (isTokenAvailable && permissions?.read) {
          getCandidatesList();
        }
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }, [page, order, orderBy, filters, searchTerm, isTokenAvailable, permissions])

    const getCandidatesList = async() => {
      let url = `/ats/candidate?take=${limit}&skip=${(page-1)*limit}&order=${order === 'asc'?1:-1}&orderBy=${orderBy}&term=${searchTerm}` ;
      if (filters.startDate) {
        url = `${url}&startDate=${filters.startDate}`;
      }
      if (filters.endDate) {
          url = `${url}&endDate=${filters.endDate}`;
      }
      if (filters?.org?._id) {
        url = `${url}&organizationId=${filters.org._id}`;
      }
      try{
      const { data } = await apiService.post(url, {});
      const candidateListData = data?.result;
      const count = data?.count;
      setCandidates(candidateListData);
      setPages(Math.ceil(count/limit));
      setCandidateListLoading(false);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    async function fetchOrganizationDetails(id:string) {
      let url = `/organizations/${id}` ;
      const { data } = await apiService.get(url);
      setFilters((prevState: any) => {
        return {...prevState, "org": data};
      });
    }

    const candidateList=()=>{
      if(candidates.length > 0){
        const candidatesList = candidates.map((row:any, index:number) => {
          return(
            <TableRow
                key={row?._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="custom__cell__row"
                >
                <TableCell align="left" className="custom__cell__element">
                    <span>{row?.firstName}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">
                    <span>{row?.lastName}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.emailAddresses.length ? getFormattedValue(row?.emailAddresses) : ''}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.phoneNumbers.length ? getFormattedValue(row?.phoneNumbers) : ''}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.organization?.name}</TableCell>
                <TableCell align="left" className="custom__cell__element">{dateFormat(row?.createdAt)}</TableCell>
                <TableCell align="left" className="custom__cell__element action__icons d-flex">
                  <IconButton title="Sync Candidate" onClick={()=>syncATSCandidate(row?._id, row?.organization?._id)}>
                    <SyncIcon />
                  </IconButton>
                </TableCell>
            </TableRow>
          )
      })
      return candidatesList;
      }else{
        return (<TableRow>
                  <TableCell colSpan={colSpan} className="no__data">
                  No Data Available !
                  </TableCell>
              </TableRow>)
      }
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setCandidateListLoading(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setCandidateListLoading(true);
      setPage(value)
    }

    const handleSearchChange = (value: string) => {
      if ((value && value.length > 3) || value.length === 0) {
        setPage(1);
        setCandidateListLoading(true);
        setSearchTerm(value);
      }
    };

    const handleFilterChange = (filterValues: any) => {
      setCandidateListLoading(false);
      setFilters(filterValues);
    }
  
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorFilter(anchorFilter ? null : event.currentTarget);
      setOpenFilter((previousOpen) => !previousOpen);
    }

    const syncATSCandidate = async (id: string, orgId: string) => {
        if (id && orgId) {
            try {
                let url = `/ats/candidate/${id}/sync?orgId=${orgId}`;
                const response = await apiService.get(url);
                let message:string = "Synchronizing ATS candidate!";
                toastMessage(response?.status, message, "info");
                setTimeout(() => {
                    setCandidateListLoading(true);
                    getCandidatesList();
                }, 1000);
            }catch(error:any){
                toastMessage(error?.response?.data?.statusCode, error?.response?.data?.message, "error");
            }
        }
    }

    const dateFormat = (date:any) => {
        if (date) {
            let currentDate = moment(date).format('DD-MMM-YYYY');
            return currentDate;
        }
    }

    const getFormattedValue = (arr:any) => {
        let returnValue:string = '';
        arr.map((element:any, index:number) => {
            if (arr.length > 1 && index !== arr.length - 1) {
                returnValue = element.value + ', ';
            } else {
                returnValue = element.value;
            }
        });
        return returnValue;
    }

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        ATS Candidates
                    </div>
                    <div className="search__wrapper">
                        <TextField
                            id="search"
                            type="search"
                            name="new-search"
                            autoComplete='off'
                            placeholder="Search"
                            variant="outlined"
                            className="custom__search"
                            defaultValue={searchTerm}
                            onChange={(event: any) => handleSearchChange(event.target.value)}
                            sx={{ width: 350 }}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>
                </div>
                <div className="table__wrapper">
                  <TableContainer component={Paper} className="custom__table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        isAction = {true}
                        isSorting = {true}
                    />
                        <TableBody>
                            {candidateListLoading ? <TableSkeltonComponent rows={rowSpan} columns={colSpan} /> : candidateList()
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={colSpan}>
                                  <TablePagination count={pages} siblingCount={0} defaultPage={page} boundaryCount={2} className="custom__pagination" onChange={handleChange}/>
                                </TableCell>                                        
                            </TableRow>
                        </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
            </div> 
            {openFilter && <FilterPopupComponent 
              open={openFilter}
              popoverId={popoverId} 
              anchorEl={anchorFilter}
              filterState={filters}
              onlyDateFilter={true}
              organizationFilter={true}
              onClose={handleToggleFilterPopup}
              onApplyFilter={handleFilterChange}
            />}  
        </>
    );
  }
