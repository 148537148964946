import React, { useEffect } from "react";
import {
  Button,
  Tooltip,
  CircularProgress, Link, ButtonGroup
} from "@mui/material";
import { PlanData, PlanType } from "../../../types/plans.interface";
import { useNavigate } from "react-router";
import CheckIcon from "@mui/icons-material/Check";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { ContactUsDialog } from "../Contact-us-dialog";
import { apiService } from "../../../core/services/api.service";
import {
  freemiumFeatures,
  liteFeatures,
  planIntervalType,
  premiumFeatures,
  proFeatures, LiteJobsQuantity, PremiumJobsQuantity, reactWebsiteHubspotFormBaseUrl
} from "../../../core/constants";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toastMessage } from "../../../core/services/toast";
import { ToggleButton } from "./ToggleButton.component";
import { AtsPartners } from "../atsPartners/AtsPartners.component";
import ThankyouPopupComponent from "../../dialogs/thankyou-popup/thankyou-popup.component";

const initialDisableCounterState = {
  'LITE': {
    increment: false,
    decrement: true
  },
  'PREMIUM': {
    increment: false,
    decrement: true
  }
}

export function PlansComponent(props: any) {
  const [showContactUs, setShowContactUs] = React.useState(false);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [subType, setSubType] = React.useState(planIntervalType.MONTHLY);
  const [filteredPlan, setFilteredPlan] = React.useState();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [liteJobsIndex, setLiteJobsIndex] = React.useState(0);
  const [premiumJobsIndex, setPremiumJobsIndex] = React.useState(0);
  const [disableCounter, setDisableCounter] = React.useState(initialDisableCounterState);

  useEffect(() => {
    setShowLoader(false);
    setTimeout(() => {
      handlePremiumMonthly(props?.quantity?.find((qty : any )=> qty.planCode === PlanType.PREEMIUM)?.quantity, PlanType.PREMIUM_MONTHLY);
    }, 1000);
  }, [])

  const handleJobPostChangeIncrement = (addOnId: any, planId: any, planCode: any) => {
    if (planCode === PlanType.LITE) {
      if (liteJobsIndex < (LiteJobsQuantity.length - 1)) {
        const index = liteJobsIndex + 1;
        const value = LiteJobsQuantity[index];
        setLiteJobsIndex(index);
        handleAddon(value, addOnId, planId, planCode);
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: false}}
        })
        if (index === LiteJobsQuantity.length - 1) {
          setDisableCounter(prevstate => {
            return {...prevstate, LITE: {increment: true, decrement: false}}
          })
        }
      } else {
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: true, decrement: false}}
        })
      }
    }
    if (planCode === PlanType.PREEMIUM) {
      if (premiumJobsIndex < (PremiumJobsQuantity.length - 1)) {
        const index = premiumJobsIndex + 1;
        const value = PremiumJobsQuantity[index];
        setPremiumJobsIndex(index);
        handleAddon(value, addOnId, planId, planCode);
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: false}}
        })
      } else {
        handleAddon("Above 100", addOnId, planId, planCode);
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: true, decrement: false}}
        })
      }
    }
  }

  const handleJobPostChangeDecrement = (addOnId: any, planId: any, planCode: any) => {
    if (planCode === PlanType.LITE) {
      if (liteJobsIndex > 0) {
        const index = liteJobsIndex - 1;
        const value = LiteJobsQuantity[index];
        setLiteJobsIndex(index);
        handleAddon(value, addOnId, planId, planCode);
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: false}}
        })
        if (index === 0) {
          setDisableCounter(prevstate => {
            return {...prevstate, LITE: {increment: false, decrement: true}}
          })
        }
      } else {
        setDisableCounter(prevstate => {
          return {...prevstate, LITE: {increment: false, decrement: true}}
        })
      }
    }
    if (planCode === PlanType.PREEMIUM) {
      if (premiumJobsIndex > 0) {
        const index = premiumJobsIndex - 1;
        const value = PremiumJobsQuantity[index];
        setPremiumJobsIndex(index);
        handleAddon(value, addOnId, planId, planCode);
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: false}}
        })
        if (index === 0) {
          setDisableCounter(prevstate => {
            return {...prevstate, PREMIUM: {increment: false, decrement: true}}
          })
        }
      } else {
        setDisableCounter(prevstate => {
          return {...prevstate, PREMIUM: {increment: false, decrement: true}}
        })
      }  
    }  
  }
  
  // Update Addon price on each onChange event
  const handleAddon = (value: any, addOnId: any, planId: any, planCode: any) => {
    props.handleAddonPrice(value, addOnId, planId, planCode);
    if(planCode === PlanType.PREEMIUM && subType === planIntervalType.MONTHLY){
      handlePremiumMonthly(value, PlanType.PREMIUM_MONTHLY);
    }
  };

  const handlePremiumMonthly = (qty: any, planCode: any) =>{
      const filteredPlan = props?.plans?.find((plan : any) => plan?.plan_code === PlanType.PREMIUM_MONTHLY);
      const addOn = filteredPlan?.addons?.find((addon : any) => addon?.addon_code === 'PREMIUMJOBS_MONTHLY');
      const planId = filteredPlan?.plan_id;
      const addOnId = addOn?.addon_id;
      setFilteredPlan(filteredPlan);
      props.handleAddonPrice(qty, addOnId, planId, planCode);
  }

  const onSubmitHandler = async (plan: PlanData) => {
    if(subType === planIntervalType.MONTHLY && filteredPlan && plan?.plan_code === PlanType.PREEMIUM){
       plan = filteredPlan
    }
    setShowLoader(true);
    //update plan
    const addOns = plan.addons?.map((addon) => ({
      name: addon?.name,
      addon_code: addon?.addon_code,
      price: addon?.price_brackets[0]?.price,
      quantity: addon?.selected_qty,
    }));
    const organizationDetails = props?.organization;
    const payload = {
      _id: organizationDetails?._id,
      name: organizationDetails?.name,
      website: organizationDetails?.website,
      email: organizationDetails?.email,
      contact: organizationDetails?.contact,
      revenue: organizationDetails?.revenue,
      size: organizationDetails?.size,
      personName: organizationDetails?.personName,
      plan: {
        name: plan?.name,
        plan_code: plan?.plan_code,
      },
      addons: [...addOns],
    };

    const upgradePayload = {
      plan: {
        name: plan?.name,
        plan_code: plan?.plan_code,
      },
      addons: [...addOns],
    };

    // ---------- Update Organization Details When Plan Is Selected ---------
    if (props?.isLoggedIn) {
      // If user is logged in then use /organizations api
      try {
        await apiService.put(`/organizations/${payload._id}`, payload);
        // Need to encode the request id into Base64 version when navigating
        if (props?.isUpgrade) {
          const { data } = await apiService.post(
            `/subscription-management/organization/subscription`,
            upgradePayload
          );
          window.location.href = data?.url;
          setShowLoader(false);
        } else {
          setShowLoader(false);
          navigate(`/subscriptions/checkout?requestId=${btoa(payload._id)}`);
        }
      } catch (error: any) {
        setShowLoader(false);
        toastMessage(error.response.data.statusCode, error?.response?.data?.message, "error");
      }
    } else {
      // redirecting to create organization request form
      setShowLoader(false);
      const returnObj = {
        plan: {
          name: plan?.name,
          plan_code: plan?.plan_code,
        },
        addons: [...addOns],
        action: "buyPlan"
      };
      const reqId = btoa(JSON.stringify(returnObj));
      const url = `organization-lead?requestId=${reqId}`;
      window.location.href = `/${url}`;
    }
    // -----------------------------------------------
  };


  const handleContactUsClose = () => {
    setShowContactUs(false);
  };

  const planFeatures=(planCode:string, qty: any, isQtyChanged: boolean)=>{
    interface Feature{
      [key:string]:string[]
    }
    const features:Feature={
      "TRIAL":freemiumFeatures,
      "PRO":proFeatures,
      "PREMIUM":premiumFeatures,
      "PREMIUM_MONTHLY":premiumFeatures,
      "LITE":liteFeatures
    }
    const feature = features[planCode]?.map((feature) => {
      const featureText = feature.includes('#qty#') ? feature.replace('#qty#', props?.quantity?.find((qty : any )=> qty?.planCode === planCode)?.quantity) : feature;
      return (
        <Tooltip title={featureText} key={featureText}>
          <div className="list-content">
            <div className="left_list_content_icon">
            <PlayCircleFilledWhiteIcon
              sx={{
                stroke: "#fff",
                width: 26,
                height: 26,
                fontSize:26,
              }}
            />
            </div>
            <div className="right_list_content_text">
              {featureText}
            </div>
          </div>
        </Tooltip>
      )
    })
    
    return feature;
  }

  const planTerm=(planCode:string)=>{
    switch (planCode) {
      case PlanType.FREEMIUM:
        return "For 2 Months";
      case PlanType.LITE:
        return "For 2 Months";
      case PlanType.PREEMIUM:
        if (subType === planIntervalType.MONTHLY) return "Per Month";
        else return "For 1 Year";
      case PlanType.PREMIUM_MONTHLY:
        return "Per Month";
    }
  }

  const upgradeButtons = (planCode:string) => {
    let btn: string = "Buy Plan";
    if (planCode === PlanType.FREEMIUM) {
      btn = "Post free job";
    }
    if (planCode === PlanType.LITE) {
      btn = "Buy Lite plan";
    }
    if (planCode === PlanType.PREEMIUM) {
      btn = "Buy Premium Plan";
    }
    return btn;
  }
  
  const handleSubType = (type : any) => {
    setSubType(type);
    if(type === planIntervalType.MONTHLY){
      handlePremiumMonthly(props?.quantity?.find((qty : any )=> qty.planCode === PlanType.PREEMIUM)?.quantity, PlanType.PREMIUM_MONTHLY);
    }
  }

  const handleRequestQuote = (plan: PlanData) => {
    if(subType === planIntervalType.MONTHLY && filteredPlan && plan?.plan_code === PlanType.PREEMIUM){
      plan = filteredPlan
    }
    const addOns = plan.addons?.map((addon) => ({
      name: addon?.name,
      addon_code: addon?.addon_code,
      price: plan?.plan_code === PlanType.LITE ? (addon?.price_brackets[0]?.price * 2) : addon?.price_brackets[0]?.price,
      quantity: addon?.selected_qty,
    }));
    const planDetail = {
      name: plan?.name,
      plan_code: plan?.plan_code,
      durationText: planTerm(plan?.plan_code)
    };
    if (props?.isLoggedIn || props?.isUpgrade || props?.isRenew) {
      requestQuote(planDetail, addOns);
    } else {
      const returnObj = {
        plan: planDetail,
        addons: [...addOns],
        action: "requestQuote"
      };
      const reqId = btoa(JSON.stringify(returnObj));
      const url = `organization-lead?requestId=${reqId}`;
      window.location.href = `/${url}`;
    }
  }

  const requestQuote = async (planDetail: any, addOns: any) => {
    setShowLoader(true);
    const payload = {
      plan: {
        name: planDetail?.name,
        plan_code: planDetail?.plan_code,
        durationText: planDetail?.durationText
      },
      addons: [...addOns],
    };
    try {
      apiService.post(
        `/subscription-management/organization/subscription?quote=true`,
        payload
      );
      setShowLoader(false);
      setOpenPopup(true);
    } catch (error: any) {
      setShowLoader(false);
      toastMessage(error.response.data.statusCode, error?.response?.data?.message, "error");
    }
  }

  const handlePopupClose = () => {
    setOpenPopup(false);
  }

  const handleButtonWithNewTabClick = (url: string) => {
    window.open(url, '_blank');
  };

  const handleContactUSRedirection = () => {
    const contactUsUrl = `${reactWebsiteHubspotFormBaseUrl}/contactus/`;
    window.open(contactUsUrl, '_blank');
  }

  const getPlanCardDiagonalStrip = (plan: any) => {
    return (
      <div className="diagonal_strip">
        <div className="diagonal_strip_content">
        {plan?.plan_code === PlanType.FREEMIUM  && <>Credit Card <br /> not required!</>}
        {plan?.plan_code === PlanType.LITE  && <></>}
        {plan?.plan_code === PlanType.PREEMIUM  && <>Best Value !</>}
        {plan?.plan_code === PlanType.PRO  && <></>}
        </div>
      </div>
    );
  }

  const getPlanCardAddons = (plan: any) => {
    const cardAddons = plan?.addons?.map((addon: any) => (
      <div
        className="addon-details-div"
        key={addon?.addon_id}
      >
        {!plan.name.includes(
          PlanType.Enterprise
        ) ? (
          <Tooltip title={addon?.name}>
            <span className="list-content">
              Jobs Posts:
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={addon?.name}>
            <span className="list-content list-content-1">
              <CheckIcon
                sx={{
                  stroke: "#9f2223",
                  width: 14,
                  height: 14,
                  fontWeight: "bold",
                  strokeWidth: 3,
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 2,
                }}
              />
              {addon?.name}
            </span>
          </Tooltip>
        )}
        {!plan?.name?.includes(
          PlanType.Enterprise
        ) && (
            <ButtonGroup size="small" aria-label="small outlined button group" className="jobPostBtnGroup">
              <Button className="jobPostBtn" onClick={(event) =>
                handleJobPostChangeDecrement(
                  addon?.addon_id,
                  plan?.plan_id,
                  plan?.plan_code
                )
              } disabled={
                plan?.plan_code ===
                PlanType.FREEMIUM || disableCounter[plan?.plan_code as keyof typeof disableCounter]?.decrement
              }><RemoveCircleIcon /></Button>
              <Button className="jobPostBtnField" disabled>{addon?.selected_qty}</Button>
              <Button className="jobPostBtn" onClick={(event) =>
                handleJobPostChangeIncrement(
                  addon?.addon_id,
                  plan?.plan_id,
                  plan?.plan_code
                )
              } disabled={
                plan?.plan_code ===
                PlanType.FREEMIUM || disableCounter[plan?.plan_code as keyof typeof disableCounter]?.increment
              }><AddCircleIcon /></Button>
            </ButtonGroup>
        )}
      </div>
    ));

    return cardAddons;
  }

  const getPlanCardPrice = (plan: any) => {
    if ((plan?.plan_code !== PlanType.PREEMIUM || subType === planIntervalType.ANNUALLY) && plan?.plan_code !== PlanType.LITE) {
      return (
        <div className="plan-price">
        $
        {props.plans?.find(
          (planDetails: any) =>
            planDetails?.plan_id === plan?.plan_id
        )?.totalPrice
          ? Math.trunc(
              props.plans?.find(
                (planDetails: any) =>
                  planDetails?.plan_id ===
                  plan?.plan_id
              )?.totalPrice
            )
          : 0}
        </div>
      );
    }
    if (plan?.plan_code === PlanType.PREEMIUM &&
      subType === planIntervalType.MONTHLY) {
        return (
          <div className="plan-price">
          $
          {props.plans?.find(
            (planDetails: any) =>
              planDetails?.plan_code ===
              PlanType.PREMIUM_MONTHLY
          )?.totalPrice
            ? Math.trunc(
                props.plans?.find(
                  (planDetails: any) =>
                    planDetails?.plan_code ===
                    PlanType.PREMIUM_MONTHLY
                )?.totalPrice
              )
            : 0}
          </div>
        );
    }
    if (plan?.plan_code === PlanType.LITE) {
      return (
        <div className="plan-price">
        $
        {props.plans?.find(
          (planDetails: any) =>
            planDetails?.plan_id === plan?.plan_id
        )?.totalPrice
          ? Math.trunc(
              props.plans?.find(
                (planDetails: any) =>
                  planDetails?.plan_id ===
                  plan?.plan_id
              )?.totalPrice
            ) * 2
          : 0}
        </div>
      );
    }
  }

  const buyPlanButton = (plan: any) => {
    if (plan?.plan_code !== PlanType.PRO) {
      return (
        <Button
          variant="contained"
          type="submit"
          fullWidth
          className={`buy-plan-btn ${plan?.plan_code === PlanType.FREEMIUM ? 'no_link_btn' : '' }`}
          onClick={() => onSubmitHandler(plan)}
        >
          {props?.isUpgrade
            ? "Upgrade"
            : upgradeButtons(plan?.plan_code)}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          type="submit"
          fullWidth
          className="buy-plan-btn no_link_btn"
          id="basic-button"
          onClick={handleContactUSRedirection}
        >
          Contact Us
        </Button>
      );
    }
  }

  const requestQuoteLink = (plan: any) => {
    if (plan?.plan_code === PlanType.PREEMIUM || plan?.plan_code === PlanType.LITE) {
      return (
        <div className="request_quote_link">
          <Link component="button" variant="body2" onClick={() => handleRequestQuote(plan)} underline="hover" className="quote_btn">
            {'Request a quote'}
          </Link>
        </div>
      );
    }        
  }

  const getPlanCard = () => {
    const plansCard = props.plans?.map(
      (plan: any) => (
        plan.plan_code !== PlanType.PREMIUM_MONTHLY && (
          <div className={`pricing-card-base ${plan?.plan_code}`} key={plan?.plan_id}>                        
            {getPlanCardDiagonalStrip(plan)}            
            <div className="pricing-card-base-1 ">
              <div className="pricind-card">
                <div className="pricing_header">
                  <div className="plan-name">
                    <Tooltip title={plan?.name}>
                      <span className="plan-name-text">
                        {plan?.name && plan.name.charAt(0).toUpperCase() + plan.name.slice(1).toLowerCase()}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className="plan-name-desc">
                  <div className="plan-name">
                    <Tooltip title={plan?.description}>
                      <span className="plan-desc">
                        {plan?.description}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                {plan?.plan_code !== PlanType.PRO && <div className="addon-details">
                  {!plan?.name?.includes(PlanType.PRO) && getPlanCardAddons(plan)}
                </div>
                }    
                {plan?.plan_code !== PlanType.PRO && <div className="pricing-btn-container">
                  {plan?.plan_code !== PlanType.PRO && (
                    <div className="price-container">
                      <div className="toogle_btn_wrapper">
                        {plan?.plan_code === PlanType.PREEMIUM && (
                          <ToggleButton
                            selectedSubType={subType}
                            handleSubType={handleSubType}
                          />
                        )}
                      </div>
                      {getPlanCardPrice(plan)}
                      <div className="plan-price-per">
                        {planTerm(plan?.plan_code)}
                      </div>
                    </div>
                  )}
                </div>
                }
                <div className={ `plan-features ${plan?.plan_code === PlanType.PRO ?  "pro-features" : ""}`}>
                  {planFeatures(plan?.plan_code, 0, false)}
                </div>
              </div>
            </div>
            <div className="pricing-btn-container_footer">
              {buyPlanButton(plan)}
              {requestQuoteLink(plan)}
            </div>
          </div>
        )
      )
    )
    return plansCard;
  }
  
  return (
    <>
      <div
        className={`pricing-root ${
          props?.isLoggedIn
            ? "pageHeightWithNavbar"
            : "pageHeightWithoutNavbar"
        }`}
      >
        <div
          className={`pricing-container ${
            props?.isLoggedIn
              ? "pageHeightWithNavbar"
              : "pageHeightWithoutNavbar pricing-wrapper"
          }`}
        >
          <div className="pricing-header-container">
            <h1 className={`pricing-header ${!props?.isLoggedIn ? 'marginTop140' : ''}`}>Hire better and faster!</h1>
            <div className="pricing-header-description">
            Tap into the power of our <span className="break_head_desc">advanced hiring platform</span> 
            </div>
          </div>
          <div className="pricing-main">
            {props.plans.length  ? (
              getPlanCard()
            ) : (
              <>
                <div className="no--content">
                  <CircularProgress className="custom__spinner" />
                </div>
              </>
            )}
          </div>
          <ContactUsDialog
            showContactUs={showContactUs}
            handleContactUsClose={handleContactUsClose}
          />
          <div className="additional-details schedule_demo_section">
            <div className="content__wrapper">
              <button className="cmn_btn" onClick={() => handleButtonWithNewTabClick(`${reactWebsiteHubspotFormBaseUrl}/schedule-demo/`)}>
                Schedule Demo
              </button>
              <button className="cmn_btn" onClick={() => handleButtonWithNewTabClick(`${reactWebsiteHubspotFormBaseUrl}/contactus/`)}>
                Contact Sales
              </button>
            </div>
          </div>
          {!props?.isLoggedIn && <div className="additional-details partner_container">
            <AtsPartners />
          </div>}
        </div>
        {openPopup && <ThankyouPopupComponent 
          open={openPopup}
          msg="Requested quote of the plan is sent on your registered email address."
          onClose={handlePopupClose}
        />}
      </div>
      {showLoader &&
        <div className="no--content">
          <CircularProgress className="custom__spinner" />
        </div>}
    </>
  );
}
