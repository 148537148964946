import { permissions } from "../role-feature-permissions";

class AuthorizationService {
    getPermissions(role: string, feature: string, isPoc: boolean = false, isSuperAdmin: boolean = false) {
        if (role && feature) {
            let userRole = role;
            if (isPoc) {
                userRole = 'poc';
            }
            if (isSuperAdmin) {
                userRole = 'super_admin';
            }
            const rolePermissions = permissions[userRole as keyof typeof permissions];
            const featurePermissions = rolePermissions[feature as keyof typeof rolePermissions];
    
            return featurePermissions;
        } else {
            return null;
        }
    };
}

export const authorizationService = new AuthorizationService();
