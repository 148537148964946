import React from "react";
import "../../../components/admin/organizations/organization-details.scss";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppDispatch, useAppSelector } from "../../../../store";
import OrganizationDetailsComponent from "../../../components/admin/organizations/organization-details.component";
import { fetchMyOrganizationDetails } from "../../../../store/slice/organization-slice";

export default function OrganizationSubscriptionComponent() {
    const dispatch = useAppDispatch();
    const organizationDetails : any = useAppSelector(
        state => state.organization.myOrganization
    );
    const [loading, setLoading] = React.useState(false);

    const breadcrumbs = [
        <Link key="1" color="inherit" to="/customer-portal/dashboard" className="inactive_menu">
            Home
        </Link>,
        <Typography key="3" color="text.primary" className="active_menu">
            Subscription
        </Typography>,
    ];

    const getOrganizationDetails =() => {
        dispatch(fetchMyOrganizationDetails());
    }

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard__main">
                <div className="dashboard_header">
                    <div className="heading_wrapper">
                        <div className="dashboard__heading">Subscription</div>
                    </div>
                </div>
                <OrganizationDetailsComponent
                    loading={loading}
                    renderedFrom="organization"
                    organizationDetails={organizationDetails}
                    showLoader={(value: boolean) => setLoading(value)}
                    getOrganizationDetails={getOrganizationDetails}
                />
            </div>
        </>
    )
}
