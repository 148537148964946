import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import { visuallyHidden } from '@mui/utils';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void;
    order?: any;
    orderBy?: string;
    isAction: boolean;
    isSorting: boolean;
    headCells: Array<any>
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, isSorting, onRequestSort } =
      props;
    const createSortHandler =
      (property: any) => (event: React.MouseEvent<unknown>) => {
        if (onRequestSort) onRequestSort(event, property);
      };
  
    return (
      <TableHead>
        <TableRow>
          {props.headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.isWidth ? '50%': ''}
              className={!isSorting ? 'custom__header': ''}
            >
              {isSorting && (headCell.sorting === undefined || headCell.sorting === true) ? <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className="custom__header"
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              : <span className="custom__header">{headCell.label}</span>}
            </TableCell>
          ))}
          {props.isAction && (<TableCell className="custom__header">
            Action
          </TableCell>)}
        </TableRow>
      </TableHead>
    );
  }