import React from "react";
import CircularProgress, {
    CircularProgressProps,
  } from "@mui/material/CircularProgress";
  import { createTheme, useMediaQuery } from '@mui/material';
  import Typography from "@mui/material/Typography";
  import Box from "@mui/material/Box";
  
export default function RedirectTimer() {
    const [progress, setProgress] = React.useState<number>(100);
    const [timeRemaining, setTimeRemaining] = React.useState<number>(10);
    const totalTime = 10;
  
    React.useEffect(() => {
      // after every second change remaining time and value of circular bar progress
      const timer = setInterval(() => {
        setTimeRemaining((prevTime)=>--prevTime)
        let remainingProgress = (100 * (timeRemaining-1)) / totalTime;
        setProgress(+remainingProgress.toFixed(0));
        // navigate to dashboard when remainingProgress is 0 ie. total time completed
        if (remainingProgress === 0) {
          setProgress(100);
          setTimeRemaining(totalTime);
          window.location.replace(`/customer-portal/dashboard`)
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [timeRemaining]);
  
    return (
      <div style={{ color: "#9f2223" }}>
        <CircularProgressWithLabel
          color={"inherit"}
          value={progress}
          label={timeRemaining}
        />
      </div>
    );
  }
  
  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number; label: number }
  ) {
    const customTheme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 426, 
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
    });
    
    const isMobileScreen = useMediaQuery(customTheme.breakpoints.down('sm'));
  
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress size={isMobileScreen ? 45:80} variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant={isMobileScreen ? "h6":"h4"} component="div" color="black">
            {props.label}
          </Typography>
        </Box>
      </Box>
    );
  }