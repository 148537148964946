import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import './pricing-header.scss';
import logo from "../../../assets/tango_logo.png";

import {pricingLeftMenus, pricingRightMenus, pricingMobileViewMenus, reactWebsiteBaseUrl} from '../../core/constants';

function PricingHeaderComponent() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" className='appbar'>
      <Container maxWidth="xl" className='appbar_container'>
        <Toolbar disableGutters className='appbar_toolbar'>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <a href={reactWebsiteBaseUrl} className='headerLogoLink' target="_blank">
              <img src={logo} alt="Logo" />
            </a>
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1
            }}
            className='mobileLogo'
          >
            <a href={reactWebsiteBaseUrl} className='headerLogoLink' target="_blank">
              <img src={logo} alt="Logo" />
            </a>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} className='mobileMenuBox'>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              className='menuIcon'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                style: {
                  width: '100%',
                  maxWidth: 'unset',
                }
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              className='drodpown_menu'
            >
              {pricingMobileViewMenus.map((menu) => (
                <MenuItem key={menu.label}>
                  <a href={menu.url} target={menu.url === '' ? "_self" : "_blank"} className='mobileMenuItems'>{menu.label}</a>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pricingLeftMenus.map((menu) => (
              <a
                href={menu.url}
                target={menu.url === '' ? "_self" : "_blank"}
                key={menu.label}
                className="menuItems"
              >
                {menu.label}
              </a>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                {pricingRightMenus.map((menu) => (
                <a
                    href={menu.url}
                    target="_blank"
                    key={menu.label}
                    className='menuItems'
                >
                    {menu.label}
                </a>
                ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Button className='getStartedBtn'>
                Get Started
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default PricingHeaderComponent;