import React from "react";
import './landing-page.scss';
import dayjs from 'dayjs';
import { UserRole } from "../../../types/user.interface";
import { apiService } from "../../../core/services/api.service";
import { userDashboardCardLinks, userDashboardCharts, adminDashboardCardLinks, adminDashboardCharts } from "../../../core/constants";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Skeleton } from "@mui/material";
import Button from '@mui/material/Button';
import CountCard from "../../../components/common/dashboard/count-card.component";
import ChartCard from "../../../components/common/dashboard/chart-card-component";
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import { useAppSelector } from "../../../../store";
import { toastMessage } from "../../../core/services/toast";

export default function LandingPageComponent() {
    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );
    const initialFilterValue = {
        frequency: 'monthly',
        frequencyType: 'YTD',
        startDate: dayjs(dayjs().startOf('year')).format("YYYY-MM-DD") ,
        endDate: dayjs(dayjs().endOf('year')).format("YYYY-MM-DD")
    }
    const [loading, setLoading] = React.useState(false);
    const [userStats, setUserStats] = React.useState([]);
    const [charts, setCharts] = React.useState<any>(null);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement >(null);
    const [open, setOpen] = React.useState(false);
    const canBeOpen = Boolean(anchorEl);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;

    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
    );

    const keys=[0,1,2,3];

    React.useEffect(()=>{
        setLoading(true);
        if(isTokenAvailable){
            fetchData(userDetails);
        }
    },[userDetails, filters,isTokenAvailable])

    const fetchData = async(userInfoData: any) => {
        if (userInfoData && userInfoData.role !== '') {
            try{
                let stats: any = [];
                let charts: any = [];
                const { url, cardLinks, reportCharts }: any = getValuesByRole(userInfoData);
                const { data } = await apiService.get(url);
                data.cards.forEach((item: any) => {
                    const card = cardLinks.find((cardItem: any) => cardItem.id === item.id);
                    stats.push({
                        id: item.id,
                        title: item.label,
                        count: item.value,
                        url: card?.url,
                        subtitle: item.subtitle ? item.subtitle : '',
                        isActive: item.isActive ? item.isActive : false,
                        activeCount: item.activeCount ? item.activeCount : ''
                    });
                });
                Object.keys(reportCharts).forEach((key: string, index: number) => {
                    const dashboardChart = reportCharts[key as keyof typeof reportCharts];
                    const chartData = data[key as keyof typeof reportCharts];
                    let chart = {
                        id: key,
                        type: dashboardChart.type,
                        chartHeight: dashboardChart.chartHeight,
                        height: dashboardChart.height,
                        width: dashboardChart.width,
                        title: dashboardChart.title,
                        chartData: chartData,
                        showCustomeLegends: dashboardChart.customLegends,
                        legendAlignment: dashboardChart.legendAlignment
                    };
                    charts.push(chart);
                });
                setCharts(charts);
                setUserStats(stats);
                setLoading(false);
            } catch(err:any) {
                toastMessage(err.response.data.statusCode, null)
            }
        }
    }

    const getValuesByRole = (userInfoData: any) => {
        if (userInfoData?.role === UserRole.Recruiter || userInfoData?.role === UserRole.HRManager ) {
            const url = `/user/org-report?startDate=${filters.startDate}&endDate=${filters.endDate}&frequency=${filters.frequency}`;
            return {url, cardLinks: userDashboardCardLinks, reportCharts: userDashboardCharts};
        }
        if (userInfoData?.role === UserRole.Admin) {
            const url = `/user/admin-report?startDate=${filters.startDate}&endDate=${filters.endDate}&frequency=${filters.frequency}`;
            return {url, cardLinks: adminDashboardCardLinks, reportCharts: adminDashboardCharts};
        }
    }

    const handleFilterChange = (filterValues: any) => {
        setLoading(true);
        setFilters(filterValues);
    }
    
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    }

    return (
        <>
            <div className="dashboard__main">
                <div className="dashboard_header">
                    <div className="heading_wrapper">
                        <div className="dashboard__heading">Hey there, {userDetails?.name}!</div>
                        <div className="dashboard__subheading">
                            Welcome back, we're happy to have you here!
                        </div>
                    </div>
                    <div className="filter__wrapper">
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>
                            Filter
                            <span className="count">(1)</span>
                        </Button>
                    </div>
                </div>
                <div className="dashboard__cards">
                    {loading && <div className="dashboard_inner_card">
                        {[...Array(4)].map((value, index) => {
                            return (
                                <Skeleton width={287} height={150} key={keys[index]} animation="wave" variant="text" />
                            )
                        })}                            
                    </div>}
                    {!loading && <div className="dashboard_inner_card">
                            {userStats.map((data:any) =>
                                <div className="count_box" key={data.id}>
                                    <CountCard data={data} filters={filters} redirectOnPage={true} />
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div className="graph__wrapper">
                    {loading && Object.keys(userDashboardCharts).map((value, key) => {
                        return (
                            <Skeleton key={value} width={`${userDashboardCharts[value as keyof typeof userDashboardCharts].width}%`} height={userDashboardCharts[value as keyof typeof userDashboardCharts].height} variant="rectangular" animation="wave" />
                        )
                    })}
                    {!loading && charts && <ChartCard data={charts} />}
                </div>                   
            </div>
            {open && <FilterPopupComponent 
                open={open}
                popoverId={popoverId} 
                anchorEl={anchorEl}
                filterState={filters}
                onlyDateFilter={false}
                onClose={handleToggleFilterPopup}
                onApplyFilter={handleFilterChange}
            />}
        </>
    )
}
