import React from "react";
import dayjs from 'dayjs';
import { Skeleton } from "@mui/material";
import { useSearchParams, Link  } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PaidIcon from '@mui/icons-material/Paid';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PlanCard1Component from '../../../components/admin/organizations/plan-cards/PlanCard1.component';
import PlanCard2Component from '../../../components/admin/organizations/plan-cards/PlanCard2.component';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from '@mui/material/InputAdornment';
import { apiService } from "../../../core/services/api.service";
import IconButton from "@mui/material/IconButton";
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from "@mui/material/Avatar";
import { useAppSelector } from "../../../../store";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import { authorizationService } from "../../../core/services/authorization.service";
import './admin-subscription.scss';
import { toastMessage } from "../../../core/services/toast";
interface Data {
    email: string;
    publishedDate: string;
    plan: string;
    name: string;
    contact: string;
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  type Order = 'asc' | 'desc';
  
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Email',
    },
    {
        id: 'contact',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Contact',
    },
    {
        id: 'publishedDate',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Requested Date',
    },
    {
        id: 'plan',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Plan',
    },
  ];

export default function AdminSubscription(props:any){
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          Subscriptions
        </Typography>,
      ];
    let [searchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const initialFilterValue = {
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };
    const plans:any = [];
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('')
    const [plansData, setPlansData] = React.useState<any>(null);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;
    const [permissions, setPermissions] = React.useState<any>(null);

    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
      );
    const isTokenAvailable: any = useAppSelector(
        (state) => state.auth.token
      );
    
    const keys = [0,1,2,3];

    React.useEffect(() => {
        const organizationsPermissions = authorizationService.getPermissions(userDetails?.role, 'organizations', false, userDetails?.isSuperAdmin);
        setPermissions(organizationsPermissions);
      }, [userDetails]);
      

    React.useEffect(()=>{
        if (isTokenAvailable && permissions && permissions?.read) {
            setLoading(true);
            fetchData();
        }
    },[permissions,filters,isTokenAvailable])

    const fetchData = async() => {
        try{
            let url = `/user/subcription-report`;
            if (filters.startDate) {
                url = `${url}?startDate=${filters.startDate}`;
            }
            if (filters.endDate) {
                url = `${url}${filters.startDate ? '&' : '?'}endDate=${filters.endDate}`;
            }
            const { data } = await apiService.get(url);
            setPlansData(data);
            setLoading(false);
        } catch(err:any) {
            toastMessage(err.response.data.statusCode,null);
        }
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
        stableSort(plans, getComparator(order, orderBy)),
        [order, orderBy],
    );

    const handleFilterChange = (filterValues: any) => {
        setPlansData(null);
        setFilters(filterValues);
    }
    
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilter(anchorFilter ? null : event.currentTarget);
        setOpenFilter((previousOpen) => !previousOpen);
    }

    const planCard1ComponentIcon=(index:number)=>{
        interface PlanCard{
            [key:number]:any
        }
        const planCard:PlanCard = {
            0:<PaidIcon className='icon' />,
            1:<BusinessIcon className='icon' /> ,
        }
        
        return planCard[index]||<AccountCircleOutlinedIcon className='icon' />;
    }
    const planCard2ComponentIcon=(index:number)=>{
        interface PlanCard{
            [key:number]:any
        }
        const planCard:PlanCard = {
            0:<TrackChangesIcon className='icon' />,
            1:<CorporateFareIcon className='icon' /> ,
            2:<AcUnitIcon className='icon' /> ,
            3:<SupervisedUserCircleIcon className='icon' />,
        }
        
        return planCard[index]||<AccountBalanceOutlinedIcon className='icon' />;
    }

    return(
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper2">
                    <div className="heading">
                        Subscriptions
                    </div>
                    <div className="search__wrapper">
                        <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>
                </div>
                <div className='table__wrapper'>
                    <div className='subscriptions'>
                        {loading ? [...Array(3)].map((value, index) => {
                        return (
                            <Skeleton
                            width={388}
                            height={81}
                            key={keys[index]}
                            animation="wave"
                            variant="rectangular"
                            />
                        );
                        }) : (
                        plansData?.counts.map((item: any, index: number) => {
                             return (
                                <PlanCard1Component key={item.label} count={item.value} text={item.label} icon={
                                    planCard1ComponentIcon(index)
                                } />
                            );
                        }))}
                    </div>
                    <div className='subscriptions'>
                        {loading ? [...Array(4)].map((value, index) => {
                            return (
                                <Skeleton
                                width={288}
                                height={222}
                                key={keys[index]}
                                animation="wave"
                                variant="rectangular"
                                />
                            );
                            }) : (
                            plansData?.organizationsSubscriptionTypeChart.map((item: any, index: number) => {
                                return (
                                    <PlanCard2Component key={item.label} count={item.value} text={item.label} icon={
                                        planCard2ComponentIcon(index)
                                    } />
                                );
                            })
                        )}
                    </div>
                    {loading ? (
                        <Skeleton
                        width={"100%"}
                        height={230}
                        animation="wave"
                        variant="rectangular"
                        />
                    ) : (
                    <div className="request__card">
                        <div className="request__title">
                            <div className="request__card__title">
                                Plan Request
                            </div>
                            <div className="search__wrapper">
                            <TextField
                                id="search"
                                type="search"
                                placeholder="Search by name"
                                variant="outlined"
                                className="custom__search"
                                value={searchTerm}
                                onChange={(event: any) => setSearchTerm(event.target.value)}
                                sx={{ width: 350 }}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                }}
                            />
                            </div>
                        </div>
                        <div className="table__wrapper plan__table">
                        {
                        plans.length > 0 ? (
                            <TableContainer component={Paper} className="custom__table">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    isAction={true}
                                    isSorting = {true}
                                />
                                    <TableBody>
                                    {visibleRows.map((row, index) => {
                                        return(
                                            <TableRow
                                                key={row.email}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                className="custom__cell__row"
                                                >
                                                <TableCell align="left" className="custom__cell width70">
                                                    <Avatar>
                                                        <BusinessIcon /> 
                                                    </Avatar>
                                                    <span>{row.name}</span>
                                                </TableCell>
                                                <TableCell align="left" className="custom__cell__element">{row.email}</TableCell>
                                                <TableCell align="left" className="custom__cell__element">{row.contact}</TableCell>
                                                <TableCell align="left" className="custom__cell__element">{row.publishedDate}</TableCell>
                                                <TableCell align="left" className="custom__cell__element">{row.plan}</TableCell>
                                                <TableCell align="left" className="custom__cell__element action__icons">
                                                    <IconButton title="View Details">
                                                        <VisibilityOutlinedIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                     {visibleRows.length === 0 && (<TableRow>
                                      <TableCell colSpan={5} className="no__data">
                                        No Data Available !
                                      </TableCell>
                                    </TableRow>)}
                                    </TableBody>
                                    {/* <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Pagination 
                                                count={10} 
                                                siblingCount={0} 
                                                defaultPage={1} 
                                                boundaryCount={2} 
                                                className="custom__pagination"
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                    components={{
                                                        previous: (props) => <Button startIcon={<ArrowBackIcon />} {...props} className="pagination__buttons">Prev</Button>,
                                                        next: (props) => <Button endIcon={<ArrowForwardIcon />}  {...props} className="pagination__buttons">Next</Button>,
                                                    }}
                                                      {...item}
                                                    />
                                                )}/>
                                            </TableCell>                                        
                                        </TableRow>
                                    </TableFooter> */}
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className="no--content"> No content</div>
                        )
                    }
                        </div>
                    </div>)}
                </div>
            </div>   
            {openFilter && <FilterPopupComponent 
                open={openFilter}
                popoverId={popoverId} 
                anchorEl={anchorFilter}
                filterState={filters}
                onlyDateFilter={true}
                onClose={handleToggleFilterPopup}
                onApplyFilter={handleFilterChange}
            />}
        </>
    )
}