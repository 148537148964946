import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../../app/core/services/api.service";

// Resend Email otp/invitation code
export const resendEmailOTP = createAsyncThunk(
  "resendEmailOTP",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await apiService.post(`/invitation/resend`, payload);
      return data;
    } catch (error: any) {
      const errorData = {
        statusCode: error.response.status,
        error: error.response.data.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

// accept invitation
export const invitationAccept = createAsyncThunk(
  "invitationAccept",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await apiService.post(`/invitation/accept`, payload);
      return data;
    } catch (error: any) {
      console.log("invitation : ", error);
      const errorData = {
        statusCode: error?.response?.data?.statusCode,
        error: error?.response?.data?.message,
      };
      return rejectWithValue(errorData);
    }
  }
);

const emailVerificationSlice = createSlice({
  name: "emailVerification",
  initialState: {
    response: null,
    error: null,
    statusCode: null,
  },
  reducers: {
    resetResponse(state: any) {
      state.response = null;
    },
    resetError(state: any) {
      state.response = null;
      state.error = null;
      state.statusCode = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resendEmailOTP.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.response = action.payload;
        state.error = null;
        state.statusCode = 200;
      }
    );
    builder.addCase(
      resendEmailOTP.rejected,
      (state: any, action: PayloadAction<any>) => {
        state.response = null;
        const data = action.payload ? action.payload : null;
        state.statusCode = data ? data.statusCode : 500;
        state.error = data?.error ?? "Something went wrong";
      }
    );

    builder.addCase(
      invitationAccept.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.response = action.payload;
        state.error = null;
        state.statusCode = 200;
      }
    );
    builder.addCase(
      invitationAccept.rejected,
      (state: any, action: PayloadAction<any>) => {
        state.response = null;
        const data = action.payload ? action.payload : null;
        state.statusCode = data ? data.statusCode : 500;
        state.error = data?.error ?? "Something went wrong";
      }
    );
  },
});

export const emailVerificationActions = emailVerificationSlice.actions;
export default emailVerificationSlice.reducer;
