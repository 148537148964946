import { Box, Button } from "@mui/material";
import "./ToggleButton.component.scss";
import React from "react";
import { planIntervalType } from "../../../core/constants";

export function ToggleButton(props: any) {
  const [subType, setSubType] = React.useState(props?.selectedSubType ? props?.selectedSubType : planIntervalType.MONTHLY);
  const handleSubType = (subtype: any) => {
    setSubType(subtype);
    props.handleSubType(subtype);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box className="mask-box">
        <Box
          className="mask"
          style={{
            transform: `translateX(${
              subType === planIntervalType.MONTHLY ? 0 : "100px"
            })`,
          }}
        />
        <Button
          disableRipple
          variant="text"
          sx={{
            color: "#323539",
            textTransform: "none !important",
            flexDirection:"column"
          }}
          onClick={() => handleSubType(planIntervalType.MONTHLY)}
        >
          <span className="left_month_textI">Pay Monthly</span>
          <span className="left_month_textII"> Commit annually </span>
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{
            color: "#323539",
            textTransform: "none !important",
            flexDirection:"column"
          }}
          onClick={() => handleSubType(planIntervalType.ANNUALLY)}
        >
          <span className="rtl_annual_textI">Pay Annually</span>
        </Button>
      </Box>
    </Box>
  );
}
