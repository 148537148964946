import React from "react";
import { Link } from "react-router-dom";
import { authorizationService } from "../../../core/services/authorization.service";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { apiService } from "../../../core/services/api.service";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import Avatar from "@mui/material/Avatar";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import {NSFWMediaTypes, NSFWCategories} from "../../../core/constants";
import DownloadIcon from '@mui/icons-material/Download';
import ChatIcon from '@mui/icons-material/Chat';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";
import moment from "moment";
import { useAppSelector } from "../../../../store";

  interface Data {
    name: string;
    email: string;
    nsfwStatus: number;
    mediaType: string;
    nsfwCategory: string;
    createdAt: string;
  }
  
  type Order = 'asc' | 'desc';
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    sorting: boolean;
    numeric: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'Email',
    },
    {
        id: 'mediaType',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Media Type',
    },
    {
        id: 'nsfwCategory',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Category',
    },
    {
        id: 'nsfwStatus',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        sorting: false,
        label: 'NSFW Status',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        sorting: true,
        label: 'Created Date',
    },
  ];

  const rowSpan = 10;
  const colSpan = 7;
  const initialFilterValue = {
    startDate: null,
    endDate: null
  };

  export default function NsfwComponent() {
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          NSFW Profiles
        </Typography>,
    ];
    
    const userDetails: any = useAppSelector(
      (state) => state.auth.userDetail
    );
    const isTokenAvailable: any = useAppSelector(
      (state) => state.auth.token
    );
    const limit = 100;
    const [nsfwProfiles, setNsfwProfiles] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [nsfwProfilesListLoading, setNsfwProfilesListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;
    const [status, setStatus] = React.useState('all');
    const [mediaType, setMediaType] = React.useState('all');
    const [category, setCategory] = React.useState('all');
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [index, setIndex] = React.useState(-1);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [popoverContent, setPopoverContent] = React.useState<any>(null);

    React.useEffect(() => {
      const nsfwPermissions = authorizationService.getPermissions(userDetails?.role, 'nsfw', false, userDetails?.isSuperAdmin);
      setPermissions(nsfwPermissions);
    }, [userDetails]);

    React.useEffect(() => {
      setNsfwProfilesListLoading(true);
      const delayDebounceFn = setTimeout(() => {
        if (isTokenAvailable && permissions?.read) {
          getNsfwProfilesList();
        }
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }, [page, order, orderBy, filters, status, mediaType, category, isTokenAvailable, permissions])

    const getNsfwProfilesList = async() => {
      let url = `/nsfw?take=${limit}&skip=${(page-1)*limit}&order=${order === 'asc'?1:-1}&orderBy=${orderBy}` ;
      if (status && status !== "all") {
        url = `${url}&status=${status}`;
      }
      if (mediaType && mediaType !== "all") {
        url = `${url}&mediaType=${mediaType}`;
      }
      if (category && category !== "all") {
        url = `${url}&nsfwCategory=${category}`;
      }
      if (filters.startDate) {
        url = `${url}&startDate=${filters.startDate}`;
      }
      if (filters.endDate) {
          url = `${url}&endDate=${filters.endDate}`;
      }
      try{
      const { data } = await apiService.get(url);
      const nsfwProfilesListData = data?.result || [];
      const count = data?.count || 0;
      setNsfwProfiles(nsfwProfilesListData);
      setPages(Math.ceil(count/limit));
      setNsfwProfilesListLoading(false);
      }catch(err:any){
        toastMessage(err.response.data.statusCode, null);
      }
    }

    const onMarkSafe = (nsfwStatus: boolean, index:number) => {
        if (nsfwStatus) {
            setIndex(index)
            setOpenConfirmation(true);
        } else {
          toastMessage(-1, "Already marked as safe", "info");
          return false;
        }
    }

    const handleConfirmationClose = () => {
      setOpenConfirmation(false);       
      setIndex(-1);
    };

    const handleConfirmationConfirm = (value: number) => {
        setNsfwProfilesListLoading(true);
        markCandidateSafe(nsfwProfiles[value])
    }

    const markCandidateSafe = async (profile: any) => {
      try{
          let url = `/nsfw/${profile._id}`;
          const payload = {
            nsfwStatus: false
          };
          const response = await apiService.put(url, payload);
          getNsfwProfilesList();
          let message:string =`Successfully marked profile as safe`;
          toastMessage(response?.status, message, "success");
      }catch(err:any){
          let message:string =err?.response?.data?.message?err?.response?.data?.message:null;
          toastMessage(err?.response?.data?.statusCode, message);
      } finally {
        handleConfirmationClose();
      }     
    }

    const onDownloadMediaAsset = (index: number) => {
      const profile = nsfwProfiles[index];
      const url = profile?.media?.publicUrl || profile?.media?.url;
      if (url) {
        window.open(url, '_blank');
      } else {
        toastMessage(500, 'Media asset is not available!');
      }
    }

    const dateFormat = (date:any) => {
      if (date) {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
      }
    }

    const nsfwProfilesList=()=>{
      if(nsfwProfiles.length > 0){
        const nsfwProfilesList = nsfwProfiles.map((row:any, index:number) => {
          return(
            <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="custom__cell__row"
                >
                <TableCell align="left" className="custom__cell">
                    <Avatar>
                        <PersonOutlineOutlinedIcon /> 
                    </Avatar>
                    <span>{row?.candidate?.name || row?.user?.name}</span>
                </TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.candidate?.email || row?.user?.email}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.mediaType ? NSFWMediaTypes[row?.mediaType as keyof typeof NSFWMediaTypes] : ''}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.nsfwCategory ? NSFWCategories[row?.nsfwCategory as keyof typeof NSFWCategories] : ''}</TableCell>
                <TableCell align="left" className="custom__cell__element">{row?.nsfwStatus ? 'Not Safe' : 'Safe'}</TableCell>
                <TableCell align="left" className="custom__cell__element">{dateFormat(row?.createdAt)}</TableCell>
                <TableCell align="left" className="custom__cell__element action__icons d-flex">
                  {iconsTableCell(row)}
                </TableCell>
            </TableRow>
          )
      })
      return nsfwProfilesList;
      }else{
        return noDataTableRow();
      }
    }

    const iconsTableCell = (row: any) => {
      const tableCell = (
        <>
        {row?.media && (row?.media?.publicUrl || row?.media?.url) && <IconButton title="Download Media Asset" onClick={()=>onDownloadMediaAsset(index)}>
                    <DownloadIcon />
                  </IconButton>}
                  {row?.textualContent && Object.keys(row?.textualContent).length > 0 && <IconButton aria-owns={open ? 'simple-popover' : undefined} aria-haspopup="true" onClick={(event) => handleTogglePopover(event, row?.textualContent)}>
                    <ChatIcon />
                  </IconButton>}
                  <IconButton title={row?.nsfwStatus ? "Mark as safe" : "Already marked as safe"} onClick={()=>onMarkSafe(row?.nsfwStatus, index)}>
                    <AddTaskIcon />
                  </IconButton>
        </>
      )
      return tableCell;
    }

    const noDataTableRow = () => {
      return (<TableRow>
        <TableCell colSpan={colSpan} className="no__data">
        No Data Available !
        </TableCell>
      </TableRow>);
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
      ) => {
        setNsfwProfilesListLoading(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setNsfwProfilesListLoading(true);
        setPage(value)
    }

    const handleFilterChange = (filterValues: any) => {
        setNsfwProfilesListLoading(false);
        setFilters(filterValues);
    }
  
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorFilter(anchorFilter ? null : event.currentTarget);
      setOpenFilter((previousOpen) => !previousOpen);
    }

    const handleStatusChange = (value: string) => {
        setNsfwProfilesListLoading(true);
        setPage(1);
        setStatus(value);
    };
  
    const handleMediaTypeChange = (value: string) => {
        setNsfwProfilesListLoading(true);
        setPage(1);
        setMediaType(value);
    };
  
    const handleCategoryChange = (value: string) => {
        setNsfwProfilesListLoading(true);
        setPage(1);
        setCategory(value);
    };

    const handleTogglePopover = (event: React.MouseEvent<HTMLButtonElement>, textContent: any) => {
      setPopoverContent(null);
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setPopoverContent(textContent);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
      setPopoverContent(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <div className="dashboard__header">
                <div className="org__details">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
            </div>
            <div className="dashboard">
                <div className="dashboard__wrapper">
                    <div className="heading">
                        NSFW Profiles
                    </div>
                    <div className="search__wrapper">
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-select"
                            variant="outlined"
                            label="Status"
                            size="small"
                            className="filter__select__box"
                            defaultValue={status}
                            onChange={(event)=> handleStatusChange(event.target.value)} 
                            fullWidth
                        >
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="true">Not Safe</MenuItem>
                          <MenuItem value="false">Safe</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="media-type-select-label">Media Type</InputLabel>
                        <Select
                            labelId="media-type-select-label"
                            id="media-type-select"
                            variant="outlined"
                            className="filter__select__box"
                            label="Media Type"
                            size="small"
                            defaultValue={status}
                            onChange={(event)=> handleMediaTypeChange(event.target.value)} 
                            fullWidth
                        >
                          <MenuItem value="all">All</MenuItem>
                          {Object.keys(NSFWMediaTypes).map((mediaType: string) => {
                            return (
                              <MenuItem key={mediaType} value={mediaType}>{NSFWMediaTypes[mediaType as keyof typeof NSFWMediaTypes]}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            variant="outlined"
                            className="filter__select__box"
                            label="Category"
                            size="small"
                            defaultValue={status}
                            onChange={(event)=> handleCategoryChange(event.target.value)} 
                            fullWidth
                        >
                          <MenuItem value="all">All</MenuItem>
                          {Object.keys(NSFWCategories).map((category: string) => {
                            return (
                              <MenuItem key={category} value={category}>{NSFWCategories[category as keyof typeof NSFWCategories]}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Button variant="outlined" sx={{alignSelf: 'center'}} startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                    </div>
                </div>
                <div className="table__wrapper">
                  <TableContainer component={Paper} className="custom__table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        isAction = {true}
                        isSorting = {true}
                    />
                        <TableBody>
                            {nsfwProfilesListLoading ? <TableSkeltonComponent rows={rowSpan} columns={colSpan} /> : nsfwProfilesList()
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={colSpan}>
                                  <TablePagination count={pages} siblingCount={0} defaultPage={page} boundaryCount={2} className="custom__pagination" onChange={handleChange}/>
                                </TableCell>                                        
                            </TableRow>
                        </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
            </div> 
            {openFilter && <FilterPopupComponent 
              open={openFilter}
              popoverId={popoverId} 
              anchorEl={anchorFilter}
              filterState={filters}
              onlyDateFilter={true}
              onClose={handleToggleFilterPopup}
              onApplyFilter={handleFilterChange}
            />}  
            {openConfirmation && <ConfirmationPopupComponent
              open={openConfirmation}
              index={index}
              title="Mark as safe"
              message="Are you sure you want to mark this profile as safe?"
              btnLabel="Confirm"
              onClose={handleConfirmationClose}
              onConfirm={handleConfirmationConfirm}
            />}
            <Popover
              id="simple-popover"
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
            >
              <div className="textContentPopover">
                <div className="popoverHeading">
                  <span>Text Content</span>
                </div>
                <div className="popoverContent">
                  <table>
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{popoverContent?.field}</td>
                        <td>{popoverContent?.value}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Popover>
        </>
    );
}
