import './contact-admin.scss';
import { Dialog, DialogTitle, IconButton, DialogContent, InputLabel, TextField, FormHelperText, Button, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { apiService } from "../../../core/services/api.service";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toastMessage } from '../../../core/services/toast';

interface FormFields {
    message: string;
}

export default function ContactAdminComponent(props: any) {
    const {open, organizationDetails, onClose} = props;

    const initialValues = {
        message: ''
    };

    const validations = Yup.object().shape({
        message: Yup.string().required('Message is required'),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validations,
        onSubmit: async (values) => {
            if (formik.isValid) {
                handleSubmit(values);
            }
        },
    })

    const handleSubmit = async (formValues: FormFields) => {
        const subscription = organizationDetails?.subscription;
        if (subscription) {
            const addOns = subscription.addons?.map((addon: any) => ({
                name: addon?.name,
                addon_code: addon?.addon_code,
                price: addon?.price,
                quantity: addon?.quantity,
            }));
            const url = `/contact/to_admin`;
            const payload = {
                message: formValues.message,
                type: "CONTACT_ADMIN",
                plan: {
                    name: subscription?.plan?.name,
                    plan_code: subscription?.plan?.plan_code,
                  },
                addons: [...addOns]
            }
            try {
                const response = await apiService.post(url, payload);
                let message:string =`Contact details submitted successfully`;
                toastMessage(response.status,message);
                formik.resetForm({ values: initialValues });
                handleClose();
            } catch(err:any) {
                toastMessage(err.response.data.statusCode,null);
            }
        } else {
            handleClose();
        }
    }

    const handleClose = () => {
        onClose();
    };

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        maxWidth: 454,
                        padding: '14px 8px 20px',
                        borderRadius: 6
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        Contact Administrator
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography className="contact__admin_subheading" component="div">{"All your active jobs are consumed. To post new jobs contact admin."}</Typography>
                    <div className="user__form">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input__items">
                                <InputLabel size="normal" className="input__label" focused>Message</InputLabel>
                                <TextField
                                    id="message"
                                    name="message"
                                    type="text"
                                    multiline
                                    rows={2}
                                    value={formik.values.message}
                                    variant="outlined"
                                    placeholder="Message"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.message && Boolean(formik.errors.message)}
                                    fullWidth />
                                {(formik.touched.message && formik.errors.message) && (
                                    <FormHelperText id='message-error-text' className="error_message">{formik.errors.message}</FormHelperText>
                                )}
                            </div>
                            <div className="action__btn">
                                <Button type="submit" color="secondary" variant="contained" className="custom__action__button confirm__button">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
    );
}