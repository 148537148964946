import React, { useEffect, useState } from "react";

import logo from "../../../../assets/tango_logo.png";
import backgroundVideo from "../../../../assets/background_video.mp4";
import { authController } from "../../../core/controllers/auth/auth.controller";

import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim("The email cannot include leading and trailing spaces")
    .strict(true)
    .email("Invalid Email")
    .required("Please Enter Email Address")
});

export const ForgotPasswordPageComponent = () => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showButton, setShowButton] = useState<boolean>(true);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleRegisterBtnDisplay(false);
      authController.forgotPassword({
        email: values.email,
        handleRegisterBtnDisplay: handleRegisterBtnDisplay,
      });
    },
  });

  const handleRegisterBtnDisplay = (status: boolean) => {
    setShowButton(status);
  };

  useEffect(() => {
    authController.redirectToDashboard();
    let isUserLoggedIn = authController.isUserLoggedIn();
    setShowLoader(isUserLoggedIn);
  }, []);

  return (
    <>
      {showLoader ? (
        <div className="no--content">
          <CircularProgress className="custom__spinner" />
        </div>
      ) : (
        <>
          <div className="video__wrapper">
            <video className="videoTag" autoPlay loop muted>
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          </div>

          <div className="content__background"></div>
          <div className="login__wrapper">
            <div className="loginBox">
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <div className="heading">Forgot Password</div>
              <form onSubmit={formik.handleSubmit}>
                <div className="input__items">
                  <InputLabel size="normal" className="input__label" focused>
                    Email address
                  </InputLabel>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    variant="outlined"
                    placeholder="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText
                      id="email-error-text"
                      className="error_message"
                    >
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </div>
                <div className="action__btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
              {showButton && (
                <div>
                  <span className="remember__text">Redirect back to</span>
                  &nbsp;&nbsp;&nbsp;
                  <a className="m-r-5" href={`/login`}>
                    Login
                  </a>
                </div>
              )}
              {!showButton && <CircularProgress size={20} />}
            </div>
          </div>
        </>
      )}
    </>
  );
};
