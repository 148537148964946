import React from 'react'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    IconButton,
    Typography,
    InputLabel,
    Select,
    MenuItem
  } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import "../dialog.scss"
import { apiService } from '../../../core/services/api.service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TypeOptions } from 'react-toastify/dist/types';
import CloseIcon from '@mui/icons-material/Close';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import InviteUser from "../users/invite-users/invite-user.component";
import { toastService } from '../../../core/services/toast.service';
import { UserRole } from "../../../types/user.interface";
import ConfirmationPopupComponent from "../confirmation-popup/confirmation-popup.component";
import { useAppSelector } from "../../../../store";
import { toastMessage } from '../../../core/services/toast';
interface FormFields {
    recruiter: string;
    hiringManager: string;
};

export default function JobAssociateComponent(props:any){
    const { onClose, onRefresh, index, open, jobDetails } = props;

    const organizationDetails : any = useAppSelector(
        state => state.organization.myOrganization
    );

    const [hiringManagers, setHiringManagers] = React.useState([]);
    const [recruiters, setRecruiters] = React.useState([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = React.useState(UserRole.Recruiter);
    const [inviteOpen, setInviteOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const initialValues = {
        recruiter: (jobDetails?._recruiter) ? jobDetails?._recruiter?._id : '',
        hiringManager: (jobDetails?._user) ? jobDetails?._user?._id : ''
    };

    const validations = Yup.object().shape({
        recruiter: Yup.string(),
        hiringManager: Yup.string()
    })

    React.useEffect(()=>{
        fetchHiringManagers();
        fetchRecruiters();
    },[])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validations,
        onSubmit: async (values, { resetForm }) => {
            if (formik.isValid) {
                if (values.recruiter === '' && values.hiringManager === '') {
                    const status: TypeOptions = "error";
                    const message: string = "Either Recruiter or Hiring Manager is mandatory!";
                    toastService.showToastNotification(status, message);
                    return false;
                } else {
                    handleSubmit(values);
                    resetForm({ values: {recruiter: '', hiringManager: ''} });
                }
            }
        }
    })

    const handleSubmit = async (formValues: FormFields) => {
        const url = `/employment/association/${jobDetails?._id}`;
        const payload = {
            _user: formValues.hiringManager,
            _recruiter: formValues.recruiter
        }
        try {
            const response = await apiService.patch(url, payload);
            handleClose();
            onRefresh();
            let message:string =`Successfully associated user in job`;
            toastMessage(response.status, message)
        } catch(err:any) {
            toastMessage(err.response.data.statusCode,null);
        }
    }

    const validateEmailDomain = (email: string) => {
        let { emailDomains } = organizationDetails;
        let emailDomain = email.split("@")[1]?.trim();
        return emailDomains.some((v: string) => v === emailDomain);
    };

    const fetchHiringManagers = async () => {
        try {
            let url = '/organizations/hr/list' ;
            const { data } = await apiService.get(url);
            const hrList = data || [];
            setHiringManagers(hrList);
        } catch (err: any) {
            setHiringManagers([]);
            toastMessage(err.response.data.statusCode, null);
        }
    }
    
    const fetchRecruiters = async () => {
        try {
            let url = '/organizations/recuiter/list' ;
            const { data } = await apiService.get(url);
            const recruiterList = data || [];
            setRecruiters(recruiterList);
        } catch (err: any) {
            setRecruiters([]);
            toastMessage(err.response.data.statusCode, null);
        }
    }

    const handleInviteOpen = (role: any) => {
        setSelectedDropdownValue(role);
        setInviteOpen(true);
    };

    const handleInviteSubmit = (event: any) => {
        hrInvitationClick(event.email);
    };

    const hrInvitationClick = async (email: string) => {
        let validEmailInput = validateEmailDomain(email);
        if (!validEmailInput) {
          alert(
            "Email domain of the invitee doesn't match with that of the company"
          );
          return;
        }
        const payload = {
          email: email,
          role: selectedDropdownValue,
        };
        let url = "/organizations/invite/recruiter";
        const role = selectedDropdownValue;
        if (role === UserRole.HRManager) {
          url = "/organizations/hr/invite";
        }
        try {
          const response = await apiService.post(url, payload);
          if (role === UserRole.HRManager) {
            fetchHiringManagers();
          } else {
            fetchRecruiters();
          }
          setInviteOpen(false);
          let message: string = `Successfully sent invitation to ${payload.email}`;
          toastMessage(response.status, message);
        } catch (err: any) {
          toastMessage(err.response.data.statusCode, null);
        }
    };

    const handleInviteClose = () => {
        setInviteOpen(false);
    };

    const handleDissociatePopupOpen = (role: any) => {
        setSelectedDropdownValue(role);
        setOpenConfirmation(true);
    }

    const handleConfirmationClose = () => {
        setOpenConfirmation(false);        
    };

    const handleConfirmationConfirm = () => {
        const recruiterValue = selectedDropdownValue === UserRole.Recruiter ? '' : formik.values.recruiter;
        const hrValue = selectedDropdownValue === UserRole.HRManager ? '' : formik.values.hiringManager;
        formik.setFieldValue('recruiter', recruiterValue);
        formik.setFieldValue('hiringManager', hrValue);
        setOpenConfirmation(false);
    };

    const handleClose = () => {
        onClose(index);
    };
    return(
        <>
            <Dialog onClose={handleClose} open={open} fullWidth PaperProps={{
                          style: {
                            maxWidth: 454,
                            padding: '14px 8px 20px',
                            borderRadius: 6
                          },
                        }}>
                <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                    <div>
                        Job Association
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component="div" className="dialog_content">
                        <div className='user_details_wrapper'>
                            <div className='detail_row'>
                                <span className='detail_label'>Name: </span>
                                <span className='detail_value'>{jobDetails?.title}</span>
                            </div>
                            <div className='detail_row'>
                                <span className='detail_label'>Posted By: </span>
                                <span className='detail_value'>{jobDetails?._user?.name}</span>
                            </div>
                        </div>
                        <div className='association_form'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="input__items">
                                    <InputLabel id="demo-simple-select-label" className="input__label">Hiring Manager</InputLabel>
                                    <Grid container spacing={2}>
                                        <Grid item md={10} sm={10} xs={12}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined" 
                                                fullWidth
                                                name="hiringManager"
                                                size='small'
                                                value={formik.values.hiringManager}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value="" disabled>
                                                <em>Select the value</em>
                                                </MenuItem>
                                                {hiringManagers.map((data:any,index:number) => (<MenuItem key={data._id} value={data._id}>{data?._user?.name? data?._user?.name : data?._user?.email}</MenuItem>))}
                                                <MenuItem value="">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    type="submit"
                                                    className="custom__action__button confirm__button custom__invite__btn"
                                                    onClick={() => handleInviteOpen(UserRole.HRManager)}
                                                    sx={{textTransform:"none !important" }}
                                                >
                                                    Invite a new Hiring Manager
                                                </Button>
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item md={2} sm={2} xs={12} sx={{textAlign: 'right'}}>
                                            <IconButton title="Dissociate" onClick={()=>handleDissociatePopupOpen(UserRole.HRManager)} disabled={formik.values.hiringManager === '' || formik.values.hiringManager === null} sx={{borderRadius: '6px', border: '1px solid #E5E5E7', background: '#F8F9FB'}}>
                                                <PersonRemoveOutlinedIcon sx={{color: '#9F2223'}} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="input__items">
                                    <InputLabel id="demo-simple-select-label" className="input__label">Recruiter</InputLabel>
                                    <Grid container spacing={2}>
                                        <Grid item md={10} sm={10} xs={12}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined" 
                                                fullWidth
                                                name="recruiter"
                                                size='small'
                                                value={formik.values.recruiter}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value="" disabled>
                                                <em>Select the value</em>
                                                </MenuItem>
                                                {recruiters.map((data:any,index:number) => (<MenuItem key={data._id} value={data._id}>{data?._user?.name? data?._user?.name : data?._user?.email}</MenuItem>))}
                                                <MenuItem value="">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    type="submit"
                                                    className="custom__action__button confirm__button custom__invite__btn"
                                                    onClick={() => handleInviteOpen(UserRole.Recruiter)}
                                                    sx={{textTransform:"none !important" }}
                                                >
                                                    Invite a new Recruiter
                                                </Button>
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item md={2} sm={2} xs={12} sx={{textAlign: 'right'}}>
                                            <IconButton title="Dissociate" onClick={()=>handleDissociatePopupOpen(UserRole.Recruiter)} disabled={formik.values.recruiter === '' || formik.values.recruiter === null} sx={{borderRadius: '6px', border: '1px solid #E5E5E7', background: '#F8F9FB'}}>
                                                <PersonRemoveOutlinedIcon sx={{color: '#9F2223'}} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                                {jobDetails?.isRecruiterAsHiringManager && formik.values.recruiter !== '' && formik.values.hiringManager !== '' && <Alert severity="info" className='isRecruiterAsHiringManagerMsg'>The recruiter and hiring manager are same for this job post.</Alert>}
                                <Grid container spacing={2} sx={{mt: '16px'}}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Button color="primary" variant="contained" onClick={handleClose} className="custom__action__button cancel__button"> Cancel </Button>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Button color="secondary" variant="contained" type="submit" className="custom__action__button confirm__button"> Save </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Typography>
                </DialogContent>
            </Dialog>
            {inviteOpen && <InviteUser
                open={inviteOpen}
                emailDomain={organizationDetails}
                handleClose={handleInviteClose}
                handleInviteSubmit={handleInviteSubmit}
            />}
            {openConfirmation && <ConfirmationPopupComponent
                open={openConfirmation}
                index={index}
                title="Dissociate"
                message="Do you want to dissociate user from Job?"
                btnLabel="Dissociate"
                onClose={handleConfirmationClose}
                onConfirm={handleConfirmationConfirm}
            />}
        </>
    )
}