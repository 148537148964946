import { makeObservable, observable, action, computed } from 'mobx';
import { ComponentType, createContext, useContext } from 'react';
import { AuthControllerInterface, AuthMethodEnum, AuthOptions, AuthResponse, SignUpOptions, ForgotPasswordOptions } from './auth.interface';
import { apiService } from '../../services/api.service';
import { toastService } from "../../services/toast.service";
import { firebaseService } from '../../services/firebase.service';
import { redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { UserRole } from '../../../types/user.interface';
import 'react-toastify/dist/ReactToastify.css';
import { TypeOptions } from 'react-toastify/dist/types';
import { authActions } from '../../../../store/slice/auth-slice';
import store from '../../../../store';
import { toastMessage } from '../../services/toast';
class AuthController implements AuthControllerInterface {

    constructor() {
        makeObservable(this);
        firebaseService.auth.onAuthStateChanged((user) => {
            if (user) {
                this.fetchAccessToken();
            }
        })
    }

    @observable
    private _token: string | null = null;

    private authInfo: AuthResponse | null = null;

    private hasSubscription: string | null = null;

    @computed get authorized(): boolean {
        return this._token != null;
    }

    get token() {
        return this._token;
    }
    setTokenToLocalStorage(){
        if(this.token){
            localStorage.setItem('token', this.token);
        }
    }
    setSubscriptionStatusToLocalStorage(){
        if(this.hasSubscription){
            localStorage.setItem('hasSubscription', this.hasSubscription);
        }
    }
    removeTokenFromLocalStorage(){
        localStorage.removeItem('token');
        localStorage.removeItem('hasSubscription');
    }
    get role(){
        let roleType = "";
        if(localStorage.getItem('token')){
            const token:any = localStorage?.getItem('token');
            let decodedToken:any = jwt_decode(token);
            roleType = decodedToken?.role;
        }
        return roleType;
    }
    isUserLoggedIn(){
        if(localStorage?.getItem('token')){
            return true
        }else{
            return false
        }
    }
    isOrganizationSubscribed(){
        if(localStorage?.getItem('hasSubscription') === 'true'){
            return true
        }else{
            return false
        }
    }
    @action
    async fetchAccessToken() {
        if (!firebaseService.auth.currentUser) {
            return;
        };
       
            let numberOfRetry=0;
            // if firebase api fails re-execute it. Check this for 5 times. 
            while(numberOfRetry<5 ){
                try {
                    const idToken = await firebaseService.auth.currentUser?.getIdToken();
                    const response = await apiService.post("/auth/login/__handler/firebase", {
                        token: idToken,
                    });   
                    const accessToken = response.data;
                    this.authInfo = {
                        method: AuthMethodEnum.EmailAndPassword,
                        accessToken: accessToken,
                        refreshToken: accessToken,
                    };
                    this._token = this.authInfo.accessToken;
                    apiService.token = accessToken;
                    if(accessToken){
                        store.dispatch(authActions.setTokenTrue());
                    }
                    break;
                    
                } catch (error:any) {
                    numberOfRetry += 1;
                    const pathname = window.location.pathname;
                    // logout the user 
                    if(numberOfRetry === 5 && pathname!=='/login'){
                        toastMessage(error.response.data.statusCode,"Access denied. Not a valid user","error");
                        this.authInfo = null;
                        this._token = null;
                        this.removeTokenFromLocalStorage();
                        window.location.replace(`/login`)
                    }
                }
            }
    }

    async subscription(args: AuthOptions){
        if(!args.isSubscriptionCheckout){
          try{
            if(this.role !== UserRole.Admin && (this.role === UserRole.Recruiter || this.role === UserRole.HRManager)){
                const {data} =await apiService.get("/organizations/my_organization");
                const orgData = data;
                if (orgData && !orgData?.subscription) {
                    this.hasSubscription = "false";
                    this.setSubscriptionStatusToLocalStorage();
                }else{
                    this.hasSubscription = "true";
                    this.setSubscriptionStatusToLocalStorage();
                }   
            }else{
                this.hasSubscription = "true";
                this.setSubscriptionStatusToLocalStorage();
            }
            this.redirectToDashboard();
          }catch(err:any){
            toastMessage(err.response.data.statusCode, null);
          }
        }else{
            this.redirectToSubscriptionCheckout(args.requestId);
        }
    }
    
    @action
    async login(args: AuthOptions) {
        const credential = await firebaseService.singinWithEmailAndPassword(args.username as string, args.password as string);
        if (credential?.accessToken) {
            try {
                const idToken = await firebaseService.auth.currentUser?.getIdToken();
                const response = await apiService.post("/auth/login/__handler/firebase", {
                    token: idToken,
                });
                const accessToken = response.data;
                this.authInfo = {
                    method: args.method,
                    accessToken: accessToken,
                    refreshToken: accessToken,
                };
                this._token = this.authInfo.accessToken;
                apiService.token = accessToken;
                this.setTokenToLocalStorage();
                this.subscription(args);
            } catch (error:any) {
                this.removeTokenFromLocalStorage();
                args?.handleRegisterBtnDisplay && args?.handleRegisterBtnDisplay(true);
                let message:string = "Login Failed!";
                toastMessage(error.response.data.statusCode,message,"error");
                return redirect("/login");
            }
        } else {
            this.removeTokenFromLocalStorage();
            let status:TypeOptions = "error";
            let message:string = '';
            args?.handleRegisterBtnDisplay && args?.handleRegisterBtnDisplay(true);
            switch(credential.code) {
                case 'auth/user-not-found':
                    message = "User doesn't exist!";
                    break;
                case 'auth/wrong-password':
                    message = 'Incorrect Password!';
                    break;
                default:
                    message = 'Login Failed!';
                    break;
             }
             toastService.showToastNotification(status, message);
            return redirect("/login");
        }
    }

    @action
    async signup(args: SignUpOptions) {
        try {
            const response = await apiService.post("/invitation/accept", args.payload);
            
            if (response.status === 201) {
                let message:string ="Successfully Registered";
                toastMessage(response.status, message, "success");
                setTimeout(()=> window.location.replace(`/login`),1000)
            } else {
                alert("Some error occurred")
            }

        } catch (error:any) {
            let message:string ="";
            if(error.response.data.statusCode === 400){
                message = error.response.data.message
            }else{
                message = "Signup Failed"
            }
            toastMessage(error.response.data.statusCode, message);
            // alert('Signup Failed')
            throw error;
        }
    }

    @action
    async forgotPassword(args: ForgotPasswordOptions) {
        try {
            const response = await firebaseService.sendPasswordResetEmail(args.email);
            if (response && response.status === "success") {
                let status:TypeOptions ="success";
                let message:string ="Successfully sent reset password email!";
                toastService.showToastNotification(status, message);
                setTimeout(()=> window.location.replace(`/login`), 1500);
            } else {
                let status:TypeOptions = "error";
                let message:string = '';
                args?.handleRegisterBtnDisplay && args?.handleRegisterBtnDisplay(true);
                switch(response.code) {
                    case 'auth/user-not-found':
                        message = "User doesn't exist!";
                        break;
                    case 'auth/invalid-email':
                        message = 'Invalid Email address!';
                        break;
                    default:
                        message = 'Something went wrong!';
                        break;
                }
                toastService.showToastNotification(status, message);
            }
        } catch (error:any) {
            args?.handleRegisterBtnDisplay && args?.handleRegisterBtnDisplay(true);
            let status:TypeOptions = "error";
            let message:string = "Something went wrong!";
            toastService.showToastNotification(status, message);
        }
    }

    logout() {
        this.authInfo = null;
        this._token = null;
        this.removeTokenFromLocalStorage();
        window.location.replace(`/login`)
    }

    redirectToDashboard(){
        if(localStorage.getItem('token')){
            let url ="";
            let status:TypeOptions ="success";
            let message:string ="Successfully Login";
            if(this.role === UserRole.Admin){
                url = "admin-portal/dashboard";
            }else if(this.role === UserRole.Recruiter || this.role === UserRole.HRManager){
                url = "customer-portal/dashboard";
            }else{
                status = "error";
                message = "Access Denied. Not a Valid User";
                url = "login";
                this.removeTokenFromLocalStorage();

            }
            window.location.replace(`/${url}`);
            toastService.showToastNotification(status, message);
        }
    }

    redirectToSubscriptionCheckout(id: string|undefined){
        if(localStorage.getItem('token')){
            let url ="";
            let status:TypeOptions ="success";
            let message:string ="Successfully Login";
            if(this.role === UserRole.Admin){
                url = `subscriptions/checkout?requestId=${id}`;
            }else if(this.role === UserRole.Recruiter || this.role === UserRole.HRManager){
                url = `subscriptions/checkout?requestId=${id}`;
            }else{
                status = "error";
                message = "Access Denied. Not a Valid User";
                url = "login";
                this.removeTokenFromLocalStorage();

            }
            window.location.replace(`/${url}`);
            toastService.showToastNotification(status, message);
        }
    }

    redirectToSubscriptionPlans(id: string|undefined){
        if(localStorage.getItem('token')){
            let url ="";
            let status:TypeOptions ="info";
            let message:string ="Please buy Subscription first";
            if(this.role === UserRole.Admin){
                url = `organizational-plans?requestId=${id}`;
            }else if(this.role === UserRole.Recruiter || this.role === UserRole.HRManager){
                url = `organizational-plans?requestId=${id}`;
            }else{
                status = "error";
                message = "Access Denied. Not a Valid User";
                url = "login";
                this.removeTokenFromLocalStorage();

            }
            window.location.replace(`/${url}`);
            toastService.showToastNotification(status, message);
        }
    }

}

export const authController: AuthControllerInterface = new AuthController();
export const authContext = createContext<AuthController | AuthControllerInterface>(authController);

/* Hook to use store in any functional component */
export const useAuthController = () => useContext(authContext);


// /* HOC to inject store to any functional or class component */
export const withAuthController = (
    C: ComponentType,
) => {
    return function (
        props: any
    ) {
        return <C {...props} authController={authController} />
    }
}

