import React from "react";
import { Dialog, DialogTitle, IconButton, DialogContent, InputLabel, TextField, FormHelperText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function InviteUser(props:any){

    const inviteSchema = Yup.object().shape({
        email: Yup.string().trim('The email cannot include leading and trailing spaces').strict(true).email('Invalid Email').required('Email is required')
    })

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: inviteSchema,
        onSubmit: async(values,{resetForm}) => {
          if(formik.isValid){
            resetForm()
            props.handleInviteSubmit(values)
          }
        }
      })

    return(
        <>
            <Dialog
            open={props?.open}
            onClose={props?.handleClose}
            PaperProps={{
                style: {
                minWidth: 450,
                padding: "15px 20px",
                borderRadius: 6
                },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title" className="custom__title" component="div">
                <div>
                Invite User
                </div>
                <IconButton aria-label="close" onClick={props?.handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="user__form">
                <form onSubmit={formik.handleSubmit}>
                <div className="input__items">
                    <InputLabel size="normal" className="input__label" focused>Email address</InputLabel>
                    <TextField 
                    id="email" 
                    name="email" 
                    type="email" 
                    variant="outlined" 
                    placeholder="Email" 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth />
                    {(formik.touched.email && formik.errors.email) && (
                        <FormHelperText id='email-error-text' className="error_message">{formik.errors.email}</FormHelperText>
                    )}
                </div>
                <div className="action__btn">
                    <button type="submit">Invite</button>
                </div>
                </form>
            </DialogContent>
            </Dialog>
        </>
    )
}