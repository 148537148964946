import React from "react";

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Alert from '@mui/material/Alert';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Checkbox from '@mui/material/Checkbox';
import { authorizationService } from "../../../core/services/authorization.service";
import IconButton from '@mui/material/IconButton';
import { useNavigate, Link } from "react-router-dom";
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import { apiService } from "../../../core/services/api.service";
import parse from 'html-react-parser';
import JobAssociateComponent from "../../../components/dialogs/job-associate/job-associate.component";
import moment from 'moment';
import { useAppDispatch, useAppSelector } from "../../../../store";
import { fetchMyOrganizationDetails } from "../../../../store/slice/organization-slice";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { SubscriptionFeatureEnum } from "../../../types/plans.interface";
import CloseIcon from "@mui/icons-material/Close";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
import ContactAdminComponent from "../../../components/dialogs/contact-admin/contact-admin.component";

interface Data {
    description: string;
    createdAt: string;
    status: string;
    title: string;
    check: string;
}
  
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isWidth: boolean,
  }
  
  const headCells: HeadCell[] = [
    {
        id: 'check',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: '',
    },
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        isWidth: false,
        label: 'Name',
    },
    {
        id: 'description',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Description',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Posted',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        isWidth: false,
        label: 'Status',
    }
  ];

export default function AtsJobs(props:any){
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/customer-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          ATS Jobs
        </Typography>,
    ];
    const navigate = useNavigate();
    const userDetails: any = useAppSelector(
        (state) => state.auth.userDetail
    );
    const index = -1;
    const [jobs, setJobs] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [page, setPage] = React.useState(1);
    const [limit] = React.useState(10);
    const [pages, setPages] = React.useState(0);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [selected, setSelected] = React.useState<any>([]);
    const dispatch = useAppDispatch();
    const organizationDetails : any = useAppSelector(state => state.organization.myOrganization);
    const [open, setOpen] = React.useState(false);
    const [openContactAdmin, setOpenContactAdmin] = React.useState(false);
    const [isATS, setIsATS] = React.useState(0);
    
    const isTokenAvailable: any = useAppSelector(
      (state) => state.auth.token
    );

    React.useEffect(() => {
        const atsJobsPermissions = authorizationService.getPermissions(userDetails?.role, 'atsJobs', userDetails?.isPoc);
        setPermissions(atsJobsPermissions);
      }, [userDetails]);

    React.useEffect(()=>{
      if (isTokenAvailable) {
        if (organizationDetails?.ats?._id) {
          setIsATS(1);
        }else{
          setIsATS(0);
        }
      }
    },[isTokenAvailable, organizationDetails])

    React.useEffect(()=>{
        if(isTokenAvailable){
          getJobs();
        }
    },[page, organizationDetails?._id,isTokenAvailable])
    
    React.useEffect(()=>{
    isTokenAvailable && dispatch(fetchMyOrganizationDetails());
    },[isTokenAvailable])

    const getJobs = async() => {
        try{
            let url = `/ats/job?next=""` ;
            const { data } = await apiService.get(url)
            setJobs(data.results)
            setPages(Math.ceil(data.count/limit))
        }catch(err:any){
          toastMessage(err?.response?.data?.statusCode, err?.response?.data?.message);
        }
    }

    const isSelected = (name:any) => selected.indexOf(name) !== -1;

      const dateFormat = (date:any) => {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
      }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    const publish = () => {
      if (organizationDetails?.subscription && organizationDetails?.subscriptionAddons.length !== 0) {
        const jobAddon = organizationDetails?.subscriptionAddons.filter(
          (addon: any) => addon.name === SubscriptionFeatureEnum.JOB
        );
        if (jobAddon.length !== 0 && (jobAddon[0]?.usageCount + selected.length) > jobAddon[0]?.totalCount) {
          if (userDetails?.isPoc) {
            setOpen(true);
            return;
          } else {
            setOpenContactAdmin(true);
            return;
          }
        }
      }
      navigate("/customer-portal/publish-jobs", { state: { selected: selected } });
    };

    const handleConfirmationClose = (value: number) => {
        setOpenConfirmation(false);
    };

    const handleContactAdminClose = () => {
      setOpenContactAdmin(false);
      setSelected([]);
    };

    const handleClick = (event:any, name:any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected:any = [];
        const NOCHECK_ITEM=-1;
        if (selectedIndex === NOCHECK_ITEM) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };

      const handleClose = () => {
        setOpen(false);
      };

      const handleUpgradeAddon = () => {
        setOpen(false);
        navigate(`/organizational-plans?requestId=${btoa(organizationDetails?._id)}`, {state: {isUpgrade: true}});
      };

    return (
      <>
        <div className="dashboard__header">
          <div className="org__details">
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="medium" className="icon" />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
        <div className="dashboard">
          <div className="dashboard__wrapper2">
            <div className="heading">Jobs</div>
            <div className="search__wrapper">
              {permissions?.add && <Button
                variant="contained"
                className="custom__button custom__create__button"
                onClick={publish}
                disabled={selected.length <= 0}
              >
                Push Jobs
              </Button>}
            </div>
          </div>
          <div className="table__wrapper">
            {!isATS && jobs.length === 0 && <div className="connectToATSMsgWrapper">
              <Alert severity="error" className='connectToATSMsg'>Your organization is not connected with ATS to see jobs, request your organization's POC to connect with ATS.</Alert>
            </div>}
            <TableContainer component={Paper} className="custom__table">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <EnhancedTableHead
                  headCells={headCells}
                  isAction={false}
                  isSorting={false}
                />
                <TableBody>
                  {jobs?.map((row: any, index: number) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={row?.title}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className="custom__cell__row"
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event: any) => handleClick(event, row)}
                            role="checkbox"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          className="custom__cell__element"
                        >
                          {row?.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="custom__cell__element"
                        >
                          {row?.description ? parse(row?.description): ""}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="custom__cell__element"
                        >
                          {dateFormat(row?.created_at)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="custom__cell__element"
                        >
                          {row?.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {jobs.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} className="no__data">
                        No Data Available !
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <TablePagination count={pages} siblingCount={0} defaultPage={page} boundaryCount={2} className="custom__pagination" onChange={handleChange} />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
        {openConfirmation && (
          <JobAssociateComponent
            open={openConfirmation}
            index={index}
            onClose={handleConfirmationClose}
          />
        )}
        {openContactAdmin && (
          <ContactAdminComponent
            open={openContactAdmin}
            organizationDetails={organizationDetails}
            onClose={handleContactAdminClose}
          />
        )}
        {open && (
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="atsUpgradePopup"
          >
            <DialogTitle
              id="alert-dialog-title"
              className="custom__title__upgrade"
              component="div"
            >
              {"All your active jobs are consumed"}
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon sx={{ color: "#9f2223" }} />
              </IconButton>
            </DialogTitle>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                className="custom__button custom__create__button atsUpgradeBtn"
                onClick={handleUpgradeAddon}
              >
                Click here to Upgrade
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
}