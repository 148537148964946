const Requests = [{
    id: 1,
    name: "Globe Solutions",
    status: "Active",
    email: "john@globesolutions.co",
    contact: '8181818181',
    publishedDate: '5 Oct 2023',
    plan: 'Enterprise'
},
{
    id: 2,
    name: "ABC Organization",
    status: "Active",
    email: "scott@abc.com",
    contact: '8281818181',
    publishedDate: '29 Sept 2023',
    plan: 'Enterprise'
},
{
    id: 3,
    name: "Softech Corporation",
    status: "Active",
    email: "kieth@softech.com",
    contact: '8381818181',
    publishedDate: '20 Sept 2023',
    plan: 'Enterprise'
},
{
    id: 4,
    name: "Forgeahead Solutions",
    status: "Active",
    email: "jack@forgeahead.io",
    contact: '8481818181',
    publishedDate: '15 Sept 2023',
    plan: 'Enterprise'
}];

const AdminMenu = [{
    id: 1,
    name: 'dashboard',
    label: 'Dashboard',
    url: '/admin-portal/dashboard',
    icon: 'dashboard'
},
{
    id: 2,
    name: 'organizations',
    label: 'Organizations',
    url: '/admin-portal/organizations',
    icon: 'business'
},
{
    id: 3,
    name: 'users',
    label: 'Users',
    url: '/admin-portal/admin-users',
    icon: 'people_outline'
},
{
    id: 4,
    name: 'subscriptions',
    label: 'Subscriptions',
    url: '/admin-portal/subscriptions',
    icon: 'payments'
},
{
    id: 5,
    name: 'candidates',
    label: 'Candidates',
    url: '/admin-portal/candidates',
    icon: 'account_circle'
},
{
    id: 6,
    name: 'atsCandidates',
    label: 'ATS Candidates',
    url: '/admin-portal/ats-candidates',
    icon: 'account_circle'
},
{
  id: 7,
  name: 'jobs',
  label: 'Jobs',
  url: '/admin-portal/jobs',
  icon: 'work'
},
{
    id:8,
    name: 'reportedUsers',
    label: 'Reported Users',
    url: '/admin-portal/reported-users',
    icon: 'contact_page'
},
{
    id:9,
    name: 'referral',
    label: 'Referrals',
    url: '/admin-portal/referral',
    icon: 'contact_page'
},
{
  id:10,
  name: 'nsfw',
  label: 'NSFW Profiles',
  url: '/admin-portal/nsfw',
  icon: 'person_off'
}]

const UsersMenu = [{
    id: 1,
    name: 'dashboard',
    label: 'Dashboard',
    url: '/customer-portal/dashboard',
    icon: 'dashboard'
},
{
    id: 2,
    name: 'users',
    label: 'Users',
    url: '/customer-portal/manage-user',
    icon: 'people_outline'
},
{
    id: 3,
    name: 'jobs',
    label: 'Jobs',
    url: '/customer-portal/jobs',
    icon: 'work'
},
{
    id: 4,
    name: 'atsJobs',
    label: 'ATS Jobs',
    url: '/customer-portal/ats-jobs',
    icon: 'work'
},
{
    id: 5,
    name: 'subscription',
    label: 'Subscription',
    url: '/customer-portal/organization-subscription',
    icon: 'payments'
}]

const frequencies = {
    'dateRange': 'dateRange',
    'ytd': 'YTD',
    'qtd': 'QTD',
    'mtd': 'MTD'
};

const frequencyType = {
    'monthly': 'monthly',
    'weekly': 'weekly',
    'daily': 'daily'
};

const chartType = {
    'pie': 'pie',
    'doughnut': 'doughnut',
    'line': 'line',
    'bar': 'bar'
};

const userDashboardCardLinks = [
    {
        id: 'userCount',
        url: '/customer-portal/manage-user'
    },
    {
        id: 'recruitersCount',
        url: '/customer-portal/manage-user?tab=recruiter'
    },
    {
        id: 'hrCount',
        url: '/customer-portal/manage-user?tab=hr'
    },
    {
        id: 'jobsCount',
        url: '/customer-portal/jobs'
    }
];

const userDashboardCharts = {
    'totalJobsChart': {
        title: 'Total Jobs',
        type: 'pie',
        chartHeight: '192px',
        height: 410,
        width: '29',
        tooltipLabel: 'Jobs',
        customLegends: true,
        legendAlignment: 'bottom'
    },
    'jobPostsChart': {
        title: 'Job Posts',
        type: 'bar',
        chartHeight: '312px',
        height: 410,
        width: '69',
        tooltipLabel: '',
        customLegends: false,
        legendAlignment: ''
    },
    'usersChart': {
        title: 'Users',
        type: 'line',
        chartHeight: '295px',
        height: 410,
        width: '69',
        tooltipLabel: '',
        customLegends: false,
        legendAlignment: ''
    },
    'usersStatsChart': {
        title: 'User Status',
        type: 'doughnut',
        chartHeight: '140px',
        height: 410,
        width: '29',
        tooltipLabel: 'Users',
        customLegends: true,
        legendAlignment: 'bottom'
    },
    'applicationsChart': {
        title: 'Applications',
        type: 'line',
        chartHeight: '272px',
        height: 370,
        width: '100',
        tooltipLabel: '',
        customLegends: false,
        legendAlignment: ''
    },
};

const adminDashboardCardLinks = [
    {
        id: 'organizationsCount',
        url: '/admin-portal/organizations'
    },
    {
        id: 'jobsCount',
        url: '#job-posts'
    },
    {
        id: 'candidatesCount',
        url: '/admin-portal/candidates'
    },
    {
        id: 'subscriptionsCount',
        url: '/admin-portal/subscriptions'
    }
];

const adminDashboardCharts = {
    'organizationsSubscriptionTypeChart': {
        title: 'Organization Subscription Type',
        type: 'doughnut',
        chartHeight: '205px',
        height: 296,
        width: '49',
        tooltipLabel: 'Subscription Type',
        customLegends: true,
        legendAlignment: 'right'
    },
    // uncomment after adding candidate subscription feature
    // 'candidateSubscriptionTypeChart': {
    //     title: 'Candidates Subscription Type',
    //     type: 'doughnut',
    //     chartHeight: '205px',
    //     height: 296,
    //     width: '49',
    //     tooltipLabel: 'Subscription Type',
    //     customLegends: true,
    //     legendAlignment: 'right'
    // },
    'organizationsSubscriptionChart': {
        title: 'Organization Subscription ',
        type: 'pie',
        // chartHeight: '288px', uncomment after adding candidate subscription feature
        // height: 366, uncomment after adding candidate subscription feature
        // width: '36', uncomment after adding candidate subscription feature
        chartHeight: '205px',
        height: 296,
        width : '49',
        tooltipLabel: 'Subscription',
        customLegends: true,
        legendAlignment: 'right'
    },
    'organizationsChart': {
        title: 'Organization',
        type: 'line',
        chartHeight: '280px',
        height: 366,
        // width: '62', uncomment after adding candidate subscription feature
        width : '100',
        tooltipLabel: '',
        customLegends: false,
        legendAlignment: ''
    },
    'jobPostsChart': {
        title: 'Job Posts',
        type: 'bar',
        chartHeight: '312px',
        height: 410,
        width: '69',
        tooltipLabel: '',
        customLegends: false,
        legendAlignment: ''
    },
    'totalJobsChart': {
        title: 'Total Jobs',
        type: 'pie',
        chartHeight: '192px',
        height: 410,
        width: '29',
        tooltipLabel: 'Jobs',
        customLegends: true,
        legendAlignment: 'bottom'
    },
    // uncomment after adding candidate subscription feature
    // 'candidatesSubscriptionChart': {
    //     title: 'Candidate Subscription ',
    //     type: 'pie',
    //     chartHeight: '288px',
    //     height: 366,
    //     width: '36',
    //     tooltipLabel: 'Subscription',
    //     customLegends: true,
    //     legendAlignment: 'right'
    // },
    'candidatesChart': {
        title: 'Candidates',
        type: 'line',
        chartHeight: '280px',
        height: 366,
        // width: '62', uncomment after adding candidate subscription feature
        width : '100',
        tooltipLabel: '',
        customLegends: false,
        legendAlignment: ''
    },
};

const barCartOptions: any = {
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
            labels: {
                boxWidth: 12,
                useBorderRadius: true,
                borderRadius: 4
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            border: {
                display: false
            },
            grid: {
                display: false
            }
        },
        y: {
            stacked: true,
            beginAtZero: true,
            border: {
                display: false,
                dash: [6, 12]
            },
            ticks: {
                stepSize: 1
            }
        },
    },
};

const barChartBarColors = [
    '#49d4bf',
    '#ea6a4f',
    '#f1a553',
    '#3270a4',
];

const lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
            labels: {
                boxWidth: 12,
                useBorderRadius: true,
                borderRadius: 4
            }
        }
    },
    scales: {
        x: {
            border: {
                display: false
            },
            grid: {
                display: false
            }
        },
        y: {
            beginAtZero: true,
            border: {
                display: false,
                dash: [6, 12]
            },
            ticks: {
                stepSize: 1,
            }
        },
    },
};

const lineChartBorderColors = [
    '#eee79e',
    '#b0c9e9'
];

const lineChartBackgroundColors = [
    '#ede05a',
    '#488ee7'
];

const lineChartAreaFillColors = [
    '#eee79e',
    '#b0c9e9'
];

const pieChartOptions: any = {
    responsive: true,
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
    plugins: {
        legend: {
            display: false,
        },
    }
}

const pieChartBackgroundColors = [
    '#49d4bf',
    '#ea6a4f',
    '#f1a553',
    '#3270a4',
];

const doughnutChartOptions: any = {
    responsive: true,
    cutout: 50,
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
    plugins: {
        legend: {
            display: false,
        },
    }
}

const doughnutChartBackgroundColors = [
    '#74C0A9',
    '#9747FF',
    '#ebabb9',
    '#e7d621',
    '#ec4f5e',
    '#f1a553',
    '#ea6a4f'
];

const coreJobsQuantity = [10, 20, 30, 50, 100, "Above 100"];
const PremiumJobsQuantity = [5, 10, 15, 20, 25, 50, 75, 100];
const OrganizationalPlans = '/organizational-plans';
const CheckoutSuccess = '/subscription/success'
const CheckoutPlan = '/subscriptions/checkout';
const unsubscribe = '/unsubscribe';
const SupportContact = {
  support_mail: "support@tangoapp.co",
  support_phone_no: "+123456789",
};

const OrganizationEmployeeSize = [
    'Small (1-50 employees)',
    'Medium (51-500 employees)',
    'Large (501-1,000 employees)',
    'Enterprise (1,001+ employees)'
];

const employeeSize = {
  SIZE_A: '1-9',
  SIZE_B: '10-100',
  SIZE_C: '100-500',
  SIZE_D: '500-1,000',
  SIZE_E: '1,000-10,000',
  SIZE_F: '10,000+',
};

const OrganizationRevenue = [
    'Less than $1 million',
    '$1 million to $10 million',
    '$10 million to $50 million',
    '$50 million to $100 million',
    '$100 million to $500 million',
    '$500 million to $1 billion',
    '$1 billion to $5 billion',
    '$5 billion to $10 billion',
    'Over $10 billion'
];

const subscriptionAddons = {
    JOB: 'Job',
    VIDEO_ANALYSIS: 'Video Analysis',
    OTHER: 'Other'
}

const freemiumFeatures = ['1 active job for free', '2 months of swiping, matching and hiring', 'Top 5 candidates for the job']
const premiumFeatures = ['Post up to #qty# active jobs', '1 year of swiping, matching and hiring','Custom videos (Coming soon)','Video analysis (Coming soon)', 'Automated job video scripts (Coming soon)', 'Automated job descriptions (Coming soon)']
const proFeatures = ['Post unlimited jobs', 'Dedicated customer success manager', 'White glove service', '10 custom videos (Coming soon)', '10 video job fit analysis (Coming soon)'];

const LiteJobsQuantity = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
const liteFeatures = ['2 months of swiping, matching and hiring','Unlimited profile views','Unlimited candidate interactions','Global search', 'ATS integration']
const planIntervalType = {
    MONTHLY : 'Pay Monthly Commit annually',
    ANNUALLY : 'Pay Upfront Best Value Commit annually'
}

const phoneRegExp = /(^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$)/;

const atsPartners = [
    {
      name: "ApplicantStack",
      url: "/ats_logo/ApplicantStack.jpg",
    },
    {
      name: "Ashby",
      url: "/ats_logo/Ashby.png",
    },
    {
      name: "BambooHR",
      url: "/ats_logo/BambooHR.jpg",
    },
    {
      name: "Breezy",
      url: "/ats_logo/Breezy.jpg",
    },
    {
      name: "CATS",
      url: "/ats_logo/CATS.jpg",
    },
    {
      name: "Clockwork",
      url: "/ats_logo/Clockwork.jpg",
    },
    {
      name: "Comeet",
      url: "/ats_logo/Comeet.jpg",
    },
    {
      name: "Cornerstone TalentLink",
      url: "/ats_logo/Cornerstone.jpg",
    },
    {
      name: "EngageATS",
      url: "/ats_logo/EngageATS.png",
    },
    {
      name: "Eploy",
      url: "/ats_logo/Eploy.png",
    },
    {
      name: "Fountain",
      url: "/ats_logo/Fountain.png",
    },
    {
      name: "Greenhouse",
      url: "/ats_logo/Greenhouse_Square_Logo.jpg",
    },
    {
      name: "Greenhouse - Job Boards API",
      url: "/ats_logo/Greenhouse.jpeg",
    },
    {
      name: "Homerun",
      url: "/ats_logo/homerun.png",
    },
    {
      name: "HR Cloud",
      url: "/ats_logo/HR_Cloud.jpg",
    },
    {
      name: "Infinite BrassRing",
      url: "/ats_logo/Infinite.png",
    },
    {
      name: "JazzHR",
      url: "/ats_logo/JazzHR.jpg",
    },
    {
      name: "JobScore",
      url: "/ats_logo/JobScore.png",
    },
    {
      name: "Jobsoid",
      url: "/ats_logo/Jobsoid.jpg",
    },
    {
      name: "Jobvite",
      url: "/ats_logo/Jobvite.png",
    },
    {
      name: "Lano",
      url: "/ats_logo/Lano.png",
    },
    {
      name: "Lever",
      url: "/ats_logo/Lever.jpg",
    },
    {
      name: "Occupop",
      url: "/ats_logo/Occupop.png",
    },
    {
      name: "Oracle Fusion Recruiting Cloud",
      url: "/ats_logo/Oracle_Recruiting.png",
    },
    {
      Pname: "Personio Recruiting",
      url: "/ats_logo/Personio+Recruiting.jpeg",
    },
    {
      name: "ClayHR",
      url: "/ats_logo/PlatformClayHR.png",
    },
    {
      name: "Freshteam",
      url: "/ats_logo/PlatformFreshteam.png",
    },
    {
      name: "Harbour ATS",
      url: "/ats_logo/PlatformHarbour.png",
    },
    {
      name: "iCIMS",
      url: "/ats_logo/PlatformICIMS.png",
    },
    {
      name: "JobAdder",
      url: "/ats_logo/Platformjobadder.png",
    },
    {
      name: "Recruitive",
      url: "/ats_logo/PlatformRecruitive.png",
    },
    {
      name: "Tellent",
      url: "/ats_logo/PlatformTellent.png",
    },
    {
      name: "Tribepad",
      url: "/ats_logo/PlatformTribepad.png",
    },
    {
      name: "Zoho Recruit",
      url: "/ats_logo/PlatformZoho_recruit.png",
    },
    {
      name: "Polymer",
      url: "/ats_logo/Polymer_square.png",
    },
    {
      name: "Recruiterflow",
      url: "/ats_logo/RecruiterFlow.jpg",
    },
    {
      name: "Sage HR",
      url: "/ats_logo/Sage.jpg",
    },
    {
      name: "SmartRecuiters",
      url: "/ats_logo/SmartRecruiters.png",
    },
    {
      name: "SuccessFactors",
      url: "/ats_logo/SuccessFactors.jpg",
    },
    {
      name: "TalentLyft",
      url: "/ats_logo/TalentLyft.png",
    },
    {
      name: "TalentReef",
      url: "/ats_logo/TalentReef.png",
    },
    {
      name: "Oracle Taleo",
      url: "/ats_logo/Taleo.jpg",
    },
    {
      name: "Teamtailor",
      url: "/ats_logo/Teamtailor.jpg",
    },
    {
      name: "UKG Pro Recruiting",
      url: "/ats_logo/UKG.png",
    },
    {
      name: "Workable",
      url: "/ats_logo/Workable.jpg",
    },
    {
      name: "Workday",
      url: "/ats_logo/Workday.jpg",
    },
];
const candidateStatus = {
    'registered': 'Registered',
    'active': 'Active',
    'inactive': 'Inactive'
};

const NSFWMediaTypes = {
  'IMAGE': 'Image',
  'VIDEO': 'Video',
  'TEXT': 'Text',
};

const NSFWCategories = {
  'CANDIDATE_PROFILE_IMAGE': 'Candidate Profile Image',
  'CANDIDATE_PROFILE_VIDEO': 'Candidate Profile Video',
  'CANDIDATE_PROFILE_CONTENT': 'Candidate Profile Content',
  'USER_PROFILE_CONTENT': 'User Profile Content',
}

const pricingLeftMenus = [{
  label: 'Overview',
  url: process.env.REACT_APP_WEBSITEBASEURL,
},
{
  label: 'How It Works',
  url: `${process.env.REACT_APP_WEBSITEBASEURL}/how-it-works/`,
},
{
  label: 'Pricing',
  url: '',
}];

const pricingRightMenus = [{
  label: 'Schedule Demo',
  url: `${process.env.REACT_APP_WEBSITEHUBSPOTFORMBASEURL}/schedule-demo/`,
},
{
  label: 'Contact Us',
  url: `${process.env.REACT_APP_WEBSITEHUBSPOTFORMBASEURL}/contactus/`,
}];

const pricingMobileViewMenus = [{
  label: 'Overview',
  url: process.env.REACT_APP_WEBSITEBASEURL,
},
{
  label: 'How It Works',
  url: `${process.env.REACT_APP_WEBSITEBASEURL}/how-it-works/`,
},
{
  label: 'Pricing',
  url: '',
},
{
  label: 'Schedule Demo',
  url: `${process.env.REACT_APP_WEBSITEHUBSPOTFORMBASEURL}/schedule-demo/`,
},
{
  label: 'Contact Us',
  url: `${process.env.REACT_APP_WEBSITEHUBSPOTFORMBASEURL}/contactus/`,
}];

// ------------------ Service constants ----------------
// export const reactApiBaseUrl= "http://ec2-3-219-113-202.compute-1.amazonaws.com/api"
export const reactApiBaseUrl = process.env.REACT_APP_APIBASEURL
export const reactAtsApiBaseUrl = process.env.REACT_APP_ATSAPIBASEURL
export const reactPublicS3BucketUrl = process.env.REACT_APP_PUBLICS3BUCKETURL
export const reactWebsiteBaseUrl = process.env.REACT_APP_WEBSITEBASEURL
export const reactWebsiteHubspotFormBaseUrl = process.env.REACT_APP_WEBSITEHUBSPOTFORMBASEURL
// -----------------------------------------------------

export { AdminMenu, UsersMenu, Requests, coreJobsQuantity, PremiumJobsQuantity, LiteJobsQuantity, OrganizationalPlans, CheckoutPlan,CheckoutSuccess, frequencies, frequencyType, chartType, userDashboardCardLinks, userDashboardCharts, adminDashboardCardLinks, adminDashboardCharts, barCartOptions, barChartBarColors, lineChartOptions, lineChartBorderColors, lineChartBackgroundColors, lineChartAreaFillColors, pieChartOptions, pieChartBackgroundColors, doughnutChartOptions, doughnutChartBackgroundColors, SupportContact, OrganizationEmployeeSize, OrganizationRevenue, subscriptionAddons , freemiumFeatures, premiumFeatures, proFeatures, liteFeatures, planIntervalType, phoneRegExp, atsPartners, candidateStatus, employeeSize, unsubscribe, pricingLeftMenus, pricingRightMenus, pricingMobileViewMenus, NSFWMediaTypes, NSFWCategories}

