import React from "react";
import { Link, useLocation, useNavigate} from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { apiService } from "../../../core/services/api.service";

import { UserRole } from "../../../types/user.interface";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "../../../../store";
import InviteUser from "../../../components/dialogs/users/invite-users/invite-user.component";
import EnhancedTableHead from "../../../components/common/enhanced-header.component"
import { toastMessage } from "../../../core/services/toast";

interface Data {
  name: string;
  hiringManager: string;
  recruiter: string;
}
interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  isWidth: boolean,
  disablePadding: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    label: "Name",
    numeric: false,
    disablePadding: false,
    isWidth: true
  },
  {
    id: "hiringManager",
    label: "Hiring Manager",
    numeric: false,
    disablePadding: false,
    isWidth: false
  },
  {
    id: "recruiter",
    label: "Recruiter",
    numeric: false,
    disablePadding: false,
    isWidth: false
  },
];

export default function PublishJobs(props: any) {
  const { state } = useLocation();
  const jobs = state?.selected || [];
  const navigate = useNavigate();
  const organizationDetails : any = useAppSelector(
    state => state.organization.myOrganization
  );
  const validationSchema = Yup.object({
    jobs: Yup.array().of(
      Yup.object().shape({
        _recruiter: Yup.string().required("Recruiter required"),
        _user: Yup.string(),
      })
    ),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const breadcrumbs = [
    <Link key="1" color="inherit" to="/customer-portal/dashboard" className="inactive_menu">
      Home
    </Link>,
    <Link key="2" color="inherit" to="/customer-portal/ats-jobs" className="inactive_menu">
      ATS Jobs
    </Link>,
    <Typography key="3" color="text.primary" className="active_menu">
      Publish Jobs
    </Typography>,
  ];

  const [hiringManagers, setHiringManagers] = React.useState([]);
  const [recruiters, setRecruiters] = React.useState([]);
  const [selectedJobs, setSelectedJobs] = React.useState([...jobs]);
  const [selectedTabValue, setSelectedTabValue] = React.useState(
    UserRole.Recruiter
  );
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    fetchHiringManagers();
    fetchRecruiters();
    setSelectedJobs(state?.selected);
  }, [selectedJobs]);

  const fetchHiringManagers = async () => {
    try {
      let url = '/organizations/hr/list' ;
      const { data } = await apiService.get(url);
      const hrList = data || [];
      setHiringManagers(hrList);
    } catch (err: any) {
      setHiringManagers([]);
      toastMessage(err.response.data.statusCode, null);
    }
  }

  const fetchRecruiters = async () => {
    try {
      let url = '/organizations/recuiter/list' ;
      const { data } = await apiService.get(url);
      const recruiterList = data || [];
      setRecruiters(recruiterList);
    } catch (err: any) {
      setRecruiters([]);
      toastMessage(err.response.data.statusCode, null);
    }
  }

  const handleClose = () => {
    navigate("/customer-portal/ats-jobs");
  };

  const validateEmailDomain = (email: string) => {
    let { emailDomains} = organizationDetails;
    let emailDomain = email.split("@")[1]?.trim();
    return emailDomains.some((v: string) => v === emailDomain);
  };

  const hrInvitationClick = async (email: string) => {
    let validEmailInput = validateEmailDomain(email);
    if (!validEmailInput) {
      alert(
        "Email domain of the invitee doesn't match with that of the company"
      );
      return;
    }
    const payload = {
      email: email,
      role: selectedTabValue,
    };
    let url = "/organizations/invite/recruiter";
    const role = selectedTabValue;
    if (role === UserRole.HRManager) {
      url = "/organizations/hr/invite";
    }
    try {
      const response = await apiService.post(url, payload);
      
      if (role === UserRole.HRManager) {
        fetchHiringManagers();
      } else {
        fetchRecruiters();
      }
      setIsOpen(false);
      let message: string = `Successfully sent invitation to ${payload.email}`;
      toastMessage(response.status, message, "success");
    } catch (err: any) {
      toastMessage(err.response.data.statusCode,null);
    }
  };

  const onSubmit = (jobs: any) => {
    jobs?.map(async (data: any, index: number) => {
      let request = { ...data, ...selectedJobs[index] };
      if (request?._user === "") {
        delete request?._user;
      }
      try {
        let url = "/employment/publish";
        const responseData = await apiService.post(url, request);
        if (responseData) {
          let message = `${request.name} job successfully created.`;
          toastMessage(responseData.status, message, "success");
          if (index === jobs?.length - 1) {
            navigate("/customer-portal/jobs");
          }
        }
      } catch (err: any) {
        let message = "";
        if (err?.response?.status === 409) {
          message = request.name + " " + err?.response?.data?.message;
        } else {
          message = `${request.name} job not created.`;
        }
        toastMessage(err.response.data.statusCode,message);
      }
    });
  };

  const handleInviteOpen = (role: any) => {
    setSelectedTabValue(role);
    setIsOpen(true);
  };

  const handleInviteClose = () => {
    setIsOpen(false);
  };

  const handleInviteSubmit = (event: any) => {
    hrInvitationClick(event.email);
  };

  return (
    <>
      <div className="dashboard__header">
        <div className="org__details">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="medium" className="icon" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard__wrapper">
          <div className="heading">
            Publish
          </div>                  
        </div>
        <form onSubmit={handleSubmit((d) => onSubmit(d?.jobs))}>
          <div className="table__wrapper">
            <TableContainer component={Paper} className="custom__table">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <EnhancedTableHead
                headCells={headCells}
                isAction = {false}
                isSorting = {false}
              />
                <TableBody>
                  {selectedJobs?.map((item: any, index: number) => (
                      <TableRow
                        key={item?.code}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className="custom__cell__row"
                      >
                        <TableCell
                          align="left"
                          className="custom__cell__element"
                        >
                          {item?.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          padding='normal'
                          className="custom__cell__element"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            size="small"
                            {...register(`jobs.${index}._user`)}
                            name={`jobs.${index}._user`}
                            sx={{width: '300px'}}
                          >
                            <MenuItem value="" disabled>
                              <em>Select the value</em>
                            </MenuItem>
                            {hiringManagers.map((data: any, index: number) => (
                              <MenuItem value={data._id} key={data._id}>
                                {data?._user?.name
                                  ? data?._user?.name
                                  : data?._user?.email}
                              </MenuItem>
                            ))}
                            <MenuItem value="">
                              <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                className="custom__action__button confirm__button custom__invite__btn"
                                onClick={() => handleInviteOpen(UserRole.HRManager)}
                              >
                                Invite
                              </Button>
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell
                          align="left"
                          padding='normal'
                          className="custom__cell__element"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            size="small"
                            {...register(`jobs.${index}._recruiter`)}
                            name={`jobs.${index}._recruiter`}
                            sx={{width: '300px'}}
                          >
                            <MenuItem value="" disabled>
                              <em>Select the value</em>
                            </MenuItem>
                            {recruiters.map((data: any, index: number) => (
                              <MenuItem value={data._id} key={data._id}>
                                {data?._user?.name
                                  ? data?._user?.name
                                  : data?._user?.email}
                              </MenuItem>
                            ))}
                            <MenuItem value="">
                              <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                className="custom__action__button confirm__button custom__invite__btn"
                                onClick={() => handleInviteOpen(UserRole.Recruiter)}
                              >
                                Invite
                              </Button>
                            </MenuItem>
                          </Select>
                          <FormHelperText
                            id="name-error-text"
                            className="error_message"
                          >
                            {errors.jobs?.[index]?._recruiter?.message}
                          </FormHelperText>
                        </TableCell>
                      </TableRow>
                  ))}
                  {selectedJobs?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3} className="no__data">
                        No Data Available !
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="publish__actions">
            <div className="publish__action__btns">
              <Button
                color="primary"
                variant="contained"
                onClick={handleClose}
                className="custom__action__button cancel__button"
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                className="custom__action__button confirm__button"
              >
                Publish
              </Button>
            </div>
          </div>
        </form>
      </div>

      <InviteUser
        open={isOpen}
        emailDomain={organizationDetails}
        handleClose={handleInviteClose}
        handleInviteSubmit={handleInviteSubmit}
      />
    </>
  );
}
