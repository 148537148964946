import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SupportContact } from "../../core/constants";

export function ContactUsDialog(props: any) {
  const handleContactUsClose = () => {
    props.handleContactUsClose();
  };
  return (
    <Dialog
      open={props.showContactUs}
      PaperProps={{
        style: {
          padding: "14px 8px 20px",
          position: "absolute",
          margin: "auto",
          borderRadius: 10,
          width: 300,
          height: 180
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="custom__title"
        component="div"
        sx={{ color: "#9f2223", fontFamily: 'Inter-Regular, sans-serif' }}
      >
        <div>Contact Us</div>
        <IconButton aria-label="close" onClick={handleContactUsClose}>
          <CloseIcon sx={{ color: "#9f2223" }} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText className="contact_us_data" sx={{marginBottom:"10px"}}>
          <label className="contact_us_label"> Email : </label>{" "}
          {SupportContact.support_mail}
        </DialogContentText>
        {/* Commenting this code for now, will be required in future */}
        {/* <DialogContentText className="contact_us_data">
          <label className="contact_us_label"> Phone No : </label>{" "}
          {SupportContact.support_phone_no}
        </DialogContentText> */}
      </DialogContent>
    </Dialog>
  );
}
