import React from "react";
import dayjs from 'dayjs';
import { authorizationService } from "../../../core/services/authorization.service";
import { useNavigate, useSearchParams,Link  } from "react-router-dom";
import { apiService } from "../../../core/services/api.service";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FilterPopupComponent from "../../../components/common/filter-popup.component";
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Avatar from "@mui/material/Avatar";
import BusinessIcon from '@mui/icons-material/Business';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import ConfirmationPopupComponent from "../../../components/dialogs/confirmation-popup/confirmation-popup.component";
import AddEditOrganization from "../../../components/dialogs/organizations/add-edit-organization.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import { useAppSelector } from "../../../../store";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";
import SyncIcon from '@mui/icons-material/Sync';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import moment from "moment";
import SyncCandidatesPopupComponent from "../../../components/dialogs/sync-candidates/sync-candidates-popup.component";

interface Data {
    website: string;
    email: string;
    contact: string;
    name: string;
    address: string;
    createdAt: string;
  }

  
  type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    sorting: boolean;
    numeric: boolean;
  }
  
  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      sorting: true,
      label: 'Name',
    },
    {
      id: 'website',
      numeric: true,
      disablePadding: false,
      sorting: true,
      label: 'Site',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      sorting: true,
      label: 'Email',
    },
    {
      id: 'contact',
      numeric: true,
      disablePadding: false,
      sorting: false,
      label: 'Contact No',
    },
    {
      id: 'address',
      numeric: true,
      disablePadding: false,
      sorting: false,
      label: 'Address',
    },
    {
      id: 'createdAt',
      numeric: true,
      disablePadding: false,
      sorting: true,
      label: 'Created Date',
    },
  ];

export default function OrganizationComponent() {
    const navigate = useNavigate();
    const userDetails: any = useAppSelector(
      (state) => state.auth.userDetail
    );
    const isTokenAvailable: any = useAppSelector(
      (state) => state.auth.token
    );
    let [searchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const initialFilterValue = {
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
    };
    const breadcrumbs = [
        <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
          Home
        </Link>,
        <Typography key="2" color="text.primary" className="active_menu">
          Organizations
        </Typography>,
      ];
    const itemsPerPage= 50;
    const [organizationList, setOrganizationList] = React.useState<any>([]);
    const [permissions, setPermissions] = React.useState<any>(null);
    const [data, setData] = React.useState({id:'',name:''});
    const [index, setIndex] = React.useState(-1);
    const [searchTerm, setSearchTerm] = React.useState('')
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [openSyncPopup, setOpenSyncPopup] = React.useState(false);
    const [selectedOrganizationIdToSync, setSelectedOrganizationIdToSync] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [filters, setFilters] = React.useState(initialFilterValue);
    const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLButtonElement >(null);
    const [openFilter, setOpenFilter] = React.useState(false);
    const canBeOpen = Boolean(anchorFilter);
    const popoverId = canBeOpen ? 'filter-popover' : undefined;
    const [selectedOrganization, setSelectedOrganization] = React.useState<any>(null);
    
    // React.useEffect(()=>{
    //     setTimeout(getOrganizationList, 2000)
    // },[])
  
    React.useEffect(() => {
      const organizationsPermissions = authorizationService.getPermissions(userDetails?.role, 'organizations', false, userDetails?.isSuperAdmin);
      setPermissions(organizationsPermissions);
    }, [userDetails]);

    React.useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (isTokenAvailable && permissions?.read) {
          getOrganizationList();
        }
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }, [permissions,itemsPerPage, page, order, orderBy, filters, searchTerm, isTokenAvailable])

    const getOrganizationList = async () => {
      try{
        const organizationList = await fetchOrganizationList()
        setOrganizationList(organizationList)
      }catch(err:any){

      }
      setIsLoaded(true)
    }

    const fetchOrganizationList = async () => {
        let url = `/organizations?take=${itemsPerPage}&skip=${(page-1)*itemsPerPage}&order=${order === 'asc' ? 1 : -1}&orderBy=${orderBy}` ;
        if (searchTerm.trim() !== '') {
          url = `${url}&term=${searchTerm}`;
        }
        if (filters.startDate) {
          url = `${url}&startDate=${filters.startDate}`;
        }
        if (filters.endDate) {
            url = `${url}&endDate=${filters.endDate}`;
        }
        try{
        const { data } = await apiService.get(url)
        const result = data?.result || [];
        const count = data?.count || 0;
        const totalPages = Math.ceil(count/itemsPerPage);
        setCount(totalPages);
        return result;
        }catch(err:any){
          toastMessage(err.response.data.statusCode,null);
        }
    }

    const organizations=()=>{
      if(organizationList.length > 0){
        const organizations=  organizationList.map((row:any, index:number) => (
          <TableRow
          key={row._id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className="custom__cell__row"
          >
          <TableCell component="th" scope="row" className="custom__cell">
              <Avatar>
                  <BusinessIcon /> 
              </Avatar>
              <span>{row.name}</span>
          </TableCell>
          <TableCell align="left" className="custom__cell__element">{row.website}</TableCell>
          <TableCell align="left" className="custom__cell__element">{row.email}</TableCell>
          <TableCell align="left" className="custom__cell__element">{row.contact}</TableCell>
          <TableCell align="left" className="custom__cell__element">{row.address}</TableCell>
          <TableCell align="left" className="custom__cell__element">{dateFormat(row.createdAt)}</TableCell>
          <TableCell align="left" className="custom__cell__element action__icons">
            {permissions?.details && <IconButton title="View Details" onClick={() => openOrganizationDetailsPage(row._id)}>
              <VisibilityOutlinedIcon />
            </IconButton>}
            {permissions?.delete && <IconButton onClick={()=>handleConfirmationClickOpen(index)} title="Delete">
              <DeleteOutlineIcon />
            </IconButton>}
            {permissions?.edit &&<IconButton title="Edit" onClick={() => handleEditClick(row._id)}>
              <DriveFileRenameOutlineIcon />
            </IconButton>}
            {(row?.poc?.status === 'registered' || row?.poc?.status === 'active') && permissions?.report && <IconButton title="Report" onClick={() => openOrganizationReport(row._id)}>
              <AssessmentOutlinedIcon />
            </IconButton>}
            <IconButton title={row.ats ? "Sync ATS Candidates" : "ATS is not connected"} onClick={() => openSyncATSCandidatesPopup(row)}>
              <SyncIcon />
            </IconButton>
            <IconButton title="ATS Candidates" onClick={() => handleATSCandidateRedirection(row)}>
              <PeopleAltIcon />
            </IconButton>
            {
              row?.poc?.status === 'invited' && permissions?.resendInvite && 
              <IconButton onClick={()=>reSendInvite(row.email)} title="Resend Invite">
                <SendOutlinedIcon />
              </IconButton>
            }
          </TableCell>
        </TableRow>
        ));
        return organizations;
      }else{
        return (
          <TableRow>
            <TableCell colSpan={7} className="no__data">
              No Data Found
            </TableCell>
          </TableRow>
        )
      }
    }

    const handleClickOpen = () => {
      setData({id:'',name:''})
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setTimeout(getOrganizationList, 2000)
    };

    const handleConfirmationClickOpen = (index:number) => {
      setIndex(index);
      const organization = organizationList[index] || null;
      setSelectedOrganization(organization);
      setOpenConfirmation(true);
    };
  
    const handleConfirmationClose = (value: number) => {
      organizationList.splice(value, 1);
      setOpenConfirmation(false);
    };

    const handleEditClick = async (id:string) => {
      try{
      let url = `/organizations/${id}` ;
      const { data } = await apiService.get(url)
      setData(data)
      setOpen(true);
      }catch(err:any){
        toastMessage(err.response.data.statusCode,null);
      }
    }

    const dateFormat = (date:any) => {
      if (date) {
        let currentDate = moment(date).format('DD-MMM-YYYY');
        return currentDate;
      }
    }

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data,
    ) => {
      setIsLoaded(false);
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const reSendInvite = async (email:string) => {
      let message:string = "";
      try {
        setIsLoaded(false)
        let url = `/invitation/resend`;
        const response = await apiService.post(url, {email, task: "new recruiter"});
        setIsLoaded(true)
        message = `Invitation sent successfully to ${email}`;
        toastMessage(response.status, message, "success");
      }catch(error:any){
        let message = "";
        if(error.response.data.statusCode === 400){
            message = error.response.data.message
        }else{
            message = "Invitation sent failed"
        }
        setIsLoaded(true)
        toastMessage(error.response.data.statusCode, message, "error");
      }
    }

    const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setIsLoaded(false);
      setPage(value)
    }

    const openOrganizationReport = (orgId: string) => {
      navigate(`/admin-portal/organization-report/${orgId}`);
    }

    const handleFilterChange = (filterValues: any) => {
      setIsLoaded(false);
      setFilters(filterValues);
    }
  
    const handleToggleFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorFilter(anchorFilter ? null : event.currentTarget);
      setOpenFilter((previousOpen) => !previousOpen);
    }

    const openOrganizationDetailsPage = (orgId: string) => {
      navigate(`/admin-portal/organization-details/${orgId}`);
    }

    const handleSearch = (value: string) => {
      if ((value && value.length > 3) || value.length === 0) {
        setPage(1);
        setIsLoaded(false);
        setSearchTerm(value);
      }
    }

    const openSyncATSCandidatesPopup = (organization: any) => {
      if (organization?.ats) {
        setOpenSyncPopup(true);
        setSelectedOrganizationIdToSync(organization._id);
      } else {
        setSelectedOrganizationIdToSync(null);
        setOpenSyncPopup(false);
        return false;
      }
    }

    const handleCloseSyncCandidatesPopup = () => {
      setSelectedOrganizationIdToSync(null);
      setOpenSyncPopup(false);
    }

    const handleATSCandidateRedirection = (org: any) => {
      const requestData = btoa(org?._id);
      navigate(`/admin-portal/ats-candidates?requestData=${requestData}`);
    }

        return (
            <>
                <div className="dashboard__header">
                    <div className="org__details">
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="dashboard">
                    <div className="dashboard__wrapper">
                        <div className="heading">
                            Organizations
                        </div>
                        <div className="search__wrapper">
                            <TextField
                                id="search"
                                type="search"
                                name="new-search"
                                autoComplete='off'
                                placeholder="Search"
                                variant="outlined"
                                className="custom__search"
                                defaultValue={searchTerm}
                                onChange={(event: any) => handleSearch(event.target.value)}
                                sx={{ width: 350 }}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                }}
                            />
                            <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} aria-describedby={popoverId} className="custom__button custom__filter__button" onClick={handleToggleFilterPopup}>Filter</Button>
                            {permissions?.add && <Button variant="contained" className="custom__button custom__create__button"  onClick={handleClickOpen}>Create</Button>}
                        </div>
                    </div>
                    <div className="table__wrapper">
                      <TableContainer component={Paper} className="custom__table">
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              headCells={headCells}
                              isAction={true}
                              isSorting = {true}
                          />
                              <TableBody>
                              {!isLoaded ?  <TableSkeltonComponent rows={10} columns={7} /> :organizations()}
                              </TableBody>
                              <TableFooter>
                                  <TableRow>
                                      <TableCell colSpan={7}>
                                        <TablePagination color="primary" count={count} siblingCount={0} defaultPage={page} boundaryCount={2} page={page} className="custom__pagination" onChange={onPageChange} />
                                      </TableCell>                                        
                                  </TableRow>
                              </TableFooter>
                          </Table>
                      </TableContainer>
                    </div>
                      {open && <AddEditOrganization
                        open={open}
                        data={data}
                        onClose={handleClose}
                      />}
                      <ConfirmationPopupComponent
                        open={openConfirmation}
                        index={index}
                        title="Delete"
                        message={`Are you sure want to delete organization ${selectedOrganization?.name}?`}
                        btnLabel="Delete"
                        onClose={handleConfirmationClose}
                        onConfirm={handleConfirmationClose}
                      />
                      {openFilter && <FilterPopupComponent 
                        open={openFilter}
                        popoverId={popoverId} 
                        anchorEl={anchorFilter}
                        filterState={filters}
                        onlyDateFilter={true}
                        onClose={handleToggleFilterPopup}
                        onApplyFilter={handleFilterChange}
                      />}
                      {openSyncPopup && <SyncCandidatesPopupComponent 
                        open={openSyncPopup}
                        orgId={selectedOrganizationIdToSync}
                        onClose={handleCloseSyncCandidatesPopup}
                      />}
                </div>  
            </>

        );
    }

