import React from "react";
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { useAppSelector } from "../../../../store";
import { authorizationService } from "../../../core/services/authorization.service";
import TextField from '@mui/material/TextField';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Avatar from "@mui/material/Avatar";
import { apiService } from "../../../core/services/api.service"
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EnhancedTableHead from "../../../components/common/enhanced-header.component";
import TableSkeltonComponent from "../../../components/common/table-skelton.component";
import TablePagination from "../../../components/common/tablePagination";
import { toastMessage } from "../../../core/services/toast";

interface Data {
  name: string;
  email: string;
  role: string;
  createdAt: string;
  reportedBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  isWidth: boolean,
}

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    isWidth: false,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isWidth: false,
    label: 'Email',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    isWidth: false,
    label: 'Role',
  },
  {
    id: 'reportedBy',
    numeric: true,
    disablePadding: false,
    isWidth: false,
    label: 'Reported By',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    isWidth: false,
    label: 'Reported Date',
  }
];

const EnhancedTableFooter = (props: any) => {
  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.onPageChange(value)
  }
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={4}>
          <TablePagination count={props.pages} siblingCount={0} defaultPage={props.page} page={props.page} boundaryCount={2} className="custom__pagination" onChange={onPageChange}/>
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default function AdminReportedUsersComponent() {
  const userDetails: any = useAppSelector(
    (state) => state.auth.userDetail
  );
  const limit =10;
  const navigate = useNavigate();
  const [userList, setUserList] = React.useState<any>([])
  const [permissions, setPermissions] = React.useState<any>(null);
  const [userListLoading, setUserListLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState('')
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('createdAt');

  const isTokenAvailable: any = useAppSelector(
    (state) => state.auth.token
  );

  const breadcrumbs = [
    <Link key="1" color="inherit" to="/admin-portal/dashboard" className="inactive_menu">
      Home
    </Link>,
    <Typography key="2" color="text.primary" className="active_menu">
      Reported Users
    </Typography>,
  ];

  React.useEffect(() => {
    const reportedUsersPermissions = authorizationService.getPermissions(userDetails?.role, 'reportedUsers', false, userDetails?.isSuperAdmin);
    setPermissions(reportedUsersPermissions);
  }, [userDetails]);

  React.useEffect(() => {
    if(isTokenAvailable){
      const delayDebounceFn = setTimeout(() => {
        getUsersList();
      }, 1500)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [page, order, orderBy, searchTerm, isTokenAvailable])

  const getUsersList = async () => {
    setUserList([]);
    const userListData = await fetchUserList();
    setUserList(userListData);
  }

  const userCount =(data:any)=>{
    if(data[0].count.length){
      return data[0].count[0].count;
    }else{
      return 0;
    }
}

  const fetchUserList = async () => {
    try{
    let url = `/report/users?take=${limit}&skip=${(page - 1) * limit}&order=${order === 'asc' ? 1 : -1}&orderBy=${orderBy}&name=${searchTerm}`;
    const { data } = await apiService.get(url);
    const userListData = data.length ? data[0].result : [];
    const count = data.length ? userCount(data) : 0;
    setPages(Math.ceil(count / limit))
    setUserListLoading(false);
    return userListData;
    }catch(err:any){
      toastMessage(err.response.data.statusCode,null);
    }
  }

  const users = ()=>{
    if(userList.length > 0){
      const users = userList.map((row: any, index: number) => {
        return (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className="custom__cell__row"
          >
            <TableCell align="left" className="custom__cell width70">
              <Avatar>
                <PersonOutlineOutlinedIcon />
              </Avatar>
              <span>{ row?.reportee?.name}</span>
            </TableCell>
            <TableCell align="left" className="custom__cell__element">{row?.reportee?.email}</TableCell>
            <TableCell align="left" className="custom__cell__element">{row?.reportee?.role}</TableCell>
            <TableCell align="left" className=" custom__cell__element">
              {row?.reporter?.name}
            </TableCell>
            <TableCell align="left" className=" custom__cell__element">
              {dateFormat(row?.createdAt)}
            </TableCell>
            <TableCell align="left" className="custom__cell__element action__icons d-flex">
            {(permissions?.viewHistory) && (<IconButton title="View History" onClick={() => navigateToHistoryPage(row)}>
              <VisibilityOutlinedIcon />
            </IconButton>)}
          </TableCell>
          </TableRow>
        )
      })
      return users;
    }else{
      return (<TableRow>
                <TableCell colSpan={6} className="no__data">
                  No Data Available !
                </TableCell>
              </TableRow>)
    }
  }

  const dateFormat = (date:any) => {
    let currentDate = moment(date).format('DD-MMM-YYYY');
    return currentDate;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setUserListLoading(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (value: number) => {
    setUserListLoading(true);
    setPage(value)
  }

  const handleSearch = (value: string) => {
    if ((value && value.length > 3) || value.length === 0) {
      setPage(1);
      setUserListLoading(true);
      setSearchTerm(value);
    }
  }

  const navigateToHistoryPage = (reportedUserData: any) => {
    navigate("/admin-portal/reported-user/history", { state: { reportedUserData: reportedUserData } })
  }

  return (
    <>
      <div className="dashboard__header">
        <div className="org__details">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" className="icon" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard__wrapper">
          <div className="heading">
            Reported Users
          </div>
          <div className="search__wrapper">
            <TextField
              id="search"
              type="search"
              name="new-search"
              autoComplete='off'
              placeholder="Search by name"
              variant="outlined"
              className="custom__search"
              defaultValue={searchTerm}
              onChange={(event: any) => handleSearch(event.target.value)}
              sx={{ width: 350 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/* <Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} className="custom__button custom__filter__button">Filter</Button> */}
          </div>
        </div>
        <div className="table__wrapper">
          <TableContainer component={Paper} className="custom__table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                isAction={true}
                onRequestSort={handleRequestSort}
                isSorting={true}
              />
              <TableBody>
                {userListLoading ? <TableSkeltonComponent rows={10} columns={6} /> : users()
                }
              </TableBody>
              <EnhancedTableFooter
                users={userList}
                pages={pages}
                page={page}
                onPageChange={handleChange}
              />
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
