import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// Local imports
import "./createOrganization.scss";
import logo from "../../../../assets/tango_logo_colored.svg";
import group from "../../../../assets/Group.svg";
// MUI imports
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import Autocomplete from "@mui/material/Autocomplete";
import LanguageIcon from "@mui/icons-material/Language";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsIcon from '@mui/icons-material/Groups';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
// Form related imports
import { useFormik } from "formik";
import * as Yup from "yup";

import { apiService } from "../../../core/services/api.service";
import { employeeSize, phoneRegExp } from "../../../core/constants";
import { toastService } from "../../../core/services/toast.service";
import { TermsOfService } from "../../auth/terms-of-service/terms-of-service";
import AppFooter from "../../../components/app-footer/app-footer";
import { toastMessage } from "../../../core/services/toast";
import ThankyouPopupComponent from "../../../components/dialogs/thankyou-popup/thankyou-popup.component";

const orgSchema = Yup.object().shape({
  companyName: Yup.string().trim().required("Company name is required"),
  website: Yup.string().trim().required("Website is required").test("websiteValidation","Enter valid website (must start with https://)",(websiteName)=>{
    const websiteRegex = /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})(?:\/[\w.-]*)*\/?$/;
    const result = websiteRegex.test(websiteName);
    return result;
  }),
  companySize: Yup.string().trim().required("Company size is required"),
  personName: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .trim("The email cannot include leading and trailing spaces")
    .strict(true)
    .email("Invalid Email")
    .required("Email is required"),
  contact: Yup.string().trim().required("Contact Number is required").test("contactValidation","Enter Valid Contact Number",(contact)=>{
    const result = phoneRegExp.test(contact);
    return result;
  }),
  address: Yup.string().trim().required("Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  zipcode: Yup.string().trim().required("Zip is required"),
  country: Yup.string().required("Country is required"),
});

const CreateOrganization = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [cities, setCities] = React.useState<any>([]);
  const [cityInputValue, setCityInputValue] = React.useState("");
  const [showLoader, setshowLoader] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const [termsAndPolicyError, setTermsAndPolicyError] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [requestId, setRequestId] = React.useState('');
  const reqId: any = searchParams.get("requestId");
  const requestData: any = JSON.parse(atob(reqId));

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (cityInputValue.length > 2) {
        fetchCities();
      } else {
        setCities([]);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [cityInputValue]);

  React.useEffect(() => {
    if (!reqId && !requestData) {
      toastService.showToastNotification(
        "info",
        "You have not selected any plan. First select a plan"
      );
      navigate("/organizational-plans");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      website: "",
      companySize: "",
      personName: "",
      email: "",
      contact: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
      country: "",
    },
    enableReinitialize: true,
    validationSchema: orgSchema,
    onSubmit: async (values) => {
      if (!checked) {
        setTermsAndPolicyError(true);
        return;
      }
      const contact = values.contact.replace(/[()\s\-.]/g, '');
      try {
        const payload = {
          name: values?.companyName,
          size: values?.companySize,
          address: values?.address,
          city: values?.city,
          state: values?.state,
          country: values?.country,
          zipcode: values?.zipcode,
          website: values?.website,
          email: values?.email,
          personName: values?.personName,
          contact: contact,
          plan: requestData?.plan,
          addons: requestData?.addons,
        };
        setshowLoader(true);
        let url = "/organization-lead/signup";
        if (requestData?.action === "requestQuote") {
          url = `${url}?quote=true`;
        }
        const { data,status } = await apiService.post(
          url,
          payload
        );
        setshowLoader(false);
        if (data?._id) {
          const returnObj = {
            _id: data?._id,
            email: values?.email,
          };
          const reqId = btoa(JSON.stringify(returnObj));
          setRequestId(reqId);
          if (requestData?.action === "requestQuote") {
            setOpenPopup(true);
          } else {
            navigate(`/email-verification?requestId=${reqId}`);
            toastMessage(status,"Please check email for Secure Code to verify your email address","success");
          }
        }
      } catch (error: any) {
        setChecked(false);
        setshowLoader(false);
        toastMessage(error.response.data.statusCode, error?.response?.data?.message)
      }
    },
  });

  const handlePopupClose = () => {
    setOpenPopup(false);
    setTimeout(() => {
      navigate(`/organizational-plans`);
    }, 100);
  }

  const isCompNameError=()=>{return (formik.touched.companyName && Boolean(formik.errors.companyName))};
  const isCompSizeError=()=>{return (formik.touched.companySize && Boolean(formik.errors.companySize))};
  const isWebsiteError=()=>{return (formik.touched.website && Boolean(formik.errors.website))}
  const isPersonNameError=()=>{return (formik.touched.personName && Boolean(formik.errors.personName))}
  const isEmailError=()=>{return (formik.touched.email && Boolean(formik.errors.email))}
  const isContactError=()=>{return (formik.touched.contact && Boolean(formik.errors.contact))}
  const isAddressError=()=>{return (formik.touched.address && Boolean(formik.errors.address))}
  const isCityError=()=>{return (formik.touched.city && Boolean(formik.errors.city))}
  const isStateError=()=>{return (formik.touched.state && Boolean(formik.errors.state))}
  const isCountryError=()=>{return (formik.touched.country && Boolean(formik.errors.country))}
  const isZipCodeError=()=>{return (formik.touched.zipcode && Boolean(formik.errors.zipcode))}

  const isError = () => {
    const value =
    isCompNameError() ?? 
    isCompSizeError() ?? 
    isWebsiteError() ?? 
    isPersonNameError() ?? 
    isEmailError() ?? 
    isContactError() ?? 
    isAddressError() ?? 
    isCityError() ?? 
    isStateError() ?? 
    isCountryError() ?? 
    isZipCodeError();

    return value;
  };

  const fetchCities = async () => {
    setCities([]);
    try {
      const url = `/organizations/search/location?partialLocation=${cityInputValue.trim()}`;
      const { data } = await apiService.get(url);
      const citiesList = data || [];
      setCities(citiesList);
    } catch (err: any) {
      setCities([]);
      toastMessage(err?.response?.data?.message,null);
    }
  };

  const handleCityChange = (value: any) => {
    if (value && value !== null) {
      formik.setFieldValue("city", value.city);
      formik.setFieldValue("state", value.state);
      formik.setFieldValue("country", value.country);
    } else {
      formik.setFieldValue("city", "");
      formik.setFieldValue("state", "");
      formik.setFieldValue("country", "");
    }
  };

  const handleChange = (checked: any) => {
    setChecked(checked);
    if (!checked) {
      setTermsAndPolicyError(true);
    } else {
      setTermsAndPolicyError(false);
    }
  };

  return (
    <>
      <div className="create-org-root">
        <div className="organization-container">
          <div className="create-form">
            <div className="form-heading">
              <img alt="" style={{ height: "56px" }} src={logo} />
            </div>
            <div className="form">
              <div className="form-left-side">
                <img alt="" src={group} className="group" />
              </div>
              <form
                className="form-right-side"
                onSubmit={formik.handleSubmit}
              >
                <Grid container>
                  <Box className="element">
                    <div className="icon-wrapper">
                      <BusinessIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Company name
                      </InputLabel>
                      <TextField
                        id="companyName"
                        name="companyName"
                        type="text"
                        size="small"
                        placeholder="Company name"
                        variant="outlined"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>
                  <Box className="element">
                    <div className="icon-wrapper">
                      <LanguageIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Website
                      </InputLabel>
                      <TextField
                        id="website"
                        name="website"
                        type="text"
                        size="small"
                        placeholder="Website"
                        variant="outlined"
                        value={formik.values.website}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.website &&
                          Boolean(formik.errors.website)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>
                      {(formik.touched.website && formik.errors.website) && (
                        <FormHelperText id='name-error-text' className="error_message">{formik.errors.website}</FormHelperText>
                      )}
                    
                    <Box className="element">
                    <div className="icon-wrapper">
                      <GroupsIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Company size
                      </InputLabel>
                      <Select
                        labelId="company-size-label"
                        id="company-size"
                        variant="outlined"
                        displayEmpty
                        name="companySize"
                        className='input__select'
                        fullWidth
                        size='small'
                        value={formik.values.companySize}
                        onChange={formik.handleChange}
                        error={formik.touched.companySize && Boolean(formik.errors.companySize)}
                      >
                        <MenuItem sx={{fontSize:14}} value="" disabled>
                            Select company size
                        </MenuItem>
                        {Object.values(employeeSize).map((data: any) => (
                            <MenuItem key={data} sx={{fontSize:14}} value={data}>{data}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Box>

                  <Box className="element">
                    <div className="icon-wrapper">
                      <AccountCircleOutlinedIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Name
                      </InputLabel>
                      <TextField
                        id="personName"
                        name="personName"
                        type="text"
                        size="small"
                        placeholder="Name"
                        variant="outlined"
                        value={formik.values.personName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.personName &&
                          Boolean(formik.errors.personName)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>

                  <Box className="element">
                    <div className="icon-wrapper">
                      <EmailOutlinedIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Email
                      </InputLabel>
                      <TextField
                        id="email"
                        name="email"
                        type="text"
                        size="small"
                        placeholder="Email"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>
                  {(formik.touched.email && formik.errors.email) && (
                        <FormHelperText id='name-error-text' className="error_message">{formik.errors.email}</FormHelperText>
                      )}
                  <Box className="element">
                    <div className="icon-wrapper">
                      <PhoneOutlinedIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Phone
                      </InputLabel>
                      <TextField
                        id="contact"
                        name="contact"
                        type="text"
                        size="small"
                        placeholder="Phone"
                        variant="outlined"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.contact &&
                          Boolean(formik.errors.contact)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>
                  {(formik.touched.contact && formik.errors.contact) && (
                        <FormHelperText id='name-error-text' className="error_message">{formik.errors.contact}</FormHelperText>
                      )}
                  <Box className="element">
                    <div className="icon-wrapper">
                      <LocationOnOutlinedIcon sx={{ color: "#9F2223" }} />
                    </div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Address
                      </InputLabel>
                      <TextField
                        id="address"
                        name="address"
                        type="text"
                        size="small"
                        placeholder="Address"
                        variant="outlined"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>

                  <Box className="element">
                    <div
                      className="icon-wrapper"
                      style={{ border: "none" }}
                    ></div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        City
                      </InputLabel>
                      <FormControl
                        className="city_dropdown"
                        variant="standard"
                        sx={{ width: "168px", alignItems: "center" }}
                      >
                        <Autocomplete
                          disablePortal
                          id="city-dropdown"
                          onInputChange={(event: any, newInputValue) => {
                            setCityInputValue(newInputValue);
                          }}
                          size="small"
                          options={cities}
                          filterOptions={(x) => x}
                          sx={{ width: "100%" }}
                          value={formik.values.city ? formik.values.city : null}
                          getOptionLabel={(option: any) =>
                            typeof option === "string" ? option : option.city +", "+ option.state
                          }
                          onChange={(event: any, newValue) => {
                            handleCityChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="city"
                              className="cityDroddownInput"
                              placeholder="Search city"
                              error={
                                formik.touched.city &&
                                Boolean(formik.errors.city)
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        State
                      </InputLabel>
                      <TextField
                        id="state"
                        name="state"
                        type="text"
                        size="small"
                        placeholder="State"
                        variant="outlined"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        fullWidth
                        disabled
                        className="disabled__input"
                      />
                    </Grid>
                  </Box>
                  <Box className="element">
                    <div
                      className="icon-wrapper"
                      style={{ border: "none" }}
                    ></div>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Country
                      </InputLabel>
                      <TextField
                        id="country"
                        name="country"
                        type="text"
                        size="small"
                        placeholder="Country"
                        variant="outlined"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        fullWidth
                        disabled
                        className="disabled__input"
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <InputLabel
                        size="normal"
                        className="input__label"
                        focused
                        required
                      >
                        Zipcode
                      </InputLabel>
                      <TextField
                        id="zipcode"
                        name="zipcode"
                        type="text"
                        size="small"
                        placeholder="Zipcode"
                        variant="outlined"
                        value={formik.values.zipcode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.zipcode &&
                          Boolean(formik.errors.zipcode)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Box>
                </Grid>
                <TermsOfService handleChange={handleChange} fontSize={"14px"}/>
                {isError() && (
                  <Box sx={{ color: "red", ml: "50px" }}>
                    Please fill all mandatory fields before proceed.
                  </Box>
                )}
                {termsAndPolicyError && (
                  <Box sx={{ color: "red", ml: "50px" }}>
                    Please agree to the Terms Of Service and Privacy Policy.
                  </Box>
                )}
                <div className="action__btn">
                  <Button variant="contained" disabled={showLoader} type="submit">
                    Proceed{" "}
                    <ArrowForwardOutlinedIcon
                      fontSize="medium"
                      sx={{ marginLeft: "10px" }}
                    />
                  </Button>
                </div>
                <div className="remember__text">
                  <span className="remember__text">
                    Already have an account?
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <a href={`/login`}>
                    Login
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showLoader && (
        <div className="no--content">
          <CircularProgress className="custom__spinner" />
        </div>
      )}
      <AppFooter/>
      <ThankyouPopupComponent 
        open={openPopup}
        requestId={requestId}
        msg="Requested quote of the plan is sent on entered email address."
        onClose={handlePopupClose}
      />
    </>
  );
};

export default CreateOrganization;
